import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import img1 from "../../Assets/delivery-app.jpg";
import List from "../../Assets/restaurant-app.jpg";
import Products from "./Products";
import { AppContext } from "../../Context/AppContext";
import * as Bs from "react-icons/bs";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import PackageSkelton from "../../Components/PackageSkelton";
import ProductSkelton from "../../Components/ProductSkelton";

function ViewProducts() {
  const { id } = useParams();
  const [productData, setProductData] = useState(null);
  const { productList, productLoading } = useContext(AppContext);

  useEffect(() => {
    getProductDetails();
  }, [productList]);

  const getProductDetails = () => {
    if (!id || !productList) return;

    const getSingleProduct = productList.find((x) => x?.pId == id);
    setProductData(getSingleProduct);
  };
  console.log("data", productData);
  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container">
          {!productLoading ? (
            <>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-lg-6 mb-3">
                  <div className="card">
                    <h4 className="header text-center">
                      {productData?.productName ?? "N/A"}
                    </h4>
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="image-profile">
                          <img
                            src={
                              productData?.profilephoto != null
                                ? process.env.REACT_APP_AWS_IMAGE_URL +
                                  "products/" +
                                  productData?.profilephoto
                                : img1
                            }
                            alt=""
                            className="card-image"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12">
                        <div className="admin-data">
                          <ul className="support-service">
                            <li>
                              <h6>
                                Available Platforms :{" "}
                                {productData != null &&
                                  Array.isArray(productData?.worksOn) &&
                                  productData?.worksOn.map((platform) => {
                                    if (platform === "ANDROID") {
                                      return (
                                        <i key={platform}>
                                          <Bs.BsAndroid />
                                        </i>
                                      );
                                    }
                                    if (platform === "WINDOWS") {
                                      return (
                                        <i key={platform}>
                                          <Bs.BsWindows />
                                        </i>
                                      );
                                    }
                                    if (platform === "WEB") {
                                      return (
                                        <i key={platform}>
                                          <Bs.BsGlobe />
                                        </i>
                                      );
                                    }
                                    if (platform === "iOS") {
                                      return (
                                        <i key={platform}>
                                          <Bs.BsApple />
                                        </i>
                                      );
                                    }
                                  })}{" "}
                              </h6>
                            </li>

                            <li>
                              <h6>
                                Price per Month :{" "}
                                <span>£{productData?.price_monthly}</span>
                              </h6>
                            </li>
                            <li>
                              <h6>
                                Price per Year :{" "}
                                <span>£{productData?.price_yearly}</span>
                              </h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="p-3 m-3 des_">
                        <h6 className="head-des">
                          {" "}
                          Description :
                          <span className="">
                            {productData?.description ?? "N/A"}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-lg-6">
                  <div className="card images-div">
                    <div className="p-3 m-3">
                      <h4 className="text-center mb-3">Related Images</h4>
                      <div className="row">
                        {productData != null &&
                          productData.photos != null &&
                          Array.isArray(productData.photos) &&
                          productData.photos.map((images, index) => {
                            return (
                              <div
                                key={index}
                                className="col-md-4 p-3 text-center"
                              >
                                <div className="image-row">
                                  <img
                                    src={
                                      images != null
                                        ? process.env.REACT_APP_AWS_IMAGE_URL +
                                          "products/" +
                                          images?.filename
                                        : img1
                                    }
                                    alt=""
                                    className="image-size"
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row mt-3">
                  <div className="col-md-10">
                    {productData?.features.length !== 0 && <h5>Features</h5>}

                    {!productLoading ? (
                      productData != null &&
                      productData?.features != null &&
                      Array.isArray(productData?.features) &&
                      productData?.features.map((featureGroup, index) => (
                        <div className="border" key={index}>
                          <div className="col-md-12 col-lg-12 col-sm-12 border-bottom">
                            <h6 className="feature_bold">
                              {featureGroup.groupName}
                            </h6>
                          </div>
                          <div className="col-md-12 col-sm-12">
                            {Array.isArray(featureGroup.features) &&
                              featureGroup.features.map((feature, subIndex) => (
                                <div key={subIndex} className="row p-1">
                                  <div className="col-md-3 ">
                                    <p className="main-feature">
                                      {feature?.featureName}
                                    </p>
                                  </div>
                                  <div className="col-md-9">
                                    <p className="feature-des">
                                      {feature?.desc}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))
                    ) : (
                      <>loading</>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ProductSkelton count={1} />
          )}
        </div>
      </Sidebar>
    </div>
  );
}

export default ViewProducts;
