import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { Badge } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import * as Bi from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { AppContext } from "../../Context/AppContext";
import Utils from "../../Utils/utils";

function CustomisedInvoice() {
  const navigate = useNavigate();
  const [viewModal, setViewModal] = useState(false);
  const {
    sellerList,
    cancelInvoice,
    getAllInvoices,
    invoiceList,
    contractLoading,
  } = useContext(AppContext);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [productData, setProductData] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  console.log(invoiceList, "invoiceList");
  // const handleInvoice = () => {
  //   Modal.info({
  //     title: "Select One among the Following",
  //     content: (
  //       <div>
  //         <p>
  //           Generate Invoice From a Lead{" "}
  //           <button
  //             className="exis_btn"
  //             onClick={() => {
  //               navigate("/leads");
  //               Modal.destroyAll();
  //             }}
  //           >
  //             <Bs.BsArrowRight className="animated-arrow" />
  //           </button>
  //         </p>
  //         <p className="mt-3 mb-3">
  //           Generate Invoice For an Existing Merchant{" "}
  //           <button
  //             className="exis_btn"
  //             onClick={() => {
  //               navigate("/generate-invoice");
  //               Modal.destroyAll();
  //             }}
  //           >
  //             <Bs.BsArrowRight className="animated-arrow" />
  //           </button>
  //         </p>
  //       </div>
  //     ),
  //   });
  // };
  const ok = () => {
    setViewModal(false);
  };
  const handlePayment = (data) => {
    if (!data) return;
    sessionStorage.setItem("updatePayment", JSON.stringify(data));
    navigate("/payment-status");
  };
  const cancel = () => {
    setViewModal(false);
  };

  const handleView = (data) => {
    setSelectedInvoice(data);
    setViewModal(true);
  };
  useEffect(() => {
    if (!selectedInvoice) return;
    else {
      console.log(selectedInvoice, "sel");
      const data = selectedInvoice?.productDetails;
      const parsedData = JSON.parse(data);
      setProductData(parsedData[0]);
    }
  }, [selectedInvoice]);

  const ConfirmDelete = async (data) => {
    const id = data?.invoiceId;
    if (!id) return;
    await cancelInvoice(id, {
      onSuccess: async (res) => {
        toast.success("Invoice Cancelled Successfully!");
        await getAllInvoices();
      },
      onFailed: (err) => {
        toast.error(err.errormessage);
      },
    });
  };
  console.log(productData, "productData");
  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Sidebar>
        <Modal
          open={viewModal}
          onOk={ok}
          onCancel={cancel}
          width={1200}
          footer={null}
        >
          {selectedInvoice?.status == "cancel" ? (
            <h4 className="cancelled text-center m-3">
              Cancelled Invoice Details
            </h4>
          ) : (
            <h4 className="text-center"> Invoice Details</h4>
          )}

          <div className="card p-3 m-3">
            {/* <div className="col">
              {" "}
              {selectedInvoice?.status == "cancel" && (
                <div className="cancelled">Cancelled Invoice</div>
              )}
            </div> */}
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Invoice Number</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.invoiceNum ?? "N/A"}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Customer Name</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.cust_name ?? "N/A"}
                />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Customer Email</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.cust_email ?? "N/A"}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Invoice Date</small>
                <br />
                <input
                  className="form-control"
                  value={Utils.formatDate(
                    selectedInvoice?.invoice_date ?? "N/A"
                  )}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Due Date</small>
                <br />
                <input
                  className="form-control"
                  value={Utils.formatDate(selectedInvoice?.due_date ?? "N/A")}
                />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Invoice Total</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.total ?? "N/A"}
                />
              </div>
              {/* <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Subscription</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.subscription ?? "N/A"}
                />
              </div> */}
              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Method</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.method ?? "N/A"}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Billing Address</small>
                <br />
                <textarea
                  className="form-control"
                  value={selectedInvoice?.billingAddress ?? "N/A"}
                />
              </div>
              {/* <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Recurring Type</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.recurring_type ?? "N/A"}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Recurring Limit</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.recurring_limit ?? "N/A"}
                />
              </div> */}
            </div>

            <div className="row mt-3">
              <b>Product Details</b>
              {selectedInvoice?.productDetails != null && (
                <>
                  {JSON.parse(selectedInvoice?.productDetails).map(
                    (product, index) => (
                      <div className="col-md-4 col-sm-6 mt-3 mb-3" key={index}>
                        <div className="card p-3">
                          <p>
                            <strong>Package Name:</strong> {product.productName}
                            <br />
                            {productData &&
                              productData?.services &&
                              productData?.services?.length != 0 &&
                              productData?.services.map((data, index) => {
                                return (
                                  <>
                                    <strong>
                                      {data?.courseHead ?? "N/A"} 
                                    </strong>

                                    <br />
                                  </>
                                );
                              })}
                            <strong>Discount:</strong> {product.discount}
                            <br />
                            <strong>Tax:</strong> {product.tax}
                            <br />
                            <strong>Total:</strong> {selectedInvoice?.total}
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          </div>
        </Modal>

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <h6 className="gen_in">Invoices</h6>
            {/* <div className="gen-right">
              <button className="inv_gen_btn" onClick={handleInvoice}>
                Generate Invoice 
              </button>
            </div> */}
          </div>
        </div>

        <div className="row">
          {contractLoading ? (
            <p>Loading....</p>
          ) : (
            <>
              <div className="col-md-12 col-lg-12 mb-3 col-sm-12">
                {/* <h6 className="mt-3 mb-3">Previous Invoices</h6> */}
                <div className="card p-3 overflow">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Client Name</th>
                        <th scope="col">Client Email</th>
                        <th>Invoice Date</th>
                        <th>Due Date</th>
                        <th className="text-center">Status</th>
                        <th scope="col">Amount</th>
                        <th scope="col" className="text-center">
                          Action
                        </th>
                        {/* <th scope="col" className="text-center">
                    Action
                  </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceList != null &&
                        Array.isArray(invoiceList) &&
                        invoiceList.map((data, index) => {
                          console.log(data);
                          return (
                            <>
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  {data?.cust_name ?? "N/A"}
                                  {/* {data.status == "cancel" && (
                                    <span className="cancelled">Cancelled</span>
                                  )} */}
                                </td>
                                <td>{data?.cust_email ?? "N/A"}</td>
                                <td>
                                  {Utils.formatDate(
                                    data?.invoice_date ?? "N/A"
                                  )}
                                </td>
                                <td>
                                  {Utils.formatDate(data?.due_date ?? "N/A")}
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {data.status != "cancel" ? (
                                    <span
                                      className={
                                        data?.payement_status == "paid"
                                          ? "fp-badge success"
                                          : data?.payement_status ==
                                            "partially-paid"
                                          ? "fp-badge info"
                                          : "fp-badge pending"
                                      }
                                      style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {data?.payement_status ?? "N/A"}
                                    </span>
                                  ) : (
                                    <>
                                      <span className="fp-badge failed">
                                        Cancelled
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>{data?.total ?? "N/A"}</td>
                                <td className="text-center position-relative">
                                  <div className="btn-toggle">
                                    <button
                                      className="one-btn"
                                      onClick={() => handleView(data)}
                                    >
                                      View
                                    </button>
                                    <button
                                      className="two-btn"
                                      onClick={() => toggleDropdown(index)}
                                    >
                                      <Md.MdOutlineArrowDropDown />
                                    </button>
                                  </div>
                                  {openDropdownIndex === index && (
                                    <ul
                                      className="table-mgt"
                                      style={{ fontSize: "12px" }}
                                      role="menu"
                                    >
                                      {/* <li>
                                        <button
                                          className="btn"
                                          onClick={() => handleView(data)}
                                        >
                                          View
                                        </button>
                                      </li> */}
                                      {data.status != "cancel" && (
                                        <li>
                                          <button
                                            className="btn mb-0 pb-0"
                                            onClick={() => handlePayment(data)}
                                          >
                                            Update Payment Status
                                          </button>
                                        </li>
                                      )}
                                      <li>
                                        {data.status != "cancel" && (
                                          <button
                                            className="btn"
                                            onClick={() => ConfirmDelete(data)}
                                          >
                                            Cancel Invoice
                                          </button>
                                        )}
                                      </li>
                                      {data.file !== null &&
                                        data.file !== "" && (
                                          <li className="pt-2">
                                            <a
                                              className="ref"
                                              href={
                                                process.env
                                                  .REACT_APP_AWS_IMAGE_URL +
                                                "invoices/" +
                                                data?.file
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{
                                                fontWeight: "300 !important",
                                              }}
                                            >
                                              {" "}
                                              Download
                                            </a>
                                          </li>
                                        )}
                                      <br />
                                    </ul>
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </Sidebar>
    </div>
  );
}

export default CustomisedInvoice;
