import React from "react";
import Sidebar from "../../../Components/Sidebar";
import * as Bs from "react-icons/bs";
import * as Bi from "react-icons/bi";
import * as Ai from "react-icons/ai";
import * as Md from "react-icons/md";
import { useNavigate } from "react-router-dom";

function ViewReport() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/salesreport");
  };
  return (
    <div className="conatiner-fluid">
      <Sidebar>
        <div className="container">
          <div className="d-flex" onClick={goBack}>
            <i className="back-ar">
              <Bs.BsArrowLeft />
            </i>
            <p className="back-ar">Back</p>
          </div>
          <h6 className="head-rep">On-page Audit Result</h6>
          <br />
          <p className="head-para">
            Identify technical and content optimization issues that could hurt
            your rankings.
          </p>
          <div className="">
            <div className="card p-3">
              <div className="row">
                <div className="col-md-5">
                  <p className="head-name">Monthly Audit Report Details</p>
                </div>
                <div className="col-md-3">
                  <p className="head-dt">Jul 7, 2022 : 5.00 am</p>
                </div>
                <div className="col-md-2">
                  <button className="redo mb-2">
                    <div className="d-flex">
                      <i>
                        <Bi.BiSync />
                      </i>
                      <p>Redo Audit</p>
                    </div>
                  </button>
                </div>
                <div className="col-md-2 mx-auto">
                  <button className="export">
                    <div className="d-flex">
                      <i>
                        <Bs.BsFiletypePdf />
                      </i>
                      <p>Export to PDF</p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="card p-3 mt-3">
              <p className="head-name">Test Results</p>
              <div className="row">
                <div className="col">
                  <div className="d-flex">
                    <p className="head-tab">
                      <i className="rounded-add">
                        <Md.MdAddCircle />
                      </i>{" "}
                      Passed : <span>58</span>
                    </p>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div className="d-flex">
                    <p className="head-tab">
                      <i className="rounded-pass">
                        <Bi.BiSolidCheckCircle />
                      </i>{" "}
                      Passed : <span>58</span>
                    </p>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div className="d-flex">
                    <p className="head-tab">
                      <i className="rounded-fail">
                        <Ai.AiFillCloseCircle />
                      </i>{" "}
                      Passed : <span>58</span>
                    </p>
                  </div>
                </div>
                <div className="col">
                  {" "}
                  <div className="d-flex">
                    <p className="head-tab">
                      <i className="rounded-war">
                        <Ai.AiFillExclamationCircle />
                      </i>{" "}
                      Passed : <span>58</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-3 mt-3">
              <div className="flexed">
                <p className="head-name">Core Web Vital Assessment</p>
              </div>
              <div className="data-rep">
                <table class="table">
                  <tbody>
                    <tr>
                      <th>First Content Paint</th>
                      <td className="ext-time">960ms</td>
                      <th>Time to Interactive</th>
                      <td className="ext-time">1.38 s</td>
                    </tr>
                    <tr>
                      <th>Speed Index</th>
                      <td className="ext-time">1.62 s</td>
                      <th>Total Blocking Name</th>
                      <td className="ext-time">0.5s</td>
                    </tr>
                    <tr>
                      <th>Largest Contentful Paint</th>
                      <td className="ext-time">1.62 s</td>
                      <th>Cumulative Layout Shift</th>
                      <td className="ext-time">0.005062s</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default ViewReport;
