import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import * as Bi from "react-icons/bi";
import LeadByAdmin from "../../Components/Leads/LeadByAdmin";

function LeadList() {
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const { allSellerEnquiries } = useContext(AppContext);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [sellerData, setSellerData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    fetchSellerDetails();
    // checkAddEnquiryPrivilege();
  }, [allSellerEnquiries]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpenDropdownIndex(-1);
    }
  };

  const fetchSellerDetails = () => {
    if (!allSellerEnquiries) return;
    const sellerDetailsArray = allSellerEnquiries?.map((data) => ({
      sellerDetails: data.sellerDetails,
      pceId: data.pceId,
    }));
    setSellerData(sellerDetailsArray);
  };

  const filteredEnquiries = {
    leads: allSellerEnquiries.filter((enquiry) => enquiry.byAdmin === 1), 
    //&& enquiry.cancelStatus === 0  //!removed from the condition above
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container nav-for-tab">
          <div className="row mb-3 mt-3">
            <div className="col-md-4">
              <h6 className="">Customer's List </h6> 
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="search-bar-div">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="sr-ch"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <button className="search-btn">
                    <i>
                      <Bi.BiSearch />
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <button
                className="fp-add add"
                onClick={() => navigate("/add-enquiry")}
              >
                + Add
              </button>
            </div>
          </div>
        </div>
        <LeadByAdmin
          wrapperRef={wrapperRef}
          filteredEnquiries={filteredEnquiries}
          searchTerm={searchTerm}
        />
      </Sidebar>
    </div>
  );
}

export default LeadList;
