import React, { useContext, useEffect } from "react";
import { Fragment } from "react";
import * as Md from "react-icons/md";
import * as Fi from "react-icons/fi";
import * as Bs from "react-icons/bs";
import "../../Style/Style.css";
import Pakwaan from "../../Assets/pakawaanlogoo.png";
import { useRef } from "react";
import Signature from "../../Assets/signature.png"
import SignaturePad from "../../Components/SignaturePad";
import { useState } from "react";
import { Modal } from "antd";
import * as Gr from "react-icons/gr";
import * as Rx from "react-icons/rx";
import { FileUploader } from "react-drag-drop-files";
import * as Io from "react-icons/io5";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import { AppContext } from "../../Context/AppContext";

function ClientContractTemp() {
  const uploadRef = useRef();
  const pdfRef = useRef();
  const { fetchContractData,
    clientLoading,sendContract}=useContext(AppContext)
  const { id, contractId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [signModal, setSignModal] = useState(false);
  const [signURL, setSignURL] = useState([]);
  const [imgSrc, setImgSrc] = useState(null);
  const [infoModal, setInfoModal] = useState(false);
  const [expiryStatus, setExpiryStatus] = useState(false);
  const [uploader, setUploader] = useState(false);
  const [file, setFile] = useState(null);
  const [contractData,setContractData]=useState(null)
  const uploadFileTypes = ["PDF"];

  const dealId = atob(id);
  
  const contId = atob(contractId);
  console.log(contId,"deal");
  const handleSaveSign = (sign) => {
    if (!sign) return;
    setSignURL(sign);
  };
  useEffect(() => {
    // if(!propId || !contId) return;
    let payload = {
      dealId,
      contractId: contId,
    };
    const fetchData = async () => {
      await fetchContractData(payload, {
        onSuccess: (res) => {
          setContractData(res.data);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (!contractData) return;

    if (contractData.expired === 1) {
      setExpiryStatus(true);
    } else {
      setExpiryStatus(false);
    }

    const userData = contractData?.customersDetails;
    
    if (userData && userData.length === 0) return;
    let details = null;
    details = userData && userData.length !== 0 && JSON.parse(userData);
    setUserDetails(details);
  }, [contractData]);
  const handleClear = (value) => {
    setSignURL(value);
    setImgSrc(null);
  };
  const handleImgSource = (file) => {
    const reader = new FileReader();

    reader.onload = () => {
      setImgSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleUpload = () => {
    uploadRef.current.click();
  };
  const removeClearArea = () => {
    const elementsToExclude = document.querySelectorAll(".close_sign");
    elementsToExclude.forEach((element) => {
      element.style.display = "none";
    });
  };
  const excludeElementsFromPDF = () => {
    const elementsToExclude = document.querySelectorAll(".exclude-from-pdf");
    elementsToExclude.forEach((element) => {
      element.style.display = "none";
    });
  };
  const handleDownloadPdf = async () => {
    if (imgSrc === null) {
      excludeElementsFromPDF();
    }
    removeClearArea();

    const elements = document.querySelectorAll(".contract-page");

    elements.forEach((elem) => {
      elem.style.setProperty("border", "none", "important");
    });


    const filename = "FOODPAGE-CONTRACT" + contractId;

    //! using react-to-pdf
    // await generatePDF(pdfRef, options, { filename: filename })
    //   .then((res) => console.log(res))
    //   .catch((err) => console.error(err));

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
    });

    await doc.html(pdfRef.current, {
      callback: function (pdf) {
        pdf.save(filename + ".pdf");
      },
      html2canvas: { scale: 0.34 },
    });

    setInfoModal(false);

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const taxAmount =
    (contractData?.price * contractData?.quantity * contractData?.tax) / 100;
  let companyData;

  if (contractData?.userDetails) {
    try {
      companyData = JSON.parse(contractData.userDetails);
      console.log(companyData, "companyData");
    } catch (error) {
      console.error("Error parsing company data:", error);
    }
  } else {
    console.error("User details are undefined or null");
  }
  
  return (
    <div>
      <Modal open={uploader} onCancel={() => setUploader(false)}>
        {file != null ? (
          <Fragment>
            <div className="upload_data">
              <span>{file?.name ?? "N/A"}</span>
              <button
                type="button"
                className="close_btnn"
                onClick={() => setFile(null)}
              >
                <Io.IoCloseCircleSharp />
              </button>
            </div>
            <button
              type="button"
              className="submit_btnn"
            //   onClick={sendUploadedFile}
            //   disabled={contractLoading}
            >
              Submit
            </button>
          </Fragment>
        ) : (
          <FileUploader
            types={uploadFileTypes}
            handleChange={(file) => setFile(file)}
          />
        )}
      </Modal>
      {/* information Modal */}
      <Modal
        open={infoModal}
        onCancel={() => setInfoModal(false)}
        footer={false}
      >
        <h3 className="info_header">
          <i>
            <Io.IoInformationCircleOutline />
          </i>{" "}
          before you download..
        </h3>
        <br />
        <div className="info-conts">
          <p>
            You can download the contract, sign it, and send it to our official
            email address, <a href="mail@ferns-It.com" className="mail_link">mail@ferns-It.com</a>
          </p>
          <p>
            <i>
              The downloaded document doesn't comes up with your digital
              signature.
            </i>
          </p>
          <p>* Please make sure to sign it before you send us!</p>
        </div>
        <br />
        <a
          className="download-btn2"
          //   href={
          //     process.env.REACT_APP_AWS_IMAGE_URL +
          //     "contract-docs/" +
          //     contractData?.document
          //   }
          target="_blank"
        >
          <Md.MdOutlineFileDownload /> <span className="ps-2">Download</span>
        </a>
      </Modal>
      {/* <div
        className={
          !expiryStatus ? "wrapper_overlay disable" : "wrapper_overlay"
        }
      >
        <div className="instruction_layout">
          <h3 className="text-center">This session is expired!</h3>
          <br />
          <p>
            Looks like you've already completed this task. So you can download
            the contract here. With easy access to the document, you can review
            obligations and deadlines effortlessly. It symbolizes progress and
            marks the culmination of discussions. Downloading signifies a
            pivotal moment in your journey, signaling readiness to move forward.
            Seize the opportunity and embark confidently on the path ahead.
          </p>
          <br />
          <div className="btn-grp">
            <button
              type="button"
              className="download-btn2"
              onClick={handleDownloadPdf}
            >
              <Md.MdOutlineFileDownload />{" "}
              <span className="ps-2">Download</span>
            </button>

            <button
              type="button"
              className="go-home mt-2"
              onClick={() =>
                (window.location.href = "https://partner.foodpage.co.uk/")
              }
            >
              <Io.IoHomeOutline /> Home
            </button>
          </div>
        </div>
      </div> */}


      <div className="top-bar" id="top-bar">
        <div className="container position-relative">
          <div className="btn-grps d-flex">
            <button
              type="button"
              className="download-btn"
              onClick={() => setInfoModal(true)}
            //   disabled={contractLoading}
            >
              <i>
                {" "}
                <Md.MdOutlineFileDownload />
              </i>
            </button>
            <button
              type="button"
              className="send-btn-contract"
            //   onClick={sendContractData}
            //   disabled={contractLoading}
            >
              <Fragment>
                <Fi.FiSend /> <span className="ps-2">Send</span>
              </Fragment>
              {/* {!contractLoading ? (
                <Fragment>
                  <Fi.FiSend /> <span className="ps-2">Send</span>
                </Fragment>
              ) : (
                "Loading..."
              )} */}
            </button>
            <button
              type="button"
              className="upload-btn"
              onClick={() => setUploader(true)}
            //   disabled={contractLoading}
            >
              <Fragment>
                <Bs.BsCloudUpload /> <span className="ps-2">Upload</span>
              </Fragment>
              {/* {!contractLoading ? (
                <Fragment>
                  <Bs.BsCloudUpload /> <span className="ps-2">Upload</span>
                </Fragment>
              ) : (
                "Loading..."
              )} */}
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={signModal}
        onCancel={() => setSignModal(false)}
        style={{ overflow: "hidden" }}
      >
        <h4>Signature</h4>
        {/* <SignatureCanvas
          penColor="blue"
          ref={signRef}
          canvasProps={{
            className: "signatureCanvas",
          }}
        /> */}

        <SignaturePad
          onChange={handleSaveSign}
          onClear={handleClear}
          isDrawing={signURL}
          setIsDrawing={setSignURL}
          handleClear={handleClear}
          setImgSrc={setImgSrc}
          setSignModal={setSignModal}
        />
      </Modal>


      <div className="container-fluid position-relative">
        <h4 className="text-center head mt-3">Contract Signing Agreement</h4>
        <div
          className="contract-page-main mb-3 col-md-12 col-lg-12 col-sm-12 mx-auto"
          ref={pdfRef}
          style={{ fontSize: "10px" }}
        >
          <div className="mt-3">
            <div className="logo-contract">
              <img className="logo-t p-2" src={Pakwaan} />
              <br />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="data-cont-content">
                  {/* <b>{userDetails?.contractData?.merchantCompanyName ?? "N/A"}</b>
              <br /> */}
                  <small>
                    {/* {userDetails?.contractData?.companyName ?? "N/A"} */}
                  </small>
                  <br />
                  {/* <small>{userDetails?.contractData?.address ?? "N/A"}</small>
                  <br /> */}
                  {/* <small>{userDetails?.contractData?.phone ?? "N/A"}</small> */}
                  <br />

                  <div className="row">
                    {/* <small>{userDetails?.contractData?.email}</small> */}
                    <small className="-rt">
                      Date :
                      <b>
                        {" "}
                        {/* {userDetails?.contractData?.contractDate
                          ? Utils.formatDate(
                              userDetails?.contractData?.contractDate
                            )
                          : "N/A"} */}
                      </b>
                    </small>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="data-cont">
                  <small>
                    {userDetails?.contractData?.merchantCompanyName ?? "N/A"}
                  </small>
                  <br />
                  <p className="mer-add">
                    {userDetails?.contractData?.merchantAddress ?? "N/A"}
                  </p>
                  <small>
                    {" "}
                    {"+" + userDetails?.contractData?.merchantPhone ?? "N/A"}
                  </small>
                </div>
              </div> */}
            </div>
            <div className="contract-main">
              <p className="contract_details-main">
                This agreement is established on{" "}
                <b>
                  {" "}
                  {/* {contractData?.contractDate
                    ? Utils.formatDate(userDetails?.contractData?.contractDate)
                    : "N/A"} */}
                </b>{" "}
                between{" "}
                {/* <b> {contractData?.companyName ?? "N/A"}</b>, and */}
                its principal office situated at
                {/* {" " + userDetails?.contractData?.address ?? "N/A"}, hereinafter */}
                referred to as "Company".
                <p className="mt-1">
                  and <br />
                  <b>
                    {" "}
                    {/* {userDetails?.contractData?.merchantCompanyName ?? "N/A"} */}
                  </b>
                  , having its principal office at{" "}
                  {contractData?.userDetails?.merchantAddress ?? "N/A"},
                  hereinafter referred to as "Merchant". <br />
                </p>
                <div className="mt-3"></div>
                <p className="mt-3 mb-3">and</p>
                <b>Purpose:</b>
                <div className="mb-3"></div>
                The purpose of this agreement is to formalise the terms and
                conditions under which both parties agrees,
                <br />
                <div className="mt-3"></div>
                <h6>
                  <b>Terms and Conditions:</b>
                </h6>
                <div className="mb-3"></div>
                <ul className="list-cont">
                  <li>
                    <b>Product Details:</b> The parties hereby agree on the
                    specific products to be supplied, including quantities,
                    specifications, and quality standards.
                  </li>
                  <li>
                    <b>Price and Payment: </b>The price per unit and total
                    payment terms are as follows: Merchant shall pay
                    <b>
                      {" "}
                      {contractData?.currency}
                      {contractData?.total ?? "N/A"}
                    </b>
                    {" "}
                    to Ferns IT Consultancy for the supplied products.
                  </li>
                  <li>
                    <b>Quality Assurance:</b> The Merchant agrees to adhere to
                    all applicable quality standards and regulations in the
                    production and supply of the Ferns IT Consultancy products.
                  </li>
                  <li>
                    <b> Product Duration:</b> The Merchant acknowledges and
                    agrees that the duration{" "}
                    <strong>{contractData?.startDate}</strong> to{" "}
                    <strong>{contractData?.endDate}</strong>
                    {" "} of product usage
                    shall be as specified in this agreement, building upon the
                    terms established in the previous Deal.
                  </li>

                  <li>
                    <b> Confidentiality:</b> Both parties agree to maintain the
                    confidentiality of any proprietary or sensitive information
                    disclosed during the course of this agreement, including but
                    not limited to product specifications and business
                    strategies. The Merchant agrees that the software products
                    provided under this agreement shall not be shared with any
                    other party, and both parties shall take all necessary
                    measures to protect the confidentiality and security of the
                    data exchanged.
                  </li>
                  {/* <li>
                    <b> Consequences of Breach</b>: In the event of a breach of
                    this confidentiality agreement related to the usage of the
                    software product, the non-breaching party reserves the right
                    to immediately terminate this agreement. Furthermore, the
                    breaching party shall be liable for any resulting damages,
                    including but not limited to financial losses and legal
                    expenses incurred by the non-breaching party.
                  </li> */}
                </ul>
              </p>
              <div style={{ height: "155px" }}></div>
              <div className="mt-3 mb-3">
                <p className="deal_head text-center">DEAL DETAILS</p>
                <div className="table_paddign">
               <table className="table table-bordered table_color" >
                    <tbody>
                    <tr>
                      <td>
                      Dinner: Minimum of{" "}
                      <input className="normal_field"
                      placeholder="..." />{" "}
                      guests @ £{" "}
                      <input className="normal_field"
                      placeholder="..." />{" "}
                      per head
                      </td>
                      <td>
                      £ ...
                      </td>
                    </tr>
                    <tr>
                      <td>
                      Soft Drinks Package @ £{" "}
                      <input className="normal_field"
                      placeholder="..." />{" "}
                      per head for{" "}
                      <input className="normal_field"
                      placeholder="..." />{" "}
                      guests
                      </td>
                      <td>
                      £ ...
                      </td>
                    </tr>
                    <tr>
                      <td>
                      Dinner: Staffing charges @ £{" "}
                      <input className="normal_field"
                      placeholder="..." />{" "}
                      per head for{" "}
                      <input className="normal_field"
                      placeholder="..." />{" "}
                      guests
                      </td>
                      <td>
                      £ ...
                      </td>
                    </tr>
                    <tr>
                      <td>
                    
                      <input className="normal_field"
                      placeholder="..." />{" "}
                      Compulsory Drink Waiters @{" "}
                      <input className="normal_field"
                      placeholder="..." />{" "}
                      per head
                      </td>
                      <td>
                      £ ...
                      </td>
                    </tr>
                    <tr>
                      <td>
                      Transportation Charges
                      </td>
                      <td>
                      £ ...
                      </td>
                    </tr>
                    <tr>
                      <td>
                      Rubbish Disposal
                      </td>
                      <td>
                      £ ..
                      </td>
                    </tr>
                    <tr>
                      <td>
                      Kitchen Equipment Hire
                      </td>
                      <td>
                      £ ...
                      </td>
                    </tr>
                    <tr>
                      <td>
                      <b>Sub Total:</b>
                      </td>
                      <td>
                      £ ...
                      </td>
                    </tr>
                    <tr>
                      <td>
                      VAT @ 20%
                      </td>
                      <td>
                      £ ...
                      </td>
                    </tr>
                    <tr>
                      <td>
                      <b>Total Package Price:</b>
                      </td>
                      <td>
                      £ ...
                      </td>
                    </tr>
                    </tbody>
                  </table>
               </div>
                {/* <div className="contract_Card-main">
                  <form className="deal_form">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Product</label>
                        <div className="form-disabled-main">
                          \fhfh
                          {contractData?.productName ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> price</label>
                        <div className="form-disabled-main">
                          0
                          {contractData?.price ?? 0}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Quantity</label>
                        <div className="form-disabled-main">
                          fhude
                          {contractData?.quantity ?? 0}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Tax(%)</label>
                        <div className="form-disabled-main">
                          09
                          {contractData?.tax ?? 0}%
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor="">Tax Amount</label>

                        <div className="form-disabled-main">
                          4895
                          {taxAmount ?? 0}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor="">Total</label>
                        <div className="form-disabled-main">
                          fsj
                          {contractData?.currency + contractData?.total ?? 0}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <label htmlFor=""> Start Date</label>
                        <div className="form-disabled-main">
                          jv
                          {contractData?.startDate ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <label htmlFor=""> End Date</label>
                        <div className="form-disabled-main">
                          fds
                          {contractData?.endDate ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Subscription Type</label>
                        <div className="form-disabled-main">
                          fd
                          {contractData?.subscription ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <p className="desc">
                          
                          {" " + companyData?.selectedDeal?.description ??
                            "N/A"}
                        </p>
                      </div>
                    </div>
                  </form>
                </div> */}

                <p className="details mt-3">
                  In witness whereof, the parties hereto have executed this
                  agreement as of the date mentioned above.{" "}
                </p>

                {/* <div className="footer_contract footer_sign">
                  <p>Contract Date: {Utils.formatDate(new Date())}</p>
                  <img src={Signature} className="signature" />
                  <p>Authorised Signature:</p>
                  <br />
                  <h6 className="">
                    <b>Company Info</b>
                  </h6>
                  <p>
                    Party Name:{" "}
                    <strong className="ps-2">JERRY FERNANDEZ</strong>
                  </p>
                  <h6>
                    Company Name: <strong className="ps-2">Foodpage</strong>{" "}
                  </h6>
                  <br />
                  <p>
                    <strong>Merchant</strong>
                  </p>
                  <div
                    className="signature-pad"
                    onClick={() => setSignModal(true)}
                  >
                    <i>
                      <Rx.RxPlus />
                    </i>
                  </div>
                  <img src={Signature} className="signature" />
                  <p>Merchant's Signature : </p>
                  <h6 className="mt-3">Merchant Name:</h6>
                  <h6 className="mt-3">Merchant address:</h6>
                </div> */}
                <br />
                <div
                  className="footer_contract footer_sign-contract"
                // style={{ marginTop: "20rem" }}
                >
                  <p>
                    <b>
                      Contract Date:
                      {/* {Utils.formatDate(
                        userDetails?.contractData?.contractDate
                      ) ?? "N/A"} */}
                    </b>
                  </p>
                  {/* <p>Title: {userDetails?.contractData?.title ?? "N/A"}</p> */}
                  <div className="row mb-3 footer-box">
                    <div className="col-md-8 col-sm-12">
                      <u>
                        <b>Company Info</b>
                      </u>
                      <p
                        className="mt-3 mb-3"
                        style={{ position: "relative", top: "20px" }}
                      >
                        {" "}
                        JERRY FERNANDEZ <br />
                        {/* {userDetails?.contractData?.companyName ?? "N/A"} */}
                      </p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      {" "}
                      <p className="authorised_sign">
                        Authorised Signature: <br />
                        <br />
                        <img src={Signature} className="signature" />
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3 mb-3 footer-box">
                    <div className="col-md-8 col-sm-12">
                      <u>
                        {" "}
                        <b>Merchant</b>
                      </u>
                      <h6 className="mt-3">
                        {/* {" " + userDetails?.contractData?.merchantCompanyName ??
                          "N/A"} */}
                        <br />({" "}
                        <small>
                          {" "}
                          {/* {" " + companyData?.selectedDeal?.name ?? "N/A"} */}
                        </small>
                        )
                      </h6>
                      <h6 className="mt-3">
                        {/* {" " + userDetails?.contractData?.merchantAddress ??
                          "N/A"} */}
                      </h6>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <p>Merchant Signature</p>
                      <div className="exclude-from-pdf">
                        {imgSrc != null ? (
                          <div className="sign-img">
                            <img src={imgSrc} alt="" />
                          </div>
                        ) : (
                          <Fragment>
                            <div
                              className="signature-pad"
                              onClick={() => setSignModal(true)}
                            >
                              <i>
                                <Rx.RxPlus />
                              </i>
                            </div>

                            <div className="or_">
                              <p>OR</p>
                            </div>
                            <div className="upload_">
                              <input
                                type="file"
                                ref={uploadRef}
                                name=""
                                id="upload_inp"
                                onChange={(e) =>
                                  handleImgSource(e.target.files[0])
                                }
                              />
                              <button
                                className="upload_btn"
                                onClick={handleUpload}
                              >
                                Upload
                              </button>
                            </div>
                          </Fragment>
                        )}

                        {imgSrc != null && (
                          <button
                            className="close_sign"
                            onClick={handleClear}
                            id="clear_btn"
                          >
                            <Gr.GrFormClose /> clear
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientContractTemp;