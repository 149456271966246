import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useEventPackages = () => {
  const [eventList, setEventList] = useState([]);
  const [eventLoading, setEventLoading] = useState(false);

  const getAllEvents = async () => {
    try {
      setEventLoading(true);
      await BaseClient.get(APIEndpoints.fetchAllEventsList, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setEventList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting data", err);
        },
      });
    } finally {
      setEventLoading(false);
    }
  };

  return {
    eventLoading,
    eventList,
    getAllEvents,
  };
};
export default useEventPackages;
