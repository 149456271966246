import React, { useContext, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router";

function AddFeature() {
  const navigate = useNavigate();
  const {
    fetchAllFeatureGroup,
    createFeatureGroup,
    featureGroupList,
    featureLoading,
    createFeature,
    fetchAllFeatures,
  } = useContext(AppContext);

  const [selectedGroupName, setSelectedGroupName] = useState("");

  const [initialState, setInitialState] = useState({
    group_name: "",
    desc: "",
    featureName: "",
    isNew: false,
  });
  const handleSelectChange = (value) => {
    setSelectedGroupName(value);
    setInitialState((prev) => ({ ...prev, group_name: value }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setInitialState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleFeature = async (e) => {
    e.preventDefault();
    const payload = {
      groupName: selectedGroupName,
      featureName: initialState.featureName,
      features: {
        desc: initialState.desc,
      },
    };
    await createFeature(payload, {
      onSuccess: async (res) => {
        toast.success("Feature Created Succsefully!");
        setTimeout(() => {
          navigate("/features");
          fetchAllFeatures();
        });
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };
  return (
    <div>
      <Sidebar>
        <div div className="container-fluid m-3">
          <Toaster position="top-center" reverseOrder={false} />
          <h6 className="head-h4 text-center">Feature Creation</h6>
        </div>
        <div className="container">
          <div className="card group-box m-3 mx-auto">
            <form
              action=""
              className="box group-box mt-3"
              onSubmit={(e) => handleFeature(e)}
            >
              <div className="mt-3">
                <>
                  <div className="form-group">
                    <>
                      <div className="col">
                        {" "}
                        <label className="form-label review-label">
                          Group Name
                        </label>
                        <select
                          name=""
                          id=""
                          className="form-control assign_salesmen"
                          value={selectedGroupName}
                          onChange={(e) => handleSelectChange(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Feature Group
                          </option>
                          {featureGroupList != null &&
                            Array.isArray(featureGroupList) &&
                            featureGroupList.map((group, index) => (
                              <option key={index} value={group.group_name}>
                                {group.group_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </>
                  </div>
                </>
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label mt-3">
                    Feature Name
                  </label>
                  <div className="review-group">
                    <input
                      className="form-control review-input"
                      type="text"
                      name="featureName"
                      value={initialState.featureName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <label className="text mt-3">Description</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    value={initialState.desc}
                    onChange={handleChange}
                    name="desc"
                  ></textarea>
                </div>
              </div>
              {featureLoading ? (
                <div className="button-div text-center mx-auto">
                  <button className="group-sub m-3" disabled>
                    Submitting....
                  </button>
                </div>
              ) : (
                <div className="button-div text-center mx-auto">
                  <button className="group-sub m-3" type="submit">
                    Submit
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default AddFeature;
