import React, { useState, useEffect, useContext } from "react";

import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/AppContext";

function NotAssigned({ id }) {
  const navigate = useNavigate();
  const {
    adminList,
    alljobRole,
    enquiryLoading,
    assignSalesman,
    fetchAllSellerEnquiries,
  } = useContext(AppContext);

  const [salesmenList, setSalesmenList] = useState([]);
  const [salesLoading, setSalesLoading] = useState(false);

  useEffect(() => {
    filterSalesMen();
  }, [adminList, alljobRole]);

  useEffect(() => {
    if (!id) return;

    setAssignedValues((prev) => ({ ...prev, pceId: id }));
  }, [id]);

  const filterSalesMen = () => {
    if (!adminList || !alljobRole) return;

    const data = adminList.filter((list) => {
      const jobRole = list.jobRoles;
      return jobRole.some((role) => role.role === "sales");
    });
    setSalesmenList(data);
  };
  console.log(salesmenList, "salesmenList");
  const [assignedValues, setAssignedValues] = useState({
    pceId: "",
    aId: "",
    remarks: "",
  });
  const handleSubmit = async (e) => {
    setSalesLoading(true);
    e.preventDefault();

    await assignSalesman(assignedValues, {
      onSuccess: async (res) => {
        setSalesLoading(false);
        await fetchAllSellerEnquiries();
        toast.success("Salesman assigned Successfully!");
      },
      onFailed: (err) => {
        setSalesLoading(false);
        // console.log("ERROR ON ASSIGNING A SALESMAN");
        toast.error("Assign Failed! Please try again");
      },
    });
  };

  console.log("adminList", adminList);
  return (
    <div>
      <div className="not_assigned_card card">
        <h6 className="not_assigned">Yet To Be Designated</h6>
        <br />
        <p className="text-center">
          <b>Assign a Salesman</b>
        </p>
        <br />
        <div className="container">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <select
                  name=""
                  id=""
                  className="form-control assign_salesmen"
                  onChange={(e) =>
                    setAssignedValues((prev) => ({
                      ...prev,
                      aId: e.target.value,
                    }))
                  }
                >
                  <option value="" disabled selected>
                    Choose a Salesmen
                  </option>
                  {salesmenList != null &&
                    Array.isArray(salesmenList) &&
                    salesmenList.map((list, index) => {
                      return <option value={list?.paId}>{list?.name}</option>;
                    })}
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <textarea
                  name="remarks"
                  type="text"
                  className="form-control"
                  placeholder="Enter Remark here"
                  id=""
                  value={assignedValues?.remarks}
                  onChange={(e) =>
                    setAssignedValues((prev) => ({
                      ...prev,
                      remarks: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <button
              type="submit"
              className="fp-button assign-btn mt-4"
              disabled={salesLoading}
            >
              {!salesLoading ? (
                "Assign Now"
              ) : (
                <ThreeDots
                  height="60"
                  width="60"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperClassName="loader_"
                />
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NotAssigned;
