import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import Select from "react-select";
import { AppContext } from "../../Context/AppContext";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import * as Bi from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Fragment } from "react";

function CreateAdmin({ jobRole }) {
  const navigate = useNavigate();

  const { alljobRole, privilageLoading, allPrivilages, cookieData } =
    useContext(AppContext);
  const { registerAdmin, loading, getAllAdminData } = useContext(AppContext);

  const [error, setError] = useState(false);
  const [singlejobRole, setSingleJobRole] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [privilagesByRole, setPrivilagesByRole] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [adminState, setAdminState] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    role: "",
    privilages: "",
    title: "",
    jobRole: [],
    // crAID:
  });

  useEffect(() => {
    privilegeCheck();
  }, [cookieData]);

  useEffect(() => {
    if (!alljobRole) return;
    setJobRoles();
  }, [alljobRole, jobRole]);

  const privilegeCheck = () => {
    if (!cookieData) return;
    const data = jwt_decode(cookieData);
    const currentRole = data.jobRole;

    if (!currentRole) return;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setAdminState((prev) => ({
        ...prev,
        role: checked ? value : "",
      }));
    } else {
      setAdminState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (value == "superadmin") {
      setAdminState((prev) => ({
        ...prev,
        privilages: "all",
      }));
    }
  };

  const setJobRoles = () => {
    const newJobRoles = alljobRole.map((jobs) => ({
      value: jobs.p_jbId,
      label: jobs.role,
    }));

    setSingleJobRole(newJobRoles);
  };

  const handlePrivilegeByRole = (selectedItem) => {
    if (!selectedItem || !Array.isArray(selectedItem)) return;

    const matchingPrivilages = [];

    selectedItem.forEach((item) => {
      const matchingPrivileges = allPrivilages.filter((privilege) => {
        const jobRoles = privilege.jobRoles;
        return jobRoles.includes(item?.value);
      });

      if (matchingPrivileges.length > 0) {
        matchingPrivileges.forEach((privilege) => {
          const privilegeExists = matchingPrivilages.some(
            (existingPrivilege) => existingPrivilege.value === privilege.ppId
          );

          if (!privilegeExists) {
            matchingPrivilages.push({
              value: privilege.ppId,
              label: privilege.previlageName,
            });
          }
        });
      } else {
        console.log("No matching privilege found for item:", item.value);
      }
    });
    setAdminState((prev) => ({
      ...prev,
      jobRole: [...selectedItem.map((e) => e.value)],
    }));
    setPrivilagesByRole(matchingPrivilages);
  };

  const handlePrivileges = (item) => {
    setAdminState((prev) => ({
      ...prev,
      privilages: item.map((e) => e.value).join(","),
    }));
  };
  const phonePattern = /^(\+\d{1,3})?(?:[0-9\-\(\)\/\.]\s?){6,15}[0-9]{1}$/;
  const handleAdmin = async (e) => {
    e.preventDefault();

    if (
      adminState.email.length === 0 ||
      adminState.password.length === 0 ||
      adminState.mobile.length === 0 ||
      adminState.name.length === 0 ||
      adminState.role.length === 0 ||
      adminState.title.length === 0 ||
      // adminState.jobRole.length === 0 ||
      adminState.privilages.length === 0
    ) {
      setError(true);
      return;
    }
    if (!phonePattern.test(adminState.mobile)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    const payload = adminState;

    await registerAdmin(payload, {
      onSuccess: async (res) => {
        setError(false);
        toast.success("Role Added Successfully!");
        await getAllAdminData();

        setAdminState({
          name: "",
          role: "",
          jobRole: "",
          privilages: "",
          mobile: "",
          email: "",
          password: "",
          title: "",
        });

        setTimeout(() => {
          navigate("/admin");
        }, 1200);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Sidebar>
        <div div className="container-fluid m-3">
          <h4 className="text-center">Create a Role</h4>
          <div className="card m-3 p-3 box mx-auto">
            <form onSubmit={handleAdmin}>
              <div className="row m-2 mt-3">
                <p className="form_sub">Personal info</p>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <label className="form-label review-label">Name</label>
                  <div
                    className={
                      error && adminState.name.length == 0
                        ? "review-group error"
                        : "review-group "
                    }
                  >
                    <input
                      type="text"
                      name="name"
                      value={adminState?.name}
                      onChange={handleChange}
                      className="review-input"
                    />
                    <i className="inp_ico">
                      <Bs.BsPersonCheck />
                    </i>
                  </div>
                  {error && adminState.name.length == 0 && (
                    <span className="error_">Name is required!</span>
                  )}
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <label className="form-label review-label">
                    Email Address
                  </label>
                  <div
                    className={
                      error && adminState.email.length == 0
                        ? "review-group error"
                        : "review-group "
                    }
                  >
                    <input
                      type="email"
                      name="email"
                      value={adminState?.email}
                      onChange={handleChange}
                      className="review-input"
                    />
                    <i className="inp_ico">
                      <Ai.AiOutlineMail />
                    </i>
                  </div>
                  {error && adminState.email.length == 0 && (
                    <span className="error_">Email is required!</span>
                  )}
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <label className="form-label review-label">Phone</label>
                  <div
                    className={
                      error && adminState.mobile.length == 0
                        ? "review-group error"
                        : "review-group "
                    }
                  >
                    <input
                      type="text"
                      name="mobile"
                      value={adminState?.mobile}
                      onChange={handleChange}
                      className="review-input"
                    />
                    <i className="inp_ico">
                      <Ai.AiOutlinePhone />
                    </i>
                  </div>
                  {error && adminState.mobile.length == 0 && (
                    <span className="error_">Phone Number is required!</span>
                  )}
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
                  <label className="form-label review-label">Password</label>
                  <div
                    className={
                      error && adminState.password.length == 0
                        ? "review-group error"
                        : "review-group "
                    }
                  >
                    <input
                      type="password"
                      name="password"
                      value={adminState?.password}
                      onChange={handleChange}
                      className="review-input"
                    />
                    <i className="inp_ico">
                      <Bi.BiLockOpen />
                    </i>
                  </div>
                  {error && adminState.password.length == 0 && (
                    <span className="error_">Password is required!</span>
                  )}
                </div>

                {/* <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
                  <label className="form-label review-label">
                    Confirm Password
                  </label>
                  <div className="review-group ">
                    <input type="text" name="name" className="review-input" />
                    <i className="inp_ico">
                      <Bi.BiLock />
                    </i>
                  </div>
                </div> */}
              </div>
              <div className="row m-2">
                <p className="form_sub">Role info</p>

                <div className="col-lg-3 col-md-4 col-sm-12">
                  <label className="form-label review-label">Designation</label>
                  <div
                    className={
                      error && adminState.title.length == 0
                        ? "review-group error"
                        : "review-group "
                    }
                  >
                    <input
                      type="text"
                      name="title"
                      value={adminState?.title}
                      onChange={handleChange}
                      className="review-input"
                    />
                    <i className="inp_ico">
                      <Ai.AiFillEdit />
                    </i>
                  </div>
                  {error && adminState.mobile.length == 0 && (
                    <span className="error_">Designation is required!</span>
                  )}
                </div>
                <div className="col-lg-2 col-md-4 col-sm-12">
                  <label className="form-label review-label">Role</label>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="role"
                      value="admin"
                      checked={adminState.role === "admin"}
                      onChange={handleChange}
                      id="admin"
                    />
                    <label class="form-check-label" for="admin">
                      Admin
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="role"
                      value="superadmin"
                      checked={adminState.role === "superadmin"}
                      onChange={handleChange}
                      id="superadmin"
                    />
                    <label class="form-check-label" for="superadmin">
                      Super Admin{" "}
                    </label>
                  </div>
                  <span className="sub_">
                    (Super Admin has all the privileges)
                  </span>

                  {error && adminState.role.length === 0 && (
                    <span className="error_">Role is required!</span>
                  )}
                </div>
                {(adminState.role == "admin" || adminState.role == "") && (
                  <Fragment>
                    <div className="col-lg-3 col-md-4 col-sm-12">
                      <label htmlFor="" className="form-label review-label">
                        Job Role
                      </label>
                      <Select
                        options={singlejobRole}
                        isMulti
                        onChange={(e) => handlePrivilegeByRole(e)}
                        name="jobRole"
                      />
                      {error && adminState.jobRole.length == 0 && (
                        <span className="error_">jobRole is required!</span>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <label htmlFor="" className="form-label review-label">
                        Privilages
                      </label>

                      <Select
                        options={privilagesByRole}
                        isMulti
                        name="privileges"
                        onChange={(e) => handlePrivileges(e)}
                      />

                      {error && adminState.privilages.length == 0 && (
                        <span className="error_">Please set privilege!</span>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
              {privilageLoading ? (
                <div className="button-div text-center mx-auto">
                  <button className="fp-button" disabled>
                    Submitting...
                  </button>
                </div>
              ) : (
                <div className="button-div text-center mx-auto">
                  <button className="fp-button" type="submit">
                    Submit
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default CreateAdmin;
