import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import { Badge } from "react-bootstrap";
import { AppContext } from "../../../Context/AppContext";
import { Popconfirm } from "antd";
import AssignedProducts from "./AssignedProducts";
import Sidebar from "../../../Components/Sidebar";
import NotAssigned from "./NotAssigned";
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import "animate.css";

import Stages from "../../../Components/Stages/Stages";
import EnquirySkelton from "../../../Components/EnquirySkelton";

function ViewEnquiry() {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    allSellerEnquiries,
    sellerEnquiriesLoading,
    cancelLead,
    fetchAllSellerEnquiries,
    enquiryLoading,
  } = useContext(AppContext);
  const [enquiryData, setEnquiryData] = useState(null);
  const [assign, setAssign] = useState(false);
  const [jobsArray, setJobsArray] = useState(null);

  useEffect(() => {
    getEnquiryDetails();
  }, [allSellerEnquiries]);

  useEffect(() => {
    const allJobQueueArray = enquiryData?.jobQueueStatus;

    setJobsArray(allJobQueueArray);
  }, [allSellerEnquiries, enquiryData]);

  const jobQueueStatusArray = enquiryData?.jobQueueStatus?.[0];

  const getEnquiryDetails = () => {
    if (!id || !allSellerEnquiries) return;

    const singleData = allSellerEnquiries?.find((x) => x?.pceId == id);
    setEnquiryData(singleData);
  };
  const cancelLeadData = async (e) => {
    const payload = {
      pceId: enquiryData?.pceId,
      cancelStatus: true,
      status: "cancelled",
    };

    await cancelLead(payload, {
      onSuccess: async (res) => {
        toast.success("Lead Cancelled!");
        await fetchAllSellerEnquiries();
      },
      onFailed: (err) => {
        toast.error("Lead Cancellation Unsuccessful!");
      },
    });
  };
  console.log(enquiryData, "data");
  return (
    <>
      <div className="container-fluid">
        <Toaster position="top-center" reverseOrder={false} />
        <Sidebar>
          <section id="content-wrapper" class="dashboard-content-wrapper">
            <>
              <div class="row">
                <div class="col-lg-12 ">
                  <h3 class="content-title mt-3 mb-3 text-center">
                    Enquiry Details
                  </h3>
                </div>
                {/* {enquiryData?.datafill === 1 && (
                  <div className="container">
                    <div
                      class="alert alert-success text-center alert-dismissible fade show"
                      role="alert"
                    >
                      Customer On-Boarded Successfully!
                      <button
                        type="button"
                        className="close-btnn"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                )} */}
                {enquiryData?.status === "cancelled" && (
                  <div className="container">
                    <div class="alert alert-danger text-center" role="alert">
                      This lead has been cancelled!
                    </div>
                  </div>
                )}
                <div class="col-md-8 col-lg-6 col-sm-12 mt-3">
                  <>
                    <>
                      <div class="dc-title">
                        <h6>Client info</h6>
                      </div>
                      <div class="dashboard-card card overflow">
                        <div class="">
                          <table class="table personal-info-table p-3 m-3">
                            <tbody>
                              <tr>
                                <td>
                                  <b>Name</b>
                                </td>
                                <td>
                                  {enquiryData?.sellerDetails?.sellerName ??
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Company Name</b>
                                </td>
                                <td>
                                  {enquiryData?.sellerDetails?.shopName ??
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Email</b>
                                </td>
                                <td>
                                  {enquiryData?.sellerDetails?.sellerEmail ??
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Mobile</b>
                                </td>
                                <td>
                                  {" "}
                                  {enquiryData?.sellerDetails?.mobile ?? "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Address1</b>
                                </td>
                                <td>
                                  {enquiryData?.sellerDetails?.address1 ??
                                    "N/A"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <>
                        {" "}
                        <AssignedProducts
                          id={id}
                          productData={
                            enquiryData?.products != null ||
                            enquiryData?.products.length != 0
                              ? enquiryData?.products
                              : []
                          }
                        />
                      </>
                    </>
                  </>
                </div>

                <>
                  <div className="col-lg-6 col-md-8 col-sm-12 mt-3">
                    <div class="dc-title">
                      <h6>
                        Initially Assigned Executive Details{" "}
                        {enquiryData?.status !== "cancelled" && (
                          <span className="right-side">
                            <Popconfirm
                              title="Decline the Lead"
                              description="Are you sure to Cancel this lead?"
                              onConfirm={cancelLeadData}
                              // onCancel={}
                              okText="Yes"
                              cancelText="No"
                              okType="danger"
                              icon={
                                <Ai.AiOutlineQuestionCircle
                                  style={{ color: "red" }}
                                />
                              }
                            >
                              <button
                                className="cancel-en"
                                disabled={
                                  enquiryLoading.action == "cancel" &&
                                  enquiryLoading.state
                                }
                              >
                                {enquiryLoading.action == "cancel" &&
                                enquiryLoading.state ? (
                                  <BeatLoader
                                    size={10}
                                    color="#fff"
                                    ariaLabel="three-dots-loading"
                                    wrapperClassName="loader_"
                                  />
                                ) : (
                                  "cancel this Enquiry ?"
                                )}
                              </button>
                            </Popconfirm>
                          </span>
                        )}
                      </h6>
                    </div>

                    {enquiryData?.aId != null ? (
                      <>
                        <div className="card" style={{ overflow: "hidden" }}>
                          <table className="table personal-info-table p-3 m-3">
                            <tbody>
                              <tr>
                                <td>
                                  <b>Employee Id</b>
                                </td>
                                <td>
                                  #{enquiryData?.adminDetails?.aId ?? "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Name</b>
                                </td>
                                <td>
                                  {enquiryData?.adminDetails?.name ?? "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Email Address</b>
                                </td>
                                <td>
                                  {enquiryData?.adminDetails?.email ?? "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Phone Number</b>
                                </td>
                                <td>
                                  {enquiryData?.adminDetails?.mobile ?? "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Remarks</b>
                                </td>
                                <td>{enquiryData?.remarks ?? "N/A"}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="card mt-2 p-3 ref_card">
                          <p style={{ marginBottom: "10px" }}>
                            <b>Enquiry Reference</b>
                          </p>
                          <p>
                            {enquiryData?.enq_ref ?? (
                              <>
                                <span className="no_ref">No References</span>
                              </>
                            )}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <NotAssigned id={id} />
                      </>
                    )}
                  </div>
                </>
              </div>
            </>

            <Stages
              allJobQueueArray={jobsArray}
              jobQueueStatusArray={jobQueueStatusArray}
              enquiryData={enquiryData}
              id={id}
              getEnquiryDetails={getEnquiryDetails}
            />
          </section>
        </Sidebar>
      </div>
    </>
  );
}

export default ViewEnquiry;
