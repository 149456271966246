import React from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";

function Stages() {
  const navigate = useNavigate();
  return (
    <div className="container-fluid">
      <div className="container-fluid">
        <Sidebar>
          <div div className="container-fluid m-3">
            <h6 className="head-h4">Product Stages</h6>
            <p>
              All Stages
              <span>
                <button
                  className="fp-add"
                  onClick={() => navigate("/add-stages")}
                >
                  + Add
                </button>
              </span>
            </p>
          </div>
          <div className="card p-3 m-3 overflow">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Stage</th>
                  <th scope="col">Description</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </Sidebar>
      </div>
    </div>
  );
}

export default Stages;
