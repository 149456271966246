import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useSupportChat = () => {
  const [chatList, setChatList] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const fetchAllChats = async () => {
    try {
      setChatLoading(true);
      await BaseClient.get(APIEndpoints.getSupportChat, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setChatList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting data", err);
        },
      });
    } finally {
      setChatLoading(false);
    }
  };
  const fetchMessages = async (payload, { onSuccess, onFailed }) => {
    try {
      setChatLoading(true);

      await BaseClient.post(APIEndpoints.getChatofTicket, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setChatLoading(false);
    }
  };

  const createChatMessage = async (
    payload,
    { onSuccess, onFailed, onProgress }
  ) => {
    try {
      setChatLoading(true);

      await BaseClient.post(APIEndpoints.addSupportMessage, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
        onProgress: onProgress,
      });
    } finally {
      setChatLoading(false);
    }
  };
  const updateStatus = async (payload, { onSuccess, onFailed }) => {};
  return {
    fetchAllChats,
    chatLoading,
    chatList,
    createChatMessage,
    fetchMessages,
  };
};
export default useSupportChat;
