export const APIEndpoints = {
  //Authenticaiton
  adminLogin: "auth/adminlogin",

  //Cookies
  getCookieData: "cookies/getadmintoken",
  deleteCookieData: "cookies/deleteadmintoken",

  //Job Role
  getAllJObRoles: "jobrole/getalljobrole",

  //Privilages
  getAllPrivilages: "privilage/getallprivilage",
  addPrivileges: "privilage/createPrivilage",
  editPrivileges: "privilage/updateprivilage",

  //admin
  addAdmin: "auth/addadmin",
  getAllAdmin: "admin/alladmins",
  editAdmin: "admin/updateadmin",
  fetchAllEnquiry: "admin/enquiry/getallenquiriesofmonth",

  //productsgetAllSellerEnquiries
  getAllProducts: "admin/product/getallproducts",
  createProduct: "admin/product/createproduct",
  deleteProduct: "product/deleteproduct",
  updateProduct: "admin/product/updateproduct",

  //Seller
  getAllSellerEnquiries: "admin/enquiry/getallenquiries",

  //Shop Categories
  getAllShopCategories: "shopcategory/getallshopcategories",

  //plans
  getAllPlans: "admin/plans/getallplans",
  createNewPackage: "admin/plans/createplans",
  deletePackageAdmin: "admin/plans/deleteplans",
  editPackages: "admin/plans/updateplans",

  //subscriber
  addSubscriber: "enquiry/adduserbyadmin",
  addLeadByAdmin: "admin/enquiry/addleadbyadmin",
  changeLeadToSeller: "admin/enquiry/addleadtoseller",
  updateLeadDetails: "admin/enquiry/updatelead",

  //seller
  getSellerDetails: "admin/seller/getallsellers",

  //enquiry
  assignEnquiry: "admin/enquiry/assignenquiry",
  updateEnquiry: "admin/enquiry/updateenquiry",

  //stages
  updateStage: "admin/jobqueue/updatejobqueuestat",
  cancelStage: "admin/enquiry/updateenquirystatus",
  createDeal: "admin/jobqueue/create-deal",
  updateDeals: "admin/jobqueue/update-deal",
  deleteDeal: "admin/jobqueue/delete-deal",
  getAllDealsofClient: "admin/jobqueue/getDealsByLeadId",
  finalizeDeal: "admin/jobqueue/finalize-deal",
  sendDeal: "admin/jobqueue/send-deal",

  //support
  getAllSupport: "ticket/getalltickets",

  //chat
  getSupportChat: "admin/supportchat/getallsupportChat",
  addSupportMessage: "admin/supportchat/createsupportChat",
  getChatofTicket: "userchat/getallchatofticket",
  updateTicketComplete: "ticket/updateTicket",

  //features
  getAllFeatures: "productfeatures/getallproductfeatures",
  editFeatureGroup: "admin/feature-groups/update-feature-group",
  deleteFeatureGroup: "admin/feature-groups/delete-feature-group",
  addFeature: "productfeatures/createproductfeatures",
  updateFeature: "productfeatures/updateproductfeatures",

  //profile
  getAdminData: "admin/viewadmin",
  updateLogin: "admin/updateadmin",

  //Contract Signing
  sendAgreement: "stepsinpurchase/sendMailStep",
  reAssign: "admin/jobqueue/reassignjodstatbyId",
  sendContractSingle: "admin/contract/send-contract-single",

  //DirectDebitApplication
  directDebit: "stepsinpurchase/direct-debit-mail",

  //Features
  getAllFeatureGroup: "admin/feature-groups/all-feature-groups",
  addFeatureGroup: "admin/feature-groups/create-feature-group",

  //chats
  createChatToken: "admin/createchattoken",

  //Proposal
  getAllProposalTemplates: "admin/proposal/get-propsals",
  sendPropsal: "admin/proposal/send-proposal",
  getProposal: "admin/proposal/get-proposalData",
  updateProposal: "admin/proposal/update-proposal",
  finalizeProposal: "admin/proposal/update-proposal-status",
  deleteProposalByAdmin: "admin/proposal/delete-proposal",
  fetchAllFinishedProposals: "admin/proposal/get-finalised-proposals",
  sendNewProposal: "admin/proposal/send-new-proposal",
  proposalListAll: "admin/proposal/fetch-all-proposals",
  createSingleProposal: "admin/proposal/create-new-proposal",
  getProposalByLeadId: "admin/proposal/get-lead-proposals",
  finalizeProposalDirect: "admin/proposal/update-proposal-status-new",
  getFinalizedProposalNew: "admin/proposal/get-finalized-proposals-new",

  //Contract
  getAllContracts: "admin/jobqueue/finalized-deals-list",
  sendContract: "admin/contract/send-contract",
  completeContract: "admin/contract/update-contract-status",
  olderContracts: "admin/contract/contract-list",
  fetchContractsByProposal: "admin/contract/get-contract-by-proposal",
  ticfetchAllProposalContracts: "admin/contract/fetch-all-contracts",

  //Invoicing
  fetchAllCompletedContracts: "admin/contract/completed-contractList",
  createInvoice: "admin/invoices/create-invoice",
  updateInvoice: "admin/invoices/update-invoice",
  merchantInvoice: "admin/invoices/create-merchant-invoice",
  getAllInvoices: "admin/invoices/get-all-invoices-list",
  invoiceCancellation: "admin/invoices/update-invoice-status",
  getLeadInvoice: "admin/invoices/get-invoice-LeadId",
  generateSingleInvoice: "admin/invoices/create-proposal-invoice",
  updateSingleInvoice: "admin/invoices/update-invoice-details",
  fetchInvoiceByProposal: "admin/invoices/get-proposal-invoices",
  fetchAllInvoiceList: "admin/invoices/get-all-invoices-list",
  paymentStatusUpdate: "/payment/create-manual-payment",
  fetchInvoiceDetails: "/payment/get-paymentby-leadId",
  //companyList
  getAllCompanies: "admin/companies/fetch-companies",

  //Mail
  getAllMailTemplates: "admin/mail/fetch-all-mailTemplates",
  //Events
  fetchAllEventsList: "admin/packages/get-packages",

  //Client
  fetchContractDetails: "admin/contract/fetch-contract-details",
  clientSendContractt: "admin/contract/complete-contract",
  fetchSingleContract: "admin/contract/fetch-proposal-contract",

  // package
  addPackage: "admin/packages/create-new-package",
  updatePackage: "admin/packages/update-package",
  disablePackage: "admin/packages/update-availability",
  deletePackage: "admin/packages/delete-package",
};
