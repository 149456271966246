import React from "react";
import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useClient = () => {
    const [clientLoading, setClientLoading] = useState(false);
    const [clientContract, setClientContract] = useState(null)

    const fetchContractData = async (payload, { onSuccess, onFailed }) => {
        try {
            setClientLoading(true);
            await BaseClient.post(APIEndpoints.fetchContractDetails, payload, {
                onSuccess: onSuccess,
                onFailed: onFailed,
            });
        } finally {
            setClientLoading(false);
        }
    }
    const fetchSingleContract = async (payload, { onSuccess, onFailed }) => {
        try {
            setClientLoading(true);
            await BaseClient.post(APIEndpoints.fetchSingleContract, payload, {
                onSuccess: onSuccess,
                onFailed: onFailed,
            });
        } finally {
            setClientLoading(false);
        }
    };
    const sendContract = async (payload, { onSuccess, onFailed }) => {
        try {
            setClientLoading(true);
            await BaseClient.post(APIEndpoints.clientSendContract, payload, {
                onSuccess: onSuccess,
                onFailed: onFailed,
            });
        } finally {
            setClientLoading(false);
        }
    };
    return {
        fetchContractData,
        clientLoading,
        fetchSingleContract,
        clientContract,
        sendContract
    }
}
export default useClient;