import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../Context/AppContext";
import Sidebar from "../../../Components/Sidebar";
import { ThreeDots } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";

import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Bi from "react-icons/bi";
import * as Fa from "react-icons/fa";
import * as Io from "react-icons/io";
import * as Tb from "react-icons/tb";

function UpdateEnquiry() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    shopCategories,
    addLead,
    enquiryLoading,
    fetchAllSellerEnquiries,
    updateLeadData,
    allSellerEnquiries,
  } = useContext(AppContext);

  //initial state
  const [enquiryData, setEnquiryData] = useState({
    leadId: "",
    pceId: "",
    shopName: "",
    shopUrl: "",
    name: "",
    email: "",
    mobile: "",
    countryCode: "",
    address1: "",
    address2: "",
    town: "",
    country: "",
    postCode: "",
    password: "",
    categoryId: "",
    termsAndCondtions: true,
  });
  const [isPassword, setIsPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    getEnquiryDetails();
  }, [allSellerEnquiries, id]);

  //fetch enquiry data
  const getEnquiryDetails = () => {
    if (!id || !allSellerEnquiries) return;

    const data = allSellerEnquiries.find((x) => x.pceId == id);

    const phonenumber = data?.sellerDetails?.mobile;
    const number = phonenumber?.replace(/\D/g, "");

    const countryCode = number?.slice(0, 2);

    setEnquiryData((prev) => ({
      ...prev,
      leadId: data?.leadId,
      termsAndCondtions: true,
      pceId: id,
      shopName: data?.sellerDetails?.shopName,
      shopUrl: data?.sellerDetails?.shopUrl,
      name: data?.sellerDetails?.sellerName,
      email: data?.sellerDetails?.sellerEmail,
      mobile: data?.sellerDetails?.mobile,
      address1: data?.sellerDetails?.address1,
      address2: data?.sellerDetails?.address2,
      town: data?.sellerDetails?.town,
      country: data?.sellerDetails?.country,
      postCode: data?.sellerDetails?.postcode,
      countryCode: countryCode,
    }));
  };

  //to handle onChange
  const handleChange = (e) => {
    const { name, value } = e.target;

    setEnquiryData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  //to handle phone number change
  const handlePhoneChange = (formattedValue, e) => {
    if (!formattedValue || !e) return;
    setEnquiryData((prev) => ({
      ...prev,
      mobile: formattedValue,
      countryCode: e.dialCode,
    }));
  };

  //to handle password matching
  const handlePassowrd = () => {
    if (isPassword.newPassword !== isPassword.confirmPassword) {
      setError(true);
      toast.error("Password is not matching!");
      return false;
    } else if (isPassword.newPassword === isPassword.confirmPassword) {
      setError(false);
      setEnquiryData((prev) => ({
        ...prev,
        password: isPassword?.newPassword,
      }));
      return true;
    }
  };

  //to handle form empty filed validation
  const handleValidation = () => {
    if (
      enquiryData?.shopName?.length == 0 ||
      enquiryData?.categoryId?.length == 0 ||
      enquiryData?.shopUrl?.length == 0 ||
      enquiryData?.name?.length == 0 ||
      enquiryData?.address1?.length == 0 ||
      enquiryData?.address2?.length == 0 ||
      enquiryData?.country?.length == 0 ||
      enquiryData?.countryCode?.length == 0 ||
      enquiryData?.email?.length == 0 ||
      enquiryData?.leadId?.length == 0 ||
      enquiryData?.mobile.length == 0 ||
      enquiryData?.password?.length == 0 ||
      enquiryData?.postCode?.length == 0
    ) {
      setError(true);
      return false;
    }
    return true;
  };

  //form submition
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidated = handleValidation();
    const isPasswordCheck = handlePassowrd();

    if (isValidated || !isPasswordCheck) {
      await updateLeadData(enquiryData, {
        onSuccess: async (res) => {
          toast.success("Seller added successfully!");
          await fetchAllSellerEnquiries();
          setTimeout(() => {
            navigate("/enquiry");
          }, 1300);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    }
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container-fluid">
          <div className="head-h4 text-center">Merchant Registration</div>
          <div className="container">
            <div className="card mt-3 p-3 main-gradient box">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <p className="form_sub">Shop info</p>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <label htmlFor="Privilege Name" className="review-label">
                      Company Name
                    </label>
                    <div
                      className={
                        !enquiryData?.shopName?.length === 0 && !error
                          ? "review-group error"
                          : "review-group "
                      }
                    >
                      <input
                        name="shopName"
                        type="text"
                        id=""
                        className="review-input"
                        value={enquiryData?.shopName}
                        onChange={(e) => handleChange(e)}
                      />
                      <i className="inp_ico">
                        <Bs.BsShop />
                      </i>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <label htmlFor="Privilege Name" className="review-label">
                      Shop Category
                    </label>
                    <div
                      className={
                        !error && enquiryData.categoryId?.length === 0
                          ? "review-group error"
                          : "review-group"
                      }
                    >
                      <select
                        name="categoryId"
                        id=""
                        onChange={(e) =>
                          setEnquiryData((prev) => ({
                            ...prev,
                            categoryId: e.target.value,
                          }))
                        }
                        className="fp-select"
                      >
                        <option value="" selected disabled>
                          Select a category
                        </option>
                        {shopCategories != null &&
                          shopCategories.map((category, index) => {
                            return (
                              <option value={category?.scgID} key={index}>
                                {category?.name ?? "N/A"}
                              </option>
                            );
                          })}
                      </select>
                      <i className="inp_ico">
                        <Ai.AiTwotoneShop />
                      </i>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <label htmlFor="Privilege Name" className="review-label">
                      Site URL
                    </label>
                    <div
                      className={
                        !error && enquiryData.shopUrl?.length === 0
                          ? "review-group error"
                          : "review-group"
                      }
                    >
                      <input
                        name="shopUrl"
                        type="text"
                        id=""
                        className="review-input"
                        value={enquiryData?.shopUrl}
                        onChange={(e) => handleChange(e)}
                      />
                      <i className="inp_ico">
                        <Bs.BsShopWindow />
                      </i>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <p className="form_sub">Contact info</p>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Contact Name
                      </label>
                      <div
                        className={
                          !error && enquiryData?.name?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <input
                          name="name"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData.name}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Bs.BsFilePerson />
                        </i>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Email Address
                      </label>
                      <div
                        className={
                          !error && enquiryData?.email?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <input
                          name="email"
                          type="email"
                          id=""
                          className="review-input"
                          value={enquiryData.email}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Io.IoMdMailOpen />
                        </i>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Phone Number
                      </label>

                      <div
                        className={
                          !error &&
                          enquiryData != null &&
                          enquiryData?.mobile?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <PhoneInput
                          className="phone-input"
                          country={"gb"}
                          value={enquiryData?.mobile}
                          onChange={handlePhoneChange}
                        />
                        <i className="inp_ico" id="phone_ico">
                          <Bs.BsTelephone />
                        </i>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Address Line 1
                      </label>
                      <div
                        className={
                          !error && enquiryData?.address1?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <input
                          name="address1"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.address1}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Fa.FaRegAddressCard />
                        </i>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Address Line 2
                      </label>
                      <div
                        className={
                          !error && enquiryData?.address2?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <input
                          name="address2"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.address2}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Fa.FaRegAddressCard />
                        </i>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Town/City
                      </label>
                      <div
                        className={
                          !error && enquiryData?.town?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <input
                          name="town"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.town}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Bi.BiBuildingHouse />
                        </i>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4 mt-3">
                      <label htmlFor="Privilege Name" className="review-label">
                        County
                      </label>
                      <div
                        className={
                          !error && enquiryData?.country?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <input
                          name="country"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.country}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Tb.TbBuildingBank />
                        </i>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4 mt-3">
                      <label htmlFor="Privilege Name" className="review-label">
                        Postal Code
                      </label>
                      <div
                        className={
                          !error && enquiryData?.postCode?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <input
                          name="postCode"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.postCode}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Bs.BsPostcard />
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <p className="form_sub">Password</p>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        New Password
                      </label>
                      <div
                        className={
                          !error && isPassword.newPassword?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <input
                          type="password"
                          name=""
                          id=""
                          className="review-input"
                          value={isPassword?.newPassword}
                          onChange={(e) => {
                            setIsPassword((prev) => ({
                              ...prev,
                              newPassword: e.target.value,
                            }));
                            setEnquiryData((prev) => ({
                              ...prev,
                              password: e.target.value,
                            }));
                          }}
                        />
                        <i className="inp_ico">
                          <Bi.BiLock />
                        </i>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Confirm Password
                      </label>
                      <div
                        className={
                          !error && isPassword.confirmPassword?.length === 0
                            ? "review-group error"
                            : "review-group"
                        }
                      >
                        <input
                          type="text"
                          name=""
                          id=""
                          className="review-input"
                          value={isPassword?.confirmPassword}
                          onChange={(e) => {
                            setIsPassword((prev) => ({
                              ...prev,
                              confirmPassword: e.target.value,
                            }));
                          }}
                        />
                        <i className="inp_ico">
                          <Bi.BiLockOpen />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row mt-3">
                  <p className="form_sub">Enquiry info</p>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="Privilege Name" className="review-label">
                      <Ai.AiOutlineEdit /> Reference
                    </label>
                    <textarea
                      name="enq_ref"
                      id=""
                      cols="30"
                      rows="10"
                      className="form-control"
                      value={enquiryData?.enq_ref}
                      onChange={(e) => handleChange(e)}
                    ></textarea>
                  </div>
                </div> */}
                <br />
                <button
                  type="submit"
                  className="fp-button ad_btn mt-3"
                  disabled={
                    enquiryLoading.state && enquiryLoading.action === "add"
                  }
                >
                  {!enquiryLoading.state && enquiryLoading.action != "add" ? (
                    "Submit"
                  ) : (
                    <ThreeDots
                      height="60"
                      width="60"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperClassName="loader_"
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default UpdateEnquiry;
