import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useSupport = () => {
  const [supportList, setSupportList] = useState([]);
  const [supportLoading, setSupportLoading] = useState(false);

  const getAllSupportData = async () => {
    try {
      setSupportLoading(true);
      await BaseClient.get(APIEndpoints.getAllSupport, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setSupportList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting data", err);
        },
      });
    } finally {
      setSupportLoading(false);
    }
  };
  const updateCompleteStatus = async (payload, { onSuccess, onFailed }) => {
    try {
      setSupportLoading({
        action: "change",
        state: true,
      });

      await BaseClient.put(APIEndpoints.updateTicketComplete, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setSupportLoading({
        action: "",
        state: false,
      });
    }
  };
  return {
    getAllSupportData,
    supportLoading,
    supportList,
    updateCompleteStatus,
  };
};
export default useSupport;
