import React, { useContext, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import * as Md from "react-icons/md";
import { AppContext } from "../../Context/AppContext";

function Privileges() {
  const navigate = useNavigate();
  const { allPrivilages, privilageLoading, alljobRole } =
    useContext(AppContext);

  const getJobRole = (item) => {
    if (!item || item.length === 0) return [];

    const roles = alljobRole
      .filter((role) => item.includes(role.p_jbId))
      .map((role) => role.role);
    return roles.join(", ");
  };

  return (
    <>
      <div className="container-fluid">
        <Sidebar>
          <div div className="container-fluid m-3">
            <h6 className="head-h4">Privileges</h6>
            <p>
              All Privileges List{" "}
              <span>
                <button
                  className="fp-add"
                  onClick={() => navigate("/add-privileges")}
                >
                  + Add
                </button>
              </span>
            </p>
          </div>
          <div className="card p-3 m-3 overflow">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Privilege Name</th>
                  <th scope="col">Front Route</th>
                  <th scope="col">Back Route</th>
                  <th scope="col">Authorised For</th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!privilageLoading
                  ? allPrivilages != null &&
                    Array.isArray(allPrivilages) &&
                    allPrivilages.map((privilege, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{privilege?.previlageName ?? "N/A"}</td>
                            <td>{privilege?.f_route ?? "N/A"}</td>
                            <td>{privilege?.b_route ?? "N/A"}</td>
                            <td>{getJobRole(privilege?.jobRoles)}</td>
                            <td className="text-center">
                              <div className="btn-toggle">
                                <button
                                  className="one-btn btn-full"
                                  onClick={() =>
                                    navigate(
                                      `/edit-privileges/${privilege?.ppId}`
                                    )
                                  }
                                >
                                  Edit
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : "loading"}
              </tbody>
            </table>
          </div>
        </Sidebar>
      </div>
    </>
  );
}

export default Privileges;
