import React, { useState } from "react";
import Sidebar from "../../../Components/Sidebar";
import * as Ai from "react-icons/ai";
import * as Md from "react-icons/md";
import { useNavigate } from "react-router-dom";
function SalesExecutive() {
  const navigate = useNavigate();
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const rowData = [
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      role: "admin",
      date: "22-05-2023",
      status: "Pending",
    },
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      role: "admin",
      date: "22-05-2023",
      status: "Pending",
    },
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      role: "admin",
      date: "22-05-2023",
      status: "Pending",
    },
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      role: "admin",
      date: "22-05-2023",
      status: "Pending",
    },
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      role: "admin",
      date: "22-05-2023",
      status: "Pending",
    },
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      role: "admin",
      date: "22-05-2023",
      status: "Pending",
    },
  ];
  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container-fluid m-3">
          <h6 className="head-h4">Manage Executives</h6>
          <p>
            List of executives
            <span>
              <button
                className="fp-add"
                onClick={() => navigate("/add-sales-executive")}
              >
                + Add
              </button>
            </span>
          </p>
        </div>
        <div className="card p-3 m-3 overflow">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Date of Joining</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Email</th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {rowData.map((row, index) => (
                <tr key={row.id}>
                  <th scope="row">{row.id}</th>
                  <td>{row.name}</td>
                  <td>{row.date}</td>
                  <td>{row.mobile}</td>
                  <td>{row.email}</td>
                  <td className="text-center position-relative">
                    <div className="btn-toggle">
                      <button className="one-btn">Action</button>
                      <button
                        className="two-btn"
                        onClick={() => toggleDropdown(index)}
                      >
                        <Md.MdOutlineArrowDropDown />
                      </button>
                    </div>
                    {openDropdownIndex === index && (
                      <ul className="table-mgt" role="menu">
                        <li>
                          <button
                            className="btn"
                            onClick={() => navigate("/view-sales-executive")}
                          >
                            View
                          </button>
                        </li>
                        <li>
                          <button
                            className="btn"
                            onClick={() => navigate("/edit-sales-executive")}
                          >
                            Edit
                          </button>
                        </li>
                      </ul>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Sidebar>
    </div>
  );
}

export default SalesExecutive;
