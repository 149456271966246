import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import toast, { Toaster } from "react-hot-toast";
import * as Fi from "react-icons/fi";
import img1 from "../../Assets/delivery-app.jpg";
import Select from "react-select";
import { AppContext } from "../../Context/AppContext";
import * as Bs from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Upload } from "antd";

function EditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [edit, setEdit] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const {
    getAllProductData,
    productList,
    featureList,
    productLoading,
    updateProduct,
  } = useContext(AppContext);
  const [SingleProduct, setSingleProduct] = useState({
    profilephoto: null,
    photos: [],
    productName: "",
    worksOn: [],
    subscription: [],
    device: [],
    pdfId: [],
    description: "",
    price_yearly: null,
    price_monthly: null,
    duration_monthly: "",
    duration_yearly: "",
    durationIn: "",
  });

  const handleDeviceChange = (selectedDevice) => {
    setSingleProduct((prev) => {
      const updatedDevices = prev.device.includes(selectedDevice)
        ? prev.device.filter((device) => device !== selectedDevice)
        : [...prev.device, selectedDevice];

      return { ...prev, device: updatedDevices };
    });
  };
  const options = featureList.map((group) => ({
    value: group.pdfId,
    label: group.featureName,
  }));
  const subscription = [
    { value: "MONTHLY", label: "MONTHLY" },
    { value: "YEARLY", label: "YEARLY" },
  ];
  const worksOn = [
    { value: "iOS", label: "iOS" },
    { value: "WINDOWS", label: "WINDOWS" },
    { value: "WEB", label: "WEB" },
    { value: "ANDROID", label: "ANDROID" },
  ];
  const deviceOption = [
    { value: "TABLET", label: "TABLET" },
    { value: "MOBILE", label: "MOBILE" },
  ];
  const findSingleProduct = () => {
    const data = productList.find((x) => x.pId == id);
    setSingleProduct(data);
  };

  useEffect(() => {
    findSingleProduct();
  }, [productList]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setSingleProduct((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleWorksOnChange = (selectedOptions) => {
    setSingleProduct((prev) => ({
      ...prev,
      worksOn: selectedOptions.map((option) => option.value),
    }));
  };
  const handleSubscriprion = (subselected) => {
    setSingleProduct((prev) => ({
      ...prev,
      subscription: subselected.map((option) => option.value),
    }));
  };

  const handleSelectChange = (selectedOptions) => {
    const pdfIds = selectedOptions.map((option) => option.value);
    setSingleProduct((prev) => ({
      ...prev,
      pdfId: Array.isArray(pdfIds) ? pdfIds : [pdfIds],
    }));
  };
  const handlePhotoChange = (images) => {
    const filesArray = Array.from(images);
    setUploadedPhotos(filesArray);
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      onSuccess(e.target.result, file);
    };

    reader.onerror = () => {
      onError(new Error("File reading failed"));
    };

    reader.readAsDataURL(file);
  };

  const handleUpdateFeature = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const pdfIdArray = Array.isArray(SingleProduct.pdfId)
      ? SingleProduct.pdfId
      : [SingleProduct.pdfId];
    formData.append("pId", SingleProduct.pId);
    formData.append("productName", SingleProduct.productName);
    formData.append("description", SingleProduct.description);
    formData.append("price_monthly", SingleProduct.price_monthly);
    formData.append("price_yearly", SingleProduct.price_yearly);
    formData.append("pdfId", pdfIdArray.join(","));
    formData.append("currency", "£");
    formData.append("durationIn", "days");
    formData.append("device", JSON.stringify(SingleProduct.device));
    formData.append("duration_monthly", "30");
    formData.append("duration_yearly", "365");
    formData.append("worksOn", JSON.stringify(SingleProduct.worksOn));
    formData.append("subscription", JSON.stringify(SingleProduct.subscription));

    if (
      SingleProduct.profilephoto &&
      typeof SingleProduct.profilephoto !== String
    ) {
      formData.append("profilephoto", SingleProduct.profilephoto);
    }

    for (let i = 0; i < uploadedPhotos.length; i++) {
      formData.append("photos", uploadedPhotos[i]);
    }
    console.log("FormData:", formData);
    await updateProduct(formData, {
      onSuccess: async (res) => {
        console.log("FormData:", formData);
        toast.success("Succesfully updated Product!");
        setTimeout(() => {
          navigate("/products");
        });
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };
  const handleOpen = () => {
    setOpenUpload(true);
    setEdit(true);
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <div div className="container-fluid m-3">
          <Toaster position="top-center" reverseOrder={false} />
          <h4 className="head-h4">Edit Product</h4>
        </div>
        <div className="container">
          <div className="card m-3">
            <h6 className="text-center m-3">Update Product</h6>
            <form action="" onSubmit={(e) => handleUpdateFeature(e)}>
              <div className="row m-3 p-3">
                <div className="col-md-6 col-sm-12 mt-3">
                  <label className="form-label review-label">
                    Product Name
                  </label>
                  <div className="review-group">
                    <input
                      type="text"
                      className="review-input"
                      name="productName"
                      value={SingleProduct?.productName ?? "N/A"}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-3 col-sm-12 mt-3">
                  <label htmlFor="" className="form-label review-label">
                    Price Monthly
                  </label>
                  <div className="review-group">
                    <input
                      className="review-input"
                      type="text"
                      name="price_monthly"
                      value={SingleProduct?.price_monthly ?? "N/A"}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 mt-3">
                  <label htmlFor="" className="form-label review-label">
                    Price Yearly
                  </label>
                  <div className="review-group">
                    <input
                      className="form-control review-input"
                      type="text"
                      name="price_yearly"
                      value={SingleProduct?.price_yearly ?? "N/A"}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mt-3">
                  <label
                    htmlFor="pdfSelect"
                    className="form-label review-label"
                  >
                    Select Features
                  </label>
                  <Select
                    name="pdfId"
                    id="pdfSelect"
                    value={options.filter((option) =>
                      SingleProduct?.pdfId.includes(option.value)
                    )}
                    onChange={(e) => handleSelectChange(e)}
                    options={options}
                    isMulti
                  />
                </div>
                <div className="col-md-4 col-sm-12 mt-3">
                  <label htmlFor="" className="form-label review-label">
                    Subscription Types
                  </label>
                  <Select
                    name="subscriprion"
                    id="subscription"
                    value={subscription.filter((option) =>
                      SingleProduct?.subscription.includes(option.value)
                    )}
                    onChange={handleSubscriprion}
                    options={subscription}
                    isMulti
                  />
                </div>
                <div className="col-md-4 col-sm-12 mt-3">
                  <label htmlFor="" className="form-label review-label">
                    Available Platforms
                  </label>
                  <Select
                    name="worksOn"
                    id="worksOnSelect"
                    value={worksOn.filter((option) =>
                      SingleProduct?.worksOn.includes(option.value)
                    )}
                    onChange={handleWorksOnChange}
                    options={worksOn}
                    isMulti
                  />
                </div>

                <div className="">
                  <div className="col-sm-12 mt-3">
                    <label htmlFor="" className="form-label review-label mb-2">
                      Description
                    </label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      name="description"
                      value={SingleProduct?.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-2 col-sm-12 mt-3">
                  <label className="form-label review-label">Devices</label>
                  {deviceOption.map((option) => (
                    <div key={option.value} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`device-${option.value}`}
                        value={option.value}
                        checked={SingleProduct?.device.includes(option.value)}
                        onChange={() => handleDeviceChange(option.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`device-${option.value}`}
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="col-md-5 col-sm-12 ">
                  <div className="profile_upload">
                    <label htmlFor="" className="form-label review-label">
                      Profile Image
                    </label>
                    {edit != true && (
                      <div className="img-dic">
                        <img
                          src={
                            SingleProduct?.profilephoto != null
                              ? process.env.REACT_APP_AWS_IMAGE_URL +
                                "products/" +
                                SingleProduct?.profilephoto
                              : img1
                          }
                          alt=""
                          className="card-image"
                        />
                      </div>
                    )}

                    {openUpload && (
                      <>
                        <br />
                        <input
                          type="file"
                          name="profilephoto"
                          accept="image/*"
                          onChange={(e) => {
                            setSingleProduct((prev) => ({
                              ...prev,
                              profilephoto: e.target.files[0],
                            }));
                          }}
                        />
                      </>
                    )}

                    <p className="file-name">
                      {SingleProduct?.profilephoto &&
                      SingleProduct.profilephoto instanceof File ? (
                        <img
                          src={URL.createObjectURL(SingleProduct.profilephoto)}
                          alt="Profile"
                          value
                          className="pre-img"
                        />
                      ) : (
                        ""
                      )}
                    </p>
                    {edit != true && (
                      <p onClick={handleOpen} className="button-change mt-3">
                        Change
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <div className="profile_upload">
                    <label htmlFor="" className="form-label review-label mb-2">
                      Upload Images (max 10)
                    </label>
                    <br />
                    {/* <Upload
                      customRequest={customRequest}
                      onChange={handlePhotoChange}
                      maxCount={10}
                    >
                      <Button icon={<Fi.FiUploadCloud />}>Upload</Button>
                    </Upload> */}
                    <input
                      type="file"
                      name=""
                      id=""
                      onChange={(e) => handlePhotoChange(e.target.files)}
                    />
                    {uploadedPhotos &&
                      uploadedPhotos.length != 0 &&
                      uploadedPhotos.map((img) => (
                        <p className="file_name">{img?.name}</p>
                      ))}
                    <div className="col-md-12 mt-3">
                      <label className="form-label review-label mb-2">
                        Product Photos
                      </label>
                      <div className="d-flex flex-wrap">
                        {SingleProduct?.photos.map((photo, index) => (
                          <div key={index} className="m-2 d-flex ">
                            <img
                              src={
                                photo?.filename != null
                                  ? process.env.REACT_APP_AWS_IMAGE_URL +
                                    "products/" +
                                    photo?.filename
                                  : img1
                              }
                              alt=""
                              className="n-image"
                            />
                            <button className="remove-pic">
                              <Bs.BsTrash />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-div text-center mx-auto">
                {productLoading ? (
                  <button className="fp-button mb-3" type="submit" disabled>
                    Submitting..
                  </button>
                ) : (
                  <button className="fp-button mb-3" type="submit">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default EditProduct;
