import React from "react";
import Sidebar from "../../Components/Sidebar";

function ViewSubscriptionDetails() {
  return (
    <div className="container-fluid">
      <Sidebar>
        <h4 className="head-hs mb-3 mt-3">View Subscription Details</h4>
        <div className="card payment mastercard">
          <div className="header">
            <h4>Order Online System</h4>
          </div>
          <div className="admin-data">
            <ul className="support-service">
              <li>
                <h6>
                  Name: <span> Gwen Irin</span>
                </h6>
              </li>
              <li>
                <h6>
                  Product Name : <span>Admin</span>
                </h6>
              </li>
              <li>
                <h6>
                  Subscription Type : <span>Monthly</span>
                </h6>
              </li>
              <li>
                <h6>
                  Amount :<span>125</span>
                </h6>
              </li>
              <li>
                <h6>
                  Available Devices :<span></span>
                </h6>
              </li>
              <li>
                <h6>
                  Date of Payment :<span>12-04-2023</span>
                </h6>
              </li>
              <li>
                <h6>
                  Expiry Date :<span>12-05-2023</span>
                </h6>
              </li>
            </ul>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default ViewSubscriptionDetails;
