import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useContract = () => {
  const [contractLoading, setContractLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [invoiceList, setInvoiceList] = useState(null);
  const [companiesList, setCompaniesList] = useState("");
  const fetchFinalizedContracts = async (payload, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.getAllContracts, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const sendContractToClient = async (formData, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.sendContract, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const sendSingleContract = async (formData, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.sendContractSingle, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const getAllInvoices = async () => {
    try {
      setContractLoading(true);
      await BaseClient.get(APIEndpoints.getAllInvoices, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setInvoiceList(res?.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting Admin List!", err);
        },
      });
    } finally {
      setContractLoading(false);
    }
  };
  const getCompaniesData = async () => {
    try {
      setContractLoading(true);
      await BaseClient.get(APIEndpoints.getAllCompanies, {
        onSuccess: (res) => {
          setCompaniesList(res?.data);
        },
        onFailed: (err) => {
          console.log("companies Data Error", err);
        },
      });
    } finally {
      setContractLoading(false);
    }
  };
  const leadInvoices = async (id, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.getLeadInvoice + `/${id}`, [], {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };

  const getAllContractList = async (payload, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.olderContracts, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const updateContractStatus = async (payload, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.completeContract, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const getAllCompletedContracts = async (payload, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.fetchAllCompletedContracts, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const generateInvoice = async (payload, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.createInvoice, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const singleInvoiceGeneration = async (payload, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.generateSingleInvoice, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const updateInvoiceDetails = async (payload, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.updateSingleInvoice, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const createMerchantInvoice = async (payload, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.post(APIEndpoints.merchantInvoice, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const updateInvoiceFile = async (formData, id, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);
      await BaseClient.put(APIEndpoints.updateInvoice + `/${id}`, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  const cancelInvoice = async (id, { onSuccess, onFailed }) => {
    try {
      setContractLoading(true);

      await BaseClient.put(APIEndpoints.invoiceCancellation + `/${id}`, [], {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setContractLoading(false);
    }
  };
  return {
    sendContractToClient,
    contractLoading,
    fetchFinalizedContracts,
    cancelInvoice,
    getAllContractList,
    getAllCompletedContracts,
    createMerchantInvoice,
    getAllInvoices,
    invoiceList,
    updateContractStatus,
    generateInvoice,
    invoiceNumber,
    updateInvoiceFile,
    setInvoiceNumber,
    leadInvoices,
    sendSingleContract,
    companiesList,
    getCompaniesData,
    singleInvoiceGeneration,
    updateInvoiceDetails
  };
};
export default useContract;
