import React from "react";
import Sidebar from "../../../Components/Sidebar";
import Backbutton from "../../../Components/Backbutton";

function EnquiryAction() {
  
  return (
    <div className="container-fluid">
      <Sidebar>
       
        <div div className="container mt-3">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="card ">
                <div className="red-header">
                  <h5 className="text-center">Enquiry DETAILS</h5>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="p-3 m-3">
                      <ul className="support-service">
                        <b>
                          {" "}
                          <h5 className="m-1 mb-3">Client Details</h5>
                        </b>
                        <li>
                          <h6>
                            Client Name: <span>fg</span>
                          </h6>
                        </li>
                        <li>
                          <h6>
                            Email : <span>gfdxgdfg</span>
                          </h6>
                        </li>
                        <li>
                          <h6>
                            Mobile : <span>gfdg</span>
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="p-3 m-3">
                      <ul className="support-service">
                        <b>
                          {" "}
                          <h5 className="m-1 mb-3">Product Details</h5>
                        </b>
                        <li>
                          <h6>
                            Product Name: <span>fg</span>
                          </h6>
                        </li>
                        <li>
                          <h6>
                            Subscription Types : <span>gfdxgdfg</span>
                          </h6>
                        </li>
                        <li>
                          <h6>
                            Date of Enquiry : <span>gfdg</span>
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div>
                      <h4 className="text-center mb-3 assign">
                      Assign Executive
                      </h4>
                      <div className="card p-3 m-3">
                        <form className="boxed">
                          <div>
                            <div class="form-group">
                              <select className="check-form ">
                                <option value="volvo" class="mt-2">
                                  Assign Executive
                                </option>
                                <option value="saab">Alfred Mishel</option>
                                <option value="mercedes">Liyano Mathew</option>
                              </select>
                            </div>
                          </div>
                          <button className="save-btn">Save</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default EnquiryAction;
