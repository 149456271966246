import React from "react";
import * as Ri from "react-icons/ri";
import * as IoIos from "react-icons/io";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

function SignaturePad(
    {
        onChange,
        isDrawing,
        setIsDrawing,
        handleClear,
        setImgSrc,
        setSignModal,
    }
) {
    const canvasRef = useRef(null);
    const [isEmpty, setIsEmpty] = useState(true);
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas && canvas.getContext("2d");

        // Set initial styles
        context.strokeStyle = "black";
        context.lineWidth = 3;
        context.lineCap = "round";

        let drawing = false;
        let lastX = 0;
        let lastY = 0;

        const getMousePosition = (canvas, e) => {
            const rect = canvas.getBoundingClientRect();
            return {
                x: e.clientX - rect.left,
                y: e.clientY - rect.top,
            };
        };

        const startDrawing = (e) => {
            drawing = true;
            const { x, y } = getMousePosition(canvas, e);
            [lastX, lastY] = [x, y];
        };

        const draw = (e) => {
            if (!drawing) return;

            const { x, y } = getMousePosition(canvas, e);
            context.beginPath();
            context.moveTo(lastX, lastY);
            context.lineTo(x, y);
            context.stroke();

            [lastX, lastY] = [x, y];
            setIsEmpty(false);
        };

        const stopDrawing = () => {
            drawing = false;
            setIsDrawing([
                ...isDrawing,
                context.getImageData(0, 0, canvas.width, canvas.height),
            ]);
            onChange(isDrawing);
        };

        canvas.addEventListener("mousedown", startDrawing);
        canvas.addEventListener("mousemove", draw);
        canvas.addEventListener("mouseup", stopDrawing);
        canvas.addEventListener("mouseout", stopDrawing);

        return () => {
            canvas.removeEventListener("mousedown", startDrawing);
            canvas.removeEventListener("mousemove", draw);
            canvas.removeEventListener("mouseup", stopDrawing);
            canvas.removeEventListener("mouseout", stopDrawing);
        };
    }, []);

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        setIsDrawing([]);
        handleClear(isDrawing);
        setImgSrc(null);
        setIsEmpty(true);
    };

    const convertToImage = () => {
        setSignModal(false);

        if (isEmpty) return;
        const canvas = canvasRef.current;

        const dataUrl = canvas.toDataURL();
        setImgSrc(dataUrl);
    };

    return (
        <div>
            <canvas
                ref={canvasRef}
                width={450}
                height={200}
                style={{ border: "1px solid black" }}
            ></canvas>
            <div className="btn-grp">
                <button
                    type="button"
                    className="sign-btn save"
                    onClick={convertToImage}
                >
                    <IoIos.IoIosCheckmark />
                    save
                </button>
                <button type="button" className="sign-btn clear"
                    onClick={clearCanvas}
                >
                    <Ri.RiCloseLine />
                    clear
                </button>
            </div>
        </div>
    )
}
export default SignaturePad;