import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useProduct = () => {
  const [productList, setProductList] = useState([]);
  const [productLoading, setProductLoading] = useState(false);
  const getAllProductData = async () => {
    try {
      setProductLoading(true);
      await BaseClient.get(APIEndpoints.getAllProducts, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setProductList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting data", err);
        },
      });
    } finally {
      setProductLoading(false);
    }
  };
  const createProduct = async (formData, { onSuccess, onFailed }) => {
    try {
      setProductLoading(true);
      await BaseClient.post(APIEndpoints.createProduct, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProductLoading(false);
    }
  };
  const deleteProduct = async (id, { onSuccess, onFailed }) => {
    try {
      setProductLoading(true);

      await BaseClient.delete(APIEndpoints.deleteProduct + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProductLoading(false);
    }
  };
  const updateProduct = async (formData, { onSuccess, onFailed }) => {
    try {
      setProductLoading(true);
      await BaseClient.post(APIEndpoints.updateProduct, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProductLoading(false);
    }
  };
  return {
    getAllProductData,
    productLoading,
    productList,
    updateProduct,
    createProduct,
    deleteProduct,
  };
};
export default useProduct;
