import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Sidebar from "../../Components/Sidebar";
import * as Ai from "react-icons/ai";
import Stages from "../../Components/Stages/Stages";
import NotAssigned from "../Sales/SubscriberEnquiry/NotAssigned";
import { Popconfirm } from "antd";
import LeadStages from "../../Components/Stages/LeadStages";
import { BeatLoader } from "react-spinners";

function ViewSingle() {
  const params = useParams();
  const navigate = useNavigate();
  const id = params?.id;
  const {
    allSellerEnquiries,
    sellerEnquiriesLoading,
    cancelLead,
    getDetailsOfUser,
    fetchAllSellerEnquiries,
    fetchFinalizedContracts,
    enquiryLoading,
  } = useContext(AppContext);
  const [enquiryData, setEnquiryData] = useState(null);
  const [assign, setAssign] = useState(false);
  const [jobsArray, setJobsArray] = useState(null);
  const [deals, setDeals] = useState(null);

  useEffect(() => {
    getEnquiryDetails();
  }, [allSellerEnquiries]);

  useEffect(() => {
    const allJobQueueArray = enquiryData?.jobQueueStatus;

    setJobsArray(allJobQueueArray);
  }, [allSellerEnquiries, enquiryData]);

  const jobQueueStatusArray = enquiryData?.jobQueueStatus?.[0];

  const getEnquiryDetails = () => {
    const id = params.id;
    if (!id || !allSellerEnquiries) return;
    const singleData = allSellerEnquiries?.find((x) => x?.pceId == params?.id);
    setEnquiryData(singleData);
  };

  useEffect(() => {
    if (enquiryData != null) {
      getDetails();
    }
  }, [enquiryData]);

  const getDetails = async () => {
    const payload = {
      leadId: params?.leadId,
    };

    await getDetailsOfUser(payload, {
      onSuccess: async (res) => {
        setDeals(res.data);
      },
      onFailed: (err) => {
        console.log("failed");
      },
    });
  };
  const cancelLeadData = async (e) => {
    const payload = {
      pceId: enquiryData?.pceId,
      cancelStatus: true,
      status: "cancelled",
    };

    await cancelLead(payload, {
      onSuccess: async (res) => {
        toast.success("Lead Cancelled!");
        await fetchAllSellerEnquiries();

        setTimeout(() => {
          window.location.href = "/leads";
        }, 1000);
      },
      onFailed: (err) => {
        toast.error("Lead Cancellation Unsuccessful!");
      },
    });
  };
  console.log(enquiryData);
  return (
    <>
      <div className="container-fluid">
        <Toaster position="top-center" reverseOrder={false} />
        <Sidebar>
          <section id="content-wrapper" class="dashboard-content-wrapper">
            <>
              <div class="row">
                <div class="col-lg-12 ">
                  <h3 class="content-title mt-3 mb-3 text-center">
                    Lead Details
                  </h3>
                </div>
                {enquiryData?.datafill === 1 && (
                  <div className="container">
                    <div
                      class="alert alert-success text-center alert-dismissible fade show"
                      role="alert"
                    >
                      Customer On-Boarded Successfully!
                      <button
                        type="button"
                        className="close-btnn"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                )}
                {enquiryData?.status === "cancelled" && (
                  <div className="container">
                    <div class="alert alert-danger text-center" role="alert">
                      This lead has been cancelled!
                    </div>
                  </div>
                )}
                <div class="col-md-8 col-lg-6 col-sm-12 mt-3">
                  <>
                    <>
                      <div class="dc-title">
                        <h6>Client info</h6>
                      </div>
                      <div class="dashboard-card card overflow">
                        <div class="">
                          <table class="table personal-info-table p-3 m-3">
                            <tbody>
                              <tr>
                                <td>
                                  <b>Name</b>
                                </td>
                                <td>
                                  {enquiryData?.sellerDetails?.sellerName ??
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Company Name</b>
                                </td>
                                <td>
                                  {enquiryData?.sellerDetails?.shopName ??
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Email</b>
                                </td>
                                <td>
                                  {enquiryData?.sellerDetails?.sellerEmail ??
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Mobile</b>
                                </td>
                                <td>
                                  {" "}
                                  {enquiryData?.sellerDetails?.mobile ?? "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Address</b>
                                </td>
                                <td className="address_lead">
                                  {enquiryData?.sellerDetails?.address1 ??
                                    "N/A"}
                                  {enquiryData?.sellerDetails?.address2 ??
                                    "N/A"}{" "}
                                  {enquiryData?.sellerDetails?.town ?? "N/A"}{" "}
                                  {enquiryData?.sellerDetails?.country ?? "N/A"}{" "}
                                  {enquiryData?.sellerDetails?.postcode ??
                                    "N/A"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  </>
                </div>

                <>
                  {enquiryData?.status !== "cancelled" && (
                    <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                      <div class="dc-title">
                        <h6>
                          Initially Assigned Executive Details{" "}
                          {enquiryData?.status !== "cancelled" && (
                            <span className="right-side">
                              <Popconfirm
                                title="Decline the Lead"
                                description="Are you sure to Cancel this lead?"
                                onConfirm={cancelLeadData}
                                // onCancel={}
                                okText="Yes"
                                cancelText="No"
                                okType="danger"
                                icon={
                                  <Ai.AiOutlineQuestionCircle
                                    style={{ color: "red", marginRight: "5px" }}
                                  />
                                }
                              >
                                <button
                                  className="cancel-en"
                                  disabled={
                                    enquiryLoading.action == "cancel" &&
                                    enquiryLoading.state
                                  }
                                >
                                  {enquiryLoading.action == "cancel" &&
                                  enquiryLoading.state ? (
                                    <BeatLoader
                                      size={10}
                                      color="#fff"
                                      ariaLabel="three-dots-loading"
                                      wrapperClassName="loader_"
                                    />
                                  ) : (
                                    "Cancel this Lead ?"
                                  )}
                                </button>
                              </Popconfirm>
                            </span>
                          )}
                        </h6>
                      </div>

                      {enquiryData?.aId != null ? (
                        <>
                          <div
                            className="card lead_card"
                            style={{ overflow: "hidden" }}
                          >
                            <table className="table personal-info-table p-3 m-3">
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Employee Id</b>
                                  </td>
                                  <td>
                                    #{enquiryData?.adminDetails?.aId ?? "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Name</b>
                                  </td>
                                  <td>
                                    {enquiryData?.adminDetails?.name ?? "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Email Address</b>
                                  </td>
                                  <td>
                                    {enquiryData?.adminDetails?.email ?? "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Phone Number</b>
                                  </td>
                                  <td>
                                    {enquiryData?.adminDetails?.mobile ?? "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Remarks</b>
                                  </td>
                                  <td>{enquiryData?.remarks ?? "N/A"}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : (
                        <>
                          <NotAssigned id={id} />
                        </>
                      )}
                    </div>
                  )}
                </>
              </div>
              {enquiryData?.status !== "cancelled" && (
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <LeadStages
                      allJobQueueArray={jobsArray}
                      jobQueueStatusArray={jobQueueStatusArray}
                      enquiryData={enquiryData}
                      id={id}
                      leadId={params.leadId}
                      getEnquiryDetails={getEnquiryDetails}
                      deals={deals}
                    />
                  </div>
                </div>
              )}
            </>
          </section>
        </Sidebar>
      </div>
    </>
  );
}

export default ViewSingle;
