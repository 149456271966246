import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const usePackage = () => {
  const [planList, setPlanList] = useState([]);
  const [planLoading, setPlanLoading] = useState(false);
  const [packageLoading, setPackageLoading] = useState(false);
  const getAllPlanList = async () => {
    try {
      setPlanLoading(true);
      await BaseClient.get(APIEndpoints.getAllPlans, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setPlanList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting data", err);
        },
      });
    } finally {
      setPlanLoading(false);
    }
  };
  const createPackage = async (payload, { onSuccess, onFailed }) => {
    try {
      setPlanLoading(true);
      await BaseClient.post(APIEndpoints.createNewPackage, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPlanLoading(false);
    }
  };
  const addEventPackage = async (payload, { onSuccess, onFailed }) => {
    try {
      setPackageLoading(true);
      await BaseClient.post(APIEndpoints.addPackage, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPackageLoading(false);
    }
  };
  const updateEventPackage = async (payload, { onSuccess, onFailed }) => {
    try {
      setPackageLoading(true);
      await BaseClient.put(APIEndpoints.updatePackage, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPackageLoading(false);
    }
  };
  const deletePackage = async (id, { onSuccess, onFailed }) => {
    try {
      setPlanLoading(true);
      await BaseClient.delete(APIEndpoints.deletePackageAdmin + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPlanLoading(false);
    }
  };

  const deleteEventPackage = async (id, { onSuccess, onFailed }) => {
    try {
      setPlanLoading(true);
      await BaseClient.delete(APIEndpoints.deletePackage + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPlanLoading(false);
    }
  };
  const updatePackage = async (payload, { onSuccess, onFailed }) => {
    try {
      setPlanLoading(true);

      await BaseClient.put(APIEndpoints.editPackages, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPlanLoading(false);
    }
  };
  const disableEventPackage = async (id, { onSuccess, onFailed }) => {
    try {
      // setPlanLoading(true);
      await BaseClient.put(APIEndpoints.disablePackage + `/${id}`, [], {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPlanLoading(false);
    }
  };
  return {
    getAllPlanList,
    planLoading,
    planList,
    createPackage,
    updatePackage,
    deletePackage,
    addEventPackage,
    packageLoading,
    updateEventPackage,
    deleteEventPackage,
    disableEventPackage,
  };
};
export default usePackage;
