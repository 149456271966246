import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../../Components/Sidebar";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../Context/AppContext";

function ViewMerchant() {
  const { id } = useParams();
  const { getAllSeller, sellerLoading, sellerList } = useContext(AppContext);
  const [isPending, setPending] = useState(true);
  const [sellerData, setSellerData] = useState(null);

  useEffect(() => {
    getProductDetails();
  }, [sellerList]);

  const getProductDetails = () => {
    if (!id || !sellerList) return;

    const getSingleSeller = sellerList.find((x) => x?.id == id);
    setSellerData(getSingleSeller);
  };
  const changeStatus = () => {
    setPending((prevState) => !prevState);
  };
  console.log(sellerData);
  return (
    <div className="container-fluid">
      <Sidebar>
        <section id="content-wrapper" class="dashboard-content-wrapper">
          <div class="row">
            <div class="col-lg-12 ">
              <h3 class="content-title mt-3 mb-3">Dashboard</h3>
            </div>
            <div class="col-md-6">
              <div class="dc-title">
                <h6>Personal info</h6>
              </div>
              <div class="dashboard-card card">
                <div class="dc-header">
                  {/* <button className="edit_btn" onClick={()=>navigate('/profile-edit')}>
                      <i>
                        <Ai.AiOutlineEdit />
                      </i>
                    </button> */}
                </div>
                <div class="">
                  <table class="table personal-info-table p-3 m-3">
                    <tbody>
                      <tr>
                        <td>
                          <b>Name</b>
                        </td>
                        <td>{sellerData?.name ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Email</b>
                        </td>
                        <td>{sellerData?.email ?? "N/A"}</td>
                      </tr>
                      {/* <tr>
                        <td>
                          <b>Phone</b>
                        </td>
                        <td>{sellerData?.phone ?? "N/A"}</td>
                      </tr> */}
                      <tr>
                        <td>
                          <b>Mobile</b>
                        </td>
                        <td>{sellerData?.mobile ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <b>Seller Status</b>
                        </td>
                        <td>
                          {sellerData?.partner_seller_status === 1
                            ? "Verified Seller"
                            : "Trial User"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>shop URL</b>{" "}
                        </td>
                        <td>{sellerData?.shopUrl ?? "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="dc-title dashboard-card">
                <h6>Billing</h6>
              </div>
              <div class="">
                <div class="card dashboard-card">
                  <table class="table personal-info-table p-3 m-3">
                    <tbody>
                      <tr>
                        <td>
                          <b>Shop Name</b>
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {sellerData?.shopName ?? "N/A"}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Address 1</b>
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {sellerData?.address1 ?? "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Address 2</b>
                        </td>
                        <td>{sellerData?.address2 ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Town</b>
                        </td>
                        <td>{sellerData?.town ?? "N/A"}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Postcode</b>
                        </td>
                        <td>{sellerData?.postcode ?? "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="dashboard-card mt-3">
                <div class="dc-header">
                  <div class="dc-title">Purchase List(dummy)</div>
                </div>
                <div class="card p-3 overflow">
                  <table class="table ">
                    <thead>
                      <tr>
                        <th scope="col">Product</th>
                        <th scope="col">Purchase Date</th>
                        <th scope="col">Subscription Type</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>On-demand delivery App</td>
                        <td>01-01-23</td>
                        <td>Monthly</td>
                        <td>£599</td>
                      </tr>
                      <tr>
                        <td>Customer App</td>
                        <td>22-11-2022</td>
                        <td>Monthly</td>
                        <td>£199</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Sidebar>
    </div>
  );
}

export default ViewMerchant;
