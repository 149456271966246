import React from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function Backbutton() {
  const navigate = useNavigate();
  const handleBackward = () => {
    navigate(-1);
  };
  return (
    <div className="container">
      <div className="bac_cont" onClick={handleBackward}>
        <i className="p-2 ARROW_BTN">
          <FaArrowLeftLong />
        </i>
      </div>
    </div>
  );
}

export default Backbutton;
