import React, { Fragment, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import * as Bs from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import * as Ai from "react-icons/ai";
import { toast, Toaster } from "react-hot-toast";
import * as Io from "react-icons/io";
import * as Bi from "react-icons/bi";
import * as Tb from "react-icons/tb";
import Select from "react-select";
import * as Fa from "react-icons/fa";
import * as Gr from "react-icons/gr";
import { Modal } from "antd";
import { AppContext } from "../../Context/AppContext";

function Customers({ wrapperRef, filteredEnquiries, searchTerm }) {
  const {
    enquiryLoading,
    shopCategories,
    updateLeadData,
    companiesList,
    fetchAllSellerEnquiries,
  } = useContext(AppContext);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [leadEdit, setleadEdit] = useState(false);
  const [sortedList, setSortedList] = useState(null);
  const [newJobQueueStatus, setNewJobQueueStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [editValues, setEditValues] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [error, setError] = useState(false);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedList?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedList?.length / itemsPerPage);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Parse and set the initial selected categories from the edit state

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const filterLeads = () => {
      setFilteredLeads(
        filteredEnquiries?.leads?.filter((enquiry) => {
          const data = enquiry.sellerDetails;
          const shopName = data?.shopName?.toLowerCase() || "";
          const shopUrl = data?.shopUrl?.toLowerCase() || "";
          const sellerEmail = data?.sellerEmail?.toLowerCase() || "";
          const mobile = data?.mobile?.toString() || "";

          return (
            shopName.includes(searchTerm) ||
            shopUrl.includes(searchTerm) ||
            sellerEmail.includes(searchTerm) ||
            mobile.includes(searchTerm)
          );
        })
      );
    };

    filterLeads();
  }, [searchTerm, filteredEnquiries]);

  const getCurrentStatus = (enquiry) => {
    if (enquiry?.cmpStatus === 0) {
      return "pending";
    }

    const data = enquiry.jobQueueStatus
      ?.slice()
      .reverse()
      .find((x) => x?.status === "completed");

    return data?.taskname || "Not Started";
  };

  useEffect(() => {
    if (filteredLeads != null && Array.isArray(filteredLeads)) {
      const sortedFilterLeads = filteredLeads
        .slice()
        .sort((a, b) =>
          a.sellerDetails?.shopName
            ?.trim()
            .localeCompare(b.sellerDetails?.shopName?.trim())
        );
      setSortedList(sortedFilterLeads);
    }
  }, [filteredLeads]);

  useEffect(() => {
    if (sortedList && Array.isArray(sortedList) && sortedList.length > 0) {
      const jobQueueStatusArray = sortedList[0]?.jobQueueStatus || [];
      setNewJobQueueStatus(jobQueueStatusArray);
    }
  }, [sortedList]);

  const cancelUpdate = () => {
    setleadEdit(false);
  };

  const handleEdit = (data, enquiry) => {
    if (data == null) {
      setleadEdit(false);
    } else {
      setleadEdit(true);
      setUpdateData(enquiry);
      setEditValues(data);
    }
  };
  console.log(editValues, "values");
  useEffect(() => {
    if (editValues?.categoryId) {
      const categoryIds = editValues?.categoryId
        .split(",")
        .map((id) => parseInt(id));
      const initialSelectedCategories = shopCategories
        .filter((category) => categoryIds.includes(category.scgID))
        .map((category) => ({ value: category.scgID, label: category.name }));
      setSelectedCategories(initialSelectedCategories);
    }
  }, [editValues, shopCategories]);

  // Map shopCategories to the format required by react-select
  const options = shopCategories.map((category) => ({
    value: category.scgID,
    label: category.name,
  }));

  // Handle changes from react-select
  const onCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
    handleCategoryChange(selectedOptions);
  };

  console.log(editValues, "data");
  const onChnageInfo = (e) => {
    const { name, value } = e.target;
    setEditValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
    setEditValues((prev) => ({
      ...prev,
      categoryId: selectedOptions.map((option) => option.value).join(","),
    }));
  };

  console.log(editValues, "editValues");
  const handlePhoneChange = (formattedValue) => {
    setEditValues((prevEditValues) => ({
      ...prevEditValues,
      mobile: formattedValue,
    }));
  };
  const validate = () => {
    if (
      editValues?.shopName == "" ||
      editValues?.postcode == "" ||
      editValues?.mobile == "" ||
      editValues?.sellerName == "" ||
      editValues?.name == "" ||
      editValues?.sellerEmail == "" ||
      editValues?.country == "" ||
      editValues?.town == "" ||
      editValues?.address1 == ""
    ) {
      toast.error("Fields cannot be Empty!");
      return false;
    }
    return true;
  };
  const handleUpdateLead = async () => {
    if (!validate()) {
      return;
    }
    const payload = {
      leadId: updateData?.leadId,
      pceId: updateData?.pceId,
      shopName: editValues?.shopName,
      mobile: editValues?.mobile,
      name: editValues?.sellerName,
      email: editValues?.sellerEmail,
      countryCode: editValues?.countryCode,
      address1: editValues?.address1,
      address2: editValues?.address2,
      town: editValues?.town,
      country: editValues?.country,
      shopUrl: editValues?.shopUrl,
      categoryId: editValues.categoryId || "",
      postCode: editValues?.postcode,
    };

    await updateLeadData(payload, {
      onSuccess: async (res) => {
        toast.success("Lead updated successfully!");
        setleadEdit(false);
        await fetchAllSellerEnquiries();
      },
      onFailed: (err) => {
        toast.error("Failed to update lead:", err);
      },
    });
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <Modal
        open={leadEdit}
        footer={false}
        width={"70%"}
        onCancel={cancelUpdate}
      >
        <h4 className="text-center mx-auto">Update Lead</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleUpdateLead();
          }}
        >
          <div className="row">
            {/* <p className="form_sub">Company info</p>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <label htmlFor="Privilege Name" className="review-label">
                Company Name <span className="required_">*</span>
              </label>
              <div className="review-group ">
                <input
                  name="shopName"
                  type="text"
                  id=""
                  className="review-input"
                  value={editValues?.shopName}
                  onChange={onChnageInfo}
                />
                <i className="inp_ico">
                  <Bs.BsShop />
                </i>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <label htmlFor="Privilege Name" className="review-label">
                Category
              </label>

              
              <Select
                isMulti
                name="categories"
                options={options}
                className="fp-select"
                classNamePrefix="select"
                onChange={handleCategoryChange}
                value={selectedCategories}
              />



            </div> */}
            {/* <div className="col-lg-4 col-md-4 col-sm-4">
              <label htmlFor="Privilege Name" className="review-label">
                Select Company<span>*</span>
              </label>
              <div className={!error ? "review-group" : "review-group error"}>
                <select
                  name=""
                  id=""
                  className="form-select company_select_proposal"
                  onChange={(e) =>
                    setEditValues((prev) => ({
                      ...prev,
                      companyId: e.target.value,
                    }))
                  }
                >
                  <option value="" selected disabled>
                    Choose Company
                  </option>
                  {companiesList &&
                    companiesList.length != 0 &&
                    companiesList.map((company, index) => {
                      return (
                        <option value={company?.id} key={index}>
                          {company?.company_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-4 col-sm-4">
              <label htmlFor="Privilege Name" className="review-label">
                Site URL
              </label>
              <div className="review-group ">
                <input
                  name="shopUrl"
                  type="text"
                  id=""
                  className="review-input"
                  value={editValues?.shopUrl}
                  onChange={onChnageInfo}
                  disabled
                />
                <i className="inp_ico">
                  <Bs.BsShopWindow />
                </i>
              </div>
            </div> */}
            <div className="row mt-3">
              <p className="form_sub">Contact info</p>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <label htmlFor="Privilege Name" className="review-label">
                  Contact Name <span className="required_">*</span>
                </label>
                <div className="review-group ">
                  <input
                    name="sellerName"
                    type="text"
                    id=""
                    className="review-input"
                    value={editValues?.sellerName}
                    onChange={onChnageInfo}
                  />
                  <i className="inp_ico">
                    <Bs.BsFilePerson />
                  </i>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <label htmlFor="Privilege Name" className="review-label">
                  Email Address <span className="required_">*</span>
                </label>
                <div className="review-group ">
                  <input
                    name="sellerEmail"
                    type="email"
                    id=""
                    className="review-input"
                    value={editValues?.sellerEmail}
                    onChange={onChnageInfo}
                  />
                  <i className="inp_ico">
                    <Io.IoMdMailOpen />
                  </i>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <label htmlFor="Privilege Name" className="review-label">
                  Phone Number <span className="required_">*</span>
                </label>

                <div className="review-group">
                  <PhoneInput
                    className="phone-input"
                    country={"gb"}
                    name="mobile"
                    value={editValues?.mobile}
                    onChange={handlePhoneChange}
                  />
                  <i className="inp_ico" id="phone_ico">
                    <Bs.BsTelephone />
                  </i>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <label htmlFor="Privilege Name" className="review-label">
                  Address Line 1
                </label>
                <div className="review-group ">
                  <input
                    name="address1"
                    type="text"
                    id=""
                    className="review-input"
                    value={editValues?.address1}
                    onChange={onChnageInfo}
                  />
                  <i className="inp_ico">
                    <Fa.FaRegAddressCard />
                  </i>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4">
                <label htmlFor="Privilege Name" className="review-label">
                  Address Line 2
                </label>
                <div className="review-group ">
                  <input
                    name="address2"
                    type="text"
                    id=""
                    className="review-input"
                    value={editValues?.address2}
                    onChange={onChnageInfo}
                  />
                  <i className="inp_ico">
                    <Fa.FaRegAddressCard />
                  </i>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4">
                <label htmlFor="Privilege Name" className="review-label">
                  Town/City
                </label>
                <div className="review-group ">
                  <input
                    name="town"
                    type="text"
                    id=""
                    className="review-input"
                    value={editValues?.town}
                    onChange={onChnageInfo}
                  />
                  <i className="inp_ico">
                    <Bi.BiBuildingHouse />
                  </i>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4 mt-3">
                <label htmlFor="Privilege Name" className="review-label">
                  County
                </label>
                <div className="review-group ">
                  <input
                    name="country"
                    type="text"
                    id=""
                    className="review-input"
                    value={editValues?.country}
                    onChange={onChnageInfo}
                  />
                  <i className="inp_ico">
                    <Tb.TbBuildingBank />
                  </i>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4 mt-3">
                <label htmlFor="Privilege Name" className="review-label">
                  Postal Code
                </label>
                <div className="review-group ">
                  <input
                    name="postcode"
                    type="text"
                    id=""
                    className="review-input"
                    value={editValues?.postcode}
                    onChange={onChnageInfo}
                  />
                  <i className="inp_ico">
                    <Bs.BsPostcard />
                  </i>
                </div>
              </div>
            </div>
          </div>

          <br />
          {enquiryLoading ? (
            <button className="fp-button ad_btn mt-3" disabled>
              Updatting...
            </button>
          ) : (
            <button type="submit" className="fp-button ad_btn mt-3">
              Update
            </button>
          )}
        </form>
      </Modal>
      <div className="card p-3 overflow">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>

              <th scope="col">Client Name</th>
              <th scope="col">Email</th>

              <th scope="col" className="text-center">
                Assigned Status
              </th>
              <th scope="col" className="text-center">
                Current Status
              </th>
              <th scope="col" className="text-center">
                View
              </th>
              <th scope="col" className="text-center mx-auto">
                Update
              </th>
            </tr>
          </thead>
          <tbody ref={wrapperRef}>
            {currentItems?.map((enquiry, index) => {
              const data = enquiry.sellerDetails;
              const admin = enquiry.adminDetails;
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{data?.sellerName ?? "N/A"}</td>
                  <td>{data?.sellerEmail ?? "N/A"}</td>

                  <td className="text-center">
                    <h6>
                      {enquiry?.assignStatus === 1 ? (
                        <p className="admin-name"> {admin?.name ?? "N/A"}</p>
                      ) : (
                        <div className="fp-badge pending">Not Assigned</div>
                      )}
                    </h6>
                  </td>
                  <td className="text-center">
                    {/* {enquiry?.status === "pending" ? (
                      <Fragment>
                        <div
                          className={
                            enquiry?.datafill === 1
                              ? "fp-badge success"
                              : "fp-badge pending"
                          }
                          style={{ width: "100%" }}
                        >
                          {Array.isArray(newJobQueueStatus) &&
                          newJobQueueStatus.length != 0
                            ? getCurrentStatus(newJobQueueStatus)?.taskname ??
                              "Not Started"
                            : "Pending"}
                        </div>
                      </Fragment>
                    ) : (
                      <div
                        className={
                          enquiry?.status == "on Boarding"
                            ? "fp-badge failed"
                            : "fp-badge info"
                        }
                        style={{ width: "100%" }}
                      >
                        {enquiry?.status}
                      </div>
                    )} */}
                    <div
                      className={
                        enquiry?.status == "on Boarding" ||
                        enquiry.status == "pending"
                          ? "fp-badge info"
                          : "fp-badge failed"
                      }
                      style={{ width: "100%" }}
                    >
                      {enquiry?.status}
                    </div>
                  </td>
                  <td className="text-center position-relative">
                    <div className="btn-toggle">
                      <button
                        className="one-btn view_enquiry_btn"
                        onClick={() => {
                          window.location.href = `/view-single-enquiry/${enquiry.pceId}/${enquiry.leadId}`;
                        }}
                      >
                        View
                      </button>
                    </div>
                  </td>
                  <td className="text-center">
                    <button
                      className="btn edit-lead"
                      onClick={() => handleEdit(data, enquiry)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <Gr.GrFormPrevious />
              </button>
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                <button
                  className={`page-link ${
                    currentPage === number ? "active" : ""
                  }`}
                  onClick={() => setCurrentPage(number)}
                >
                  {number}
                </button>
              </li>
            ))}

            <li className="page-item">
              <button
                className="page-link"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <Gr.GrFormNext />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Customers;
