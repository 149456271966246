import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useSeller = () => {
  const [sellerList, setSellerList] = useState([]);
  const [shopCategories, setShopCategories] = useState([]);
  const [sellerLoading, setSellerLoading] = useState(false);

  const getAllSeller = async () => {
    try {
      setSellerLoading(true);
      await BaseClient.get(APIEndpoints.getSellerDetails, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setSellerList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting data", err);
        },
      });
    } finally {
      setSellerLoading(false);
    }
  };

  const getAllShopCategories = async () => {
    try {
      setSellerLoading(true);

      await BaseClient.get(APIEndpoints.getAllShopCategories, {
        onSuccess: (res) => {
          if (res.data != null && Array.isArray(res.data)) {
            setShopCategories(res.data);
          }
        },
        onFailed: (err) => {
          console.log("ERROR ON FETCHING SHOP CATEGORIES");
        },
      });
    } finally {
      setSellerLoading(false);
    }
  };

  return {
    getAllSeller,
    getAllShopCategories,
    sellerLoading,
    sellerList,
    shopCategories,
  };
};
export default useSeller;
