import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";
import Sidebar from "../../Components/Sidebar";
import * as Cg from "react-icons/cg";
import * as Bs from "react-icons/bs";
import { SyncLoader } from "react-spinners";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AppContext } from "../../Context/AppContext";
import Utils from "../../Utils/utils";
import Admin from "../../Assets/lottie/Group 1.png";
import Client from "../../Assets/lottie/Group 2.png";
import * as Md from "react-icons/md";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import * as Ai from "react-icons/ai";
import { EditorState, convertToRaw } from "draft-js";
import placeholder from "../../Assets/placeholder.png";

function ViewIssue() {
  const { id, ticketId } = useParams();
  const fileInputRef = useRef(null);
  const {
    supportList,
    supportLoading,
    fetchAllChats,
    chatList,
    createChatMessage,
    updateCompleteStatus,
    messageList,
    fetchMessages,
  } = useContext(AppContext);
  const [supportData, setSupportData] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [attachments, setAttachments] = useState(null);
  const [progressLevel, setProgressLevel] = useState(0);

  useEffect(() => {
    getTicketData();
  }, [id]);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (!supportList) return;
    getSingleIssue();
  }, [supportList, supportLoading]);

  useEffect(() => {
    if (supportData) {
      const filteredChatMessages = chatList.filter(
        (message) => message?.ticketID === supportData?.ticketID
      );
      setChatMessages(filteredChatMessages);
    }
  }, [supportData, chatList]);

  const getTicketData = async () => {
    if (!ticketId) return;
    const data = { ticketID: ticketId };
    await fetchMessages(data, {
      onSuccess: async (res) => {
        if (!Array.isArray(res.data)) return;
        setChatMessages(res.data);
      },
      onFailed: (err) => {
        console.log("ERROR ON FETCHING TICKET MESSAGES", err);
      },
    });
  };

  const getSingleIssue = () => {
    const getSingleSupport = supportList.find((x) => x.Id == id);
    setSupportData(getSingleSupport);

    const filteredChatMessages = chatList.filter(
      (message) => message?.ticketID === getSingleSupport?.ticketID
    );
    setChatMessages(filteredChatMessages);
  };

  const photoArray = supportData?.photo.split(", ");

  const handleResolveComplaint = async (e) => {
    e.preventDefault();
    const payload = {
      ticketId: supportData?.Id,
      status: "Closed",
    };
    setLoading(true);

    await updateCompleteStatus(payload, {
      onSuccess: async (res) => {
        toast.success("Ticket Closed Successfully!");
        setLoading(false);
        window.location.href = "/support";
        setTimeout(() => {
          fetchMessages();
        }, 800);
      },
      onFailed: (err) => {
        toast.error("Error Closing!");
        setLoading(false);
      },
    });
  };

  const showTextBox = () => {
    setNewMessage(!newMessage);
  };
  const closeSupportReply = () => {
    setNewMessage(false);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const newUploadedFiles = [];
    for (let i = 0; i < files.length; i++) {
      newUploadedFiles.push(files[i]);
    }
    setAttachments(newUploadedFiles);
  };

  const handleRemoveFile = (index) => {
    if (index < 0 || index >= attachments.length) return;
    const newData = [...attachments];
    newData.splice(index, 1);
    setAttachments(newData);
  };

  const sentMessage = async (e) => {
    e.preventDefault();
    const editorContent = convertToRaw(editorState.getCurrentContent());

    const messageText = editorContent.blocks
      .map((block) => block.text)
      .join("\n");

    const formData = new FormData();
    formData.append("ticketID", supportData?.ticketID);
    formData.append("message", messageText);
    formData.append("supportID", supportData?.Id);
    formData.append("subject", supportData?.subject);
    formData.append("shopID", supportData?.shopID);

    if (attachments != null) {
      for (let i = 0; i < attachments.length; i++) {
        formData.append("file", attachments[i]);
      }
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    // return;

    setLoading(true);
    await createChatMessage(formData, {
      onProgress: (res) => {
        const event = res?.event;
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setProgressLevel(Math.round(progress));
        }
      },
      onSuccess: async (res) => {
        toast.success("Support Message Created Successfully!");
        setLoading(false);
        setEditorState(EditorState.createEmpty());
        setProgressLevel(0);
        setAttachments(null);
        setTimeout(() => {
          getTicketData();
        }, 800);
      },
      onFailed: (err) => {
        toast.error("Error in Adding Message!");
        setLoading(false);
        setProgressLevel(0);
      },
    });
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <Toaster position="top-center" reverseOrder={false} />
        <div className="container">
          <div className="request-data position-relative">
            <h6 className="mb-3">{supportData?.subject ?? "N/A"}</h6>
            <h6 className="req-head mb-3">Request Details</h6>
            {supportData?.status !== "Closed" && (
              <button
                className="fp-button resolved-btn"
                onClick={handleResolveComplaint}
                disabled={loading}
              >
                <Bs.BsCheck2All /> <span>Resolved</span>
              </button>
            )}
            <table class="table ">
              <tr>
                <td> Case Id</td>
                <td>{supportData?.ticketID ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Case Message</td>
                <td>{supportData?.message ?? "N/A"}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{supportData?.name ?? "N/A"}</td>
              </tr>

              <tr>
                <td>Date of Request</td>
                <td>
                  {supportData?.created_at
                    ? Utils.formatDate(supportData.created_at)
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td className="badge-text">{supportData?.status ?? "N/A"}</td>
              </tr>
            </table>{" "}
            {supportData != null && supportData.photo != "" && (
              <h6 className="req-head mt-3 mb-3">attachments</h6>
            )}
            <div className="d-flex p-2">
              {supportData != null && supportData.photo != ""
                ? Array.isArray(photoArray) &&
                  photoArray?.map((image, index) => {
                    return (
                      <>
                        <img
                          src={
                            image != null && image?.length != 0
                              ? process.env.REACT_APP_20i_IMAGE_URL + image
                              : placeholder
                          }
                          className="attachment"
                          alt="No Images"
                        />
                      </>
                    );
                  })
                : ""}
            </div>
            <br />
            <div className="row chat-support m-1">
              {supportData?.status === "Closed" ? (
                <p className="chat-log">This Ticket has been Resolved!</p>
              ) : (
                <div className="button-re" onClick={showTextBox}>
                  <button className="reply-button">Reply</button>
                  <i
                    className={!newMessage ? "drop-chat" : "drop-chat rotate"}
                    onClick={showTextBox}
                  >
                    <Md.MdArrowDropDown />
                  </i>
                </div>
              )}

              <div
                className={newMessage ? "bordered-div show" : "bordered-div "}
              >
                <div className="row pt-3 px-3">
                  <form onSubmit={(e) => sentMessage(e)}>
                    <div className="card edit-box">
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(newEditorState) =>
                          setEditorState(newEditorState)
                        }
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "list",
                            "textAlign",
                            "history",
                          ],
                        }}
                        className="mb-3"
                        upl
                      />
                      <br />
                    </div>
                    <div className="d-flex message-nav py-3">
                      <button
                        type="submit"
                        className="send-button"
                        disabled={loading}
                      >
                        {loading ? "Sending..." : "Send"}
                      </button>

                      <button
                        className="up_btn"
                        onClick={handleIconClick}
                        type="button"
                      >
                        <Cg.CgAttachment /> Upload
                      </button>
                      <input
                        ref={fileInputRef}
                        type="file"
                        name=""
                        id=""
                        className="ticket_file"
                        onChange={handleFileUpload}
                        multiple
                      />

                      {/* <button
                          onClick={closeSupportReply}
                          className="close-data"
                        >
                          <Ai.AiOutlineClose />
                        </button> */}
                    </div>
                    {attachments != null && attachments.length != 0 && (
                      <div className="d-flex">
                        {attachments.map((file, index) => {
                          return (
                            <div className="file_name" key={index}>
                              <span>{file?.name ?? "N/A"}</span>
                              <button
                                type="button"
                                className="cls_btn"
                                onClick={() => handleRemoveFile(index)}
                              >
                                <Ai.AiFillCloseCircle />
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <br />
                    {progressLevel > 0 && attachments != null && (
                      <div class="progress mb-3" style={{ height: "5px" }}>
                        <div
                          class="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: `${progressLevel}%` }}
                          aria-valuenow={{ progressLevel }}
                        ></div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
            {/* <div className="msg_area_wrapper"></div> */}
            <div className="row">
              <div className="">
                {supportData?.status === "Closed" ? (
                  <>
                    {" "}
                    <h6 className="req-head mb-3 mt-3">Older Conversations</h6>
                  </>
                ) : (
                  <h6 className="req-head mb-3 mt-3">Conversations</h6>
                )}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                {chatMessages != null &&
                  Array.isArray(chatMessages) &&
                  chatMessages
                    .filter((chatMessage) => chatMessage != null)
                    .toReversed()
                    .map((chatMessage, index) => {
                      return (
                        <Fragment>
                          <div key={index}>
                            <div className="card review-card p-3">
                              <div className="verified_customer_section">
                                <div className="image_review">
                                  <div className="customer_image">
                                    {chatMessage?.client === false ? (
                                      <img
                                        src={Admin}
                                        alt="customer"
                                        className="rounded-img"
                                      />
                                    ) : (
                                      <p className="comp-name">
                                        <img
                                          src={Client}
                                          alt="customer"
                                          className="rounded-img"
                                        />
                                      </p>
                                    )}
                                  </div>

                                  <div className="_review_status">
                                    {chatMessage?.client === false ? (
                                      <p className="comp-name">Admin</p>
                                    ) : (
                                      <p className="comp-name">
                                        {chatMessage?.shopname ?? "Shop name"}
                                      </p>
                                    )}

                                    <div className="date">
                                      {" "}
                                      <div className="col-one">
                                        <p id="review_info_">
                                          <Ai.AiFillCalendar />{" "}
                                          <span>
                                            {chatMessage?.created_at
                                              ? Utils.formatDate(
                                                  chatMessage.created_at
                                                )
                                              : "N/A"}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <p className="comp_category">
                                      <span>
                                        <p className="user_connection">
                                          {chatMessage?.client === false ? (
                                            <p className="comp-des">Admin</p>
                                          ) : (
                                            <p className="comp-des">Client</p>
                                          )}
                                        </p>
                                      </span>
                                    </p>
                                  </div>
                                </div>

                                <div className="cmnt_wrapper">
                                  <p className="comp-des">
                                    {chatMessage?.message ?? "N/A"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default ViewIssue;
