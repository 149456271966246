import React, { useContext, useState, useEffect } from "react";
import * as Bi from "react-icons/bi";
import * as Ai from "react-icons/ai";
import { AppContext } from "../../../Context/AppContext";
import placeholder from "../../../Assets/placeholder.png";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";

function AssignedProducts(props) {
  const {
    productList,
    updateSingleEnquiry,
    fetchAllSellerEnquiries,
    enquiryLoading,
  } = useContext(AppContext);
  const [productData, setProductData] = useState(null);
  const [monthly, setMonthly] = useState(true);
  const [payload, setPayload] = useState({
    pceId: "",
    products: "",
    monthly: true,
  });
  const [proLoading, setProLoading] = useState(false);


  useEffect(() => {
    if (!props.id) return;

    setPayload((prev) => ({ ...prev, pceId: parseInt(props.id) }));
  }, [props.id]);

  useEffect(() => {
    setPayload((prev) => ({
      ...prev,
      monthly: monthly,
    }));
  }, [monthly]);

  const handleProductChange = (id) => {
    if (!id) return;

    const filteredData = productList.find((x) => x.pId == id);

    setProductData(filteredData);
    setPayload((prev) => ({ ...prev, products: filteredData?.pId }));
    setMonthly(true);
  };

  const handleCloseProduct = () => {
    setProductData(null);

    const selectElement = document.getElementById("dropList");
    selectElement.selectedIndex = 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (payload.products.length == 0 || payload.pceId.length == 0) return;
    setProLoading(true);

    await updateSingleEnquiry(payload, {
      onSuccess: async (res) => {
        setProLoading(false);
        toast.success("Product added successfully!");
        await fetchAllSellerEnquiries();
      },
      onFailed: (err) => {
        setProLoading(false);
        console.log(err);
        console.log("PRODUCT ADDED ON ENQUIRY NOT WORKING");
      },
    });
  };

  return (
    <div>
      <div className="card mt-2 p-3">
        {props.productData != null && props.productData[0].pId != null ? (
          props.productData.map((pro, index) => {
            return (
              <>
                <table class="table personal-info-table" key={index}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Product Name</b>
                      </td>
                      <td>{pro?.productName ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Price</b>
                      </td>
                      <td>
                        {!pro?.monthly ? pro?.price_yearly : pro?.price_monthly}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            );
          })
        ) : (
          <>
            <h4 className="not_assigned">NO PRODUCTS</h4>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="" className="form-label review-label">
                      Product
                    </label>
                    <div className="review-group ">
                      <select
                        name=""
                        id="dropList"
                        className="fp-select"
                        onChange={(e) => handleProductChange(e.target.value)}
                      >
                        <option value="0" selected disabled>
                          Choose a Product
                        </option>
                        {productList != null &&
                          Array.isArray(productList) &&
                          productList.map((product, index) => {
                            return (
                              <>
                                <option value={product.pId} key={index}>
                                  {product?.productName ?? "N/A"}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      <i className="inp_ico">
                        <Bi.BiFoodMenu />
                      </i>
                    </div>
                    <br />
                    {productData != null ? (
                      <>
                        <div className="card product_card_ ">
                          <button
                            className="close_btn_"
                            type="button"
                            onClick={() => handleCloseProduct()}
                          >
                            <Ai.AiOutlineClose />
                          </button>
                          <h5>{productData?.productName ?? "N/A"}</h5>
                          <div className="avail_platforms">
                            {productData?.worksOn.includes("ANDROID") && (
                              <i className="platform_ico">
                                <Ai.AiFillAndroid />
                              </i>
                            )}
                            {productData?.worksOn.includes("iOS") && (
                              <i className="platform_ico">
                                <Ai.AiFillApple />
                              </i>
                            )}
                          </div>

                          <div className="pro_img_">
                            <img
                              src={
                                productData.profilephoto != null
                                  ? process.env.REACT_APP_AWS_IMAGE_URL +
                                    "products/" +
                                    productData?.profilephoto
                                  : placeholder
                              }
                              alt=""
                            />
                          </div>
                          <div className="price_details">
                            <div
                              className={
                                monthly
                                  ? "price_ active monthly"
                                  : "price_ monthly"
                              }
                              onClick={() => setMonthly(true)}
                            >
                              £{productData?.price_monthly ?? "-"}/mo
                            </div>
                            {productData.price_yearly != "0" ? (
                              <div
                                className={
                                  monthly
                                    ? "price_ yearly"
                                    : "price_ active yearly"
                                }
                                onClick={() => setMonthly(false)}
                              >
                                £{productData?.price_yearly ?? "-"}/yearly
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    <button
                      type="submit"
                      className="fp-button add_prod"
                      disabled={proLoading}
                    >
                      {!proLoading ? (
                        "Add product"
                      ) : (
                        <ThreeDots
                          height="18"
                          width="50"
                          radius="5"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          wrapperClassName="loader_"
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default AssignedProducts;
