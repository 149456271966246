import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../Style/Style.css";
import Utils from "../../Utils/utils";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../../Assets/pakawaanlogoo.png";
import Signature from "../../Assets/signature.png";
import { FaArrowLeftLong } from "react-icons/fa6";
import { AppContext } from "../../Context/AppContext";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import generatePDF from "react-to-pdf";
import jsPDF from "jspdf";
import SendMailTemplateModal from "../../Components/mail/SendMailTemplateModal";

function ContractDoc() {
  const { sendSingleContract, contractLoading } = useContext(AppContext);
  const [total, setTotal] = useState(0);
  const [showMailModal, setShowMailModal] = useState(false);
  const [parsedBody, setParsedBody] = useState(null);
  const [mailSubject, setMailSubject] = useState(null);
  const [ccValue, setCcValue] = useState(null);
  const [dueDate, setDueDate] = useState("");
  const params = useParams();
  const contract = JSON.parse(sessionStorage.getItem("contractDetails"));
  console.log(contract.list.customersDetails, "contract");
  const info = JSON.parse(sessionStorage.getItem("DirectProposal"));
  const data = contract?.contractData;
  const dataDetails = contract.list.productDetails[0];
  const selectedContractProduct = info.productDetails[0];
  console.log(dataDetails, "dara");

  // const companyData = info?.companyDetails[0];
  const currentDate = Utils.formatDate(new Date());
  const targetRef = useRef();
  const options = {
    page: {
      // margin: Margin.SMALL,
      format: "letter",
      orientation: "portrait",
    },
  };
  const storedDates = JSON.parse(sessionStorage.getItem("contractDates"));
  const navigate = useNavigate();
  const comp = JSON.parse(sessionStorage.getItem("DynamicComapny"));
  // const companyDetails = JSON.parse(comp);

  const handleShowModal = () => {
    // sessionStorage.setItem("companyName", companyData?.company_name);
    sessionStorage.setItem("clientName", info?.customersDetails?.clientName);
    sessionStorage.setItem(
      "serviceName",
      JSON.stringify(selectedContractProduct)
    );
    sessionStorage.setItem("contractDate", new Date(info?.created_at));
    setShowMailModal(true);
  };
  console.log(contract, "con");

  const handleSendContract = async () => {
    let datatoSent = {
      selectedContractProduct,
      info,
      storedDates,
    };
    const data = JSON.stringify(datatoSent);

    // Add the small-font class before capturing the canvas
    targetRef.current.classList.add("small-font");

    const canvas = await html2canvas(targetRef.current, {
      imageTimeout: 0,
      scale: 2, // Adjust the scale to balance between quality and size
      useCORS: true,
    });

    // Remove the small-font class after capturing the canvas
    targetRef.current.classList.remove("small-font");

    const imgData = canvas.toDataURL("image/jpeg", 0.7);
    const pdf = new jsPDF("p", "mm", "a4", true);

    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    const mailContent = {
      subject: mailSubject,
      body: parsedBody?.data ? parsedBody?.data : parsedBody,
      cc: ccValue ?? "",
    };

    const pdfBlob = pdf.output("blob");
    const formData = new FormData();
    formData.append("email", info?.customersDetails?.email ?? "N/A");
    formData.append("username", info?.customersDetails?.clientName ?? "N/A");
    formData.append("propId", params.id);
    formData.append("userDetails", data);
    formData.append("file", pdfBlob, "contract.pdf");
    formData.append("mailContent", JSON.stringify(mailContent));

    await sendSingleContract(formData, {
      onSuccess: async (res) => {
        toast.success("Contract Sent Successfully!");
        sessionStorage.removeItem("companyName");
        sessionStorage.removeItem("clientName");
        sessionStorage.removeItem("serviceName");
        sessionStorage.removeItem("contractDate");
        setTimeout(() => {
          window.location.href = "/proposals";
        }, 1000);
      },
      onFailed: (err) => {
        toast.error("Something Went Wrong!");
        console.log(err);
      },
    });
  };

  const navBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!selectedContractProduct) {
      console.log("SELECTED PRODUCT IS NOT DEFINED");
      return;
    }

    const totalamt =
      (selectedContractProduct?.price *
        selectedContractProduct?.quantity *
        selectedContractProduct?.tax) /
      100;
    const withoutTax =
      selectedContractProduct?.price * selectedContractProduct?.quantity;
    setTotal(totalamt + withoutTax);
  }, [selectedContractProduct]);
  // console.log(companyDetails, "companyDetails ");
  return (
    <>
      {showMailModal && (
        <SendMailTemplateModal
          showMailModal={showMailModal}
          setShowMailModal={setShowMailModal}
          handleSendProposal={handleSendContract}
          parsedBody={parsedBody}
          setParsedBody={setParsedBody}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          ccValue={ccValue}
          setCcValue={setCcValue}
          title="contract"
          sendMail={handleSendContract}
          data={info}
        />
      )}
      <Toaster position="top-center" reverseOrder={false} />
      <div className="container">
        <div className="col-md-10 top_cont">
          <div className="container mt-3">
            <button className="back-contract" onClick={navBack}>
              <FaArrowLeftLong /> Back
            </button>
            {contractLoading ? (
              <button className="btn-contract" disabled>
                sending...
              </button>
            ) : (
              <button
                className="btn-contract"
                onClick={() => handleShowModal()}
              >
                Send Contract
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2"></div>
        </div>
        <h4 className="mt-3 mb-3 cont_head text-center">
          Contract Signing Agreement
        </h4>
        <div
          className="contract-page MB-3 col-md-12 col-lg-10 col-sm-12 mx-auto"
          ref={targetRef}
        >
          <div className="mt-3">
            <div className="logo-are">
              <img src={Logo} className="logo-t p-2" />

              <br />
            </div>
            <div className="data-cont">
              <b>Pakwaan</b>
              <br />
              <small>+44 1245 422 891</small>
              {/* <small> {companyData?.phone ?? "N/A"}</small> */}
              <br />
              <div className="row">
                <small>info@pakwaan.co.uk</small>
                {/* <small> {companyData?.email ?? "N/A"}</small> */}
                <small className="-rt">
                  Due Date :<b> {Utils.formatDate(storedDates.dueDate)}</b>
                  <br />
                </small>
              </div>
            </div>
            <div className="contract">
              <p className="contract_details">
                {" "}
                This agreement is established on{" "}
                <b>
                  {" "}
                  {Utils.formatDate(info?.created_at) ?? "N/A"}
                </b> between <b>Pakwaan</b>, and its principal office situated
                at
                {" " + data?.address ?? "N/A"}, hereinafter referred to as
                "Company".
                {/* <br /> */}
                <p className="mt-1">
                  and <br />
                  <b> {contract?.list?.customersDetails?.shopName ?? "N/A"}</b>,
                  having its principal office at Pakwaan Indian Punjabi Cuisine
                  1 Bridge St, Writtle, Chelmsford CM1 3EY, hereinafter referred
                  to as "Merchant". <br />
                </p>
                <div className="mt-3"></div>
                <b>Purpose:</b>
                <div className="mb-3"></div>
                The purpose of this agreement is to formalise the terms and
                conditions under which both parties agrees,
                <br />
                <div className="mt-3"></div>
                <h6>
                  <b>Terms and Conditions:</b>
                </h6>
                <div className="mb-3"></div>
                <ul className="list-cont">
                  <li>
                    <b>Product Details:</b> The parties hereby agree on the
                    specific products to be supplied, including quantities,
                    specifications, and quality standards.
                  </li>
                  <li>
                    <b>Price and Payment: </b>The price per unit and total
                    payment terms are as follows: Merchant shall pay
                    <b>
                      {" " + "£"}
                      {(dataDetails?.price *
                        dataDetails?.quantity *
                        dataDetails?.tax) /
                        100 +
                        dataDetails?.price * dataDetails?.quantity ?? 0}
                    </b>{" "}
                    to Pakwaan for the supplied products.
                  </li>
                  <li>
                    <b>Quality Assurance:</b> The Merchant agrees to adhere to
                    all applicable quality standards and regulations in the
                    production and supply of the Pakwaan products.
                  </li>
                  <li>
                    <b> Product Duration:</b> The Merchant acknowledges and
                    agrees that the duration{" "}
                    <b>
                      {Utils.formatDate(storedDates?.startDate) ?? "N/A"} to
                      {" " + Utils.formatDate(storedDates?.endDate) ?? "N/A"}
                    </b>{" "}
                    of product usage shall be as specified in this agreement,
                    building upon the terms established in the previous Deal.
                  </li>
                  <li>
                    <b> Confidentiality:</b> Both parties agree to maintain the
                    confidentiality of any proprietary or sensitive information
                    disclosed during the course of this agreement, including but
                    not limited to product specifications and business
                    strategies. The Merchant agrees that the software products
                    provided under this agreement shall not be shared with any
                    other party, and both parties shall take all necessary
                    measures to protect the confidentiality and security of the
                    data exchanged.
                  </li>
                  {/* <li>
                    <b> Consequences of Breach</b>: In the event of a breach of
                    this confidentiality agreement related to the usage of the
                    software product, the non-breaching party reserves the right
                    to immediately terminate this agreement. Furthermore, the
                    breaching party shall be liable for any resulting damages,
                    including but not limited to financial losses and legal
                    expenses incurred by the non-breaching party.
                  </li> */}
                </ul>
              </p>
              <div className="mt-3 mb-3">
                <p className="deal_head text-center mb-3">PRODUCT DETAILS</p>
                <div className="contract_Card">
                  <form className="deal_form">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Product</label>
                        <div className="form-disabled">
                          {dataDetails?.productName ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Price</label>
                        <div className="form-disabled">
                          {dataDetails?.price ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Quantity</label>
                        <div className="form-disabled">
                          {dataDetails?.quantity ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> VAT(%)</label>
                        <div className="form-disabled">
                          {dataDetails?.tax ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor="">VAT Amount</label>
                        <div className="form-disabled">
                          {(dataDetails?.price *
                            dataDetails?.quantity *
                            dataDetails?.tax) /
                            100}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor="">Total</label>
                        <div className="form-disabled">
                          £
                          {(dataDetails?.price *
                            dataDetails?.quantity *
                            dataDetails?.tax) /
                            100 +
                            dataDetails?.price * dataDetails?.quantity}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Subscription Type</label>
                        <div className="form-disabled">
                          {dataDetails?.subscription ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <p className="desc">
                          {dataDetails?.description ?? "N/A"}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="brkdiv"></div>

                <p className="details">
                  In witness whereof, the parties hereto have executed this
                  agreement as of the date mentioned above.{" "}
                </p>

                <div className="footer_contract footer_sign">
                  <p>
                    <b>
                      Contract Date:
                      {Utils.formatDate(info?.created_at) ?? "N/A"}
                    </b>
                  </p>

                  <div className="row mb-3 footer-box">
                    <div className="col-md-8 col-sm-12">
                      <u>
                        <b>Company Info</b>
                      </u>
                      <p
                        className="mt-3 mb-3"
                        style={{ position: "relative", top: "20px" }}
                      >
                        {" "}
                        [Owner Name] <br />
                        {/* {companyData?.company_name ?? "N/A"} */}
                      </p>
                      {/* <h6 style={{}}> {data.companyName ?? "N/A"}</h6> */}
                    </div>
                    <div className="col-md-4 col-sm-12">
                      {" "}
                      <p>
                        Authorised Signature:
                        <br />
                        <img src={Signature} className="signature" />
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3 mb-3 footer-box">
                    <div className="col-md-8 col-sm-12">
                      <u>
                        {" "}
                        <b>Merchant</b>
                      </u>
                      <h6 className="mt-3">
                        {info?.customersDetails?.shopName ?? "N/A"}
                        <br /> {info?.customersDetails?.clientName ?? "N/A"}
                      </h6>
                      <h6 className="mt-3 address-contract">
                        {info?.customersDetails?.address ?? "N/A"}
                      </h6>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <p>
                        Merchant's Signature : <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractDoc;
