import React from "react";
import Lottie from "react-lottie";
import notFound from "../../Assets/lottie/not_found.json";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate()
  const lottieOption = {
    loop: true,
    autoplay: true,
    animationData: notFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <div className="error_wrapper">
        <div className="not_found">
          <Lottie options={lottieOption}></Lottie>
          <br/>
          <button
            type="button"
            className="err_btn"
            onClick={() => navigate("/")}
          >
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
