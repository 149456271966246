import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Sidebar from "../../Components/Sidebar";
import Utils from "../../Utils/utils";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import { Modal } from "antd";

function SingleCustomer() {
  const params = useParams();
  const navigate = useNavigate();
  const id = params?.id;
  const leadId = params?.leadId;
  const {
    allSellerEnquiries,
    getDetailsOfUser,
    fetchProposalByleadId,
    finalize,
    getNewFinalizedProposals,
    leadInvoices,
    invoicesList,
    cancelInvoice,
    getAllInvoices,
  } = useContext(AppContext);
  const [enquiryData, setEnquiryData] = useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [openDropdownIndexInv, setOpenDropdownIndexInv] = useState(-1);
  const [proposalList, setProposalList] = useState(null);
  const [ViewProposal, setViewProposal] = useState(false);
  sessionStorage.setItem("enquiryData", JSON.stringify(enquiryData));
  const [finalizedProposals, setFinalizedProposals] = useState(null);
  const [packageDetails, setPackageDetails] = useState(null);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const [invoicesListData, setInoicesListData] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [viewModal, setViewModal] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  console.log("invoicesList", invoicesListData);

  useEffect(() => {
    getEnquiryDetails();
  }, [allSellerEnquiries]);

  const getInvoicesData = async () => {
    if (!leadId) return;
    await leadInvoices(leadId, {
      onSuccess: (res) => {
        if (res && res.data) {
          setInoicesListData(res.data);
        } else {
          setInoicesListData([]);
        }
      },
      onFailed: (err) => {
        console.log("ERROR ON GETTING INVOICES LIST", err);
      },
    });
  };

  useEffect(() => {
    getInvoicesData();
  }, []);

  console.log("invoicesList", invoicesList);

  const toggleDropdownInvoice = (index) => {
    setOpenDropdownIndexInv((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const handlePayment = (data) => {
    sessionStorage.setItem("updatePayment", JSON.stringify(data));
    navigate("/payment-status");
  };

  const ConfirmDelete = async (data) => {
    const id = data?.invoiceId;
    if (!id) return;
    await cancelInvoice(id, {
      onSuccess: async (res) => {
        toast.success("Invoice Cancelled Successfully!");
        await getInvoicesData();
      },
      onFailed: (err) => {
        toast.error(err.errormessage);
      },
    });
  };

  const getEnquiryDetails = () => {
    const id = params.id;
    if (!id || !allSellerEnquiries) return;
    const singleData = allSellerEnquiries?.find((x) => x?.pceId == params?.id);
    setEnquiryData(singleData);
  };

  useEffect(() => {
    if (enquiryData != null) {
      getLeadProposals();
      getFinalizedProposals();
    }
  }, [enquiryData]);

  const getLeadProposals = async () => {
    const payload = {
      leadId: params?.leadId,
    };

    try {
      setFetchLoading(true);
      await fetchProposalByleadId(payload, {
        onSuccess: async (res) => {
          setProposalList(res.data);
        },
        onFailed: (err) => {
          console.log("failed");
        },
      });
    } finally {
      setFetchLoading(false);
    }
  };
  console.log("selectedInvoice", selectedInvoice);
  const handleView = (data) => {
    setSelectedInvoice(data);
    setViewModal(true);
  };

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const getFinalizedProposals = async () => {
    const payload = {
      leadId: params?.leadId,
    };

    await getNewFinalizedProposals(payload, {
      onSuccess: async (res) => {
        setFinalizedProposals(res.data);
      },
      onFailed: (err) => {
        console.log("failed");
      },
    });
  };
  console.log(finalizedProposals, "finalized");
  // const handlefinalizeProposal = async (proposal) => {
  //   const payload = {
  //     propId: proposal.propId,
  //     leadId: params.leadId,
  //   };

  //   await finalizeProposal(payload, {
  //     onSuccess: async (res) => {
  //       toast.success("Proposal Finalised!");
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, []);
  //     },
  //     onFailed: (err) => {
  //       toast.error("Error in Fetching  Data!");
  //     },
  //   });
  // };

  const finalizeProposal = async (propId) => {
    await finalize(propId, {
      onSuccess: async (res) => {
        toast.success("Proposal Finalized Successfully!");
        setTimeout(() => {
          window.location.reload();
        }, []);
      },
      onFailed: (err) => {
        toast.error("Something Went Wrong!");
      },
    });
  };

  const handleInvoice = (proposal) => {
    sessionStorage.setItem("selectedProduct", JSON.stringify(proposal));
    let path = `${id}/${leadId}`;
    sessionStorage.setItem("path", path);
    sessionStorage.setItem("leadId", leadId);
    setTimeout(() => {
      navigate("/create-direct-Invoice");
    }, 1000);
  };
  const handleOk = () => {
    setViewProposal(false);
  };
  console.log(enquiryData, "enquiryData");
  const handleCancel = () => {
    setViewProposal(false);
  };
  const handleViewProposal = (proposal) => {
    setViewProposal(true);
    if (!proposal) return;
    setSelectedProposal(proposal);
    const selected = JSON.parse(proposal.productDetails);
    setPackageDetails(selected);
  };

  const addNewNavigate = () => {
    let path = `${id}/${leadId}`;
    sessionStorage.setItem("path", path);
    navigate("/add-new-propsal");
  };

  return (
    <>
      <div className="container-fluid">
        <Toaster position="top-center" reverseOrder={false} />
        <Sidebar>
          <Modal
            open={ViewProposal}
            onOk={handleOk}
            width={"60%"}
            footer={false}
            onCancel={handleCancel}
          >
            <div className="card p-3 mt-3">
              <h5 className="">Proposal Details</h5>
              {selectedProposal?.name ?? "N/A"} <br />
              {selectedProposal?.email ?? "N/A"} <br />
              {Utils.formatDate(selectedProposal?.due_date) ?? "N/A"}
              <h6 className="mt-2">Package Details</h6>
              <div className="col-md-8 mt-1">
                <table className="table table-bordered">
                  <tbody>
                    {packageDetails != null &&
                      packageDetails?.itemsArray != null &&
                      packageDetails?.itemsArray?.map((items, index) => {
                        console.log(items, "fjcsdghfdjhgjhi");
                        return (
                          <tr key={index}>
                            <td>{items?.description ?? "N/A"}</td>
                            <td>£{items?.price ?? "N/A"}</td>
                          </tr>
                        );
                      })}
                    {packageDetails != null &&
                      packageDetails?.elementArray != null &&
                      packageDetails?.elementArray?.map((items, index) => {
                        console.log(items, "fjcsdghfdjhgjhi");
                        return (
                          <tr key={index}>
                            <td>{items?.item ?? "N/A"}</td>
                            <td>£{items?.price ?? "N/A"}</td>
                          </tr>
                        );
                      })}

                    <tr>
                      <td
                        style={{
                          textAlign: "right",
                          alignItems: "right",
                        }}
                      >
                        <b>Sub Total</b>
                      </td>
                      <td>£{packageDetails?.values?.subTotal ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "right",
                          alignItems: "right",
                        }}
                      >
                        <b>VAT %</b>
                      </td>
                      <td>{packageDetails?.values?.VAT ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "right",
                          alignItems: "right",
                        }}
                      >
                        <b>VAT Amount</b>
                      </td>
                      <td>£{packageDetails?.values?.taxAmount ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "right",
                          alignItems: "right",
                        }}
                      >
                        <b>Total</b>
                      </td>
                      <td>£{packageDetails?.values?.total ?? "N/A"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal>

          {/* invoice Modal */}
          <Modal
            open={viewModal}
            onOk={() => setViewModal(false)}
            onCancel={() => setViewModal(false)}
            width={1200}
            footer={null}
          >
            {selectedInvoice?.status == "cancel" ? (
              <h4 className="cancelled text-center m-3">
                Cancelled Invoice Details
              </h4>
            ) : (
              <h4 className="text-center"> Invoice Details</h4>
            )}

            <div className="card p-3 m-3">
              {/* <div className="col">
              {" "}
              {selectedInvoice?.status == "cancel" && (
                <div className="cancelled">Cancelled Invoice</div>
              )}
            </div> */}
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                  <small>Invoice Number</small>
                  <br />
                  <input
                    className="form-control"
                    value={selectedInvoice?.invoiceNum ?? "N/A"}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                  <small>Customer Name</small>
                  <br />
                  <input
                    className="form-control"
                    value={selectedInvoice?.cust_name ?? "N/A"}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                  <small>Customer Email</small>
                  <br />
                  <input
                    className="form-control"
                    value={selectedInvoice?.cust_email ?? "N/A"}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                  <small>Invoice Date</small>
                  <br />
                  <input
                    className="form-control"
                    value={Utils.formatDate(
                      selectedInvoice?.invoice_date ?? "N/A"
                    )}
                  />
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                  <small>Due Date</small>
                  <br />
                  <input
                    className="form-control"
                    value={Utils.formatDate(selectedInvoice?.due_date ?? "N/A")}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                  <small>Invoice Total</small>
                  <br />
                  <input
                    className="form-control"
                    value={selectedInvoice?.total ?? "N/A"}
                  />
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                  <small>Method</small>
                  <br />
                  <input
                    className="form-control"
                    value={selectedInvoice?.method ?? "N/A"}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 mt-1 mb-2">
                  <small>Billing Address</small>
                  <br />
                  <textarea
                    className="form-control"
                    value={selectedInvoice?.billingAddress ?? "N/A"}
                  />
                </div>
                {/* <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Recurring Type</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.recurring_type ?? "N/A"}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 mt-1 mb-2">
                <small>Recurring Limit</small>
                <br />
                <input
                  className="form-control"
                  value={selectedInvoice?.recurring_limit ?? "N/A"}
                />
              </div> */}
              </div>

              <div className="row mt-3">
                <b>Product Details</b>
                {selectedInvoice?.productDetails != null && (
                  <>
                    {JSON.parse(selectedInvoice.productDetails)?.map(
                      (product, index) => (
                        <div
                          className="col-md-4 col-sm-6 mt-3 mb-3"
                          key={index}
                        >
                          <div className="card p-3">
                            <p>
                              <strong>Package Name:</strong>{" "}
                              {product.productName} <br />
                              <strong>Bill Total:</strong>{" "}
                              {selectedInvoice?.total}
                              <br />
                              <strong>Discount:</strong> {product.discount}
                              <br />
                              <strong>VAT:</strong> {product.tax}%
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal>
          <section id="content-wrapper" class="dashboard-content-wrapper">
            <>
              <div class="row">
                <div class="col-lg-12 ">
                  <h3 class="content-title mt-3 mb-3 text-center">
                    Lead Details
                  </h3>
                </div>

                <div class="col-md-8 col-lg-6 col-sm-12 mt-3">
                  <>
                    <div class="dc-title">
                      <h6>Client info</h6>
                    </div>
                    <div class="dashboard-card card overflow">
                      <div class="">
                        <table class="table personal-info-table p-3 m-3">
                          <tbody>
                            <tr>
                              <td>
                                <b>Name</b>
                              </td>
                              <td>
                                {enquiryData?.sellerDetails?.sellerName ??
                                  "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Email</b>
                              </td>
                              <td>
                                {enquiryData?.sellerDetails?.sellerEmail ??
                                  "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Mobile</b>
                              </td>
                              <td>
                                {" "}
                                +{enquiryData?.sellerDetails?.mobile ?? "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Address</b>
                              </td>
                              <td className="address_lead">
                                {enquiryData?.sellerDetails?.address1 ?? "N/A"}
                                {enquiryData?.sellerDetails?.address2 ??
                                  "N/A"}{" "}
                                {enquiryData?.sellerDetails?.town ?? "N/A"}{" "}
                                {enquiryData?.sellerDetails?.country ?? "N/A"}{" "}
                                {enquiryData?.sellerDetails?.postcode ?? "N/A"}
                              </td>
                            </tr>
                            {enquiryData?.enq_ref != "" &&
                              enquiryData?.enq_ref != null && (
                                <tr>
                                  <td>
                                    <b>Remarks</b>
                                  </td>
                                  <td className="address_lead">
                                    {enquiryData?.enq_ref ?? "N/A"}
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-6 mt-3">
                  <button className="pro-gen-right" onClick={addNewNavigate}>
                    New Proposal
                  </button>
                </div>
              </div>
            </>
            {proposalList && proposalList.length != 0 && (
              <>
                {!fetchLoading ? (
                  <Fragment>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 mt-3">
                        <h6>Proposal List</h6>
                        <div className="card p-2">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Package Name</th>
                                <th scope="col"> Date</th>
                                <th className="text-center" scope="col">
                                  File
                                </th>
                                <th scope="col" className="text-center">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {proposalList != null &&
                                proposalList.length != 0 &&
                                proposalList.map((proposal, index) => {
                                  const packageDetails = JSON.parse(
                                    proposal?.productDetails
                                  );

                                  return (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          {packageDetails?.packageName ?? "N/A"}
                                        </td>
                                        <td>
                                          {Utils.formatDate(
                                            proposal?.created_at ?? "N/A"
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <a
                                            href={
                                              proposal?.file != null &&
                                              process.env
                                                .REACT_APP_AWS_IMAGE_URL +
                                                "proposal-docs/" +
                                                proposal?.file
                                            }
                                          >
                                            <Bs.BsDownload />
                                          </a>
                                        </td>
                                        {proposal.finalized == false ? (
                                          <td className="text-center position-relative">
                                            <div className="btn-toggle">
                                              <button
                                                className="one-btn"
                                                onClick={() =>
                                                  finalizeProposal(
                                                    proposal.propId
                                                  )
                                                }
                                              >
                                                Finalize
                                              </button>
                                              <button
                                                className="two-btn"
                                                onClick={() =>
                                                  toggleDropdown(index)
                                                }
                                              >
                                                <Md.MdOutlineArrowDropDown />
                                              </button>
                                            </div>

                                            <>
                                              {openDropdownIndex === index && (
                                                <ul
                                                  className="table-mgt"
                                                  role="menu"
                                                >
                                                  <li>
                                                    <button
                                                      className="btn"
                                                      onClick={() =>
                                                        finalizeProposal(
                                                          proposal.propId
                                                        )
                                                      }
                                                    >
                                                      Finalize
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="btn"
                                                      onClick={() =>
                                                        handleViewProposal(
                                                          proposal
                                                        )
                                                      }
                                                    >
                                                      View
                                                    </button>
                                                  </li>
                                                </ul>
                                              )}
                                            </>
                                          </td>
                                        ) : (
                                          <td className="text-center position-relative">
                                            <div className="btn-toggle">
                                              <button
                                                className="one-btn"
                                                style={{
                                                  fontSize: "10px",
                                                }}
                                                disabled
                                              >
                                                Finalized
                                              </button>
                                              <button
                                                className="two-btn"
                                                onClick={() =>
                                                  toggleDropdown(index)
                                                }
                                              >
                                                <Md.MdOutlineArrowDropDown />
                                              </button>
                                            </div>

                                            <>
                                              {openDropdownIndex === index && (
                                                <>
                                                  <ul
                                                    className="table-mgt"
                                                    role="menu"
                                                  >
                                                    <li>
                                                      <button
                                                        className="btn"
                                                        disabled
                                                      >
                                                        Finalized
                                                      </button>
                                                    </li>
                                                  </ul>
                                                  <ul
                                                    className="table-mgt"
                                                    role="menu"
                                                  >
                                                    <li>
                                                      <button
                                                        className="btn"
                                                        onClick={() =>
                                                          handleViewProposal(
                                                            proposal
                                                          )
                                                        }
                                                      >
                                                        View
                                                      </button>
                                                    </li>
                                                  </ul>
                                                </>
                                              )}
                                            </>
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {finalizedProposals && finalizedProposals.length != 0 && (
                        <>
                          <div className="col-md-6 col-sm-12 mt-3">
                            <>
                              <h6>Finalized Proposal List</h6>
                              <div className="card p-2">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Package Name</th>
                                      <th scope="col">Due Date</th>
                                      <th className="text-center" scope="col">
                                        File
                                      </th>
                                      <th scope="col" className="text-center">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {finalizedProposals != null &&
                                      finalizedProposals.map(
                                        (proposal, index) => {
                                          const packageDetails = JSON.parse(
                                            proposal?.productDetails
                                          );
                                          console.log(proposal, "details");
                                          return (
                                            <>
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {packageDetails?.packageName ??
                                                    "N/A"}
                                                </td>
                                                <td>
                                                  {proposal?.dueDate ?? "N/A"}
                                                </td>
                                                <td className="text-center">
                                                  <a
                                                    href={
                                                      proposal?.file != null &&
                                                      process.env
                                                        .REACT_APP_AWS_IMAGE_URL +
                                                        "proposal-docs/" +
                                                        proposal?.file
                                                    }
                                                  >
                                                    <Bs.BsDownload />
                                                  </a>
                                                </td>
                                                <td className="text-center position-relative">
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      handleInvoice(proposal)
                                                    }
                                                    className="submit_btn mt-0"
                                                  >
                                                    Generate Invoice
                                                  </button>
                                                  {/* <div className="btn-toggle">
                                          <button
                                            className="one-btn"
                                            onClick={() =>
                                              handleInvoice(proposal)
                                            }
                                          >
                                            Invoice
                                          </button>
                                          <button
                                            className="two-btn"
                                            onClick={() =>
                                              toggleDropdown(index)
                                            }
                                          >
                                            <Md.MdOutlineArrowDropDown />
                                          </button>
                                        </div>

                                        <>
                                          {openDropdownIndex === index && (
                                            <ul
                                              className="table-mgt"
                                              role="menu"
                                            >
                                              <li>
                                                <button
                                                  className="btn"
                                                  onClick={() =>
                                                    handleInvoice(proposal)
                                                  }
                                                >
                                                  Generate Invoice
                                                </button>
                                              </li>
                                            </ul>
                                          )}
                                        </> */}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          </div>
                        </>
                      )}
                    </div>
                  </Fragment>
                ) : (
                  "Loading"
                )}
              </>
            )}

            <br />
            {invoicesListData && invoicesListData.length != 0 && (
              <>
                <h6>Invoice List</h6>
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-sm-10">
                    <div className="card">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            {/* <th scope="col">Client Name</th>
                        <th scope="col">Client Email</th> */}
                            <th>Invoice Date</th>
                            <th>Due Date</th>
                            <th scope="col" className="text-center">
                              Status
                            </th>
                            <th className="text-center">Payment Status</th>
                            <th scope="col">Amount</th>
                            <th scope="col">File</th>
                            {/* <th scope="col" className="text-center">
                          Action
                        </th> */}
                            <th scope="col" className="text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoicesListData != null &&
                            Array.isArray(invoicesListData) &&
                            invoicesListData.map((data, index) => {
                              const prodData = data?.productDetails
                                ? JSON.parse(data?.productDetails)
                                : [];
                              console.log(data, "data inv");
                              return (
                                <>
                                  {data.file !== null && data.file !== "" && (
                                    <Fragment>
                                      <tr>
                                        <th scope="row">{index + 1}</th>
                                        {/* <td> {data?.cust_name ?? "N/A"} </td>  */}
                                        {/* <td>{data?.cust_email ?? "N/A"}</td> */}
                                        <td>
                                          {Utils.formatDate(
                                            data?.invoice_date ?? "N/A"
                                          )}
                                        </td>
                                        <td>
                                          {Utils.formatDate(
                                            data?.due_date ?? "N/A"
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className={
                                              data?.status == "open"
                                                ? "fp-badge info"
                                                : "fp-badge failed"
                                            }
                                            style={{
                                              paddingRight: "10px",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            {data.status}
                                          </span>
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className={
                                              data?.payement_status ==
                                              "partially-paid"
                                                ? "fp-badge info"
                                                : data?.payement_status ==
                                                  "paid"
                                                ? "fp-badge success"
                                                : "fp-badge pending"
                                            }
                                            style={{
                                              paddingRight: "10px",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            {data?.payement_status ?? "N/A"}
                                          </span>
                                        </td>
                                        <td>{data?.total ?? 0}</td>
                                        <td>
                                          {data.file !== null &&
                                          data.file !== "" ? (
                                            <a
                                              className="ref"
                                              href={
                                                process.env
                                                  .REACT_APP_AWS_IMAGE_URL +
                                                "invoices/" +
                                                data?.file
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {" "}
                                              <Bs.BsDownload />
                                            </a>
                                          ) : (
                                            "No invoice"
                                          )}
                                        </td>
                                        <td className="text-center position-relative">
                                          {data.status != "cancel" ? (
                                            <Fragment>
                                              <div className="btn-toggle">
                                                <button
                                                  className="one-btn"
                                                  onClick={() =>
                                                    handlePayment(data)
                                                  }
                                                  style={{ fontSize: "9px" }}
                                                >
                                                  Update Payment
                                                </button>
                                                <button
                                                  className="two-btn"
                                                  onClick={() =>
                                                    toggleDropdownInvoice(index)
                                                  }
                                                >
                                                  <Md.MdOutlineArrowDropDown />
                                                </button>
                                              </div>
                                              {openDropdownIndexInv ===
                                                index && (
                                                <ul
                                                  className="table-mgt"
                                                  role="menu"
                                                >
                                                  {/* <li>
                                        <button
                                          className="btn"
                                          onClick={() => handleView(data)}
                                        >
                                          View
                                        </button>
                                      </li> */}
                                                  <li>
                                                    <button
                                                      className="btn mb-0 pb-0"
                                                      onClick={() =>
                                                        handleView(data)
                                                      }
                                                    >
                                                      View
                                                    </button>
                                                  </li>
                                                  {data.status != "cancel" && (
                                                    <li>
                                                      <button
                                                        className="btn mb-0 pb-0"
                                                        onClick={() =>
                                                          handlePayment(data)
                                                        }
                                                      >
                                                        Update Payment Status
                                                      </button>
                                                    </li>
                                                  )}
                                                  <li>
                                                    {data.status == "cancel" ? (
                                                      <p
                                                        style={{
                                                          color: "red",
                                                          marginTop: "5px",
                                                          background:
                                                            "rgb(250, 195, 195)",
                                                          padding: "5px",
                                                          borderRadius: "4px",
                                                        }}
                                                      >
                                                        Cancelled
                                                      </p>
                                                    ) : (
                                                      <button
                                                        className="btn"
                                                        onClick={() =>
                                                          ConfirmDelete(data)
                                                        }
                                                      >
                                                        Cancel Invoice
                                                      </button>
                                                    )}
                                                  </li>
                                                  {/* {data.file !== null &&
                                        data.file !== "" && (
                                          <li>
                                            <a
                                              className="ref"
                                              href={
                                                process.env
                                                  .REACT_APP_AWS_IMAGE_URL +
                                                "invoices/" +
                                                data?.file
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {" "}
                                              Download
                                            </a>
                                          </li>
                                        )} */}
                                                  <br />
                                                </ul>
                                              )}
                                            </Fragment>
                                          ) : (
                                            <button
                                              className="submit_btn mt-0"
                                              onClick={() => handleView(data)}
                                            >
                                              View
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    </Fragment>
                                  )}
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        </Sidebar>
      </div>
    </>
  );
}

export default SingleCustomer;
