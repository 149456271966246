import React from "react";
import Sidebar from "../../Components/Sidebar";

function BillingMessages() {
  return (
    <div className="container-fluid">
      <Sidebar>
        <div div className="container-fluid m-3">
          <h6 className="head-h4">Head</h6>
          <p>Name Here</p>
        </div>
      </Sidebar>
    </div>
  );
}

export default BillingMessages;
