import React, { createContext, useContext, useEffect, useState } from "react";
import useAuth from "../Hooks/useAuth";
import useJobRoles from "../Hooks/useJobRoles";
import usePrivilages from "../Hooks/usePrivilages";
import useAdmin from "../Hooks/useAdmin";
import useProduct from "../Hooks/useProduct";
import useSellerEnquiries from "../Hooks/useSellerEnquiries";
import usePackage from "../Hooks/usePackage";
import jwt_decode from "jwt-decode";
import useSeller from "../Hooks/useSeller";
import useEnquiry from "../Hooks/useEnquiry";
import useSupport from "../Hooks/useSupport";
import useSupportChat from "../Hooks/useSupportChat";
import useFeatures from "../Hooks/useFeatures";
import useProfile from "../Hooks/useProfile";
import usePayment from "../Hooks/usePayment";
import useChat from "../Hooks/useChat";
import useProposal from "../Hooks/useProposal";
import useContract from "../Hooks/useContract";
import useEventPackages from "../Hooks/useEventPackages";
import useClient from "../Hooks/useClient";

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [balanceDue, setBalanceDue] = useState(0);
  const [previlages, setPrevilages] = useState(null);
  const [selectedContractProduct, setSelectedContractProduct] = useState(null);

  const {
    loginAdmin,
    getCookieData,
    deleteCookieData,
    authLoading,
    cookieData,
    pageLoading,
    isJobRole,
    role,
    cookieError,
    setCookieError,
    clearCache,
  } = useAuth();

  const {
    registerAdmin,
    loading,
    getAllAdminData,
    adminList,
    updateAdmin,
    reportList,
    getMonthlyEnquiry,
  } = useAdmin();
  const { requestToken, sendMessage, newChat, setNewChat, chats } = useChat();
  const {
    fetchAllFeatures,
    featureLoading,
    featureList,
    fetchAllFeatureGroup,
    featureGroupList,
    updateFeatureGroup,
    deleteFeatureGroup,
    createFeatureGroup,
    updateFeature,
    createFeature,
  } = useFeatures();

  const {
    sendContractToClient,
    contractLoading,
    fetchFinalizedContracts,
    getAllContractList,
    updateContractStatus,
    generateInvoice,
    getAllCompletedContracts,
    invoiceNumber,
    setInvoiceNumber,
    invoiceList,
    getAllInvoices,
    updateInvoiceFile,
    createMerchantInvoice,
    cancelInvoice,
    leadInvoices,
    companiesList,
    getCompaniesData,
    singleInvoiceGeneration,
    sendSingleContract,
  } = useContract();
  const {
    getAllProductData,
    productList,
    productLoading,
    createProduct,
    deleteProduct,
    updateProduct,
  } = useProduct();
  const { getallJobRoles, alljobRole, jobRoleLoading } = useJobRoles();
  const {
    getAllPlanList,
    planList,
    planLoading,
    createPackage,
    deleteEventPackage,
    deletePackage,
    updatePackage,
    addEventPackage,
    packageLoading,
    updateEventPackage,
    disableEventPackage,
    
  } = usePackage();

  const {
    getAllSeller,
    getAllShopCategories,
    sellerLoading,
    sellerList,
    shopCategories,
  } = useSeller();
  const {
    supportLoading,
    supportList,
    getAllSupportData,
    updateCompleteStatus,
  } = useSupport();
  const {
    fetchAllChats,
    chatLoading,
    chatList,
    createChatMessage,
    fetchMessages,
  } = useSupportChat();
  const {
    getallPrivilages,
    createPrivilege,
    updatePrivileges,
    allPrivilages,
    privilageLoading,
  } = usePrivilages();
  const { fetchLoginData, profileLoading, loginData, updateUserDetails } =
    useProfile();
  const {
    getAllTemplates,
    templates,
    proposalLoading,
    proposalSending,
    proposalList,
    updateProposal,
    getAllProposals,
    finalizeProposal,
    deleteProposal,
    getFinalisedProposals,
    fetchAllProposalData,
    allProposals,
    createSingleProposal,
    getAllProposalContracts,
    isProposalDataLoading,
    proposalContracts,
    getAllInvoicesList,
    proposalInvoices,
    getAllMailTemplates,
    mailLoading,
    mailTemplates,
    fetchProposalByleadId,
    finalize,
    getNewFinalizedProposals,
    proposalsList,
  } = useProposal();
  const { eventLoading, eventList, getAllEvents } = useEventPackages();
  const {
    fetchAllSellerEnquiries,
    allSellerEnquiries,
    updateDealData,
    sellerEnquiriesLoading,
    finalizeDeal,
    getDetailsOfUser,
    DealSending,
    deleteDealbyId,
  } = useSellerEnquiries();
  const {
    fetchContractData,
    clientLoading,
    fetchSingleContract,
    clientContract,
    sendContract,
  } = useClient();
  const {
    addLead,
    enquiryLoading,
    assignSalesman,
    updateSingleEnquiry,
    fetchEnquiryData,
    completeStage,
    stageData,
    cancelLead,
    contractSigning,
    multipleAssignment,
    updateLeadToSeller,
    updateLeadData,
    updateDeal,
  } = useEnquiry();
  const {
    paymentLoading,
    sendLink,
    updatePaymentStatus,
    fetchPaymentByLeadId,
  } = usePayment();

  useEffect(() => {
    getCookieData();
  }, []);

  useEffect(() => {
    if (!cookieData) return;

    const data = jwt_decode(cookieData);

    if (data.role == "superadmin") {
      getCompaniesData();
      getAllPlanList();
      getallJobRoles();
      getAllAdminData();
      getMonthlyEnquiry();
      getAllSeller();
      // fetchAllChats();
      getAllInvoices();
      getAllTemplates();
    }

    if (cookieData != null) {
      if (data.role == "superadmin" || data?.jobRole.includes("support")) {
        // getAllSupportData();
      }
      fetchAllSellerEnquiries();
      fetchLoginData();
      getallPrivilages();
         getAllInvoices();
      getAllProductData();
      fetchAllFeatures();
      getAllAdminData();
      // fetchAllFeatureGroup();
      getAllShopCategories();
      fetchAllProposalData();
      getAllProposalContracts();
      getAllInvoicesList();
      getAllMailTemplates();
      getAllEvents();
    }
  }, [cookieData]);

  return (
    <AppContext.Provider
      value={{
        loginAdmin,
        getCookieData,
        clearCache,
        deleteCookieData,
        authLoading,
        cookieData,
        setCookieError,
        cookieError,
        cancelLead,
        pageLoading,
        completeStage,
        getAllInvoices,
        invoiceList,
        stageData,
        previlages,
        setPrevilages,
        isJobRole,
        role,
        alljobRole,
        fetchLoginData,
        profileLoading,
        loginData,
        jobRoleLoading,
        fetchAllChats,
        chatLoading,
        chatList,
        fetchPaymentByLeadId,
        getallPrivilages,
        supportLoading,
        supportList,
        createPrivilege,
        fetchMessages,
        updateUserDetails,
        updatePrivileges,
        fetchEnquiryData,
        allPrivilages,
        privilageLoading,
        registerAdmin,
        loading,
        productList,
        getFinalisedProposals,
        updateAdmin,
        productLoading,
        deleteProduct,
        getAllProductData,
        adminList,
        getAllAdminData,
        fetchAllSellerEnquiries,
        allSellerEnquiries,
        getAllSupportData,
        singleInvoiceGeneration,
        sellerEnquiriesLoading,
        fetchAllFeatureGroup,
        updatePaymentStatus,
        planLoading,
        planList,
        getAllPlanList,
        getAllSeller,
        sellerLoading,
        sellerList,
        shopCategories,
        sendContractToClient,
        contractLoading,
        addLead,
        enquiryLoading,
        assignSalesman,
        createFeatureGroup,
        updateSingleEnquiry,
        createChatMessage,
        contractSigning,
        updateLeadToSeller,
        multipleAssignment,
        fetchAllFeatures,
        featureLoading,
        featureList,
        updateLeadData,
        getMonthlyEnquiry,
        reportList,
        updateCompleteStatus,
        paymentLoading,
        sendLink,
        createFeature,
        updateFeatureGroup,
        featureGroupList,
        updateFeature,
        deleteFeatureGroup,
        createProduct,
        updateProduct,
        requestToken,
        sendMessage,
        newChat,
        deletePackage,
        setNewChat,
        chats,
        createPackage,
        updatePackage,
        deleteDealbyId,
        updateDeal,
        getDetailsOfUser,
        fetchFinalizedContracts,
        finalizeDeal,
        leadInvoices,
        updateDealData,
        getAllTemplates,
        templates,
        deleteProposal,
        proposalLoading,
        proposalSending,
        proposalList,
        deleteEventPackage,
        getAllProposals,
        updateProposal,
        finalizeProposal,
        DealSending,
        getAllContractList,
        getAllCompletedContracts,
        updateContractStatus,
        generateInvoice,
        invoiceNumber,
        updateInvoiceFile,
        setInvoiceNumber,
        createMerchantInvoice,
        cancelInvoice,
        balanceDue,
        setBalanceDue,
        finalize,
        companiesList,
        getCompaniesData,
        fetchAllProposalData,
        allProposals,
        createSingleProposal,
        sendSingleContract,
        selectedContractProduct,
        setSelectedContractProduct,
        getAllProposalContracts,
        isProposalDataLoading,
        proposalContracts,
        getAllInvoicesList,
        proposalInvoices,
        fetchProposalByleadId,
        getAllMailTemplates,
        mailLoading,
        mailTemplates,
        eventLoading,
        eventList,
        getAllEvents,
        fetchContractData,
        clientLoading,
        fetchSingleContract,
        clientContract,
        sendContract,
        getNewFinalizedProposals,
        proposalsList,
        addEventPackage,
        packageLoading,
        updateEventPackage,
        disableEventPackage,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
