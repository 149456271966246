import React from "react";
import Sidebar from "../../../Components/Sidebar";
import Backbutton from "../../../Components/Backbutton";

function AddSalesExecutive() {
  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="card p-3 box">
          <form action="">
            <h4 className="text-center mb-3 pb-3">Add Exective Details</h4>
            <div className="row m-2">
              <div className="form-group col">
                <label className="form-label review-label">Name</label>
                <div className="review-group">
                  <input
                    type="text"
                    className="form-control review-input"
                    name="client"
                  />
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="" className="form-label review-label">
                  Email
                </label>
                <div className="review-group">
                  <input className="form-control review-input" type="text" />
                </div>
              </div>
            </div>
            <div className="row m-2">
              <div className="form-group col">
                <label htmlFor="" className="form-label review-label">
                  Phone
                </label>
                <div className="review-group">
                  <input className="form-control review-input" type="text" />
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="" className="form-label review-label">
                  Address
                </label>
                <div className="review-group">
                  <input className="form-control review-input" type="text" />
                </div>
              </div>
            </div>
            <div className="row m-2">
              <div className="form-group col-md-6 ">
                <label htmlFor="" className="form-label review-label">
                  Experience
                </label>
                <div className="review-group">
                  <input className="form-control review-input" type="text" />
                </div>
              </div>
            </div>
            <div className="button-div text-center mx-auto">
              <button className="fp-button">Submit</button>
            </div>
            {/* <button className="fp-button">Submit</button> */}
          </form>
        </div>
      </Sidebar>
    </div>
  );
}

export default AddSalesExecutive;
