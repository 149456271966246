import React, { useContext, Fragment } from "react";
import { BeatLoader } from "react-spinners";
import { AppContext } from "../../Context/AppContext";
import { toast } from "react-hot-toast";
import { Popconfirm } from "antd";
import * as Ai from "react-icons/ai";

function LeadQualification({
  jobQueueStatusArray,
  enquiryData,
  handleLead,
  leadQulification,
}) {
  const { cancelLead, enquiryLoading, fetchAllSellerEnquiries, completeStage } =
    useContext(AppContext);
  if (!jobQueueStatusArray) return;

  const handleLeadQulification = async () => {
    const payload = {
      psasId: jobQueueStatusArray?.jobId,
      cmpStatus: true,
      sId: enquiryData.aId,
    };

    await completeStage(payload, {
      onSuccess: async (res) => {
        toast.success("Lead Qualified Successfully!");

        window.location.reload();
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
      },
    });
  };

  const cancelLeadData = async (e) => {
    const payload = {
      pceId: enquiryData?.pceId,
      cancelStatus: true,
      status: "cancelled",
      sId: enquiryData.aId,
    };

    await cancelLead(payload, {
      onSuccess: async (res) => {
        toast.success("Lead Cancelled!");
        await fetchAllSellerEnquiries();
      },
      onFailed: (err) => {
        toast.error("Lead Cancellation Unsuccessful!");
      },
    });
  };

  return (
    <div>
      {leadQulification?.status === "completed" ? (
        <Fragment>
          <div className="mt-3">
            <div class="alert alert-success text-center" role="alert">
              The seller has accepted this lead, indicating that the initial
              stage is completed. Let's move forward with the subsequent stages
              to successfully complete the lead.
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="col-md-12 col-sm-12 col-lg-6 mt-3">
          <div className="">
            {enquiryData?.status !== "cancelled" && (
              <div className="stage-card card mb-3">
                <div className="mx-auto">
                  <h4 className="text-center">
                    {jobQueueStatusArray?.taskname}
                  </h4>
                  <p>Complete this action to go further!</p>
                  <div className="text-center">
                    <button
                      className="complete"
                      onClick={handleLeadQulification}
                      disabled={
                        enquiryLoading.action == "complete" &&
                        enquiryLoading.state
                      }
                    >
                      {enquiryLoading.action == "complete" &&
                      enquiryLoading.state ? (
                        <BeatLoader
                          size={10}
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          wrapperClassName="loader_"
                        />
                      ) : (
                        "Confirm"
                      )}
                    </button>
                    <Popconfirm
                      title="  Decline the Lead"
                      description="Are you sure to Cancel this lead?"
                      onConfirm={cancelLeadData}
                      // onCancel={}
                      okText="Yes"
                      cancelText="No"
                      okType="danger"
                      icon={
                        <Ai.AiOutlineQuestionCircle
                          style={{ color: "red", marginRight: "10px" }}
                        />
                      }
                    >
                      <button
                        className="reschedule"
                        disabled={
                          enquiryLoading.action == "cancel" &&
                          enquiryLoading.state
                        }
                      >
                        {enquiryLoading.action == "cancel" &&
                        enquiryLoading.state ? (
                          <BeatLoader
                            size={10}
                            color="#fff"
                            ariaLabel="three-dots-loading"
                            wrapperClassName="loader_"
                          />
                        ) : (
                          "Cancel"
                        )}
                      </button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default LeadQualification;
