import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function CanceledLeads({ wrapperRef, filteredEnquiries, searchTerm }) {
  const [filteredCancelledEnquiries, setFilteredCancelledEnquiries] = useState(
    []
  );

  useEffect(() => {
    if (filteredEnquiries && filteredEnquiries.cancelled) {
      const filteredLeads = filteredEnquiries.cancelled.filter((enquiry) => {
        const { sellerDetails } = enquiry;
        const searchText =
          `${sellerDetails?.sellerName} ${sellerDetails?.shopUrl} ${sellerDetails?.sellerEmail} ${sellerDetails?.mobile}`.toLowerCase();
        return searchText.includes(searchTerm);
      });
      setFilteredCancelledEnquiries(filteredLeads);
    }
  }, [searchTerm, filteredEnquiries]);

  if (!filteredEnquiries) return;
  console.log(filteredEnquiries);
  return (
    <div className="overflow">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Company Name</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>

            <th scope="col" className="text-center">
              Assigned Status
            </th>
            <th scope="col" className="text-center">
              Current Status
            </th>
            <th scope="col" className="text-center">
              View
            </th>
          </tr>
        </thead>
        <tbody ref={wrapperRef}>
          {filteredCancelledEnquiries != null &&
            filteredCancelledEnquiries != null &&
            filteredCancelledEnquiries.map((enquiry, index) => {
              // console.log("can", enquiry);
              const data = enquiry.sellerDetails;
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{data?.shopName ?? "N/A"}</td>
                  <td>{data?.sellerName ?? "N/A"}</td>
                  <td>{data?.sellerEmail ?? "N/A"}</td>
                  <td className="text-center">
                    {enquiry?.assignStatus === 1 ? (
                      <div className="fp-badge success">Assigned</div>
                    ) : (
                      <div className="fp-badge pending">Not Assigned</div>
                    )}
                  </td>
                  <td className="text-center">
                    {enquiry?.status === "pending" ? (
                      <div className="fp-badge pending">Pending</div>
                    ) : (
                      <div className="fp-badge failed">Cancelled</div>
                    )}
                    {/* <h4>
                          <Badge bg="secondary" className="badges" pill>
                            {enquiry?.status ?? "N/A"}
                          </Badge>
                        </h4> */}
                  </td>
                  <td className="text-center position-relative">
                    <div className="btn-toggle">
                      <button
                        className="one-btn view_enquiry_btn"
                        onClick={() => {
                          window.location.href = `/view-enquiry/${enquiry.pceId}`;
                        }}
                      >
                        View
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default CanceledLeads;
