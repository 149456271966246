import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import { useParams } from "react-router";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import * as Ri from "react-icons/ri";
import { AppContext } from "../../Context/AppContext";
import { ThreeDots } from "react-loader-spinner";

function UpdateAdmin() {
  const navigate = useNavigate();
  const { id } = useParams();
  const selectInputRef = useRef();
  const {
    adminList,
    alljobRole,
    allPrivilages,
    updateAdmin,
    getAllAdminData,
    loading,
  } = useContext(AppContext);

  const [adminData, setAdminData] = useState({
    aId: "",
    name: "",
    email: "",
    mobile: "",
    role: "",
    title: "",
    privilages: [
      {
        ppId: "",
        previlageName: "",
      },
    ],
    jobRole: [],
  });

  const [selectedJob, setSelectedJob] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [selectedPrivilege, setSelectedPrivilege] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [privilegeList, setPrivilegeList] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [error, setError] = useState(false);

  useEffect(() => {
    //get admin details by id
    getAdminById();
  }, [adminList]);

  useEffect(() => {
    if (adminData?.role == "admin") {
      //get selected  job role from admin data
      getJobRoleDetails();

      // to get privilege data by job role
      getPrivilegeByRole();
    }
  }, [adminData]);

  useEffect(() => {
    if (adminData?.role == "admin") {
      //to show existing privileges other than selected privileges
      getAllPrivileges();
    }
  }, [adminData, allPrivilages]);

  //get admin details by id
  const getAdminById = () => {
    if (!id || !adminList) return;
    const data = adminList.find((x) => x.paId == id);

    const role = adminData?.role;

    //when role is super admin
    if (role == "superadmin") {
      setAdminData((prev) => ({
        ...prev,
        aId: id,
        name: adminData?.name,
        mobile: adminData?.mobile,
        title: adminData?.title,
        email: adminData?.email,
        role: adminData?.role,
        privilages: "all",
        jobRole: [],
      }));

      return;
    }

    setAdminData({
      aId: id,
      name: data?.name,
      email: data?.email,
      mobile: data?.mobile,
      role: data?.role,
      title: data?.title,
      privilages: data?.privileges,
      jobRole: data?.jobRole,
    });
  };

  //get selected  job role from admin data
  const getJobRoleDetails = () => {
    if (!adminData || !alljobRole) return;

    const adminRoles = adminData?.jobRole?.map((str) => parseInt(str, 10));
    if (adminRoles.length === 0) return;

    const data = alljobRole.filter((role) => adminRoles.includes(role?.p_jbId));
    if (data.length === 0) return;
    const selectedJobs = data.map((role) => ({
      value: role?.p_jbId,
      label: role?.role,
    }));

    setSelectedJob(selectedJobs);
  };

  // to get privilege data by job role
  const getPrivilegeByRole = () => {
    if (!adminData) return;

    const privileges = adminData?.privilages;

    if (!privileges || privileges?.length == 0) return;
    const newSelectedPrivileges = privileges?.map((privilege) => ({
      value: privilege.ppId,
      label: privilege.previlageName,
    }));

    setSelectedPrivilege(newSelectedPrivileges);
  };

  //to show existing privileges other than selected privileges
  const getAllPrivileges = () => {
    if (!adminData || !allPrivilages) return;

    const jobRole = adminData.jobRole;
    if (!jobRole) return;

    const filteredPrivileges = allPrivilages.filter((priv) => {
      const matchingRoles = selectedJob.filter((job) => {
        const privRole = priv.jobRoles;
        const roleId = job.value;
        return privRole && privRole.includes(roleId);
      });
      return matchingRoles.length > 0;
    });

    const updatedPrivilegeList = filteredPrivileges.map((priv) => ({
      label: priv.previlageName,
      value: priv.ppId,
    }));

    setPrivilegeList(updatedPrivilegeList);
  };

  //to handle job role change
  const handleJobChange = (item) => {
    setSelectedJob(item);

    setAdminData((prev) => ({
      ...prev,
      jobRole: item.map((i) => i.value.toString()),
      privilages: [],
    }));
    setSelectedPrivilege([]);
  };

  //to handle Privileges Change
  const handlePrivilegeChange = (item) => {
    setSelectedPrivilege(item);

    setAdminData((prev) => ({
      ...prev,
      privilages: item.map((i) => ({
        ppId: i.value,
        previlageName: i.label,
      })),
    }));
  };

  // on handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAdminData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const phonePattern = /^(\+\d{1,3})?(?:[0-9\-\(\)\/\.]\s?){6,15}[0-9]{1}$/;
  // handle empty field Validation
  const handleValidation = () => {
    if (
      adminData?.name?.length == 0 ||
      adminData?.email?.length == 0 ||
      adminData?.mobile?.length == 0 ||
      adminData?.jobRole?.length == 0 ||
      adminData?.privilages?.length == 0 ||
      adminData?.title?.length == 0 ||
      adminData?.role?.length == 0
    ) {
      setError(true);
      return false;
    }
    return true;
  };

  //form submition
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidated = handleValidation();
    if (!phonePattern.test(adminData.mobile)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    const payload = {
      ...adminData,
      privilages: adminData.privilages.map((priv) => priv?.ppId),
    };

    console.log("admin data", payload);

    if (isValidated) {
      await updateAdmin(payload, {
        onSuccess: async (res) => {
          toast.success("Successfully Admin Updated!");

          await getAllAdminData();

          setTimeout(() => {
            navigate("/admin");
          }, 1500);
        },
        onFailed: (err) => {
          toast.error("ERROR ON UPDATING ADMIN");
        },
      });
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <Toaster position="top-center" reverseOrder={false} />
        <Sidebar>
          <div className="container-fluid m-3">
            <h4 className="text-center">Edit Admin</h4>
            <div className="card m-3 p-3 box mx-auto">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row m-2 mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label className="form-label review-label">Name</label>
                    <div
                      className={
                        error && adminData.name.length == 0
                          ? "review-group error"
                          : "review-group "
                      }
                    >
                      <input
                        name="name"
                        type="text"
                        className="form-control review-input"
                        value={adminData?.name ?? "N/A"}
                        onChange={(e) => handleChange(e)}
                      />
                      <i className="inp_ico">
                        <Ri.RiUser2Line />
                      </i>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-md-6 col-sm-6">
                    <label htmlFor="" className="form-label review-label">
                      Email
                    </label>
                    <div
                      className={
                        error && adminData.email.length == 0
                          ? "review-group error"
                          : "review-group "
                      }
                    >
                      <input
                        name="email"
                        className="form-control review-input"
                        type="email"
                        value={adminData?.email ?? "N/A"}
                        onChange={(e) => handleChange(e)}
                      />
                      <i className="inp_ico">
                        <Ri.RiMailOpenLine />
                      </i>
                    </div>
                  </div>
                </div>
                <div className="row m-2">
                  <div className="col-lg-6 col-md-6 col-sm-6 mt-2">
                    <label htmlFor="" className="form-label review-label">
                      Mobile
                    </label>
                    <div
                      className={
                        error && adminData.mobile.length == 0
                          ? "review-group error"
                          : "review-group "
                      }
                    >
                      <input
                        name="mobile"
                        className="form-control review-input"
                        type="tel"
                        value={adminData?.mobile ?? "N/A"}
                        onChange={(e) => handleChange(e)}
                      />
                      <i className="inp_ico">
                        <Ri.RiPhoneLine />
                      </i>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 mt-2">
                    <label htmlFor="" className="form-label review-label">
                      Designation
                    </label>
                    <div
                      className={
                        error && adminData.title.length == 0
                          ? "review-group error"
                          : "review-group "
                      }
                    >
                      <input
                        name="title"
                        className="form-control review-input"
                        type="text"
                        value={adminData?.title ?? "N/A"}
                        onChange={(e) => handleChange(e)}
                      />
                      <i className="inp_ico">
                        <Ri.RiShieldUserLine />
                      </i>
                    </div>
                  </div>
                </div>
                <div className="row m-2">
                  <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                    <label htmlFor="" className="form-label review-label">
                      Role
                    </label>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        value="admin"
                        id="admin"
                        checked={adminData?.role === "admin"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="admin">
                        Admin
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        value="superadmin"
                        id="superadmin"
                        checked={adminData?.role === "superadmin"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="superadmin">
                        Super Admin
                      </label>
                    </div>
                    {adminData?.role?.length == 0 && (
                      <span className="error_">*Admin Role is Required!</span>
                    )}
                  </div>
                  {adminData?.role == "admin" && (
                    <Fragment>
                      <div className="col-lg-4 col-md- col-sm-12 mt-2">
                        <label htmlFor="" className="form-label review-label">
                          JobRole
                        </label>

                        <Select
                          value={selectedJob}
                          options={
                            alljobRole != null &&
                            Array.isArray(alljobRole) &&
                            alljobRole.map((jobs) => {
                              const data = {
                                label: jobs.role,
                                value: jobs.p_jbId,
                              };
                              return data;
                            })
                          }
                          onChange={(item) => handleJobChange(item)}
                          isMulti
                        />
                        {adminData?.jobRole?.length == 0 && (
                          <span className="error_">
                            *Admin Job Role is Required!
                          </span>
                        )}
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-12 mt-2">
                        <label htmlFor="" className="form-label review-label">
                          Privilages
                        </label>
                        <Select
                          ref={selectInputRef}
                          name="privilages"
                          value={selectedPrivilege}
                          options={privilegeList}
                          onChange={(item) => handlePrivilegeChange(item)}
                          isMulti
                        />
                        {adminData?.privilages?.length == 0 && (
                          <span className="error_">
                            *Admin Privileges are Required!
                          </span>
                        )}
                      </div>
                    </Fragment>
                  )}
                </div>
                <div className="button-div text-center mx-auto">
                  <button
                    className="fp-button"
                    type="submit"
                    disabled={loading}
                  >
                    {!loading ? (
                      "Submit"
                    ) : (
                      <ThreeDots
                        height="60"
                        width="60"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperClassName="loader_"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Sidebar>
      </div>
    </Fragment>
  );
}

export default UpdateAdmin;
