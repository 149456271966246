export default class Utils {
  static formatDate(value) {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Create a normal date string
    const normalDate = `${day}-${month}-${year}`;

    return normalDate;
  }
  static convertToWords(number) {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand", "Million", "Billion"];

    if (number === 0) return "Zero";

    let words = "";

    function helper(n, index) {
      if (n === 0) return "";
      if (n < 10) return units[n] + " ";
      if (n < 20) return teens[n - 10] + " ";
      if (n < 100) return tens[Math.floor(n / 10)] + " " + helper(n % 10, 0);
      if (n < 1000)
        return units[Math.floor(n / 100)] + " Hundred " + helper(n % 100, 0);

      return (
        helper(Math.floor(n / 1000), index + 1) +
        thousands[index] +
        " " +
        helper(n % 1000, 0)
      );
    }

    const [wholeNumber, decimalPart] = String(number).split(".");

    words = helper(wholeNumber, 0).trim();

    if (decimalPart) {
      words += " rupees ";
      words += helper(parseInt(decimalPart), 0);
    }
    return words;
  }

  static getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  static changeDateOrder(dateStr) {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  }
}
