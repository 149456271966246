import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../Style/Style.css";
import Logo from "../../Assets/pakawaanlogoo.png";
import Utils from "../../Utils/utils";
import { AppContext } from "../../Context/AppContext";
import html2canvas from "html2canvas";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import SendMailTemplateModal from "../../Components/mail/SendMailTemplateModal";
import { MdSend } from "react-icons/md";
import { IoMdArrowBack } from "react-icons/io";

function SingleInvoice() {
  const { updateInvoiceFile, balanceDue, fetchAllProposalData } =
    useContext(AppContext);
  const targetRef = useRef();
  const isFirstLoad = useRef(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const InvoiceNumber = JSON.parse(
    sessionStorage.getItem("DirectInvoiceNumber")
  );
  const invType = JSON.parse(sessionStorage.getItem("isExport"));
  const data = JSON.parse(sessionStorage.getItem("invoiceDetails"));
  const invoiceDetails = data?.payload;
  const description = data?.productDescription;
  const productArray = JSON.parse(invoiceDetails.productDetails);
  const packageDetails = data?.packageDetails;
  console.log(packageDetails, "type");
  const [numPages, setNumPages] = useState(1);
  const comp = sessionStorage.getItem("DirectProposal");
  const [showMailModal, setShowMailModal] = useState(false);
  const [parsedBody, setParsedBody] = useState(null);
  const [mailSubject, setMailSubject] = useState(null);
  const [ccValue, setCcValue] = useState(null);

  console.log(data, "data");

  useEffect(() => {
    const calculateNumPages = () => {
      const totalPages = Math.ceil(
        targetRef.current.offsetHeight / window.innerHeight
      );
      setNumPages(totalPages);
    };

    window.addEventListener("resize", calculateNumPages);

    return () => {
      window.removeEventListener("resize", calculateNumPages);
    };
  }, []);

  const handleUpdatePDF = async () => {
    setShowMailModal(true);
  };
  const sendMail = async () => {
    setLoading(true);

    try {
      const canvas = await html2canvas(targetRef.current, {
        imageTimeout: 0,
        scale: 2,
        width: targetRef.current.offsetWidth,
        height: targetRef.current.scrollHeight,
        allowTaint: true,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/jpeg", 0.95);

      // Create PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const pageHeight = pdf.internal.pageSize.getHeight();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * pageWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      // Add the first page
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        position,
        pageWidth,
        imgHeight > pageHeight ? pageHeight : imgHeight
      );

      heightLeft -= pageHeight;

      // Add additional pages if content is larger than a single page
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "JPEG",
          0,
          position,
          pageWidth,
          imgHeight > pageHeight ? pageHeight : imgHeight
        );
        heightLeft -= pageHeight;
      }

      const pdfBlob = pdf.output("blob");
      const mailContent = {
        subject: mailSubject,
        body: parsedBody?.data ? parsedBody?.data : parsedBody,
        cc: ccValue ?? "",
      };

      const formData = new FormData();
      formData.append("file", pdfBlob, "Invoice.pdf");
      formData.append("invoiceId", id);
      formData.append("invoiceNum", InvoiceNumber?.invoiceNum);
      formData.append("mailContent", JSON.stringify(mailContent));

      await updateInvoiceFile(formData, id, {
        onSuccess: async (res) => {
          toast.success("Successfully sent Invoice");
          setLoading(false);
          const path = sessionStorage.getItem("path");
          await fetchAllProposalData();
          setTimeout(() => {
            if (path) {
              window.location.href = `/single-customer-view/${path}`;
            } else {
              window.location.href = `/leads`;
            }
          }, 1000);
        },
        onFailed: (err) => {
          setLoading(false);
          if (err != null) {
            toast.error(err.errormessage);
          } else {
            toast.error("Something Went Wrong!");
          }
        },
      });
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while generating the PDF.");
    }
  };

  console.log(productArray, "ARRAY");

  return (
    <Fragment>
      {/* <div className="showAlert_wrapper">
        <div className="card showAlert_card">
          <p className="title">
            <i>
              <FaCircleInfo />
            </i>{" "}
            Are you sure?
          </p>
          <p className="content__">
            If you navigate away from this page, the generated invoice will be
            automatically cancelled. Are you sure you want to go back?
          </p>
        </div>
      </div> */}
      {showMailModal && (
        <SendMailTemplateModal
          showMailModal={showMailModal}
          setShowMailModal={setShowMailModal}
          sendMail={sendMail}
          parsedBody={parsedBody}
          setParsedBody={setParsedBody}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          ccValue={ccValue}
          setCcValue={setCcValue}
          title="invoice"
          data={data}
        />
      )}
      <div style={{ background: "#eee" }} className="container-fluid">
        <Toaster position="top-center" />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <button
                className="back_btn"
                type="button"
                onClick={() => navigate(-1)}
              >
                <i style={{ marginRight: "3px" }}>
                  <IoMdArrowBack />
                </i>
                Back
              </button>
              {loading ? (
                <button className="send-btn" type="button" disabled>
                  Sending...
                </button>
              ) : (
                <>
                  <button
                    className="send-btn"
                    type="button"
                    onClick={handleUpdatePDF}
                  >
                    Send
                    <i style={{ marginLeft: "3px" }}>
                      <MdSend />
                    </i>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>{" "}
        <>
          <div className="row">
            <div className="col-sm-12 mx-auto mt-3 mb-3">
              <div
                className="inv_bg"
                ref={targetRef}
                style={{ border: "1px solid #eee" }}
              >
                <div className="col-md-12">
                  <div className="container inv">
                    <div className="row">
                      <div className="col-sm-6">
                        <div class="custom-col">
                          <h1 className="inv_head">Invoice</h1>

                          {/* <p class="invoice_Add">
                          30A Ipswich Road, Colchester, United Kingdom
                          <br />
                          <small>info@foodpage.co.uk </small>
                          <br />
                          <small> +44 752 553 60 15</small>
                        </p> */}
                        </div>
                        <div
                          className="custom-col"
                          style={{
                            marginTop: "117px",
                            color: "#595957",
                          }}
                        >
                          <small>Invoice To,</small>
                          <h6
                            style={{
                              color: "#000",
                              fontWeight: "600",
                              position: "relative",
                              top: "10px",
                            }}
                          >
                            {invoiceDetails?.cust_name ?? "NA"}
                          </h6>
                          <p
                            class="invoice_Add"
                            style={{
                              position: "relative",
                              // top: "10px",
                            }}
                          >
                            {invoiceDetails?.billingAddress ?? "NA"}
                            <br />
                            <p>{invoiceDetails?.cust_email ?? "NA"} </p>
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-2"></div>
                      <div className="col-sm-4">
                        <div class="logo-arera">
                          <img
                            src={Logo}
                            // style={{ position: "relative", left: "-10px" }}
                          />
                        </div>
                        <div class="table-Info">
                          <table>
                            <tr>
                              <td>
                                <b> Invoice Number</b>

                                <br />
                              </td>
                              <td> {InvoiceNumber?.invoiceNum}</td>
                            </tr>

                            <tr>
                              <td>
                                <b> Invoice Date</b>
                              </td>
                              <td>
                                {" "}
                                <small>
                                  {Utils.formatDate(
                                    invoiceDetails?.invoice_date ?? "NA"
                                  )}
                                </small>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b> Due Date</b>

                                <br />
                              </td>
                              <td>
                                <small>
                                  {Utils.formatDate(
                                    invoiceDetails?.due_date ?? "NA"
                                  )}
                                </small>
                              </td>
                            </tr>
                          </table>
                          <div
                            className="p-1"
                            style={{
                              color: "#595957",
                              // position: "relative",
                              // top: "25px",
                            }}
                          >
                            <small>Invoice From,</small>

                            <h6
                              style={{
                                color: "#000",
                                fontWeight: "600",
                                // position: "relative",
                                // top: "5px",
                              }}
                            >
                              Pakwaan
                            </h6>
                            <p
                              class="invoice_Add"
                              style={{
                                // position: "relative",
                                // top: "7px",
                                width: "100%",
                                paddingRight: "30px",
                              }}
                            >
                              Pakwaan Indian Punjabi Cuisine 1 Bridge St,
                              Writtle, Chelmsford CM1 3EY <br />
                              <p>
                                info@pakwaan.co.uk
                                <br />
                                <small> +44 1245 422 891</small>
                              </p>
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-1"></div>
                    </div>
                    <div
                      className="row mx-auto"
                      // style={{ position: "relative", top: "50px" }}
                    >
                      <div className="col-sm-12 mx-auto">
                        <table className="in_table p-3">
                          <tr
                            className="under_line"
                            style={{
                              background: "#C40C0C",
                              color: "#fff",
                              padding: "10px",
                            }}
                          >
                            <th>#</th>
                            <th>Item</th>
                            <th>Price</th>

                            {/* <th>Discount</th> */}
                          </tr>
                          <tbody>
                            {productArray != null &&
                              productArray.map((data, index) => {
                                const taxamount =
                                  ((data?.price * data?.quantity -
                                    data?.discount) *
                                    data?.tax) /
                                  100;
                                const totalValue =
                                  data.price * data.quantity -
                                  data.discount +
                                  taxamount;
                                const totalPayable =
                                  totalValue - invoiceDetails.deposit;
                                console.log(invoiceDetails.deposit, "deposit");

                                return (
                                  <>
                                    <Fragment key={index}>
                                      <tr>
                                        <td height="1" colSpan="2"></td>
                                      </tr>
                                      <tr
                                        className="under_line"
                                        style={{
                                          background: "#e1e1e1",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <td>{index + 1}</td>
                                        <td>
                                          <div className="pro-feat-000">
                                            <h6
                                              style={{
                                                position: "relative",
                                                left: "10px",
                                              }}
                                              className="text-uppercase"
                                            >
                                              {packageDetails?.packageName ??
                                                ""}
                                            </h6>
                                            {packageDetails.itemsArray !=
                                              null &&
                                              Array.isArray(
                                                packageDetails?.itemsArray
                                              ) &&
                                              packageDetails?.itemsArray.map(
                                                (item, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="m-2"
                                                  >
                                                    {item?.description ?? "N/A"}

                                                    <br />
                                                  </div>
                                                )
                                              )}
                                            {packageDetails.elementArray !=
                                              null &&
                                              Array.isArray(
                                                packageDetails?.elementArray
                                              ) &&
                                              packageDetails?.elementArray.map(
                                                (item, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="m-2"
                                                  >
                                                    {item?.item ?? "N/A"} :
                                                    {item?.price ?? 0}
                                                    <br />
                                                  </div>
                                                )
                                              )}
                                            {packageDetails?.services != null &&
                                              Array.isArray(
                                                packageDetails?.services
                                              ) &&
                                              packageDetails?.services?.map(
                                                (item, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="m-2"
                                                  >
                                                    {item?.courseHead ?? "N/A"} : 
                                                    { item?.values ?? 0}
                                                    <br />
                                                  </div>
                                                )
                                              )}
                                            <small
                                              className="mr-3 mt-3"
                                              style={{
                                                position: "relative",
                                                left: "10px",
                                              }}
                                            >
                                              {description ?? ""}
                                            </small>{" "}
                                          </div>
                                        </td>
                                        <td>
                                          <div>
                                            <td>{""}</td>
                                            {packageDetails.itemsArray !=
                                              null &&
                                              Array.isArray(
                                                packageDetails?.itemsArray
                                              ) &&
                                              packageDetails?.itemsArray.map(
                                                (item, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="m-2"
                                                  >
                                                    {item?.price ?? 0}

                                                    <br />
                                                  </div>
                                                )
                                              )}
                                            {packageDetails.elementArray !=
                                              null &&
                                              Array.isArray(
                                                packageDetails?.elementArray
                                              ) &&
                                              packageDetails?.elementArray.map(
                                                (item, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="m-2"
                                                  >
                                                    {item?.price ?? 0}

                                                    <br />
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        </td>
                                      </tr>
                                    </Fragment>
                                    <tr
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <td></td>
                                      <td>Sub Total</td>
                                      <td
                                        style={{
                                          float: "left",
                                          padding: "8px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {packageDetails?.values?.subTotal ?? 0}
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <td></td>
                                      <td>Discount</td>
                                      <td
                                        style={{
                                          float: "left",
                                          padding: "8px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {data.discount ?? "N/A"}
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <td></td>
                                      <td>
                                        VAT( {packageDetails?.values?.VAT ?? 0}
                                        %)
                                      </td>
                                      <td
                                        style={{
                                          float: "left",
                                          padding: "8px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        {data?.taxAmount ?? "N/A"}
                                      </td>
                                    </tr>

                                    <tr
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <td></td>
                                      <td>
                                        <b>Total</b>
                                      </td>
                                      <td
                                        style={{
                                          float: "left",
                                          padding: "8px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {data?.totalBeforeDeposit ?? 0}
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <td></td>
                                      <td>Deposit</td>
                                      <td
                                        style={{
                                          float: "left",
                                          padding: "8px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {invoiceDetails?.deposit ?? 0}
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <td></td>
                                      <td>
                                        <b>Payable</b>
                                      </td>
                                      <td
                                        style={{
                                          float: "left",
                                          padding: "8px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        <b>£{invoiceDetails?.total ?? 0}</b>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                        <h6
                          className="text-center"
                          style={{ fontSize: "10px" }}
                        >
                          <small> This is System generated Inovice.</small>
                        </h6>
                        {data.message != null &&
                          data.message != undefined &&
                          data.message != "" && (
                            <div className="col-md-7 mt-3">
                              <p
                                style={{
                                  textAlign: "justify",
                                  fontSize: "12px",
                                  fontFamily: "poppins",
                                }}
                              >
                                {data?.message ?? "N/A"}
                              </p>
                            </div>
                          )}
                        <div className="brk_div"></div>
                        <div className="col-md-6"></div>
                        <div className="col-sm-5">
                          <div className="more_details card p-3">
                            <h6>PAYMENT METHOD</h6>

                            {/* <h6>Ferns IT Solutions Private Limited  <br /></h6> */}
                            <p>
                              {" "}
                              {data?.bankDetails?.bank ?? "NA"}
                              {" : "}
                              {data?.bankDetails?.paymentAccount ?? "NA"}
                              <br />{" "}
                              {data?.bankDetails?.ifsc?.length != 0 &&
                                data?.bankDetails?.ifsc != null && (
                                  <>
                                    {" "}
                                    IFSC :{data?.bankDetails?.ifsc ?? "NA"}
                                    <br />
                                  </>
                                )}
                              {data?.bankDetails?.branch ?? "NA"}
                              <br />
                              {data?.bankDetails?.swifCode?.length != 0 &&
                                data?.bankDetails?.swifCode != null && (
                                  <>
                                    {" "}
                                    SwiftCode :
                                    {data?.bankDetails?.swifCode ?? "NA"}
                                    <br />
                                  </>
                                )}
                              {data?.bankDetails?.sortCode?.length != 0 &&
                                data?.bankDetails?.sortCode != null && (
                                  <>
                                    {" "}
                                    SortCode :
                                    {data?.bankDetails?.sortCode ?? "NA"}
                                    <br />
                                  </>
                                )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </Fragment>
  );
}

export default SingleInvoice;
