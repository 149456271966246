import React from "react";
import Sidebar from "../../Components/Sidebar";
import Backbutton from "../../Components/Backbutton";
import { Badge } from "react-bootstrap";
import * as Ai from "react-icons/ai"
import { useNavigate } from "react-router-dom";

function ApkManagement() {
  const navigate=useNavigate();
  return (
    <div className="container-fluid">
      <Sidebar>
        <div div className="container-fluid m-3">
          <h6 className="head-h4 APK-management-heading">APK Management</h6>
          <button className="create-btn">Create APK</button>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div class=" card overflow APK-card mt-4">
                <div className="file-card">
                  <div className="badge-div">
                    Available
                  </div>
                  <h6 className="file-heading">APK File 6 / Ver 1.2.6</h6>
                  <hr  className="APK-line"/>
                  <div >
                    <table className="  APK-management-table m-3">
                      <tbody>
                        <tr className="table-APK">
                          <td className="version-table-data">Version</td>
                          <td className="APK-data"><b>1.2.6</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Date</td>
                          <td className="APK-data"><b>4Nov</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Ads Amt</td>
                          <td className="APK-data"><b>6</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Using locatn</td>
                          <td className="APK-data"><b>-</b></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className=" text-center mx-auto">
                      <button className="APK_btn" onClick={()=>navigate("/apk-release")}><Ai.AiOutlineUpload />  Deploy APK</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class=" card overflow APK-card mt-4 ml-0">
                <div className="file-card">
                  <div className="badge-div-running">
                    Running
                  </div>
                  <h6 className="file-heading">APK File 5 / Ver 1.2.5</h6>
                  <hr className="APK-line"/>
                  <div >
                    <table className=" APK-management-table m-3">
                      <tbody>
                        <tr className="table-APK">
                          <td className="version-table-data">Version</td>
                          <td className="APK-data"><b>1.2.5</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Date</td>
                          <td className="APK-data"><b>26Oct</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Ads Amt</td>
                          <td className="APK-data"><b>5</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Using locatn</td>
                          <td className="APK-data"><b>4</b></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className=" text-center mx-auto">
                      <button className="APK_btn" onClick={()=>navigate("/apk-release")}><Ai.AiOutlineUpload />  Deploy APK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class=" card overflow APK-card mt-4">
                <div className="file-card">
                  <div className="badge-div-expired">
                    Expired
                  </div>
                  <h6 className="file-heading">APK File 4 / Ver 1.2.4</h6>
                  <hr className="APK-line"/>
                  <div >
                    <table className="  APK-management-table m-3">
                      <tbody>
                        <tr className="table-APK">
                          <td className="version-table-data">Version</td>
                          <td className="APK-data"><b>1.2.4</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Date</td>
                          <td className="APK-data"><b>11Oct</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Ads Amt</td>
                          <td className="APK-data"><b>8</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Using locatn</td>
                          <td className="APK-data"><b>3</b></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className=" text-center mx-auto">
                      <button className="APK_btn" onClick={()=>navigate("/apk-release")}><Ai.AiOutlineUpload />  Deploy APK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class=" card overflow APK-card mt-4 ml-0">
                <div className="file-card">
                  <div className="badge-div-expired">
                    Expired
                  </div>
                  <h6 className="file-heading">APK File 3 / Ver 1.2.3</h6>
                  <hr className="APK-line"/>
                  <div >
                    <table className="  APK-management-table m-3">
                      <tbody>
                        <tr className="table-APK">
                          <td className="version-table-data">Version</td>
                          <td className="APK-data"><b>1.2.3</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Date</td>
                          <td className="APK-data"><b>4Oct</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Ads Amt</td>
                          <td className="APK-data"><b>6</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Using locatn</td>
                          <td className="APK-data"><b>5</b></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className=" text-center mx-auto">
                      <button className="APK_btn" onClick={()=>navigate("/apk-release")}><Ai.AiOutlineUpload />  Deploy APK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class=" card overflow APK-card mt-4 ml-0">
                <div className="file-card">
                  <div className="badge-div-expired">
                    Expired
                  </div>
                  <h6 className="file-heading">APK File 2 / Ver 1.2.2</h6>
                  <hr className="APK-line"/>
                  <div >
                    <table className="  APK-management-table m-3">
                      <tbody>
                        <tr className="table-APK">
                          <td className="version-table-data">Version</td>
                          <td className="APK-data"><b>1.2.2</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Date</td>
                          <td className="APK-data"><b>26Sep</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Ads Amt</td>
                          <td className="APK-data"><b>3</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Using locatn</td>
                          <td className="APK-data"><b>4</b></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className=" text-center mx-auto hover-button">
                      <button className="APK_btn" onClick={()=>navigate("/apk-release")}><Ai.AiOutlineUpload />  Deploy APK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class=" card overflow APK-card mt-4 ">
                <div className="file-card">
                  <div className="badge-div-expired">
                    Expired
                  </div>
                  <h6 className="file-heading">APK File 1 / Ver 1.2.1</h6>
                  <hr className="APK-line"/>
                  <div >
                    <table className="  APK-management-table m-3">
                      <tbody>
                        <tr className="table-APK">
                          <td className="version-table-data">Version</td>
                          <td className="APK-data"><b>1.2.1</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Date</td>
                          <td className="APK-data"><b>26Sep</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Ads Amt</td>
                          <td className="APK-data"><b>7</b></td>
                        </tr>
                        <tr>
                          <td className="version-table-data">Using locatn</td>
                          <td className="APK-data"><b>9</b></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className=" text-center mx-auto">
                      <button className="APK_btn" onClick={()=>navigate("/apk-release")}><Ai.AiOutlineUpload />  Deploy APK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default ApkManagement;
