import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function RegisteredUsers({ wrapperRef, filteredEnquiries, searchTerm }) {
  const [filteredRegisteredUsers, setFilteredRegisteredUsers] = useState([]);
  useEffect(() => {
    if (filteredEnquiries && filteredEnquiries.registeredUsers) {
      const filteredUsers = filteredEnquiries.registeredUsers.filter(
        (enquiry) => {
          const { sellerDetails } = enquiry;
          const searchText =
            `${sellerDetails?.sellerName} ${sellerDetails?.shopUrl} ${sellerDetails?.sellerEmail} ${sellerDetails?.mobile}`.toLowerCase();
          return searchText.includes(searchTerm);
        }
      );

      const sortedFilteredUsers = filteredUsers.slice().sort((a, b) => {
        const shopNameA = a.sellerDetails?.shopName?.trim().toLowerCase() || "";
        const shopNameB = b.sellerDetails?.shopName?.trim().toLowerCase() || "";
        return shopNameA.localeCompare(shopNameB);
      });
      setFilteredRegisteredUsers(sortedFilteredUsers);
    }
  }, [searchTerm, filteredEnquiries]);
  return (
    <div className="overflow">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Shop Name</th>
            <th scope="col">Name</th>
            <th scope="col">Shop URL</th>
            <th scope="col">Email</th>

            <th scope="col" className="text-center">
              Assigned Status
            </th>
            <th scope="col" className="text-center">
              Current Status
            </th>
            <th scope="col" className="text-center">
              View
            </th>
          </tr>
        </thead>
        <tbody ref={wrapperRef}>
          {Array.isArray(filteredRegisteredUsers) &&
            filteredRegisteredUsers.map((enquiry, index) => {
              const data = enquiry.sellerDetails;
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{data?.shopName ?? "N/A"}</td>
                  <td>{data?.sellerName}</td>
                  <td>{data?.shopUrl ?? "N/A"}</td>
                  <td>{data?.sellerEmail ?? "N/A"}</td>
                  <td className="text-center">
                    {enquiry?.assignStatus === 1 ? (
                      <div className="fp-badge success">Assigned</div>
                    ) : (
                      <div className="fp-badge pending">Not Assigned</div>
                    )}
                  </td>
                  <td className="text-center">
                    {enquiry?.status === "pending" ? (
                      <span>
                        <div className="fp-badge pending">Pending</div>
                      </span>
                    ) : (
                      <span>
                        <div className="fp-badge failed">Cancelled</div>
                      </span>
                    )}
                  </td>
                  <td className="text-center position-relative">
                    <div className="btn-toggle">
                      <button
                        className="one-btn view_enquiry_btn"
                        onClick={() => {
                          window.location.href = `/view-enquiry/${enquiry.pceId}`;
                        }}
                      >
                        View
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default RegisteredUsers;
