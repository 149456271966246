import React, { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const usePrivilages = () => {
  const [allPrivilages, setAllPrivilages] = useState([]);
  const [privilageLoading, setPrivilageLoading] = useState(false);

  const getallPrivilages = async () => {
    try {
      setPrivilageLoading(true);
      await BaseClient.get(APIEndpoints.getAllPrivilages, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setAllPrivilages(res.data);
          }
        },
        onFailed: (err) => {
          console.log("JOB ROLE FETCHING ERROR", err);
        },
      });
    } finally {
      setPrivilageLoading(false);
    }
  };

  const createPrivilege = async (payload, { onSuccess, onFailed }) => {
    try {
      setPrivilageLoading(true);

      await BaseClient.post(APIEndpoints.addPrivileges, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPrivilageLoading(false);
    }
  };

  const updatePrivileges = async (payload, { onSuccess, onFailed }) => {
    try {
      setPrivilageLoading(true);

      await BaseClient.put(APIEndpoints.editPrivileges, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPrivilageLoading(false);
    }
  };

  return {
    getallPrivilages,
    createPrivilege,
    updatePrivileges,
    allPrivilages,
    privilageLoading,
  };
};

export default usePrivilages;
