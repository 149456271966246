import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function AdminSkelton({ count }) {
  return Array(count)
    .fill(0)
    .map((item, key) => (
      <>
        <div className="">
          <div className="card payment mastercard">
            <div className="header">
              {/* <h4 className="text-center">
                {" "}
                <Skeleton width={250} />
              </h4> */}
            </div>
            <div className="admin-data">
              <ul className="support-service">
                <li>
                  <h6>
                    <Skeleton width={250} />
                    <span>
                      {" "}
                      <Skeleton width={250} />
                    </span>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Skeleton width={250} />
                    <span>
                      {" "}
                      <Skeleton width={250} />
                    </span>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Skeleton width={250} />
                    <span>
                      {" "}
                      <Skeleton width={250} />
                    </span>
                  </h6>
                </li>
                <li>
                  <h6>
                    {" "}
                    <Skeleton width={250} />
                    <span>
                      <Skeleton width={250} />
                    </span>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Skeleton width={250} />
                    <span>
                      <Skeleton width={250} />
                    </span>
                  </h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    ));
}
export default AdminSkelton;
