import React, { Fragment, useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { Select, message } from "antd";
import { toast, Toaster } from "react-hot-toast";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import Utils from "../../Utils/utils";

function EditInvoice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    generateInvoice,
    balanceDue,
    setBalanceDue,
    invoiceNumber,
    setInvoiceNumber,
    companiesList,
    singleInvoiceGeneration,
    invoiceList,
  } = useContext(AppContext);

  const paramsId = atob(id);

  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const invoiceData = JSON.parse(sessionStorage.getItem("DirectProposal"));
  const updatedInvoice = JSON.parse(sessionStorage.getItem("updateInvoice"));
  const bankInfo = JSON.parse(updatedInvoice.bankDetails);
  const seletedPro = JSON.parse(sessionStorage.getItem("selectedProduct"));
  const productDetails = invoiceData?.productDetails;
console.log(seletedPro,"info");

  const [editInvoice, setEditInvoice] = useState({
    username: invoiceData.customersDetails.clientName,
    email: invoiceData.customersDetails.email,
    billingAddress: invoiceData?.customersDetails?.address,
    dueDate: null,
    paymentAccount: bankInfo?.paymentAccount,
    branch:bankInfo?.branch,
    bank: bankInfo?.bank,
    ifsc: bankInfo?.ifsc,
    sortCode: bankInfo?.sortCode,
    swifCode:bankInfo?.swifCode,
    discount: 0,
    deposit: 0,
    message: "",
    productDescription: invoiceData?.productDetails[0]?.description,
  });
  console.log(invoiceData, "invoiceData");
  const [invoiceState, setInvoiceState] = useState({
    invoiceNum: "",
    cust_name: "",
    cust_email: "",
    productDetails: "",
    subscription: "",
    recurring_type: "",
    recurring_limit: 0,
    billingAddress: "",
    total: 0,
    invoice_date: "",
    due_date: "",
    file: "",
    status: "",
    method: "",
    bankDetails: "",
    payement_status: "",
    expired: 0,
    company_info: null,
    companyId: null,
    deposit: 0,
    attachments: null,
    proposal: 1,
    propId: null,
    edited: 0,
    updated_at: "",
    created_at: "",
    message: "",
    description: invoiceData && invoiceData?.productDetails[0]?.description,
  });
  const [invProducts, setInvProducts] = useState(null);
  const bankInformation = bankInfo;

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  useEffect(() => {
    if (!paramsId || !invoiceList) return;

    const invoiceSingle = invoiceList.find((x) => x.propId == paramsId);
    if (invoiceSingle) {
      setInvoiceState(invoiceSingle);
      console.log(invoiceSingle, "invsin");
    }
  }, [paramsId, invoiceList]);

  const findBankData = () => {
    if (!invoiceData || !invoiceState?.productDetails) return;
    const productData = JSON.parse(invoiceState?.productDetails);
    setInvProducts(productData[0]);
    // if (!invoiceData.companyDetails[0].id || !companiesList) return;
    // const companyId = invoiceData.companyDetails[0].id;

    // const bankInfo =
    //   companiesList && companiesList.find((x) => x.id == companyId);
    // setBankInformation(bankInfo?.bankDetails);
  };

  useEffect(() => {
    findBankData();
  }, [invoiceState, companiesList]);

  useEffect(() => {
    if (!invoiceState || !invProducts) return;
    if (invProducts) {
      const beforeAdvance =
        ((invProducts?.price * invProducts?.quantity - invProducts?.discount) *
          invProducts?.tax) /
          100 +
        invProducts?.price * invProducts?.quantity -
        invProducts?.discount -
        invProducts?.deposit;
      setBalanceDue(beforeAdvance);
    }
  }, [invoiceState, invProducts]);

  const handleMethod = (e) => {
    setInvoiceState((prevEditDeals) => ({
      ...prevEditDeals,
      method: e.target.value,
    }));
  };
  const handleDiscount = (e) => {
    setInvProducts((prev) => ({
      ...prev,
      discount: e.target.value,
    }));
  };
  const handlePriceChange = (e) => {
    setInvProducts((prev) => ({
      ...prev,
      price: e.target.value,
    }));
  };
  const handleDepositChange = (e) => {
    setInvProducts((prev) => ({
      ...prev,
      deposit: e.target.value,
    }));
  };
  const handleSub = (e) => {
    setInvoiceState((prevEditDeals) => ({
      ...prevEditDeals,
      subscription: e.target.value,
    }));
  };

  useEffect(() => {
    setInvoiceState((prev) => ({
      ...prev,
      invoiceDate: formattedDate,
    }));
  }, [formattedDate]);

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setInvoiceState((prev) => {
      const isDateInput = e.target.type === "date";
      const formattedValue = isDateInput ? e.target.value : value;
      return { ...prev, [name]: formattedValue };
    });
  };

  const onChnageInfo = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setInvoiceState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // const onChangeDescription = (e) => {
  //   const { name, value } = e.target;
  //   setEditInvoice((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const validate = () => {

    if (
      !invProducts ||
      !invProducts?.productName ||
      !invProducts?.price ||
      !invProducts?.tax ||
      !invProducts?.quantity
    ) {
      toast.error(
        "Product details are not available. Please try again by generating the invoice!"
      );
      return true;
    }
    return false;
  };
  const submitInvoice = async () => {
    
    if (invoiceState.due_date == null) {
      toast.error("Please Select Duedate!");
    }
    const isValid = validate();
    setInvoiceLoading(true);
    if (isValid === false) {
      const productData = [invProducts];

      const payload = {
        propId: invoiceState.propId,
        total: balanceDue.toFixed(1),
        cust_name: invoiceState.cust_name,
        cust_email: invoiceState.cust_email,
        billingAddress: invoiceState.billingAddress,
        invoice_date: invoiceState.invoice_date,
        due_date: invoiceState.due_date,
        method: invoiceState.method,
        subscription: invoiceState.subscription,
        productDetails: JSON.stringify(productData),
        deposit: invoiceState?.deposit,
      };

      const storeData = {
        payload,
        message: invoiceState.message,
        productDescription: invoiceState.productDescription,
        bankDetails: bankInformation,
      };
      sessionStorage.setItem("invoiceDetails", JSON.stringify(storeData));

      await singleInvoiceGeneration(payload, {
        onSuccess: async (res) => {
          setInvoiceLoading(false);
          sessionStorage.setItem(
            "DirectInvoiceNumber",
            JSON.stringify(res.data)
          );
          const Invoice = JSON.parse(
            sessionStorage.getItem("DirectInvoiceNumber")
          );
          navigate(
            `/ferns-invoice-single/${Invoice != null && Invoice?.invoiceId}`
          );
          setTimeout(() => {
            setInvoiceState({
              invoiceNum: "",
              cust_name: "",
              cust_email: "",
              productDetails: "",
              subscription: "",
              recurring_type: "",
              recurring_limit: 0,
              billingAddress: "",
              total: 0,
              invoice_date: "",
              due_date: "",
              file: "",
              status: "",
              method: "",
              bankDetails: "",
              payement_status: "",
              expired: 0,
              company_info: null,
              companyId: null,
              deposit: 0,
              attachments: null,
              proposal: 1,
              propId: null,
              edited: 0,
              updated_at: "",
              created_at: "",
            });
          }, [1000]);
        },
        onFailed: (err) => {
          toast.error(err.errormessage);
          setInvoiceLoading(false);
        },
      });
    }
  };
  console.log(bankInformation, "bankInformation");
  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Sidebar>
        <section id="content-wrapper" class="dashboard-content-wrapper">
          <div className="row">
            <div className="col-md-10">
              <h6 className="gen_in">Edit Invoice</h6>
            </div>
            <div className="col-md-2">
              {invoiceLoading ? (
                <button className="gen_btn" type="button" disabled>
                  Uploading...
                </button>
              ) : (
                <button
                  className="gen_btn"
                  type="button"
                  onClick={submitInvoice}
                >
                  Generate
                </button>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col in_data">
              <small> Customer Name</small> <br />
              <div className="mt-1">
                <input
                  type="text"
                  className="p-1 invoice_input"
                  value={invoiceState?.cust_name}
                  name="cust_name"
                  onChange={onChnageInfo}
                />
              </div>
            </div>
            <div className="col in_data">
              <small> Email</small> <br />
              <div className="mt-1">
                <input
                  type="email"
                  className="p-1 invoice_input form-control"
                  value={invoiceState.cust_email}
                  onChange={onChnageInfo}
                  name="cust_email"
                />
              </div>
            </div>
            <div className="col in_data">
              <small> Payment Method</small> <br />
              <select
                name="method"
                className="form-control"
                value={invoiceState?.method}
                onChange={handleMethod}
              >
                <option value="stripe">Stripe</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <small className="">Customer Address</small>
              <br />
              <textarea
                type="text"
                className="p-1 invoice_teactarea form-control"
                value={invoiceState.billingAddress}
                onChange={onChnageInfo}
                name="billingAddress"
              />
            </div>
            <div className="col-md-2 col-sm-6">
              <small>Invoice Date</small>
              <br />
              <input
                id="invoiceDate"
                className="form-control"
                type="date"
                value={
                  invoiceState.invoice_date
                    ? new Date(invoiceState.invoice_date)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                onChange={handleChangeData}
                name="invoice_date"
              />
            </div>
            <div className="col-md-2 col-sm-6">
              <small>Due Date</small>
              <br />
              <input
                type="date"
                className="form-control"
                value={
                  invoiceState.due_date
                    ? new Date(invoiceState.due_date)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                name="due_date"
                onChange={onChnageInfo}
              />
            </div>
            <div className="col in_data">
              <small> Subscription</small> <br />
              <select
                name="subscription"
                className="form-control"
                value={invoiceState?.subscription}
                onChange={handleSub}
              >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
          </div>
          {/* <div className="row mt-3">
            <div className="col">
              <b>Invoice Number</b>
              <br />
              <div className="number_inv">INV00128</div>
            </div>
          </div> */}
          <div className="row mt-3">
            <h6 className="mt-3">Invoice Details</h6>
            <table className="table inv_table">
              <tr>
                {/* <th className="new"></th> */}
                <th className="sl_no">Sl No:</th>
                <th>Product/Service</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Tax(%)</th>
              </tr>
              <tbody>
                <tr>
                  {/* <td className="new">
                      <i className="add_icon" onClick={handleAddInput}>
                        <IoIosAddCircleOutline />
                      </i>
                    </td> */}
                  <td className="sl_no">1</td>
                  <td>
                    {/* <Select options={deals} className="sel_inv" /> */}
                    {invProducts?.productName ?? "N/A"}
                    <br />
                    <input
                      type="text"
                      className="p-1 mt-1 invoice_input"
                      value={invoiceState.description}
                      name="productDescription"
                      onChange={onChnageInfo}
                    />
                  </td>
                  <td> <input
                      type="text"
                      className="discount"
                      value={invProducts?.price}
                      onChange={handlePriceChange}
                      name="price"
                    /></td>
                  <td> {invProducts?.quantity ?? "N/A"}</td>
                  <td> {invProducts?.tax ?? "N/A"}</td>

                  {/* <td>
                    {(
                      ((invoiceData.dealData.price *
                        invoiceData.dealData.quantity -
                        editInvoice.discount) *
                        invoiceData.dealData.tax) /
                        100 +
                      invoiceData.dealData.price * invoiceData.dealData.quantity
                    ).toFixed(2)}
                  </td> */}
                </tr>
              </tbody>
              <tfoot>
                {/* <tr class="text-offset">
                  <td colspan="7">
                    <b>Total</b>
                  </td>
                  <td className="text-left">
                    {((invoiceData.dealData.price *
                      invoiceData.dealData.quantity -
                      editInvoice.discount) *
                      invoiceData.dealData.tax) /
                      100 +
                      invoiceData.dealData.price *
                        invoiceData.dealData.quantity -
                      editInvoice.discount}
                  </td>
                </tr> */}
                <tr class="text-offset" style={{ border: "none !important" }}>
                  <td colspan="4">
                    <span>Sub Total</span>
                  </td>
                  <td className="text-left">
                    {/* {(
                      ((product?.price * product?.quantity -
                        editInvoice?.discount) *
                        product?.tax) /
                      100 +
                      product?.price * product?.quantity
                    ).toFixed(2)} */}
                    {(invProducts?.price * invProducts?.quantity).toFixed(2)}
                  </td>
                </tr>
                <tr class="text-offset table-row">
                  <td colspan="4">
                    <span>Discount amount</span>
                  </td>
                  <td className="text-left">
                    {" "}
                    <input
                      type="text"
                      className="discount"
                      value={invProducts?.discount}
                      onChange={handleDiscount}
                      name="discount"
                    />
                  </td>
                </tr>
                <tr class="text-offset" style={{ border: "none !important" }}>
                  <td colspan="4">
                    <span>Tax Amount</span>
                  </td>
                  <td className="text-left">
                    {((invProducts?.price * invProducts?.quantity -
                      invProducts?.discount) *
                      invProducts?.tax) /
                      100}
                  </td>
                </tr>
                <tr class="text-offset table-row">
                  <td colspan="4">
                    <h6>
                      <b>Total</b>
                    </h6>
                  </td>
                  <td className="text-left">
                    <span>
                      {" "}
                      {((invProducts?.price * invProducts?.quantity -
                        invProducts?.discount) *
                        invProducts?.tax) /
                        100 +
                        invProducts?.price * invProducts?.quantity -
                        invProducts?.discount}
                    </span>
                  </td>
                </tr>
                <tr></tr>
                <tr class="text-offset table-row">
                  <td colspan="4" className="table-row mt-3">
                    Deposit
                  </td>
                  <td className="text-left table-row">
                    <input
                      type="text"
                      className="discount"
                      value={invProducts?.deposit ?? "N/A"}
                      onChange={handleDepositChange}
                      name="deposit"
                    />
                  </td>
                </tr>{" "}
                <tr class="text-offset">
                  <td colspan="4">
                    <b>Balance Due</b>
                  </td>
                  <td className="text-left">{balanceDue.toFixed(1)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="card p-3">
                <div className="mt-3">
                  <b>Bank details</b> <hr />
                </div>

                <Fragment>
                  <p>
                    {" "}
                    {bankInformation?.bank ?? "N/A"} a/c :
                    {bankInformation?.paymentAccount ?? "N/A"}
                  </p>
                  {bankInformation?.ifsc != null &&
                    bankInformation?.ifsc != "" && (
                      <p>IFSC : {bankInformation?.ifsc ?? "N/A"}</p>
                    )}

                  <p>Branch : {bankInformation?.branch}</p>

                  {bankInformation?.sortCode != null &&
                    bankInformation?.sortCode != undefined &&
                    bankInformation?.sortCode != "" && (
                      <p>
                        {" "}
                        SortCode : {" " + bankInformation?.sortCode ?? "N/A"}
                      </p>
                    )}
                  {bankInformation?.swifCode != null &&
                    bankInformation?.swifCode != undefined &&
                    bankInformation?.swifCode != "" && (
                      <p>
                        {" "}
                        SwtftCode : {" " + bankInformation?.swifCode ?? "N/A"}
                      </p>
                    )}
                </Fragment>
              </div>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="col in_data">
                <label htmlFor="">Message</label>
                <div className="mt-1">
                  <textarea
                    className="message-inv p-2"
                    value={invoiceState.message}
                    name="message"
                    onChange={onChnageInfo}
                  />
                </div>
              </div>
              {/* <div className="col in_data">
                <label htmlFor="">Attach file</label>
                <div className="mt-1">
                
                  <input type="file" className="attach-inv"></input>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Sidebar>
    </div>
  );
}

export default EditInvoice;
