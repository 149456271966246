import React, { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useJobRoles = () => {
  const [alljobRole, setAllJobRoles] = useState([]);
  const [jobRoleLoading, setjobRoleLoading] = useState(false);

  const getallJobRoles = async () => {
    try {
      setjobRoleLoading(true);
      await BaseClient.get(APIEndpoints.getAllJObRoles, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setAllJobRoles(res.data);
          }
        },
        onFailed: (err) => {
          console.log("JOB ROLE FETCHING ERROR", err);
        },
      });
    } finally {
      setjobRoleLoading(false);
    }
  };

  return { getallJobRoles, alljobRole, jobRoleLoading };
};

export default useJobRoles;
