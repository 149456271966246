import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../Style/Style.css";
import Logo from "../../Assets/pakawaanlogoo.png";
import Utils from "../../Utils/utils";
import { AppContext } from "../../Context/AppContext";
import html2canvas from "html2canvas";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import { FaCircleInfo } from "react-icons/fa6";

function EditedInvoices() {
  const { updateInvoiceFile, balanceDue, fetchAllProposalData } =
    useContext(AppContext);
  const targetRef = useRef();
  const isFirstLoad = useRef(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const InvoiceNumber = JSON.parse(
    sessionStorage.getItem("DirectInvoiceNumber")
  );
  const data = JSON.parse(sessionStorage.getItem("invoiceDetails"));
  const invoiceDetails = data?.payload;
  const productArray = JSON.parse(invoiceDetails.productDetails);
  const bankDetails = data.bankDetails;
  const [numPages, setNumPages] = useState(1);
  const nav = JSON.parse(sessionStorage.getItem("data"));
  const [finishStatus, setfinishStatus] = useState(false);
  const comp = sessionStorage.getItem("DirectProposal");
  const proData = JSON.parse(comp);

  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   if (!finishStatus) {
  //     if (window.confirm("Do you want to go back ?")) {
  //       setfinishStatus(true);
  //       // your logic
  //       console.log("back button clicked");
  //     } else {
  //       window.history.pushState(null, null, window.location.pathname);
  //       setfinishStatus(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  //   return () => {
  //     window.removeEventListener("popstate", onBackButtonEvent);
  //   };
  // }, []);

  useEffect(() => {
    const calculateNumPages = () => {
      const totalPages = Math.ceil(
        targetRef.current.offsetHeight / window.innerHeight
      );
      setNumPages(totalPages);
    };

    window.addEventListener("resize", calculateNumPages);

    return () => {
      window.removeEventListener("resize", calculateNumPages);
    };
  }, []);

  const handleUpdatePDF = async () => {
    setLoading(true);

    try {
      // Capture the canvas with reduced scale and JPEG format
      const canvas = await html2canvas(targetRef.current, {
        imageTimeout: 0,
        scale: 2,
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
        allowTaint: true,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/jpeg", 0.95);

      // Create PDF
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );

      const pdfBlob = pdf.output("blob");

      const formData = new FormData();
      formData.append("file", pdfBlob, "Invoice.pdf");
      formData.append("invoiceId", id);
      formData.append("invoiceNum", InvoiceNumber?.invoiceNum);

      await updateInvoiceFile(formData, id, {
        onSuccess: async (res) => {
          toast.success("Successfully sent Invoice");
          setLoading(false);
          await fetchAllProposalData();
          window.location.href = "/proposals";
        },
        onFailed: (err) => {
          setLoading(false);
          if (err != null) {
            toast.error(err.errormessage);
          } else {
            toast.error("Something Went Wrong!");
          }
        },
      });
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while generating the PDF.");
    }
  };

  return (
    <Fragment>
      {/* <div className="showAlert_wrapper">
          <div className="card showAlert_card">
            <p className="title">
              <i>
                <FaCircleInfo />
              </i>{" "}
              Are you sure?
            </p>
            <p className="content__">
              If you navigate away from this page, the generated invoice will be
              automatically cancelled. Are you sure you want to go back?
            </p>
          </div>
        </div> */}
      <div style={{ background: "#eaeff1" }} className="container-fluid">
        <Toaster position="top-center" />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {loading ? (
                <button className="btn-SEND" type="button" disabled>
                  Sending...
                </button>
              ) : (
                <button
                  className="btn-SEND"
                  type="button"
                  onClick={handleUpdatePDF}
                >
                  Send
                </button>
              )}
            </div>
          </div>
        </div>{" "}
        <>
          <div className="row">
            <div className="col-sm-12 mx-auto mt-3 mb-3">
              <div className="inv_bg" ref={targetRef}>
                <div className="col-md-12">
                  <div className="container inv">
                    <div className="row">
                      <div className="col-sm-6">
                        <div class="custom-col">
                          <h1 className="inv_head">Invoice</h1>
                          {/* <p class="invoice_Add">
                            30A Ipswich Road, Colchester, United Kingdom
                            <br />
                            <small>info@foodpage.co.uk </small>
                            <br />
                            <small> +44 752 553 60 15</small>
                          </p> */}
                        </div>
                        <div
                          className="custom-col"
                          style={{
                            position: "relative",
                            top: "115px",
                            color: "#595957",
                          }}
                        >
                          <small>Invoice To,</small>
                          <h6
                            style={{
                              color: "#000",
                              fontWeight: "600",
                              position: "relative",
                              top: "10px",
                            }}
                          >
                            {invoiceDetails?.cust_name ?? "NA"}
                          </h6>
                          <p
                            class="invoice_Add"
                            style={{
                              position: "relative",
                              top: "10px",
                            }}
                          >
                            {invoiceDetails?.billingAddress ?? "NA"}
                            <br />
                            <p>{invoiceDetails?.cust_email ?? "NA"} </p>
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-2"></div>
                      <div className="col-sm-4">
                        <div class="logo-arera">
                          <img
                            src={Logo}
                            style={{ position: "relative", left: "-10px" }}
                          />
                        </div>
                        <div class="table-Info">
                          <table>
                            <tr>
                              <td>
                                <b> Invoice Number</b>

                                <br />
                              </td>
                              <td> {InvoiceNumber?.invoiceNum}</td>
                            </tr>

                            <tr>
                              <td>
                                <b> Invoice Date</b>
                              </td>
                              <td>
                                {" "}
                                <small>
                                  {Utils.formatDate(
                                    invoiceDetails?.invoice_date ?? "NA"
                                  )}
                                </small>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b> Due Date</b>

                                <br />
                              </td>
                              <td>
                                <small>
                                  {Utils.formatDate(
                                    invoiceDetails?.due_date ?? "NA"
                                  )}
                                </small>
                              </td>
                            </tr>
                          </table>
                          <div
                            className="p-1"
                            style={{
                              color: "#595957",
                              position: "relative",
                              top: "25px",
                            }}
                          >
                            <small>Invoice From,</small>

                            <h6
                              style={{
                                color: "#000",
                                fontWeight: "600",
                                position: "relative",
                                top: "5px",
                              }}
                            >
                           Pakwaan
                            </h6>
                            <p
                              class="invoice_Add"
                              style={{
                                position: "relative",
                                top: "7px",
                                width: "100%",
                                paddingRight: "30px",
                              }}
                            >
                              Pakwaan Indian Punjabi Cuisine
                              1 Bridge St, Writtle, Chelmsford CM1 3EY
                              <br />
                              <p>
                              info@pakwaan.co.uk
                                <br />
                                <small>+44 1245 422 891</small>
                              </p>
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-1"></div>
                    </div>
                    <div
                      className="row mx-auto"
                      style={{ position: "relative", top: "75px" }}
                    >
                      <div className="col-sm-12 mx-auto">
                        <table className="in_table p-3">
                          <tr
                            className="under_line"
                            style={{
                              background: "#C40C0C",
                              color: "#fff",
                              padding: "10px",
                            }}
                          >
                            <th>#</th>
                            <th>Item</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Subscription Type</th>
                            <th>Tax(%)</th>
                            {/* <th>Discount</th> */}
                          </tr>
                          <tbody>
                            {productArray != null &&
                              productArray.map((product, index) => {
                                const taxamount =
                                  ((product?.price * product?.quantity -
                                    product?.discount) *
                                    product?.tax) /
                                  100;
                                const totalValue =
                                  product.price * product.quantity -
                                  product.discount +
                                  taxamount;
                                const totalPayable =
                                  totalValue - product.deposit;

                                return (
                                  <>
                                    <>
                                      <tr>
                                        <td height="1" colspan="4"></td>
                                      </tr>
                                      <tr
                                        className="under_line"
                                        style={{
                                          background: "#e1e1e1",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <td>{index + 1}</td>
                                        <td style={{ textAlign: "left" }}>
                                          {product?.productName ?? "NA"}
                                          <br />
                                          <small>
                                            {" "}
                                            {data?.productDescription}
                                          </small>
                                        </td>
                                        <td>{product?.price ?? "NA"}</td>
                                        <td>{product?.quantity ?? "NA"}</td>
                                        <td>
                                          {invoiceDetails?.subscription ?? "NA"}
                                        </td>

                                        <td> {product?.tax ?? "NA"}</td>
                                      </tr>
                                      <tr style={{ height: "30px" }}>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                      </tr>
                                      <tr style={{ textAlign: "right" }}>
                                        <td> </td>
                                        <td colSpan={3}></td>
                                        <td className="">Sub Total</td>
                                        <td className="">
                                          {(
                                            product.price * product.quantity
                                          ).toFixed(2)}
                                        </td>
                                      </tr>
                                      <tr style={{ textAlign: "right" }}>
                                        <td> </td>
                                        <td colSpan={3}></td>
                                        <td className="">Discount</td>
                                        <td className="">
                                          {product.discount ?? "N/A"}
                                        </td>
                                      </tr>
                                      <tr style={{ textAlign: "right" }}>
                                        <td> </td>
                                        <td colSpan={3}></td>
                                        <td className="">Tax</td>
                                        <td className="">{taxamount}</td>
                                      </tr>
                                      <tr style={{ textAlign: "right" }}>
                                        <td> </td>
                                        <td colSpan={3}></td>
                                        <td className="">Total</td>
                                        <td className="">
                                          {totalValue ?? "N/A"}
                                        </td>
                                      </tr>

                                      <tr style={{ textAlign: "right" }}>
                                        <td> </td>
                                        <td colSpan={3}></td>
                                        <td className="">Deposit</td>
                                        <td className="">
                                          {product.deposit ?? "N/A"}
                                        </td>
                                      </tr>

                                      <tr
                                        style={{
                                          textAlign: "right",
                                        }}
                                      >
                                        <td> </td>
                                        <td colSpan={3}></td>
                                        <td
                                          style={{
                                            background: "#eee",
                                            padding: "8px",
                                          }}
                                        >
                                          <b>Total Due</b>
                                        </td>
                                        <td
                                          style={{
                                            background: "#eee",
                                            padding: "8px",
                                          }}
                                        >
                                          <b></b>
                                          £  {totalPayable ?? "N/A"}
                                        </td>
                                      </tr>
                                    </>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                        {data.message != null &&
                          data.message != undefined &&
                          data.message != "" && (
                            <div className="col-md-7 mt-3">
                              <p
                                style={{
                                  textAlign: "justify",
                                  fontSize: "12px",
                                  fontFamily: "poppins",
                                }}
                              >
                                {data?.message ?? "N/A"}
                              </p>
                            </div>
                          )}
                        <div className="brk_div"></div>
                        <div className="col-md-6"></div>
                        <div
                          className="col-sm-5"
                          style={{
                            position: "relative",
                            bottom: "0",
                            top: "0%",
                          }}
                        >
                          <div className="more_details card p-3">
                            <h5>PAYMENT METHOD</h5>

                            {/* <h6>Ferns IT Solutions Private Limited  <br /></h6> */}
                            <h6>
                              {" "}
                              {bankDetails.bank ?? "NA"}
                              {" : "}
                              {bankDetails?.paymentAccount ?? "NA"}
                              <br /> IFSC :{bankDetails?.ifsc ?? "NA"} <br />
                              {/* sortCode : 512121 <br /> */}
                              {bankDetails?.branch ?? "NA"} <br />
                              {bankDetails?.sortCode != null &&
                                bankDetails?.sortCode != undefined &&
                                bankDetails?.sortCode != "" && (
                                  <p>
                                    Sort-Code {bankDetails?.sortCode ?? "NA"}
                                  </p>
                                )}
                              {bankDetails?.swiftCode != null &&
                                bankDetails?.swiftCode != undefined &&
                                bankDetails?.swiftCode != "" && (
                                  <p>
                                    Swift-Code {bankDetails?.swiftCode ?? "N/A"}
                                  </p>
                                )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="auto-msg">System Generated Invoice</p>
              </div>
            </div>
          </div>
        </>
      </div>
    </Fragment>
  );
}

export default EditedInvoices;
