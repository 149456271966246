import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Md from "react-icons/md";
import { Modal } from "antd";
import { toast, Toaster } from "react-hot-toast";
import Utils from "../../Utils/utils";
import { AppContext } from "../../Context/AppContext";

function InvoiceGeneration({ completedContracts }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { leadInvoices, cancelInvoice, fetchPaymentByLeadId } =
    useContext(AppContext);
  const params = useParams();
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [contractInfo, setContractInfo] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [viewPayment, setViewPayment] = useState(false);

  const [invoices, setInvoices] = useState(null);

  const handleCreateInvoice = (contract) => {
    setSelectedContract(contract);
    sessionStorage.setItem("selectedContract", JSON.stringify(contract));
    sessionStorage.setItem("data", JSON.stringify(location));
    navigate("/create-invoice-lead");
  };

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const showContractDetails = (contract) => {
    setSelectedContract(contract);
    setContractInfo(true);
  };
  const handleOk = () => {
    setContractInfo(false);
  };

  const handleCancel = () => {
    setContractInfo(false);
  };
  console.log(params, "params");

  const getInvoice = async () => {
    const id = params.leadId;
    await leadInvoices(id, {
      onSuccess: async (res) => {
        setInvoices(res.data);
      },
      onFailed: (err) => {
        console.error("Please Try Again!");
      },
    });
  };
  const getPaymentDetails = async () => {
    const id = params.leadId;
    const payload = {
      leadId: id,
    };

    await fetchPaymentByLeadId(payload, {
      onSuccess: async (res) => {
        setPaymentDetails(res.data);
      },
      onFailed: (err) => {
        console.error("Please Try Again!");
      },
    });
  };

  useEffect(() => {
    getInvoice();
    getPaymentDetails();
  }, [completedContracts]);

  console.log(invoices, "invoices");
  const ConfirmDelete = async (data) => {
    const id = data?.invoiceId;
    if (!id) return;
    await cancelInvoice(id, {
      onSuccess: async (res) => {
        toast.success("Invoice Cancelled Successfully!");
      },
      onFailed: (err) => {
        toast.error(err.errormessage);
      },
    });
  };
  const ok = () => {
    setViewPayment(false);
  };

  const close = () => {
    setViewPayment(false);
  };

  const handlePaymentView = (data) => {
    setViewPayment(true);
    setSelectedInvoice(data.paymentHistory);
  };
  console.log(selectedInvoice, "pdsdfdsf");
  return (
    <div>
      <div className="mt-3 mb-3">
        {selectedInvoice?.length != 0 && (
          <>
            <Modal
              open={viewPayment}
              onOk={ok}
              width={"50%"}
              footer={false}
              onCancel={close}
            >
              <div className="row">
                {selectedInvoice &&
                  selectedInvoice != undefined &&
                  selectedInvoice.map((payment, index) => {
                    return (
                      <>
                        <div className="col-md-6 col-sm-12">
                          <div
                            className="card p-2"
                            style={{ minHeight: "170px", margin: "10px" }}
                          >
                            <b>#{index + 1}</b>
                            <p>
                              Transaction Id : {payment?.trId ?? "N/A"} <br />
                              Actual Amount : {payment?.act_amt ?? "N/A"} <br />
                              Received Amount: {payment?.rcv_amt ?? "N/A"}{" "}
                              <br />
                              {payment?.status == "partially-paid" && (
                                <>
                                  Balance :{" "}
                                  {payment?.act_amt - payment?.rcv_amt ?? "0"}
                                  <br />
                                </>
                              )}
                              Date:{Utils.formatDate(payment?.tr_date)} <br />
                              {payment?.remarks ?? "N/A"}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </Modal>
          </>
        )}

        <Modal
          open={contractInfo}
          onOk={handleOk}
          width={"80%"}
          footer={false}
          onCancel={handleCancel}
        >
          <h5 className="text-center selc">Contract Details</h5>
          <div className="mt-3 mb-3">
            <div className="contract_Card">
              <>
                <form className="deal_form">
                  <h6 className="con-info">Client Info</h6>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor="">Name</label>
                      <div className="form-disabled">
                        {selectedContract?.username ?? "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor="">Email</label>
                      <div className="form-disabled">
                        {selectedContract?.email ?? "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h6 className="con-info">Product Info</h6>
                    <br />
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor=""> Product</label>
                      <div className="form-disabled">
                        {selectedContract?.dealData?.productName ?? "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor=""> price</label>
                      <div className="form-disabled">
                        {selectedContract?.dealData?.price ?? "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor=""> Quantity</label>
                      <div className="form-disabled">
                        {selectedContract?.dealData?.quantity ?? "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor=""> VAT(%)</label>
                      <div className="form-disabled">
                        {selectedContract?.dealData?.tax ?? "N/A"}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor="">VAT Amount</label>
                      <div className="form-disabled">
                        {(selectedContract?.dealData?.price *
                          selectedContract?.dealData?.quantity *
                          selectedContract?.dealData?.tax) /
                          100}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor="">Total</label>
                      <div className="form-disabled">
                        {(selectedContract?.dealData?.price *
                          selectedContract?.dealData?.quantity *
                          selectedContract?.dealData?.tax) /
                          100 +
                          selectedContract?.dealData?.price *
                            selectedContract?.dealData?.quantity}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label htmlFor=""> Start Date</label>
                      <div className="form-disabled">
                        {Utils.formatDate(
                          selectedContract?.dealData?.startDate ?? "N/A"
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <label htmlFor=""> End Date</label>
                      <div className="form-disabled">
                        {Utils.formatDate(
                          selectedContract?.dealData?.endDate ?? "N/A"
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label htmlFor=""> Subscription Type</label>
                      <div className="form-disabled">
                        {selectedContract?.dealData?.subscription ?? "N/A"}
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      {/* <p className="desc">{dealDetails.description ?? "N/A"}</p> */}
                    </div>
                  </div>
                </form>
              </>
            </div>
          </div>
        </Modal>
        {completedContracts != null && completedContracts?.length != 0 ? (
          <>
            <Table bordered size="sm" className="container new-tab">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Product Name</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {completedContracts != null &&
                  Array.isArray(completedContracts) &&
                  completedContracts.map((contract, index) => {
                    const dealInfo = contract.dealData;
                    return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{contract.username ?? "N/A"}</td>
                          <td>{contract.email ?? "N/A"} </td>
                          <td>{dealInfo.productName ?? "N/A"}</td>
                          <td className="text-center position-relative">
                            <div className="btn-toggle">
                              <button
                                className="one-btn"
                                onClick={() => showContractDetails(contract)}
                              >
                                View
                              </button>
                              <button
                                className="two-btn"
                                onClick={() => toggleDropdown(index)}
                              >
                                <Md.MdOutlineArrowDropDown />
                              </button>
                            </div>
                            {openDropdownIndex === index && (
                              <ul className="table-mgt" role="menu">
                                <li>
                                  <button
                                    className="btn"
                                    onClick={() =>
                                      showContractDetails(contract)
                                    }
                                  >
                                    View
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="btn"
                                    onClick={() =>
                                      handleCreateInvoice(contract)
                                    }
                                  >
                                    Generate
                                  </button>
                                </li>
                              </ul>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
          </>
        ) : (
          <div className="alert-msg">
            <p> No completed contracts were found.</p>
          </div>
        )}
        {invoices != null && invoices?.length != 0 ? (
          <div className="row">
            <h6 className="mt-3">Invoices</h6>
            <div className="col-md-12 col-sm-12 text-center">
              <div className="table-contract">
                <table className="table table-secondary">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Contract Date</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices != null &&
                      invoices.toReversed().map((data, index) => {
                        console.log("invoices", data);
                        return (
                          <tr className="table-light">
                            <td className="cust_line_height">{index + 1}</td>
                            <td>
                              <p className="date">
                                {" "}
                                {Utils.formatDate(data?.invoice_date)}
                              </p>
                            </td>
                            <td className="cust_line_height">
                              {data.cust_name ?? "N/A"}
                            </td>
                            <td className="cust_line_height">
                              {data?.cust_email ?? "N/A"}
                            </td>
                            <td className="cust_line_height">
                              £{data.total ?? "N/A"}
                            </td>
                            <td className="cust_line_height">
                              {data.payement_status ?? "N/A"}
                            </td>
                            <td>
                              {data?.status === "cancel" ? (
                                <p
                                  style={{
                                    color: "red",
                                    marginTop: "5px",
                                    background: "rgb(250, 195, 195)",
                                    padding: "5px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  Cancelled
                                </p>
                              ) : (
                                <>
                                  <button
                                    className="cancel-btn"
                                    onClick={() => ConfirmDelete(data)}
                                  >
                                    Cancel
                                  </button>
                                  <br />
                                </>
                              )}
                              {data?.paymentHistory &&
                                data?.paymentHistory?.length != 0 && (
                                  <>
                                    <button
                                      className="cancel-btn mt-2"
                                      style={{
                                        background: "rgb(189 235 201)",
                                        color: "green",
                                      }}
                                      onClick={() => handlePaymentView(data)}
                                    >
                                      View
                                    </button>
                                    <br />
                                  </>
                                )}

                              <br />
                              <a
                                download
                                href={
                                  process.env.REACT_APP_AWS_IMAGE_URL +
                                  "invoices/" +
                                  data?.file
                                }
                              >
                                <i className="download_icon mt-3">
                                  {" "}
                                  <Md.MdOutlineFileDownload />
                                </i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          " "
        )}
      </div>
    </div>
  );
}

export default InvoiceGeneration;
