import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { Modal } from "antd";
import Table from "react-bootstrap/Table";

import Utils from "../../Utils/utils";

import Proposal from "./Proposal";
import ContractSigning from "./ContractSigning";
import InvoiceGeneration from "./InvoiceGeneration";
import LeadQualification from "./LeadQualification";

import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";

function LeadStages({ allJobQueueArray, jobQueueStatusArray, leadId, deals }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    allSellerEnquiries,
    fetchAllSellerEnquiries,
    adminList,
    fetchFinalizedContracts,
    alljobRole,
    enquiryLoading,
    DealSending,
    finalizeDeal,
    sendLink,
    completeStage,
    getAllCompletedContracts,
    deleteDealbyId,
    multipleAssignment,
    updateDealData,
    productList,
    updateLeadToSeller,
    sellerEnquiriesLoading,
    companiesList,
  } = useContext(AppContext);
  const [selectedStage, setSelectedStage] = useState(null);
  const [finalizedDeals, setFinalizedDeals] = useState(null);
  const [showRescheduleCard, setShowRescheduleCard] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [completedContracts, setCompletedContracts] = useState(null);
  const [emailEdit, setEmailEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [lastIndex, setLastIndex] = useState(-1);
  const [stageLoadingAction, setStageLoadingAction] = useState("");
  const [enquiryData, setEnquiryData] = useState(null);
  const [salesmenList, setSalesmenList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const [dealsModal, setDealsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailsStage, setDetailsStage] = useState(false);
  const [editDeals, setEditDeals] = useState({
    startDate: null,
    endDate: null,
    price: 0,
    description: "",
    tax: null,
    trial: false,
    quantity: null,
    pId: null,
    subscription: "monthly",
    status: null,
    startDate: null,
    currency: "£",
  });
  const [selectedDeal, setSelectedDeal] = useState({
    startDate: null,
    endDate: null,
    price: 0,
    description: "",
    trial: false,
    finalize: false,
    tax: null,
    pId: null,
    subscription: "monthly",
    status: null,
    quantity: null,
    index: 0,
  });
  useEffect(() => {
    setEmail(enquiryData?.sellerDetails.sellerEmail);
    setName(enquiryData?.sellerDetails.sellerName);
  }, [enquiryData]);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [editModal, setEditModal] = useState(false);

  const handleDeal = (data) => {
    if (!data) return;
    if (data.mailStatus == true) {
      toast.error("Deal Already Sended. Please Try to Send Deal By updating.");
      return;
    } else {
      const selectedDeal = data;
      setSelectedDeal(selectedDeal);
      setEditModal(true);
    }
  };

  const handleProductSelect = (event) => {
    // const pId = event.target.value;
    // console.log("piD", pId);
    // setSelectedDeal((prev) => ({ ...prev, pId }));
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedDeal((prevDealData) => ({ ...prevDealData, [name]: checked }));
  };
  const handleSubscription = (event) => {
    const { value, name } = event.target;
    setSelectedDeal((prev) => ({
      ...prev,
      subscription: value,
    }));
  };
  const handleStatusChange = (event) => {
    const { value, name } = event.target;
    setSelectedDeal((prev) => ({
      ...prev,
      status: value,
    }));
  };
  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setSelectedDeal((prev) => {
      const isDateInput = e.target.type === "date";
      const formattedValue = isDateInput ? e.target.value : value;

      return { ...prev, [name]: formattedValue };
    });
  };

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const validated = () => {
    if (
      selectedDeal?.price?.length == 0 ||
      selectedDeal?.product?.length == 0 ||
      selectedDeal?.startDate?.length == 0 ||
      selectedDeal?.endDate?.length == 0
    ) {
      toast.error("Please Fill All Mandatory Fields!");
      return;
    }
    if (selectedDeal.subscription == null) {
      toast.error("Please Select a Subscription Method!");
      return;
    }
  };
  const statusHandle = () => {
    if (
      selectedDeal?.status?.length == 0 ||
      selectedDeal?.status == undefined
    ) {
      toast.error("Please Choose Status!");
      return;
    }
  };
  const handleDeals = async (e, index) => {
    e.preventDefault();
    validated();
    statusHandle();
    const payload = {
      subscription: selectedDeal.subscription,
      dealId: selectedDeal.dealId,
      startDate: selectedDeal?.startDate,
      endDate: selectedDeal?.endDate,
      username: name,
      email: email,
      trial: selectedDeal.trial,
    };
    if (selectedDeal.price == 0) {
      Modal.confirm({
        title:
          "You have set the price as `0`. This will classify the deal as a trial. Are you sure you want to continue?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          confirmDeal(payload);
        },
      });
    } else if (selectedDeal.price != 0) {
      await confirmDeal(payload);
    } else {
      toast.message("Price is Invalid!");
    }
  };
  const confirmDeal = async (payload) => {
    await DealSending(payload, {
      onSuccess: async (res) => {
        setEditModal(false);
        toast.success("Details Sented Successfully!");
        window.location.reload();
        setTimeout(() => {
          setSelectedDeal({
            startDate: " ",
            endDate: " ",
            price: 0,
            description: "",
            trial: false,
            finalize: false,
            description: "",
            status: null,
            index: 0,
            subscription: null,
          });
        });
      },
      onFailed: (err) => {
        if (err.errorCode == "INR") {
          toast.error("Please Fill all Mandatory Fields");
        } else {
          toast.error(err.errormessage);
        }
      },
    });
  };

  const cancelModal = () => {
    setEditModal(false);
    window.location.reload();
  };
  useEffect(() => {
    console.log("Deals state:", deals);
  }, [deals]);

  const products = productList.map((item) => ({
    value: `${item.pdfId} ${item.pId}`,
    label: item.productName,
  }));

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const ok = () => {
    setDealsModal(false);
  };

  const cancel = () => {
    setDealsModal(false);
  };
  const [initial, setInitial] = useState({
    email: enquiryData?.sellerDetails?.sellerEmail || "",
  });
  const [onBoard, setOnBoard] = useState({
    email: enquiryData?.sellerDetails?.sellerEmail || "",
  });
  console.log(enquiryData, "data");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInitial((prev) => {
      return {
        ...prev,
        [name]: isEditing
          ? value
          : enquiryData?.sellerDetails?.sellerEmail || "",
      };
    });
  };

  const handleEdit = (e) => {
    const { name, value } = e.target;
    setOnBoard((prev) => {
      return {
        ...prev,
        [name]: isEditable
          ? value
          : enquiryData?.sellerDetails?.sellerEmail || "",
      };
    });
  };

  useEffect(() => {
    getEnquiryDetails();
  }, [allSellerEnquiries]);

  const getEnquiryDetails = () => {
    if (!id || !allSellerEnquiries) return;

    const singleData = allSellerEnquiries?.find((x) => x?.pceId == id);
    setEnquiryData(singleData);
  };

  const stageDetails = async (stage) => {
    if (!stage) return;
    const arr = stage?.description;
    setShowEdit(false);
    setEmailEdit(false);
    if (arr != null && Array.isArray(arr) && arr.length > 0) {
      const keys = Object.keys(arr);
      const lastIndex = keys[keys.length - 1];
      setLastIndex(lastIndex);
    } else {
      setLastIndex(0);
    }

    setSelectedStage(stage === selectedStage ? null : stage);
    window.sessionStorage.setItem("selectedId", stage?.stpId);

    setShowRescheduleCard(true);
    setDetailsModal(true);
    // setDetailsStage
  };
  useEffect(() => {
    sessionStorage.setItem("selectedStage", JSON.stringify(selectedStage));
  }, [selectedStage]);

  const handleLead = async () => {
    if (!selectedStage || !jobQueueStatusArray) {
      toast.error("CANNOT COMPLETE THE ACTION, JOB ID IS MISSING");
      return;
    }
    const payload = {
      psasId:
        selectedStage != null ? selectedStage.jobId : jobQueueStatusArray.jobId,
      cmpStatus: true,
      status: "completed",
      sId: enquiryData.aId,
    };

    setStageLoadingAction("complete");

    await completeStage(payload, {
      onSuccess: async (res) => {
        toast.success("Stage has been completed!");
        await fetchAllSellerEnquiries();
        stageDetails(selectedStage);
        setStageLoadingAction("");
      },
      onFailed: (err) => {
        toast.error("Error on completing the stage, Please try again!");
        setStageLoadingAction("");
      },
    });
  };

  const handleEditDescription = (e) => {
    setShowEdit(!showEdit);
  };

  const handleDescriptionSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      description: editedDescription,
      psasId:
        selectedStage != null ? selectedStage.jobId : jobQueueStatusArray.jobId,
      rescheuleStat: false,
      sId: enquiryData.aId,
      indx: parseInt(lastIndex),
    };

    await completeStage(payload, {
      onSuccess: async (res) => {
        toast.success("Description Added Successfully!");
        setShowEdit(false);
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
      },
    });
  };

  const handleReSchedule = async () => {
    const payload = {
      psasId: selectedStage?.jobId,
      rescheuleStat: true,
      sId: enquiryData.aId,
    };

    setStageLoadingAction("reSchedule");

    await completeStage(payload, {
      onSuccess: async (res) => {
        toast.success("Rescheduled!");
        await fetchAllSellerEnquiries();
        stageDetails(selectedStage);

        setStageLoadingAction({
          action: "",
          status: false,
        });
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
        setStageLoadingAction("");
      },
    });
  };

  const handleMandatory = () => {
    const mandatoryDatas = allJobQueueArray.filter((x) => x.mandatory === 1);

    if (!mandatoryDatas) return;

    const incompleteTasks = mandatoryDatas.filter(
      (data) => data.status === "pending"
    );
    if (incompleteTasks.length > 0) {
      incompleteTasks.map((data) => {
        toast.info(
          `You must complete ${data.taskname} first, before Customer On-Boarding`
        );
      });

      return false;
    }
    return true;
  };

  const handleOnBoarding = async () => {
    const payload = {
      pceId: enquiryData?.pceId,
    };

    const validation = handleMandatory();

    if (!validation) return;

    await updateLeadToSeller(payload, {
      onSuccess: async (res) => {
        toast.success("Lead has successfully changed as a Merchant!");
        await fetchAllSellerEnquiries();
        setTimeout(() => {
          navigate("/enquiry");
        }, 1500);
      },
      onFailed: async (err) => {
        if (err.errorCode == "INR") {
          toast.info("Please fill in all the mandatory fields to continue!");
          setTimeout(() => {
            navigate(`/update-enquiry/${enquiryData?.pceId}`);
          }, 1500);
        } else if (err.errorCode == "DSU") {
          toast.error(err.message);
          setTimeout(() => {
            navigate(`/update-enquiry/${enquiryData?.pceId}`);
          }, 1500);
        } else {
          toast.error(err?.message);
        }
        await fetchAllSellerEnquiries();
      },
    });
  };

  useEffect(() => {
    if (enquiryData) {
      setOnBoard((prev) => ({
        ...prev,
        email: enquiryData?.sellerDetails?.sellerEmail || "",
      }));
    }
  }, [enquiryData]);

  useEffect(() => {
    if (enquiryData) {
      setInitial((prev) => ({
        ...prev,
        email: enquiryData?.sellerDetails?.sellerEmail || "",
      }));
    }
  }, [enquiryData]);

  useEffect(() => {
    filterSalesMen();
  }, [adminList, alljobRole]);

  useEffect(() => {
    if (!id) return;

    setAssignedValues((prev) => ({ ...prev, pceId: id }));
  }, [id]);

  const filterSalesMen = () => {
    if (!adminList || !alljobRole) return;

    const data = adminList.filter((list) => {
      const jobRole = list.jobRoles;
      return jobRole.some((role) => role.role === "sales");
    });
    setSalesmenList(data);
  };

  const [assignedValues, setAssignedValues] = useState({
    pceId: "",
    aId: "",
    remarks: "",
  });

  const handleReAssign = async () => {
    const payload = {
      psasId: selectedStage?.jobId,
      aId: selectedValue,
    };

    await multipleAssignment(payload, {
      onSuccess: async (res) => {
        toast.success("Re-assigned Successfully!");
        setIsModalOpen(false);
        await fetchAllSellerEnquiries();
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
      },
    });
  };

  const handleDirectDebit = async (e) => {
    e.preventDefault();
    const payload = {
      email: onBoard.email,
    };

    await sendLink(payload, {
      onSuccess: async (res) => {
        toast.success("Details Sended Successfully!");
        await fetchAllSellerEnquiries();
        setEmailEdit(false);
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
      },
    });
  };

  const hideStageModal = () => {
    if (selectedStage?.taskname === "Invoice Generation") {
      setDetailsStage(false);
    }
  };
  useEffect(() => {
    hideStageModal();
  }, [selectedStage]);

  const handleProductChange = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      pId: e.target.value,
    }));
  };

  const handlePriceChange = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      price: e.target.value,
    }));
  };
  const handleQuantity = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      quantity: e.target.value,
    }));
  };
  const handleTaxChange = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      tax: e.target.value,
    }));
  };
  const handleStartDateChange = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      startDate: e.target.value,
    }));
  };

  const handleEndDateChange = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      endDate: e.target.value,
    }));
  };

  const handleStatus = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      status: e.target.value,
    }));
  };
  const handleSub = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      subscription: e.target.value,
    }));
  };

  const handleDescriptionChange = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      description: e.target.value,
    }));
  };

  const handleTrialChange = (e) => {
    setEditDeals((prevEditDeals) => ({
      ...prevEditDeals,
      trial: e.target.checked,
    }));
  };

  const handleView = (data) => {
    if (!data) return;

    setEditDeals(data);
    setDealsModal(true);
  };

  const updateValidate = () => {
    if (editDeals?.subscription == null) {
      toast.error("Please Choose Subscription Type!");
      setLoading(false);
      return;
    }
  };
  const handleUpdateDeal = async (e) => {
    e.preventDefault();
    setLoading(true);
    updateValidate();
    const payload = {
      dealId: editDeals.dealId,
      psasId:
        selectedStage != null ? selectedStage.jobId : jobQueueStatusArray.jobId,
      finalize: false,
      description: editDeals.description,
      quantity: editDeals.quantity,
      tax: editDeals.tax,
      trial: editDeals.trial,
      status: editDeals.status,
      price: editDeals.price,
      startDate: editDeals.startDate,
      endDate: editDeals.endDate,
      subscription: editDeals.subscription,
      pId: editDeals.pId,
      email: email,
      username: name,
    };
    if (editDeals.price != 0 && editDeals.trial === true) {
      toast("You cannot Select Trial With a Price");
      setLoading(false);
      return;
    }
    if (editDeals.price == 0) {
      Modal.confirm({
        title:
          "You have set the price as `0`. This will classify the deal as a trial. Are you sure you want to continue?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          EditDeal(payload);
        },
        onCancel() {
          setDealsModal(false);
        },
      });
    } else if (editDeals.price !== 0) {
      await EditDeal(payload);
    } else {
      toast.message("Price is Invalid!");
    }
  };

  const EditDeal = async (payload) => {
    await updateDealData(payload, {
      onSuccess: async (res) => {
        toast.success("Deal Updated Successfully!");
        setDealsModal(false);
        setLoading(false);
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error(err.errormessage);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    console.log("edited", editDeals);
  }, [editDeals]);

  const getAllFinalizedDeals = async () => {
    const payload = {
      leadId: leadId,
    };
    await fetchFinalizedContracts(payload, {
      onSuccess: async (res) => {
        setFinalizedDeals(res.data);
      },
      onFailed: (err) => {
        console.error("finalized Proposal Error");
      },
    });
  };
  useEffect(() => {
    getAllFinalizedDeals();
    getCompletedContracts();
  }, []);

  const handleFinalizeDeal = async (data) => {
    const payload = {
      dealId: data.dealId,
      psasId: selectedStage != null && selectedStage.jobId,
      cmpStatus: true,
      finalize: true,
    };

    await finalizeDeal(payload, {
      onSuccess: async (res) => {
        toast.success("This Deal is Finalized!");
        setDealsModal(false);
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error(err.errormessage);
      },
    });
  };

  const ConfirmDelete = (dealId, leadId) => {
    confirmAlert({
      title: "Confirmation",
      message:
        "You are about to delete this Deal. Please be aware that this action will also permanently delete all corresponding details and data associated with this Deal. Are you sure you want to proceed with the deletion?",
      buttons: [
        {
          label: "Yes",

          onClick: async () => {
            await deleteDealbyId(dealId, leadId, {
              onSuccess: async (res) => {
                toast.success("Deals Successfully Deleted!");
                window.location.reload();
              },
              onFailed: (err) => {
                toast.error(err.errormessage);
              },
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const getCompletedContracts = async () => {
    const payload = {
      leadId: leadId,
    };
    await getAllCompletedContracts(payload, {
      onSuccess: async (res) => {
        setCompletedContracts(res.data);
        console.log("Result", res.data);
      },
      onFailed: (err) => {
        console.error(err.errormessage);
      },
    });
  };
  const stageData = sessionStorage.setItem(
    "stageDetails",
    JSON.stringify(selectedStage)
  );
  console.log(selectedDeal, "selectedDeal");
  return (
    <Fragment className="stages">
      <>
        <Modal open={editModal} closable={false} width="70%" footer={null}>
          <i onClick={cancelModal} className="float-r">
            <Ai.AiOutlineClose />
          </i>
          <div className="row">
            <div className="col-md-12 col-sm-12 card p-3">
              <h6 className="mt-3 mb-3 text-center">Send Deal</h6>
              <form className="deal_form" onSubmit={(e) => handleDeals(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <option value="" disabled>
                      Package *
                    </option>
                    <input
                      className="form-control"
                      // type="number"
                      value={selectedDeal?.packageName}
                      min={0}
                      disabled
                      name="price"
                    />
                  </div>

                  <div className="col-md-3 col-sm-12">
                    <option value="" disabled>
                      Value(Price) *
                    </option>

                    <input
                      className="form-control"
                      // type="number"
                      value={selectedDeal?.price}
                      onChange={handleChangeData}
                      placeholder="Deal Amount"
                      min={0}
                      disabled
                      name="price"
                    />
                  </div>

                  <div className="col-md-3 col-sm-12">
                    <option value="" disabled>
                      VAT(%) *
                    </option>

                    <input
                      className="form-control"
                      value={selectedDeal?.tax}
                      onChange={handleChangeData}
                      name="tax"
                      disabled
                    />
                  </div>

                 
                  <div className="col-lg-3 mt-3 col-md-6 col-sm-6">
                    <label htmlFor="" className="form-label review-label">
                      Start Date *
                    </label>
                    <br />
                    <input
                      className="form-control date-de"
                      type="date"
                      name="startDate"
                      onChange={handleChangeData}
                      value={selectedDeal?.startDate}
                    />
                  </div>
                  <div className="col-lg-3 mt-3 col-md-6 col-sm-12">
                    <label htmlFor="" className="form-label review-label">
                      End Date *
                    </label>
                    <br />
                    <input
                      className="form-control date-de"
                      type="date"
                      value={selectedDeal?.endDate}
                      onChange={handleChangeData}
                      placeholder="dd-mm-yyyy"
                      name="endDate"
                    />
                  </div>
                 

                  <div className="col-md-6 col-sm-12">
                    <option value="" disabled>
                      Email
                    </option>

                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      disabled
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <option value="" disabled>
                      Client Name
                    </option>

                    <input
                      className="form-control"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      disabled
                      name="name"
                    />
                  </div>
                </div>

                <div className="mt-3 text-center">
                  {sellerEnquiriesLoading ? (
                    <button className="complete" disabled>
                      Submitting...
                    </button>
                  ) : (
                    <button className="complete" type="submit">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          open={dealsModal}
          onOk={ok}
          onCancel={cancel}
          width={1000}
          footer={null}
        >
          <>
            <div className="row p-2">
              <div className="col-md-12 col-sm-12">
                <>
                  <Fragment>
                    <div className="card p-2 mt-3 m-2">
                      <div className="row">
                        <h4 className="deal_num text-capitalize text-center">
                          Update Deal
                        </h4>
                      </div>
                      <br />

                      <form
                        className="deal_form"
                        onSubmit={(e) => handleUpdateDeal(e)}
                      >
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <option value="" disabled>
                              Product *
                            </option>
                            <select
                              name="product"
                              id="productSelect"
                              value={editDeals?.pId || ""}
                              onChange={handleProductChange}
                              className="form-control"
                            >
                              <option value="" disabled>
                                Select a product
                              </option>
                              {productList.map((product) => (
                                <option key={product?.pId} value={product?.pId}>
                                  {product.productName}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-6 col-sm-12">
                            <option value="" disabled>
                              Value(Price) *
                            </option>

                            <input
                              className="form-control"
                              type="number"
                              value={editDeals?.price}
                              onChange={handlePriceChange}
                              placeholder="Deal Amount"
                              min={0}
                              name="price"
                            />
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <option value="" disabled>
                              Quantity
                            </option>

                            <input
                              className="form-control"
                              type="number"
                              value={editDeals?.quantity}
                              onChange={handleQuantity}
                              name="tax"
                            />
                          </div>

                          <div className="col-md-3 col-sm-12">
                            <option value="" disabled>
                              VAT(%)*
                            </option>

                            <input
                              className="form-control"
                              value={editDeals?.tax}
                              onChange={handleTaxChange}
                              name="tax"
                            />
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <option value="" disabled>
                              VAT Amount
                            </option>

                            <input
                              className="form-control"
                              value={
                                (editDeals.price *
                                  editDeals.quantity *
                                  editDeals.tax) /
                                100
                              }
                            />
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <option value="" disabled>
                              Total
                            </option>

                            <input
                              className="form-control"
                              type="number"
                              value={
                                (editDeals?.price *
                                  editDeals?.quantity *
                                  editDeals.tax) /
                                  100 +
                                editDeals?.price * editDeals?.quantity
                              }
                              disabled
                            />
                          </div>
                          <div className="col-lg-6 mt-3 col-md-6 col-sm-12">
                            <label
                              htmlFor=""
                              className="form-label review-label"
                            >
                              Start Date *
                            </label>
                            <br />
                            <input
                              className="form-control date-de"
                              type="date"
                              name="startDate"
                              placeholder="dd-mm-yyyy"
                              onChange={handleStartDateChange}
                              value={
                                editDeals?.startDate
                                  ? editDeals?.startDate.slice(0, 10)
                                  : ""
                              }
                            />
                          </div>
                          <div className="col-lg-6 mt-3 col-md-6 col-sm-12">
                            <label
                              htmlFor=""
                              className="form-label review-label"
                            >
                              End Date *
                            </label>
                            <br />
                            <input
                              className="form-control date-de"
                              type="date"
                              value={
                                editDeals?.endDate
                                  ? editDeals?.endDate.slice(0, 10)
                                  : ""
                              }
                              onChange={handleEndDateChange}
                              placeholder="dd-mm-yyyy"
                              name="endDate"
                            />
                          </div>
                          <div className="col-md-6 mt-3">
                            <label
                              htmlFor=""
                              className="form-label review-label"
                            >
                              Status *
                            </label>
                            <br />
                            <select
                              name="status"
                              className="form-control"
                              value={editDeals?.status}
                              onChange={handleStatus}
                            >
                              <option value="0" disabled>
                                Select Status *
                              </option>
                              <option value="Open">Open</option>
                              <option value="Closed Won">Closed Won</option>
                              <option value="Closed Lost">Closed Lost</option>
                              <option value="Pending">Pending</option>
                              <option value="Cancelled">Cancelled</option>
                              <option value="Expired">Expired</option>
                              <option value="On Hold">On Hold</option>
                              <option value="Qualified">Qualified</option>
                            </select>
                          </div>
                          <div className="col-md-6 mt-3">
                            <label
                              htmlFor=""
                              className="form-label review-label"
                            >
                              Subscription
                            </label>
                            <br />
                            <select
                              name="subscription"
                              className="form-control"
                              value={editDeals?.subscription}
                              onChange={handleSub}
                            >
                              <option>Select Subscription Type</option>
                              <option value="monthly">Monthly</option>
                              <option value="yearly">Yealy</option>
                            </select>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <option value="" disabled>
                              Email
                            </option>

                            <input
                              className="form-control"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              name="email"
                            />
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <option value="" disabled>
                              Client Name
                            </option>

                            <input
                              className="form-control"
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              name="name"
                            />
                          </div>
                          <div className="">
                            <div className="col-md-12 mt-2">
                              <label
                                htmlFor=""
                                className="form-label review-label "
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control"
                                onChange={handleDescriptionChange}
                                value={editDeals?.description}
                                name="description"
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="row mt-3">
                              <div>
                                Trial:{" "}
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkboxNoLabel"
                                  name="trial"
                                  checked={editDeals?.trial}
                                  onChange={handleTrialChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mx-auto text-center">
                          {/* <button
                            className="decline_deal m-2"
                            type="button"
                            onClick={handleFinalizeDeal}
                          >
                            Finalize
                          </button> */}
                          {loading ? (
                            <button
                              type="submit"
                              className="sub-btn-new"
                              disabled
                            >
                              Updating....
                            </button>
                          ) : (
                            <button type="submit" className="sub-btn-new">
                              Submit
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </Fragment>
                </>
              </div>
            </div>
          </>
        </Modal>

        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          footer={null}
        >
          <div className="row">
            <div className="col">
              <h5>Current Executive Details</h5>
              <div className="card" style={{ overflow: "hidden" }}>
                <table className="table personal-info-table p-3 m-3">
                  <tbody>
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>{enquiryData?.adminDetails?.name ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Email Address</b>
                      </td>
                      <td>{enquiryData?.adminDetails?.email ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Phone Number</b>
                      </td>
                      <td>{enquiryData?.adminDetails?.mobile ?? "N/A"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col">
              <h5 className="">Re-assign Employee</h5>
              <small>Assign This Stage to a New Executive</small>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <select
                  name=""
                  id=""
                  className="form-control assign_salesmen"
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  <option value="" disabled selected>
                    Choose a Salesmen
                  </option>
                  {salesmenList != null &&
                    Array.isArray(salesmenList) &&
                    salesmenList.map((list, index) => (
                      <option key={index} value={list.paId}>
                        {list.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="_">
                <button
                  type="submit"
                  className="re-as-btn"
                  onClick={handleReAssign}
                >
                  Assign
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
      {jobQueueStatusArray?.cmpStatus !== 1 && (
        <LeadQualification
          jobQueueStatusArray={jobQueueStatusArray}
          enquiryData={enquiryData}
          handleLead={handleLead}
          enquiryLoading={enquiryLoading}
          fetchAllSellerEnquiries={fetchAllSellerEnquiries}
        />
      )}

      {jobQueueStatusArray?.cmpStatus === 1 && (
        <div className="row mt-2" style={{ overflow: "hidden" }}>
          <div className="col-md-12 col-lg-6 col-sm-12 mb-3">
            <div className="card p-3">
              <table className="table stage_table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Stage Name</th>
                    <th scope="col" className="text-center stg-st">
                      Status
                    </th>
                    <th scope="col" className="text-center stg-st">
                      schedule Status
                    </th>
                    <th scope="col" className="text-center">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allJobQueueArray != null &&
                    Array.isArray(allJobQueueArray) &&
                    allJobQueueArray.slice(0, 10).map((data, index) => {
                      return (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <td>{data?.taskname}</td>
                          <td className="text-center td-bad">
                            <div
                              className={
                                data.status === "completed"
                                  ? "fp-badge success"
                                  : "fp-badge pending"
                              }
                            >
                              {data.status}
                            </div>
                          </td>
                          <td className="td-bad">
                            {data.rescheuleStat != null &&
                            data.rescheuleStat !== 0 ? (
                              <div className="fp-badge failed">
                                Re-Scheduled
                              </div>
                            ) : (
                              <div className="fp-badge pending">None</div>
                            )}
                          </td>
                          <td className="text-center">
                            <i onClick={() => stageDetails(data)}>
                              <Ai.AiOutlineEye />
                            </i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-sm-12 mb-3">
            {selectedStage != null &&
              enquiryData.status !== "cancelled" &&
              showRescheduleCard &&
              selectedStage?.taskname !== "Negotiation& Commitment" &&
              selectedStage?.taskname !== "Direct Debit Application" &&
              selectedStage?.taskname !== "Proposal" &&
              selectedStage?.taskname !== "Contract Signing" &&
              selectedStage?.taskname != "Invoice Generation" && (
                <div className="animate__animated animate__backInRight">
                  <div className="card p-3 stage-det-card">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <h4 className="text-center">
                          {selectedStage?.taskname ?? "N/A"}{" "}
                        </h4>
                        <span
                          className={
                            selectedStage?.status == "completed"
                              ? "status-completed"
                              : "status-pending"
                          }
                        >
                          {selectedStage?.status ?? ""}
                        </span>
                      </div>
                      {!showEdit ? (
                        <button
                          className="edit-stage"
                          onClick={() => handleEditDescription()}
                        >
                          <Bs.BsPencil />
                        </button>
                      ) : (
                        <button
                          className="edit-stage"
                          onClick={() => setShowEdit(false)}
                        >
                          <Ai.AiOutlineClose />
                        </button>
                      )}
                    </div>

                    {selectedStage !== null &&
                    selectedStage?.description !== null ? (
                      selectedStage?.description?.map((data, index) => {
                        return (
                          <>
                            <p className="fp-badge pending" id="table-badge">
                              Schedule #{index + 1 ?? ""}
                            </p>
                            <p className="date__">
                              {data?.date != null
                                ? Utils.formatDate(data.date)
                                : ""}
                            </p>
                            {showEdit && lastIndex == index ? (
                              <Fragment>
                                <form onSubmit={handleDescriptionSubmit}>
                                  <div className="description_area">
                                    <textarea
                                      value={data?.description}
                                      onChange={(e) =>
                                        setEditedDescription(e.target.value)
                                      }
                                      placeholder="Add Description about the Stage"
                                    />
                                  </div>
                                  <div className="btn_grp_">
                                    <button type="submit" className="sub-btn">
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <p className="non-edited-description">
                                  {data?.schedule ??
                                    "Add Description about the Stage"}
                                </p>
                                <hr />
                              </Fragment>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <>
                        {" "}
                        <div className="fp-badge pending" id="table-badge">
                          Schedule #{selectedStage?.rescheuleStat + 1 ?? ""}
                        </div>
                        {showEdit && lastIndex != null ? (
                          <>
                            <form onSubmit={handleDescriptionSubmit}>
                              <div className="description_area">
                                <textarea
                                  onChange={(e) =>
                                    setEditedDescription(e.target.value)
                                  }
                                  placeholder="Add Description about the Stage"
                                />
                              </div>
                              <div className="btn_grp_">
                                <button type="submit" className="sub-btn">
                                  Submit
                                </button>
                              </div>
                            </form>
                          </>
                        ) : (
                          <p className="non-edited-description">
                            {selectedStage?.schedule ??
                              "Add Description about the Stage"}
                          </p>
                        )}
                      </>
                    )}
                    {!showEdit && selectedStage.status != "completed" && (
                      <Fragment>
                        <div className="btn_grp_">
                          <Fragment>
                            {selectedStage?.stpId == 8 ? (
                              <button
                                className="complete"
                                onClick={() => handleOnBoarding()}
                                disabled={stageLoadingAction === "complete"}
                              >
                                {enquiryData?.datafill === null
                                  ? "On Board"
                                  : "Complete"}
                              </button>
                            ) : (
                              <>
                                {enquiryLoading ? (
                                  <button
                                    className="complete"
                                    type="button"
                                    disabled
                                  >
                                    Completing
                                  </button>
                                ) : (
                                  <button
                                    className="complete"
                                    type="button"
                                    onClick={() => handleLead()}
                                  >
                                    Complete
                                  </button>
                                )}
                              </>
                            )}
                          </Fragment>
                          <button
                            type="button"
                            className="reschedule"
                            onClick={() => handleReSchedule()}
                            disabled={stageLoadingAction === "reSchedule"}
                          >
                            Re-Schedule
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
            {selectedStage?.taskname === "Contract Signing" && (
              <>
                <div className="card  py-1">
                  <ContractSigning
                    selectedStage={selectedStage}
                    enquiryData={enquiryData}
                    handleLead={handleLead}
                    finalizedDeals={finalizedDeals}
                  />
                </div>
              </>
            )}
            {detailsModal &&
              detailsStage &&
              selectedStage?.taskname !== "Contract Signing" &&
              selectedStage?.taskname !== "Invoice Generation" &&
              selectedStage?.taskname !== "Customer On Boarding" &&
              selectedStage?.taskname !== "Status Closed & Live" && (
                <>
                  <div>
                    <h6 className="exe-det">
                      Details of Executive assigned for this stage. {"  "}
                      {selectedStage != null &&
                        selectedStage.cmpStatus === 0 && (
                          <>
                            <small className="re-assign" onClick={showModal}>
                              Re-Assign?
                            </small>
                          </>
                        )}
                    </h6>
                    <div class="dashboard-card card">
                      <div class="">
                        <table class="table personal-info-table p-3 m-3">
                          <tbody>
                            <tr>
                              <td>
                                <b>Name</b>
                              </td>
                              <td>
                                {selectedStage?.adminDetails?.name ?? "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Mobile</b>
                              </td>
                              <td>
                                {selectedStage?.adminDetails?.mobile ?? "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <b>Email</b>
                              </td>
                              <td>
                                {selectedStage?.adminDetails?.email ?? "N/A"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            <>
              {selectedStage?.taskname === "Proposal" && (
                <>
                  <div className="card py-1">
                    <Proposal
                      selectedStage={selectedStage}
                      enquiryData={enquiryData}
                      handleLead={handleLead}
                    />
                  </div>
                </>
              )}
              {selectedStage?.taskname === "Negotiation& Commitment" && (
                <>
                  <div className="card m-2 row">
                    <div className="row">
                      <div className="col-md-9">
                        <h5 className="selc mt-3">{selectedStage?.taskname}</h5>
                      </div>
                      <div className="col-md-3 mt-3">
                        <>
                          {enquiryLoading ? (
                            <button
                              className="complete "
                              id="deal_cmp"
                              disabled
                            >
                              Completing..
                            </button>
                          ) : (
                            <button
                              className="complete "
                              id="deal_cmp"
                              onClick={() => handleLead()}
                            >
                              Complete
                            </button>
                          )}
                        </>
                      </div>
                    </div>

                    {deals != null && deals.length != 0 && (
                      <Fragment>
                        <h6 className="mt-3">All Deals</h6>
                        <div className="mt-1" style={{ overflowY: "scroll" }}>
                          <table className="table table-secondary">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Package Name</th>
                                <th>Total</th>
                                <th className="text-center">Stage</th>

                                <th className="text-center">Mailed Status</th>
                                <th scope="col" className="text-center">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {deals != null &&
                                Array.isArray(deals) &&
                                deals.map((data, index) => {
                                  console.log(data, "data");
                                  const productData = productList.find(
                                    (x) => x.pId === data.pId
                                  );

                                  return (
                                    <tr className="table-light">
                                      <td>{index + 1}</td>
                                      <td>{data?.packageName}</td>

                                      <td>£{data?.price ?? "N/A"}</td>

                                      <td className="text-center">
                                        {data.finalize != true ? (
                                          <div className="st-bad ">
                                            {data.status ?? "N/A"}
                                          </div>
                                        ) : (
                                          <div className="cmp_badhe ">
                                            Finalized
                                          </div>
                                        )}
                                      </td>

                                      <td className="text-center">
                                        {data.mailStatus == true ? (
                                          <div
                                            className="icon__"
                                            style={{
                                              fontSize: "16px",
                                              color: "#005c4b",
                                            }}
                                          >
                                            <Bs.BsCheck2 />
                                          </div>
                                        ) : (
                                          <div
                                            className="icon__"
                                            style={{
                                              fontSize: "16px",
                                              color: "#7777",
                                            }}
                                          >
                                            <Bs.BsClock />
                                          </div>
                                        )}
                                      </td>
                                      <td className="text-center position-relative">
                                        <div className="btn-toggle">
                                          {data.mailStatus == true ? (
                                            <>
                                              <button
                                                className="one-btn"
                                                onClick={() =>
                                                  handleFinalizeDeal(data)
                                                }
                                              >
                                                Finalize
                                              </button>
                                            </>
                                          ) : (
                                            <button
                                              className="one-btn"
                                              onClick={() => handleDeal(data)}
                                            >
                                              Send
                                            </button>
                                          )}
                                          <button
                                            className="two-btn"
                                            onClick={() =>
                                              toggleDropdown(index)
                                            }
                                          >
                                            <Md.MdOutlineArrowDropDown />
                                          </button>
                                        </div>
                                        {openDropdownIndex === index && (
                                          <ul className="table-mgt" role="menu">
                                            {data.mailStatus == true && (
                                              <button
                                                className="btn"
                                                onClick={() => handleView(data)}
                                              >
                                                Update
                                              </button>
                                            )}
                                            <li>
                                              {data.mailStatus == false && (
                                                <button
                                                  className="btn"
                                                  onClick={() =>
                                                    handleDeal(data)
                                                  }
                                                >
                                                  Send
                                                </button>
                                              )}
                                            </li>

                                            {data.finalize == false && (
                                              <h6 className="mt-3">
                                                Finalized
                                              </h6>
                                            )}
                                            <li>
                                              <button
                                                className="btn"
                                                onClick={() => {
                                                  ConfirmDelete(
                                                    data.dealId,
                                                    data.leadId
                                                  );
                                                }}
                                              >
                                                Delete
                                              </button>
                                            </li>
                                          </ul>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          {/* <Table bordered hover>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Price</th>
                                <th className="text-center">Stage</th>
                                <th className="text-center">Due Date</th>
                                <th className="text-center">Mailed Status</th>
                                <th scope="col" className="text-center">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {deals != null &&
                                Array.isArray(deals) &&
                                deals.map((data, index) => {
                                  const productData = productList.find(
                                    (x) => x.pId === data.pId
                                  );

                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{productData?.productName}</td>

                                      <td>{data.price ?? "N/A"}</td>

                                      <td className="text-center">
                                        {data.finalize != true ? (
                                          <div className="st-bad ">
                                            {data.status ?? "N/A"}
                                          </div>
                                        ) : (
                                          <div className="cmp_badhe ">
                                            Finalized
                                          </div>
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {data.dueDate ?? "N/A"}
                                      </td>
                                      <td className="text-center">
                                        {data.mailStatus == true ? (
                                          <div
                                            className="icon__"
                                            style={{
                                              fontSize: "16px",
                                              color: "#005c4b",
                                            }}
                                          >
                                            <Bs.BsCheck2 />
                                          </div>
                                        ) : (
                                          <div
                                            className="icon__"
                                            style={{
                                              fontSize: "16px",
                                              color: "#7777",
                                            }}
                                          >
                                            <Bs.BsClock />
                                          </div>
                                        )}
                                      </td>
                                      <td className="text-center position-relative">
                                        <div className="btn-toggle">
                                          <button
                                            className="one-btn"
                                            onClick={() => handleView(data)}
                                          >
                                            Update
                                          </button>
                                          <button
                                            className="two-btn"
                                            onClick={() =>
                                              toggleDropdown(index)
                                            }
                                          >
                                            <Md.MdOutlineArrowDropDown />
                                          </button>
                                        </div>
                                        {openDropdownIndex === index && (
                                          <ul className="table-mgt" role="menu">
                                            {data.mailStatus == false && (
                                              <li>
                                                <button
                                                  className="btn"
                                                  onClick={() =>
                                                    handleDeal(data)
                                                  }
                                                >
                                                  Send
                                                </button>
                                              </li>
                                            )}
                                            {data.finalize != true ? (
                                              <li>
                                                <button
                                                  className="btn"
                                                  onClick={() =>
                                                    handleFinalizeDeal(data)
                                                  }
                                                >
                                                  Finalize
                                                </button>
                                              </li>
                                            ) : (
                                              <h6 className="mt-3">
                                                Finalized
                                              </h6>
                                            )}
                                            <li>
                                              <button
                                                className="btn"
                                                onClick={() => handleView(data)}
                                              >
                                                Update
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="btn"
                                                onClick={() => {
                                                  ConfirmDelete(
                                                    data.dealId,
                                                    data.leadId
                                                  );
                                                }}
                                              >
                                                Delete
                                              </button>
                                            </li>
                                          </ul>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table> */}
                        </div>
                      </Fragment>
                    )}
                  </div>
                </>
              )}
            </>
            {/* {details && (
              <>
                <div className="card mt-3 mb-3 ">
                  <form action="" className="p-3" onSubmit={sendContract}>
                    <h4 className="p-1 mt-2">Enter Contract Signing Details</h4>
                    <div className="row mt-3">
                      <div className="form-group col">
                        <label htmlFor="" className="form-label review-label">
                          Email
                        </label>
                        <div className="review-group">
                          <input
                            className="form-control review-input"
                            name="email"
                            value={initial.email}
                            onChange={handleChange}
                            disabled={!isEditing}
                          />
                          {isEditing ? (
                            <i onClick={() => setShowEdit(false)}>
                              <Ai.AiOutlineClose />
                            </i>
                          ) : (
                            <i onClick={() => setIsEditing(true)}>
                              <Bs.BsPencil />
                            </i>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="wrapper-file-input mt-3">
                        <div className="input-att">
                          <button className="attach-btn">
                            <a href="javascript: void(0)" className="att">
                              Upload File
                            </a>
                          </button>
                          <input
                            type="file"
                            id="upload_input"
                            name="file"
                            className="file-in"
                            onChange={(e) => handleFileChange(e)}
                          />
                        </div>
                        {selectedFileName && (
                          <p className="file-name">
                            Selected File: {selectedFileName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-3 d-flex">
                      <button className="sub-btn " type="submit" id="stag-sub">
                        Submit
                      </button>
                      {selectedStage &&
                      selectedStage.cmpStatus != null &&
                      selectedStage.cmpStatus != 1 ? (
                        <>
                          {" "}
                          <button
                            className="complete"
                            onClick={() => handleLead()}
                            type="button"
                          >
                            Complete
                          </button>
                        </>
                      ) : (
                        <div className="comp-badge">Completed</div>
                      )}
                    </div>
                  </form>
                </div>
              </>
            )} */}
            {selectedStage?.taskname === "Direct Debit Application" && (
              <div className="card p-3">
                <h5 className="selc mt-3 MB-3">
                  #{" " + selectedStage?.taskname ?? "N/A"}
                </h5>

                <form>
                  <div className="row mt-3">
                    <div className="form-group col">
                      <label htmlFor="" className="form-label review-label">
                        Enter Email to send Direct Debit Link
                      </label>
                      <div className="review-group">
                        <input
                          className="form-control review-input"
                          name="email"
                          value={onBoard.email}
                          onChange={handleEdit}
                          disabled={!isEditable}
                        />

                        {isEditing ? (
                          <i onClick={() => setEmailEdit(false)}>
                            <Ai.AiOutlineClose />
                          </i>
                        ) : (
                          <i onClick={() => setIsEditable(true)}>
                            <Bs.BsPencil />
                          </i>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 d-flex">
                    {selectedStage.cmpStatus == 0 && (
                      <button
                        id="btn-btn"
                        type="submit"
                        onClick={(e) => handleDirectDebit(e)}
                      >
                        Send
                      </button>
                    )}

                    {selectedStage.cmpStatus == 1 ? (
                      <div className="text-center mx-auto alarm-div">
                        <div className="cl-co">Stage Completed</div>
                      </div>
                    ) : (
                      <button
                        className="bb-btn"
                        type="button"
                        onClick={() => handleLead()}
                      >
                        Complete
                      </button>
                    )}
                  </div>
                </form>
              </div>
            )}
            {selectedStage?.taskname === "Invoice Generation" && (
              <div className="card p-3">
                <h5 className="selc mt-3 MB-3">
                  #{" " + selectedStage?.taskname ?? "N/A"}
                </h5>
                <InvoiceGeneration
                  selectedStage={selectedStage}
                  enquiryData={enquiryData}
                  completedContracts={completedContracts}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default LeadStages;
