import React from "react";
import Image1 from "../../Assets/documentSample.png";
import Logo from "../../Assets/foodpageplane.png";
import "../../Style/Style.css";
import Signature from "../../Assets/signature.png";
import Backbutton from "../../Components/Backbutton";
import Utils from "../../Utils/utils";

function ContractSettings() {
  const date = new Date();
  const currentDate = Utils.formatDate(date);
  return (
    <div className="container-fluid">
      <div className="row">
        <Backbutton />
        <div className="col-lg-2 col-md-4 col-sm-6 block_slider">
          <div className="images_contract">
            <img src={Image1} className="contract_temp"></img>
            <img src={Image1} className="contract_temp"></img>
            <img src={Image1} className="contract_temp"></img>
            <img src={Image1} className="contract_temp"></img>
            <img src={Image1} className="contract_temp"></img>
          </div>
        </div>
        <div className="col-lg-7 col-md-6 col-sm-12">
          <div className="container-fluid">
            <div className="contract-page-settings">
              <div className="mt-3">
                <div className="logo-are">
                  <img className="logo-t p-2" src={Logo} />
                  <br />
                </div>
                <div className="data-cont">
                  <b>Thomas Alva</b>
                  <br />
                  <small> 10567894567</small>
                  <br />
                  <div className="row">
                    <small> tomasalvacm@gmail.com</small>
                    <small className="-rt">
                      Date :<b> {currentDate}</b>
                    </small>
                  </div>
                </div>
                <div className="contract">
                  <p className="contract_details">
                    {" "}
                    This agreement is made and entered into on [Date] between:
                    FoodPage, registered under the laws of the United Kingdom,
                    with its principal office located at [Your Address],
                    hereinafter referred to as "Company".
                    <br />
                    <p className="mt-3 mb-3">and</p>
                    <br />
                    [Merchant 's Name], a [Legal Structure of Merchant ]
                    registered under the laws of the United Kingdom, with its
                    principal office located at [Merchant 's Address],
                    hereinafter referred to as "Merchant". <br />
                    <div className="mt-3"></div>
                    <b>Purpose:</b>
                    <div className="mb-3"></div>
                    The purpose of this agreement is to formalise the terms and
                    conditions under which both parties agree to [Brief
                    Description of the Purpose of the Contract, e.g., purchase,
                    supply, distribution of food products]. <br />
                    <div className="mt-3"></div>
                    <h6>Terms and Conditions:</h6>
                    <div className="mb-3"></div>
                    <ul className="list-cont">
                      <li>
                        <b>Product Details:</b> The parties hereby agree on the
                        specific products to be supplied, including quantities,
                        specifications, and quality standards.
                      </li>
                      <li>
                        <b>Price and Payment: </b>The price per unit and total
                        payment terms are as follows: The Company shall pay
                        [Amount] per [Unit] to the Merchant for the supplied
                        products. Payment shall be made within [Number of Days]
                        days of the receipt and acceptance of the products, as
                        per the agreed-upon terms about the Deals.
                      </li>
                      <li>
                        <b>Quality Assurance:</b> The Merchant agrees to adhere
                        to all applicable quality standards and regulations in
                        the production and supply of the foodpage products.
                      </li>
                      <li>
                        <b> Product Duration:</b> The Merchant acknowledges and
                        agrees that the duration of product usage shall be as
                        specified in this agreement, building upon the terms
                        established in the previous [deal data: start date, end
                        date, trial, tax].
                      </li>
                      <li>
                        <b> Confidentiality:</b> Both parties agree to maintain
                        the confidentiality of any proprietary or sensitive
                        information disclosed during the course of this
                        agreement, including but not limited to product
                        specifications and business strategies. The Merchant
                        agrees that the software products provided under this
                        agreement shall not be shared with any other party, and
                        both parties shall take all necessary measures to
                        protect the confidentiality and security of the data
                        exchanged.
                      </li>
                      <li>
                        <b> Consequences of Breach</b>: In the event of a breach
                        of this confidentiality agreement related to the usage
                        of the software product, the non-breaching party
                        reserves the right to immediately terminate this
                        agreement. Furthermore, the breaching party shall be
                        liable for any resulting damages, including but not
                        limited to financial losses and legal expenses incurred
                        by the non-breaching party.
                      </li>
                    </ul>
                  </p>
                  <div className="mt-3 mb-3">
                    <p className="deal_head text-center">DEAL DETAILS</p>
                    <div className="contract_Card">
                      <form className="deal_form">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <label htmlFor=""> Product</label>
                            <div className="form-disabled">Order Online</div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <label htmlFor=""> Value</label>
                            <div className="form-disabled">500</div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <label htmlFor=""> Quantity</label>
                            <div className="form-disabled">1</div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <label htmlFor=""> VAT(%</label>
                            <div className="form-disabled">12</div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <label htmlFor="">VAT Amount</label>
                            <div className="form-disabled">325</div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <label htmlFor="">Total</label>
                            <div className="form-disabled">550</div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <label htmlFor=""> Start Date</label>
                            <div className="form-disabled">12-10-2024</div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <label htmlFor=""> End Date</label>
                            <div className="form-disabled">25-12-2024</div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <label htmlFor=""> Subscription Type</label>
                            <div className="form-disabled">Monthly</div>
                          </div>
                          <div className="col-md-12 col-sm-12">
                            <p className="desc">
                              In witness whereof, the parties hereto have
                              executed this agreement as of the date mentioned
                              above.{" "}
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="brkdiv"></div>

                    <p className="details">
                      In witness whereof, the parties hereto have executed this
                      agreement as of the date mentioned above.{" "}
                    </p>
                    <div className="footer_contract footer_sign">
                      <p>
                        By(Authorised Signature):
                        <img src={Signature} className="signature" />
                      </p>
                      <p>Name: ________________________ (Printed Name)</p>
                      <p>Title: _________________________ (Title)</p>
                      <p>Date: _________________________</p>
                      <p>[Supplier/Partner's Name]</p>
                      <p>
                        By: ___________________________ (Authorised Signature)
                      </p>
                      <p>Name: ________________________ (Printed Name)</p>
                      <p>Title: _________________________ (Title)</p>
                      <p>Date: _________________________</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <button className="mt-3 ">+</button>
        </div>
      </div>
    </div>
  );
}

export default ContractSettings;
