import React from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import * as Ai from "react-icons/ai"
import * as Hi from "react-icons/hi";
import * as Ri from "react-icons/ri";
function ApkRelease() {
    const navigate = useNavigate();
    return (
        <div className="container-fluid">
            <Sidebar>
                <div className="container-fluid m-3">
                    <div className="row mb-3 mt-3">
                    <div className="col-md-4">
              <h6 className="head-h4 apk-release-heading">APK Release</h6>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="search-bar-apk">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="sr-ch"
                  />
                  <button className="search-apk-btn">
                    <i>
                    <Ai.AiOutlineSearch/>
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
                <button className="Apk-btn" onClick={()=>{navigate("/create-apk")}}>Create new release</button>
            </div>
                </div>
                </div>
                <div className="card p-3 m-3 overflow">
                    <table className="table">
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Release Name</th>
                            <th>Version</th>
                            <th>Device</th>
                            <th className="text-center">Action</th>
                            </tr>
                        </thead>
                         <tbody>
                            <tr>
                                <td><b>1</b></td>
                                <td>gxft</td>
                                <td>ver 1.2.6</td>
                                <td>hccu46</td>
                                <td className="text-center">
                                    <button className="Apk-icons "><Hi.HiDownload/></button>
                                    <button className="Apk-icons "><Ri.RiDeleteBin5Line/></button>
                                </td>
                            </tr>
                            <tr>
                                <td><b>2</b></td>
                                <td>fcchc</td>
                                <td>ver 1.2.5</td>
                                <td>chcu123</td>
                                <td className="text-center">
                                    <button className="Apk-icons"><Hi.HiDownload/></button>
                                    <button className="Apk-icons"><Ri.RiDeleteBin5Line/></button>
                                </td>
                            </tr>
                            <tr>
                                <td><b>3</b></td>
                                <td>xdhch</td>
                                <td>ver 1.2.4</td>
                                <td>dftfg635</td>
                                <td className="text-center">
                                    <button className="Apk-icons"><Hi.HiDownload/></button>
                                    <button className="Apk-icons"><Ri.RiDeleteBin5Line/></button>
                                </td>
                            </tr>
                            <tr>
                                <td><b>4</b></td>
                                <td>gvsuus</td>
                                <td>ver 1.2.3</td>
                                <td>swudyf78</td>
                                <td className="text-center">
                                    <button className="Apk-icons"><Hi.HiDownload/></button>
                                    <button className="Apk-icons"><Ri.RiDeleteBin5Line/></button>
                                </td>
                            </tr>
                            <tr>
                                <td><b>5</b></td>
                                <td>fury</td>
                                <td>ver 1.2.2</td>
                                <td>hcft267</td>
                                <td className="text-center">
                                    <button className="Apk-icons"><Hi.HiDownload/></button>
                                    <button className="Apk-icons"><Ri.RiDeleteBin5Line/></button>
                                </td>
                            </tr>
                            <tr>
                                <td><b>6</b></td>
                                <td>thgu</td>
                                <td>ver 1.2.1</td>
                                <td>fttfgf7l</td>
                                <td className="text-center">
                                    <button className="Apk-icons"><Hi.HiDownload/></button>
                                    <button className="Apk-icons"><Ri.RiDeleteBin5Line/></button>
                                </td>
                            </tr>
                            </tbody>   
                    </table>
                </div>
            </Sidebar>
        </div>
    )
}

export default ApkRelease;