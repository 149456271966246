import React, { useState, useEffect, useContext } from "react";
import { Pie } from "react-chartjs-2";
import { AppContext } from "../../Context/AppContext";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

const PieChart = () => {
  const { reportList } = useContext(AppContext);

  const filteredEnquiries = {
    leads:
      (reportList &&
        reportList.filter(
          (enquiry) => enquiry.byAdmin === 1 && enquiry.cancelStatus === 0
        )) ||
      [],
    registeredUsers:
      (reportList &&
        reportList.filter(
          (enquiry) => enquiry.byAdmin === 0 && enquiry.cancelStatus === 0
        )) ||
      [],
    cancelled:
      (reportList &&
        reportList.filter((enquiry) => enquiry.cancelStatus === 1)) ||
      [],
  };


  const data = {
    labels: ["Leads", "Registered Users", "Cancelled Enquiries"],
    datasets: [
      {
        label: "Monthly report of Enquiry",
        data: [
          filteredEnquiries.leads.length,
          filteredEnquiries.registeredUsers.length,
          filteredEnquiries.cancelled.length,
        ],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {},
    legend: {
      labels: {
        fontSize: 20,
      },
    },
  };

  return (
    <div>
      <Pie data={data} height={250} options={options} />
    </div>
  );
};

export default PieChart;
