import React, { useContext, useRef, useState } from "react";
import "../../Style/Style.css";
import Logo from "../../Assets/pakawaanlogoo.png";
import Utils from "../../Utils/utils";
import { AppContext } from "../../Context/AppContext";
import html2canvas from "html2canvas";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

function PartnerFoodpageInvoice() {
  const targetRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const InvoiceNumber = JSON.parse(sessionStorage.getItem("invoiceNumber"));
  const { updateInvoiceFile, contractLoading } = useContext(AppContext);
  const invoiceDetails = JSON.parse(sessionStorage.getItem("merchantInvoice"));
  const companyData = JSON.parse(invoiceDetails?.company_info);
  const bankDetails = JSON.parse(invoiceDetails.bankDetails);
  const productArray = JSON.parse(invoiceDetails.productDetails);
  const response = JSON.parse(sessionStorage.getItem("invoiceResponse"));


  const handleUpdatePDF = async () => {
    setLoading(true);
    const pdfBlob = await html2canvas(targetRef.current, {
      imageTimeout: 0,
      scale: 2,
      width: targetRef.current.offsetWidth,
      height: targetRef.current.offsetHeight,
      allowTaint: true,
      useCORS: true,

      pdf: {
        format: "a4",
        orientation: "portrait",
      },
    }).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      return fetch(imgData).then((res) => res.blob());
    });
    const formData = new FormData();
    formData.append("file", pdfBlob, "Invoice.pdf");
    formData.append("invoiceId", response?.invoiceId);
    formData.append("invoiceNum", response?.invoiceNum);

    await updateInvoiceFile(formData, id, {
      onSuccess: (res) => {
        toast.success("Successfully sent Invoice");
        setLoading(false);
        navigate("/custom-invoice");
      },
      onFailed: (err) => {
        setLoading(false);
        toast.error(err.errormessage);
      },
    });
  };
  return (
    <div style={{ background: "#eaeff1" }}>
      <Toaster position="top-center" />
      <div className="container">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            {contractLoading ? (
              <button className="btn-SEND" type="button" disabled>
                Sending...
              </button>
            ) : (
              <button
                className="btn-SEND"
                type="button"
                onClick={handleUpdatePDF}
              >
                Send
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 mx-auto mt-3 mb-3">
          <div className="inv_bg mt-3 p-3" ref={targetRef}>
            <div className="col-md-12">
              <div className="container inv">
                <div className="row">
                  <div className="col-sm-6">
                    <div class="custom-col">
                      <h1>Invoice</h1>
                      <p class="invoice_Add">
                        {companyData?.address ?? "N/A"}
                        <br />
                        <small>{companyData?.email ?? "N/A"}</small>
                        <br />
                        <small>{companyData?.phone ?? "N/A"}</small>
                      </p>
                    </div>
                    <div className="custom-col">
                      <small>Invoice To,</small>
                      <h6>{invoiceDetails?.cust_name ?? "NA"}</h6>
                      <p class="invoice_Add">
                        {invoiceDetails?.billingAddress ?? "NA"}
                        <br />
                        <p>{invoiceDetails?.cust_email ?? "NA"} </p>
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-2"></div>
                  <div className="col-sm-4">
                    <div class="logo-arera">
                      <img src={Logo} />
                    </div>
                    <div class="table-Info">
                      <table>
                        <tr>
                          <td>
                            <b> Invoice Date</b>
                            <br />
                            <br />
                            {Utils.formatDate(
                              invoiceDetails?.invoice_date ?? "NA"
                            )}
                          </td>
                          <th>FOOD PAGE LTD 13 A Colchester Business</th>
                        </tr>
                        <tr>
                          <td>
                            <b> Invoice Number</b>
                            <br />
                            <br />
                            {response?.invoiceNum}
                          </td>
                          <td>Centre 1 George Williams Way, Colchester</td>
                        </tr>
                        {/* <tr>
                      <td>
                        <b> Reference</b>
                        <br />
                        INV-0007
                      </td>
                      <td>Essex CO1 2JS GBR</td>
                    </tr> */}
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mx-auto">
                    <table className="in_table p-3">
                      <tbody>
                        <tr className="under_line">
                          <th>#</th>
                          <th>Item</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Tax(amount)</th>
                          <th>Discount</th>
                          <th>Total</th>
                        </tr>
                        {productArray != null &&
                          productArray.map((data, index) => {
                            return (
                              <>
                                <tr>
                                  <td height="1" colspan="4"></td>
                                </tr>
                                <tr className="under_line">
                                  <td>{index + 1}</td>
                                  <td>{data?.productName ?? "NA"}</td>
                                  <td>{data?.price ?? "NA"}</td>
                                  <td>{data?.quantity ?? "NA"}</td>
                                  <td>{data?.tax}</td>
                                  <td>{data?.discount}</td>
                                  <td>{data?.total}</td>
                                </tr>
                              </>
                            );
                          })}
                        <tr className="under_line">
                          <td colSpan={5}></td>
                          <td>
                            <b>Payable Amount</b>
                          </td>
                          <td>{invoiceDetails?.total}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="brk_div"></div>
                    <div className="col-md-6">
                      <p className="duw">
                        Due Date :
                        <small>
                          {Utils.formatDate(invoiceDetails?.due_date ?? "NA")}
                        </small>
                      </p>
                    </div>
                    <div className="col-sm-8">
                      <div className="more_details card p-3">
                        <h6>Bank Name : {bankDetails?.bank ?? "N/A"}</h6>

                        <h6>
                          Account Number :{bankDetails?.paymentAccount ?? "N/A"}
                        </h6>

                        <h6> IFSC :{bankDetails?.ifsc ?? "N/A"}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerFoodpageInvoice;
