import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../Context/AppContext";
import jwt_decode from "jwt-decode";

export const ProtectRoute = ({ children }) => {
  const { cookieData } = useContext(AppContext);

  if (!cookieData) {
    return <Navigate to="/login" />;
  }
  const data = jwt_decode(cookieData);

  if (
    data?.role.length == 0 ||
    !Array.isArray(data.jobRole) ||
    data.jobRole.length == 0
  ) {
    return children;
  }

  return children;
};

export const ProtectAuthRoute = ({ children }) => {
  const { cookieData } = useContext(AppContext);
  return !cookieData ? children : <Navigate to="/" />;
};
