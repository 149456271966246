import React, { useContext } from "react";
import { Badge } from "react-bootstrap";
import * as Gi from "react-icons/gi";
import { AppContext } from "../Context/AppContext";
import { useState } from "react";

function SalesExecutiveDashboard() {
  const { setPrevilages, allPrivilages, allSellerEnquiries } =
    useContext(AppContext);

  const getJobQueueStatus = (jobId) => {
    if (!jobId || !allSellerEnquiries) return;

    const getJobDatas = allSellerEnquiries.find((x) => x.leadId == jobId);
    const jobQue = getJobDatas.jobQueueStatus;

    if (jobQue[0]?.status == "pending") {
      return jobQue[0]?.taskname;
    }
  };


  const filterEnquiries = allSellerEnquiries.length;

  return (
    <div>
      <div id="sales">
        <div className="container-fluid m-3">
          <h6 className="head-h4 text-center mb-3">Dashboard</h6>
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count row">
                <div className="dash-counts col-md-8">
                  <h4>{filterEnquiries}</h4>
                  <h5>Total Enquiries</h5>
                </div>
                <div className="dash-imgs col-md-4">
                  <i>
                    <Gi.GiCondyluraSkull />
                  </i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das1 row"></div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das2 row">
                {/* <div className="dash-counts col-md-8">
                    <h4>100</h4>
                    <h5>Purchase Invoice</h5>
                  </div>
                  <div className="dash-imgs col-md-4">
                    <i>
                      <Gi.GiCondyluraSkull />
                    </i>
                  </div> */}
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex">
              <div className="dash-count das3 row">
                {/* <div className="dash-counts col-md-8">
                    <h4>105</h4>
                    <h5>Sales Invoice</h5>
                  </div>
                  <div className="dash-imgs col-md-4">
                    <i>
                      <Gi.GiCondyluraSkull />
                    </i>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container-fluid  p-3 m-3">
          <h4 className="text-center head-h4 mb-3">Recent Enquiries</h4>
          <div className="card p-3 overflow">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Client Name</th>
                  <th scope="col">Shop Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Current Status</th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {allSellerEnquiries != null &&
                  Array.isArray(allSellerEnquiries) &&
                  allSellerEnquiries.toReversed().map((enquiry, index) => {
                    const sellerData = enquiry?.sellerDetails;

                    return (
                      <>
                        <tr>
                          <td key={index}>{index + 1}</td>
                          <td>{sellerData?.sellerName ?? "N/A"}</td>
                          <td>{sellerData?.shopName ?? "N/A"}</td>
                          <td>{sellerData?.sellerEmail ?? "N/A"}</td>
                          <td>{sellerData?.mobile ?? "N/A"}</td>
                          <td>
                            <Badge bg="primary" className="badges" pill>
                              {enquiry?.leadId != null
                                ? getJobQueueStatus(enquiry?.leadId)
                                : "N/A"}
                            </Badge>
                          </td>
                          <td className="text-center position-relative">
                            <div className="btn-toggle">
                              <button
                                className="one-btn view_enquiry_btn"
                                onClick={() => {
                                  window.location.href = `/view-enquiry/${enquiry.pceId}`;
                                }}
                              >
                                View
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SalesExecutiveDashboard;
