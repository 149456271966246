import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Pakwaan from "../../Assets/pakawaanlogoo.png";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import { IoMdDownload } from "react-icons/io";
import { AppContext } from "../../Context/AppContext";
import { MdOutlineAttachFile } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import html2canvas from "html2canvas";
import { useLocation } from "react-router";
import Utils from "../../Utils/utils";
import Backbutton from "../../Components/Backbutton";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "../../Style/Style.css";
import * as Ti from "react-icons/ti";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import SendMailTemplateModal from "../../Components/mail/SendMailTemplateModal";
import Proposal from "../../Components/Stages/Proposal";
import { Modal } from "antd";
import "jspdf-autotable";

function Temp1() {
  const targetRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    proposalSending,
    productList,
    fetchAllProposalData,
    eventList,
    createSingleProposal,
    mailTemplates,
  } = useContext(AppContext);
  const [date, setDate] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [proposalInfo, setProposalInfo] = useState(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dueDate, setDueDate] = useState("");
  const [showMailModal, setShowMailModal] = useState(false);
  const [parsedBody, setParsedBody] = useState(null);
  const [mailSubject, setMailSubject] = useState(null);
  const [ccValue, setCcValue] = useState(null);
  const [elementArray, setElementsArray] = useState(null);
  const [itemsArray, setItemsArray] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const isSingle = searchParams.has("single");
  const [isWarn, setIsWarn] = useState(false);
  const [packageName, setPackageName] = useState(null);

  useEffect(() => {
    const proposeDate = new Date();
    setDate(proposeDate);
    const data = JSON.parse(sessionStorage.getItem("proposalData"));
    if (data != null) {
      setProposalInfo(data);
      setItemsArray(data.formedArray);
      setElementsArray(data.elements);
    }

    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  const findPackageName = () => {
    const packages =
      eventList &&
      eventList.find((x) => x?.packId == proposalInfo?.selectedPackage);
    setPackageName(packages?.title);
  };

  useEffect(() => {
    findPackageName();
  }, [proposalInfo]);

  // const handleGeneratePDF = () => {
  //   if (dueDate.length == 0) {
  //     toast.error("Please Enter Due Date!");
  //     setError(true);
  //     return;
  //   }
  //   // const element = document.getElementById("due_inp");
  //   // element.style.border = "none";
  //   generatePDF(targetRef, options, { filename: "Proposal.pdf" });
  //   // setTimeout(() => {
  //   //   element.style.border = "";
  //   // }, 1000);
  // };

  const handleGeneratePDF = async () => {
    const canvas = await html2canvas(targetRef.current, {
      imageTimeout: 0,
      scale: 3,
      width: targetRef.current.offsetWidth,
      height: targetRef.current.offsetHeight,
      allowTaint: true,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.95);

    // Create PDF
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(
      imgData,
      "JPEG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight()
    );

    pdf.save("proposal.pdf");
  };

  const productNamesArr = [];

  const handlePdf = async () => {
    if (dueDate == null || dueDate.length == 0) {
      toast.error("Due Date is Required!");
      // setSendLoading(false);
      setError(true);
      return;
    }
    sessionStorage.setItem("companyName", "Pakwaan");
    sessionStorage.setItem("clientName", proposalInfo?.proposal?.clientName);
    sessionStorage.setItem(
      "serviceName",
      productNamesArr && JSON.stringify(productNamesArr)
    );
    sessionStorage.setItem("dueDate", dueDate);
    setShowMailModal(true);
    // const pdfBlob = await html2canvas(targetRef.current).then(
    //   async (canvas) => {
    //     const imgData = canvas.toDataURL("image/png");
    //     return fetch(imgData).then((res) => res.blob());
    //   }
    // );
  };

  const sendMail = async () => {
    try {
      setSendLoading(true);

      // Get the canvas of the target element
      const canvas = await html2canvas(targetRef.current, {
        imageTimeout: 0,
        scale: 3,
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
        allowTaint: true,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/jpeg", 0.95);
      const imgProps = canvas.width / canvas.height;

      // Calculate the number of pages
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );

      const pdfBlob = pdf.output("blob");
      const enquiry = JSON.parse(sessionStorage.getItem("enquiryData"));
      const data = JSON.parse(sessionStorage.getItem("selectedStage"));
      const product = JSON.parse(sessionStorage.getItem("proposalData"));
      const details = JSON.stringify(proposalInfo?.proposal);
      const productData = {
        itemsArray,
        elementArray,
        values: proposalInfo?.values,
        packageName: packageName,
        services:proposalInfo?.service,
      };
      const formData = new FormData();
      for (let i = 0; i < attachments.length; i++) {
        formData.append(`attachments`, attachments[i]);
      }
      const mailContent = {
        subject: mailSubject,
        body: parsedBody?.data ? parsedBody?.data : parsedBody,
        cc: ccValue ?? "",
      };

      formData.append("file", pdfBlob, "Proposal.pdf");
      formData.append("username", proposalInfo.proposal.clientName);
      formData.append("email", proposalInfo.proposal.email);
      formData.append("productDetails", JSON.stringify(productData));
      formData.append("due_date", dueDate.due_date);
      formData.append("customerDetails", details);
      // formData.append("due_date", dueDate);
      formData.append("currency", "£");
      formData.append("mailContent", JSON.stringify(mailContent));

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      // return;

      if (isSingle === true) {
        await createSingleProposal(formData, {
          onSuccess: async (res) => {
            toast.success("Successfully sended Proposal");
            setSendLoading(false);
            await fetchAllProposalData();
            setShowMailModal(false);
            sessionStorage.removeItem("companyName");
            sessionStorage.removeItem("clientName");
            sessionStorage.removeItem("serviceName");
            sessionStorage.removeItem("dueDate");
            sessionStorage.removeItem("enquiryData");
            sessionStorage.removeItem("proposalData");
            setTimeout(() => {
              navigate("/proposals");
            }, [1000]);
          },
          onFailed: (err) => {
            console.log("proposal error=>", err);

            toast.error("Proposal Send Failed!");
            setSendLoading(false);
          },
        });
        return;
      }

      formData.append("leadId", enquiry?.leadId ?? "");
      await proposalSending(formData, {
        onSuccess: async (res) => {
          toast.success("Successfully sended Proposal");
          setSendLoading(false);
          const path = sessionStorage.getItem("path");
          sessionStorage.removeItem("enquiryData");
          sessionStorage.removeItem("proposalData");
          sessionStorage.removeItem("serviceName");
          sessionStorage.removeItem("dueDate");
          setTimeout(() => {
            sessionStorage.removeItem("path");
            navigate(`/single-customer-view/${path}`);
          });
        },
        onFailed: (err) => {
          console.log("proposal error=>", err);
          toast.error("Proposal Send Failed!");
          setSendLoading(false);
        },
      });
    } finally {
      setSendLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProposalInfo((prevProposalInfo) => ({
      ...prevProposalInfo,
      proposal: {
        ...prevProposalInfo.proposal,
        [name]: value,
      },
    }));
  };
  const onChangeDate = (e) => {
    const { name, value } = e.target;
    const selectedDate = new Date(value);
    const currentDate = new Date();

    if (selectedDate < currentDate) {
      toast.error("Selected date cannot be in the past");
      return;
    }
    setDueDate((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlePageback = () => {
    setIsWarn(true);
  };
  
  return (
    <Fragment>
      {showMailModal && (
        <SendMailTemplateModal
          showMailModal={showMailModal}
          setShowMailModal={setShowMailModal}
          sendMail={sendMail}
          parsedBody={parsedBody}
          setParsedBody={setParsedBody}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          ccValue={ccValue}
          setCcValue={setCcValue}
          title="proposal"
        />
      )}
      <Modal
        open={isWarn}
        onOk={() => setIsWarn(false)}
        onCancel={() => setIsWarn(false)}
        footer={null}
      >
        <div className="warn-modal">
          <h4>
            <i className="pe-2">
              <Ti.TiInfo />
            </i>
            Are you sure?
          </h4>
          <p>
            Are you sure you want to go back? <br /> Your unsaved data may be
            lost.
          </p>
          <div className="d-flex">
            <button
              type="button"
              className="warn_btn warn_back"
              onClick={() => navigate(-1)}
            >
              Yes
            </button>
            <button
              type="button"
              className="warn_btn warn_cancel"
              onClick={() => setIsWarn(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className="container-fluid">
        <Toaster position="top-center" />
        <div className="top_bar_proposal">
          <div className="row mb-3">
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <i className="back_nav_" onClick={handlePageback}>
                {" "}
                <AiOutlineArrowLeft />
              </i>
            </div>
            {!showMailModal && (
              <div className="col-md-3 col-sm-6 text-center">
                <button
                  type="button"
                  className="btn-download"
                  onClick={() => handleGeneratePDF()}
                >
                  <i>
                    <IoMdDownload />
                  </i>
                </button>
                {sendLoading ? (
                  <>
                    <button className="btn-SEND" disabled>
                      Sending...
                    </button>
                  </>
                ) : (
                  <button className="btn-SEND" onClick={handlePdf}>
                    Send
                  </button>
                )}
              </div>
            )}
          </div>
        </div>{" "}
        <div className="eee">
          <section id="content-wrapper" class="dashboard-content-wrapper">
            <div>
              <div style={{ marginTop: "20px" }} className="mt-3 p-3 attach">
                <MdOutlineAttachFile />
                <input
                  type="file"
                  className="image_input m-2"
                  onChange={(e) => setAttachments(e.target.files)}
                  multiple
                />
              </div>
              <div
                ref={targetRef}
                style={{ marginTop: "20px" }}
                className="mt-3 p-3 propsal-cont"
              >
                <div
                  className="container mx-auto p-2"
                  style={{ border: "1px solid #e1e1e1" }}
                >
                  <div className="prop-for p-3">
                    <div className="row">
                      <div className="col-md-4">
                        <img src={Pakwaan} className="pro-logo-com" />
                      </div>
                    </div>
                    <div
                      className="row mt-3"
                      style={{ position: "relative", top: "40px" }}
                    >
                      <div className="col-md-7">
                        <div className="adr-prop">
                          <h6>
                            <b>Pakwaan </b>
                          </h6>

                          <p>
                            <div id="adrs__det">
                              <p
                                style={{
                                  fontSize: "14px",
                                  textAlign: "left",
                                  fontWeight: "500",
                                }}
                              >
                                Pakwaan Indian Punjabi Cuisine 1 Bridge St,
                                Writtle, Chelmsford CM1 3EY, United Kingdom.{" "}
                                <br />
                                +44 1245 422 891 <br />
                                <a href="mailto:info@pakwaan.co.uk">
                                  info@pakwaan.co.uk
                                </a>
                                <br />
                              </p>
                            </div>
                          </p>

                          <p>
                            <div id="adrs__det">
                              <b style={{ textTransform: "uppercase" }}>
                                Quotation For
                              </b>
                              <br />
                              <p
                                style={{
                                  fontSize: "14px",
                                  textAlign: "left",
                                  fontWeight: "500",
                                }}
                              >
                                <small style={{ textTransform: "uppercase" }}>
                                  {proposalInfo?.proposal?.clientName ?? "N/A"}
                                  <br />
                                </small>
                                {proposalInfo?.proposal?.address ?? "N/A"}{" "}
                                <br />
                                {proposalInfo?.proposal?.email ?? "N/A"} <br />
                                {proposalInfo?.proposal?.phone ?? "N/A"} <br />
                              </p>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-1 col-sm-0"></div>
                      <div className="col-md-4 col-sm-12">
                        <h4 className="mt-2" style={{ color: "#000" }}>
                          {" "}
                          <b>Proposal</b>
                        </h4>
                        <br />

                        <div style={{ fontSize: "14px", marginBottom: "10px" }}>
                          Date : <br />
                          {Utils.formatDate(date)}
                        </div>

                        <div style={{ fontSize: "14px" }}>
                          Due Date : <br />
                          <input
                            type="date"
                            onChange={(e) => onChangeDate(e)}
                            name="due_date"
                            value={dueDate.due_date}
                            min={Utils.formatDate(new Date())}
                            style={{ border: "none" }}
                          />
                        </div>
                      </div>
                      <small>
                        <b>
                          Your {packageName ?? "N/A"} package is as follows:
                        </b>
                      </small>
                      <div className="col-md-12 col-sm-12 col-lg-12 mt-2">
                        <table className="table table-bordered">
                          <tbody>
                            {itemsArray != null &&
                              itemsArray.length != 0 &&
                              Array.isArray(itemsArray) &&
                              itemsArray.map((item, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{item?.description ?? "N/A"}</td>
                                      <td>
                                        £
                                        {item && item?.price.length != 0
                                          ? item?.price
                                          : 0}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            {elementArray != null &&
                              elementArray.length != 0 &&
                              Array.isArray(elementArray) &&
                              elementArray.map((item, keyIndex) => {
                                return (
                                  <>
                                    {item && item?.item.length != 0 && (
                                      <tr>
                                        <td>{item?.item ?? "N/A"}</td>
                                        <td>£{item?.price ?? 0}</td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })}
                              {proposalInfo && proposalInfo?.service?.map((data,index) =>{
                                return(
                                  <>
                                   {data && (
                                      <tr>
                                        <td>{data?.courseHead ?? "N/A"}</td>
                                        <td>£{data?.value ?? 0}</td>
                                      </tr>
                                    )}
                                  </>
                                )
                              })}
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  alignItems: "right",
                                }}
                              >
                                <b>Sub Total</b>
                              </td>
                              <td>{proposalInfo?.values?.subTotal ?? "N/A"}</td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  alignItems: "right",
                                }}
                              >
                                <b>VAT %</b>
                              </td>
                              <td>{proposalInfo?.values?.VAT ?? "N/A"}</td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  alignItems: "right",
                                }}
                              >
                                <b>VAT Amount</b>
                              </td>
                              <td>
                                £{proposalInfo?.values?.taxAmount ?? "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "right",
                                  alignItems: "right",
                                }}
                              >
                                <b>Total</b>
                              </td>
                              <td>
                                £
                                {(
                                  proposalInfo?.values?.subTotal +
                                  proposalInfo?.values?.taxAmount
                                ).toFixed(2) ?? "N/A"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: "10px",
                      marginTop: "10px",
                      marginLeft: "15px",
                    }}
                  >
                    If you have any questions concerning this quotation contact
                    +44 1245 422 891
                  </p>
                </div>
                <div
                  style={{ position: "absolute", bottom: "0px", left: "37%" }}
                  className="text-center mx-auto"
                >
                  <p className="text-center small">
                    This is system-generated proposal.
                  </p>
                </div>
              </div>
              <br />
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
}

export default Temp1;
