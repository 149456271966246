import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import { Modal } from "antd";
import * as Ai from "react-icons/ai";
import * as Md from "react-icons/md";
import { typeOf } from "react-read-more-read-less";
import toast from "react-hot-toast";
import Utils from "../../Utils/utils";

function ContractSigning({
  selectedStage,
  handleLead,
  finalizedDeals,
  enquiryData,
}) {
  const {
    getAllContractList,
    getAllCompletedContracts,
    enquiryLoading,
    updateContractStatus,
    companiesList,
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [contractList, setContractList] = useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const navigate = useNavigate();
  const params = useParams();
  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [error, setError] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [contractData, setContractData] = useState({
    companyName: "Pakwaan",
    email: "info@pakwaan.co.uk",
    phone: "+44 1245 422 891",
    address:
      "Pakwaan Indian Punjabi Cuisine1 Bridge St, Writtle, Chelmsford CM1 3EY",
    merchantCompanyName: enquiryData.sellerDetails.shopName,
    merchantClientName: enquiryData.sellerDetails.clientName,
    merchantEmail: enquiryData.sellerDetails.sellerEmail,
    merchantPhone: enquiryData.sellerDetails.mobile,
    title: null,
    // company_name:companyDetails?.company_name,
    contractDate: null,
    merchantAddress:
      enquiryData?.sellerDetails.address1 +
      " " +
      enquiryData?.sellerDetails.address2 +
      " " +
      enquiryData?.sellerDetails.country +
      " " +
      enquiryData?.sellerDetails.town +
      " " +
      enquiryData?.sellerDetails.postcode,
  });
  sessionStorage.setItem("enquiryData", JSON.stringify(enquiryData));

  useEffect(() => {
    getContractDatas();

    setContractData((prev) => ({
      ...prev,
      contractDate: getCurrentDate(),
    }));
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      setContractData((prevProposal) => ({
        ...prevProposal,
        email: selectedCompany?.email,
        phone: selectedCompany?.phone,
        company_name: selectedCompany?.company_name,
        address: selectedCompany?.address,
      }));
    }
  }, [selectedCompany]);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    return formattedDate;
  };

  const validateForm = () => {
    if (
      contractData.companyName === "" ||
      contractData.address === "" ||
      contractData.phone === "" ||
      contractData.email === "" ||
      contractData.merchantAddress === "" ||
      contractData.merchantCompanyName === "" ||
      contractData.merchantEmail === "" ||
      contractData.merchantPhone === ""
    ) {
      toast.error("Please Fill all Fields to Continue!");
      return;
    }
  };

  const handleContract = (data) => {
    setShowModal(true);
    sessionStorage.setItem("psasId", data.psasId);
    setSelectedDeal(data);
    // const id = enquiryData?.companyDetails.companyId;

    // setSelectedCompany(companyDetails);
  };

  const handleSession = () => {
    validateForm();
    const contractDetails = {
      contractData,
      selectedDeal,
    };
    sessionStorage.setItem("contractDetails", JSON.stringify(contractDetails));
    navigate(`/foodpage-contract/${params.id}`);
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setContractData((prev) => {
      const isDateInput = e.target.type === "date";
      const formattedValue = isDateInput ? e.target.value : value;
      return { ...prev, [name]: formattedValue };
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setContractData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const getContractDatas = async () => {
    const payload = {
      leadId: params.leadId,
    };
    await getAllContractList(payload, {
      onSuccess: async (res) => {
        setContractList(res.data);
      },
      onFailed: (err) => {
        console.error(err.errormessage);
      },
    });
  };

  const handleCompleteContract = async (data) => {
    if (!data) return;
    if (data.attachments == "N/A") {
      toast.error("Please send the Contract first!");
    }
    const payload = {
      contractId: data?.contractId,
    };

    await updateContractStatus(payload, {
      onSuccess: async (res) => {
        toast.success("Contract Completed Successfully");
        await getAllCompletedContracts();
        window.location.reload();
      },
      onFailed: (err) => {
        console.error(err.errormessage);
      },
    });
  };

  return (
    <>
      <Modal open={showModal} footer={false} closable={false} width="70%">
        <i className="close_ico" onClick={handleClose}>
          <Ai.AiOutlineClose />
        </i>
        <div className="card p-3">
          <h5 className="text-center">Please Update this Form to Continue</h5>
          <div className="row mt-3">
            <b>Our Company Info</b>

            <div className="col-md-6 col-sm-12">
              <option value="">Company Name</option>
              <input
                id="contractDate"
                className="form-control"
                type="text"
                value={contractData.companyName}
                onChange={handleOnChange}
                // disabled
                name="companyName"
              />
              {error && <p>Please add companyName</p>}
            </div>
            <div className="col-md-6 col-sm-12">
              <option value="" disabled>
                Email
              </option>
              <input
                className="form-control"
                type="text"
                value={contractData.email}
                onChange={handleOnChange}
                name="email"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <option value="" disabled>
                Phone
              </option>
              <input
                className="form-control"
                type="phone"
                value={contractData.phone}
                onChange={handleOnChange}
                name="phone"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <option value="" disabled>
                Contract Date
              </option>
              <input
                id="contractDate"
                className="form-control"
                type="date"
                value={contractData.contractDate}
                onChange={handleChangeData}
                name="contractDate"
              />
            </div>

            <div className="col-md-12 col-sm-12 mb-3">
              <option value="" disabled>
                Company Address
              </option>
              <textarea
                name="address"
                className="form-control"
                onChange={handleOnChange}
                value={contractData.address}
              />
            </div>
            <b>Merchant's Details</b>
            <div className="col-md-6 col-sm-12 mt-2">
              <option value="" disabled>
                Merchant Company Name
              </option>
              <input
                className="form-control"
                type="text"
                value={contractData.merchantCompanyName}
                onChange={handleOnChange}
                name="merchantCompanyName"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <option value="" disabled>
                Merchant Email
              </option>
              <input
                className="form-control"
                type="text"
                value={contractData.merchantEmail}
                onChange={handleOnChange}
                name="merchantEmail"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <option value="" disabled>
                Merchant's PhoneNumber
              </option>
              <input
                className="form-control"
                type="text"
                value={contractData.merchantPhone}
                onChange={handleOnChange}
                name="merchantPhone"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <option value="" disabled>
                Title
              </option>
              <input
                className="form-control"
                type="text"
                value={contractData.title}
                onChange={handleOnChange}
                name="title"
              />
            </div>
            <div className="col-md-12 col-sm-12">
              <option value="" disabled>
                Merchant Office Address
              </option>
              <textarea
                name="merchantAddress"
                className="form-control"
                onChange={handleOnChange}
                value={contractData.merchantAddress}
              />
            </div>
          </div>
          <div className="mx-auto text-center mt-3">
            {" "}
            <button
              type="button"
              className="sub-btn-new"
              onClick={() => handleSession()}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <div className="container-fluid">
        <div className="p-2">
          <div className="row">
            <div className="col-md-9">
              {" "}
              <h5 className="selc mt-3">{selectedStage?.taskname}</h5>
            </div>
            <div className="col-md-3 mt-3">
              {selectedStage.cmpStatus != 1 && (
                <>
                  {enquiryLoading ? (
                    <button className="complete" id="deal_cmp" disabled>
                      Completing..
                    </button>
                  ) : (
                    <button
                      className="complete"
                      id="deal_cmp"
                      onClick={() => handleLead()}
                    >
                      Complete
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          {finalizedDeals != null ? (
            <>
              <h6 className="mt-3 mb-2">Finalized Deals</h6>
              <div className="row">
                {finalizedDeals != null &&
                  Array.isArray(finalizedDeals) &&
                  finalizedDeals.map((data, index) => {
                    console.log(data, "datarr");
                    return (
                      <div className="col-md-6 col-sm-12 mb-3">
                        <div className="card p-3">
                          <h5 className="PRO_CONT_NAME">
                            {data.packageName ?? "N/A"}
                          </h5>
                          <p>
                            {/* {companyDetails?.currency} */}£
                            {data?.total ?? "N/A"} <br />
                            {data?.shopDetails?.shopName ?? "N/A"} <br />
                            {data?.startDate ?? "N/A"} to{" "}
                            {data?.endDate ?? "N/A"}
                          </p>
                          <button
                            className="cont-cre"
                            onClick={() => handleContract(data)}
                          >
                            Generate Contract
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : (
            ""
          )}

          {contractList != null && contractList.length != 0 ? (
            <div className="row">
              <h6 className="mt-3">Contracts</h6>
              <div className="col-md-12 col-sm-12 text-center">
                <div className="table-contract">
                  <table className="table table-secondary">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Contract Date</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contractList != null &&
                        contractList.toReversed().map((data, index) => {
                          const date =
                            data?.userDetails?.contractData.contractDate;
                          console.log(data, "data of ");
                          return (
                            <tr className="table-light">
                              <td className="cust_line_height">{index + 1}</td>
                              <td className="cust_line_height">
                                {Utils.formatDate(date)}
                              </td>
                              <td className="cust_line_height">
                                {data.username ?? "N/A"}
                              </td>
                              <td className="cust_line_height">
                                {data?.email ?? "N/A"}
                              </td>
                              <td className="text-center position-relative">
                                <div className="btn-toggle">
                                  <button className="one-btn">
                                    <a
                                      href={
                                        process.env.REACT_APP_AWS_IMAGE_URL +
                                        "contract-docs/" +
                                        data?.attachments
                                      }
                                      target="_blank"
                                    >
                                      {" "}
                                      <Md.MdOutlineFileDownload />
                                    </a>
                                  </button>
                                  <button
                                    className="two-btn"
                                    onClick={() => toggleDropdown(index)}
                                  >
                                    <Md.MdOutlineArrowDropDown />
                                  </button>
                                </div>
                                {openDropdownIndex === index && (
                                  <ul className="table-mgt" role="menu">
                                    <li>
                                      <button
                                        className="btn"
                                        onClick={() =>
                                          handleCompleteContract(data)
                                        }
                                      >
                                        Complete
                                      </button>
                                    </li>
                                  </ul>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {/* <Table bordered>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Contract Date</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contractList != null &&
                        contractList.toReversed().map((data, index) => {
                          const date =
                            data?.userDetails?.contractData.contractDate;
                          console.log(data, "data of ");
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{Utils.formatDate(date)}</td>
                              <td>{data.username ?? "N/A"}</td>
                              <td>{data?.email ?? "N/A"}</td>
                              <td className="text-center position-relative">
                                <div className="btn-toggle">
                                  <button className="one-btn">Action</button>
                                  <button
                                    className="two-btn"
                                    onClick={() => toggleDropdown(index)}
                                  >
                                    <Md.MdOutlineArrowDropDown />
                                  </button>
                                </div>
                                {openDropdownIndex === index && (
                                  <ul className="table-mgt mb-3" role="menu">
                                    <li>
                                      {data.attachments !== null &&
                                      data.attachments !== "" &&
                                      data.attachments !== "N/A" ? (
                                        <a
                                          href={
                                            process.env
                                              .REACT_APP_AWS_IMAGE_URL +
                                            "contract-docs/" +
                                            data?.attachments
                                          }
                                          target="_blank"
                                        >
                                          <Ai.AiOutlineDownload />
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                    <li>
                                      {data.status == "closed" ? (
                                        "Completed"
                                      ) : (
                                        <button
                                          className="btn"
                                          onClick={() =>
                                            handleCompleteContract(data)
                                          }
                                        >
                                          Complete
                                        </button>
                                      )}
                                    </li>
                                  </ul>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table> */}
                </div>
              </div>
            </div>
          ) : (
            " "
          )}
        </div>
      </div>
    </>
  );
}

export default ContractSigning;
