import React from "react";
import Sidebar from "../../Components/Sidebar";
import print from "../../Assets/print.png";
import PDF from "../../Assets/pdf.png";
import { Badge, Table } from "react-bootstrap";
import "../../Style/Style.css";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import Backbutton from "../../Components/Backbutton";
function Payments() {
  return (
    <div className="container-fluid">
      <Sidebar>
        <div div className="container-fluid m-3">
          <h6 className="head-h4">Invoices Report </h6>
          <p>Manage your Invoices Report </p>
          <span className="badge badge-danger">Danger</span>
        </div>
        <div className="card p-3 m-3 overflow">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Product Name</th>
                <th scope="col">Client Name</th>
                <th scope="col">Subscription type</th>
                <th scope="col">Amount</th>
                <th scope="col">26-04-2023</th>
                <th>Date of payment</th>
                <th scope="col" className="text-center">
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Delivey App</td>
                <td>Alphonse</td>
                <td>Monthly</td>
                <td>199</td>
                <td>19-05-2023</td>
                <td className="text-center">
                  <h4>
                    <Badge bg="danger" className="badges">
                      Pending
                    </Badge>
                  </h4>
                </td>
                <td className="text-center">
                  <i>
                    <Ai.AiOutlineEye />
                  </i>
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Waiter App</td>
                <td>John Denny</td>
                <td>Monthly</td>
                <td>199</td>
                <td>22-03-2023</td>
                <td className="text-center">
                  <h4>
                    <Badge bg="success" className="badges">
                      Paid
                    </Badge>
                  </h4>
                </td>
                <td className="text-center">
                  <i>
                    {" "}
                    <Ai.AiOutlineEye />
                  </i>
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>ePOS +</td>
                <td>Hakwes</td>
                <td>Monthly</td>
                <td>199</td>
                <td>9-04-2023</td>
                <td className="text-center">
                  <h4>
                    <Badge bg="danger" className="badges">
                      Over due
                    </Badge>
                  </h4>
                </td>
                <td className="text-center">
                  <i>
                    {" "}
                    <Ai.AiOutlineEye />
                  </i>
                </td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>Online Ordering System</td>
                <td>Mathew Gregery</td>
                <td>Monthly</td>
                <td>199</td>
                <td>18-05-2023</td>
                <td className="text-center">
                  <h4>
                    <Badge bg="success" className="badges">
                      Paid
                    </Badge>
                  </h4>
                </td>
                <td className="text-center">
                  <i>
                    {" "}
                    <Ai.AiOutlineEye />
                  </i>
                </td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td>Waiter App</td>
                <td>Gwen Gregery</td>
                <td>Yearly</td>
                <td>1999</td>
                <td>18-06-2023</td>
                <td className="text-center">
                  <h4>
                    <Badge bg="danger" className="badges">
                      Paid
                    </Badge>
                  </h4>
                </td>
                <td className="text-center">
                  <i>
                    {" "}
                    <Ai.AiOutlineEye />
                  </i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Sidebar>
    </div>
  );
}

export default Payments;
