import React from "react";
import Sidebar from "../../../Components/Sidebar";

function EditSubscriber() {
  return (
    <div className="container-fluid">
      <Sidebar>
        <div div className="container-fluid m-3">
          <h4 className="text-center">Edit seller</h4>
          <div className="card m-3 p-3 box mx-auto">
            <form action="" className="">
              <div className="row m-2">
                <div className="form-group col">
                  <label className="form-label review-label">Name</label>
                  <div className="review-group">
                    <input
                      type="text"
                      className="form-control review-input"
                      name="client"
                    />
                  </div>
                </div>
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Last Name
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
              </div>
              <div className="row m-2">
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Mobile
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Email
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
              </div>
              <div className="row m-2">
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Address line 1
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Address Line 2
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
              </div>
              <div className="row m-2">
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Town / City
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    County
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
              </div>
              <div className="row m-2">
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Landmark
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Postal Code
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
              </div>
              <div className="row m-2">
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Password
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Confirm Password
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
              </div>
              <div className="button-div text-center mx-auto">
                <button className="fp-button">Submit</button>
              </div>
         
            </form>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default EditSubscriber;
