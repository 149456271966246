import React, { useContext, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { AppContext } from "../../Context/AppContext";
import { Button, Modal } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import * as Bs from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";

function Features() {
  const navigate = useNavigate();
  const {
    featureList,
    featureLoading,
    fetchAllFeatures,
    featureGroupList,
    updateFeature,
  } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [initialFeature, setInitialFeature] = useState({
    groupName: "",
    featureName: "",
    desc: "",
  });

  const handleSelectChange = (selectedValue) => {
    setInitialFeature({ ...initialFeature, groupName: selectedValue });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const findSingleFeature = (pdfId) => {
    const dataList = featureList.find((x) => x.pdfId === pdfId);
    if (dataList) {
      setSelectedGroupName(dataList.groupName);
      setInitialFeature(dataList);
      setIsModalOpen(true);
    } else {
      return;
    }
  };

  const OnchangeFeature = (e) => {
    const { name, value } = e.target;

    setInitialFeature((prev) => {
      return {
        ...prev,
        featureName: value,
      };
    });
  };

  const OnchangeFeatureDes = (e) => {
    const { name, value } = e.target;

    setInitialFeature((prev) => {
      return {
        ...prev,
        features: {
          ...prev.features,
          desc: value,
        },
      };
    });
  };

  useEffect(() => {
    findSingleFeature();
  }, [featureList]);
  const handleUpdateFeature = async (e) => {
    e.preventDefault();
    const payload = {
      productFeaturesId: initialFeature.pdfId,
      groupName: selectedGroupName,
      featureName: initialFeature.featureName,
      features: {
        desc: initialFeature?.features?.desc,
      },
    };
    await updateFeature(payload, {
      onSuccess: async (res) => {
        toast.success("Feature Updated Succsefully!");
        await fetchAllFeatures();
        setIsModalOpen(false);
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <div>
      <Sidebar>
        <div div className="container-fluid m-3">
          <Toaster position="top-center" reverseOrder={false} />
          <h6 className="head-h4">Features </h6>
          <p>Manage Features</p>
          <button
            className="fp-add-grp"
            onClick={() => navigate("/add-feature")}
          >
            + Add
          </button>
        </div>
        <div className="card p-3 m-3 overflow feature-table">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Feature Name</th>
                <th scope="col">Group Name</th>
                <th scope="col">Description</th>
                <th scope="col" className="text-center">
                  Edit
                </th>
                <th scope="col" className="text-center">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {featureList != null &&
                Array.isArray(featureList) &&
                featureList.toReversed().map((data, index) => (
                  <>
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{data?.featureName ?? "N/A"}</td>
                      <td>{data?.groupName ?? "N/A"}</td>
                      <td>{data?.features.desc ?? "N/A"}</td>
                      <td className="text-center position-relative">
                        <div className="btn-toggle">
                          <button
                            className="edit-feat"
                            onClick={() => {
                              findSingleFeature(data.pdfId);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </td>
                      <td className="text-center ">
                        <button className="icon-act">
                          <Bs.BsTrash />
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
          <>
            <Modal
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              className="antd-modal-style"
            >
              <div className="">
                <h4 className="text-center mb-3">Edit Feature</h4>
                <form
                  action=""
                  className="box mt-1"
                  onSubmit={(e) => handleUpdateFeature(e)}
                >
                  <div className="mt-3">
                    <div className="form-group">
                      <label htmlFor="" className="form-label review-label">
                        Feature Name
                      </label>
                      <div className="review-group">
                        <input
                          className="form-control review-input"
                          type="text"
                          value={initialFeature.featureName}
                          name="featureName"
                          onChange={OnchangeFeature}
                        />
                      </div>
                    </div>
                    <div className="from-group mt-2">
                      <label className="form-label review-label">
                        Group Name
                      </label>
                      <select
                        name="groupName"
                        id=""
                        className="form-control assign_salesmen"
                        value={initialFeature.groupName}
                        onChange={(e) => handleSelectChange(e.target.value)}
                      >
                        <option value="" disabled>
                          Select Feature Group
                        </option>
                        {featureGroupList != null &&
                          Array.isArray(featureGroupList) &&
                          featureGroupList.map((group, index) => (
                            <option key={index} value={group.group_name}>
                              {group.group_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group mt-2">
                      <label className="form-label review-label">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        value={initialFeature?.features?.desc}
                        name="desc"
                        onChange={OnchangeFeatureDes}
                      ></textarea>
                    </div>
                    {featureLoading ? (
                      <div className="button-div text-center mx-auto">
                        <button className="sub-btn" disabled>
                          Submitting..
                        </button>
                      </div>
                    ) : (
                      <div className="button-div text-center mx-auto">
                        <button className="sub-btn" type="submit">
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </Modal>
          </>
        </div>
      </Sidebar>
    </div>
  );
}

export default Features;
