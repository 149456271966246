import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { APIEndpoints } from "../Constants/APIEndpoints";

export default function useChat() {
  const [connection, setConnection] = useState(null);
  const [chatToken, setChatToken] = useState(null);
  const [chats, setChats] = useState([]);
  const [newChat, setNewChat] = useState("");
  const [userId, setUserId] = useState(null);

  //Create Chat Token
  const requestToken = (chatData) => {
    // Handle null
    if (chatData == null) return;

    const data = {
      roomIdentifier: chatData?.roomIdentifier,
      userId: chatData?.userId,
    };

    console.log("data", data);

    if (chatData?.userId != null) {
      setUserId({ userId: chatData?.userId });
    }

    const token =
      "AQICAHgm5DC1V25pBVEhXdu--DOMvHAxl47LlIVxHqc_j6xXLgHKjulyhyXc2t6k8wYmKrvYAAABzDCCAcgGCSqGSIb3DQEHBqCCAbkwggG1AgEAMIIBrgYJKoZIhvcNAQcBMB4GCWCGSAFlAwQBLjARBAz6xwOMDWOdM1BFG3oCARCAggF_eButJy6qQNaFCjBS_Q2CD0hTHqTceyJm6gjvyXZRv44-yH6v6_Kgx8-b8I166jaWKoV54AWxTL9hSFWFhfLivHYve5D9AVN5omQz_2TvaKHQ19TDpi1MFb2QiKhLyFGIqzKQ55b2XdKhvamMEeWSEkBx5Ljgyp3y4rxMxClwfAYhETbT6A8UHLQO2-l9LCtzywUvzqnPTVXMidXGiKlMfIT0l4BohM3Gt6XmlLQqzNFh08y-o43Ie_FEz2SWZSYOO6fFUOvGQuU_CD0TBuIRWxA0tpExSeo_UNbR8DJR2xCKASx9S5csfLm_nv3zd1fqV_sz0eIBzIs2QTHowqrmOrlaBN6LUXnr_9dBHdovyQQW0G2Rs2tX06OYV7BwpqiHSWMN_YGkaQIx1a0jJBznfObohtjo02gAu-bhiSboGnI4Gg0X8LtQwmspF_sCHHuHHM2AcU_V3NcH7aVMZ9RPK4ubwMWoQFlh5_lR6ONSAGgjiJ-v9neR_VLJHHW0vZA!#0";

    setChatToken(token);
    initConnection(token);
    console.log("reached here");
  };

  const initConnection = async (token, chatData) => {
    const connectionInit = new WebSocket(
      "wss://edge.ivschat.us-east-1.amazonaws.com",
      token
    );
    setConnection(connectionInit);
    connectionInit.onopen = (event) => {
      console.info("Connected to the chat room.");
      // renderConnect();
    };

    connectionInit.onclose = (event) => {
      // If the websocket closes, remove the current chat token
      setChatToken(null);
      // renderDisconnect(event.reason);
    };

    connectionInit.onerror = (event) => {
      console.error("Chat room websocket error observed:", event);
    };

    connectionInit.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const eventType = data["Type"];

      switch (eventType) {
        case "EVENT":
          console.info("Received event:", data);
          // handleEvent(data);
          break;
        case "ERROR":
          console.info("Received error:", data);
          setConnection(null);
          if (data.ErrorCode && data.ErrorCode === 401) {
            requestToken(chatData);
          }
          // handleError(data);
          break;
        case "MESSAGE":
          handleMessage(data);
          break;
        default:
          console.error("Unknown message received:", event);
      }
    };
  };

  const handleMessage = (data) => {
    const attributes = data["Attributes"];
    const userId = attributes["id"];
    const image = attributes["image"];
    const name = attributes["name"];
    const msg = data["Content"];
    const emoji = attributes["emoji"];
    const isEmoji = emoji != null;
    const role = attributes["role"];

    let chatMsg = {
      attributes,
      userId,
      image,
      name,
      role,
      chat: msg,
      isEmoji,
    };

    setChats((prevState) => [...prevState, chatMsg]);
  };

  const sendMessage = () => {
    if (!chatToken || !connection || !userId) {
      return;
    }
    if (newChat.trim().length > 0) {
      const uuid = uuidv4();
      const data = `{
              "requestId": "${uuid}",
              "action": "SEND_MESSAGE",
              "Attributes": {
                "name": "CR Admin👑",
                "userId": "${userId}",
                "image": "Admin.jpg",
                "role": "admin"
              },
              "content": "${newChat
                .replace(/\\/g, "\\\\")
                .replace(/"/g, '\\"')}"
             
            }`;
      connection.send(data);
    }
    setNewChat("");
  };

  return { requestToken, sendMessage, newChat, setNewChat, chats };
}
