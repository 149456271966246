import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [reportList, setReportList] = useState(null);
  const registerAdmin = async (payload, { onSuccess, onFailed }) => {
    try {
      setLoading(true);
      await BaseClient.post(APIEndpoints.addAdmin, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setLoading(false);
    }
  };
  const getAllAdminData = async () => {
    try {
      setLoading(true);
      await BaseClient.get(APIEndpoints.getAllAdmin, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setAdminList(res?.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting Admin List!", err);
        },
      });
    } finally {
      setLoading(false);
    }
  };
  const getMonthlyEnquiry = async () => {
    try {
      setLoading(true);
      await BaseClient.get(APIEndpoints.fetchAllEnquiry, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setReportList(res?.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting data!", err);
        },
      });
    } finally {
      setLoading(false);
    }
  };
  const updateAdmin = async (payload, { onSuccess, onFailed }) => {
    try {
      setLoading(true);

      await BaseClient.put(APIEndpoints.editAdmin, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    registerAdmin,
    loading,
    getAllAdminData,
    updateAdmin,
    getMonthlyEnquiry,
    reportList,
    adminList,
  };
};
export default useAdmin;
