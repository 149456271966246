import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { AppContext } from "../../Context/AppContext";
import SuperAdmin from "../../Components/SuperAdmin";
import SalesExecutiveDashboard from "../../Components/SalesExecutiveDashboard";
import "../../Style/Style.css";
import { useNavigate } from "react-router-dom";

function Home() {
  const { role } = useContext(AppContext);
 
  
  return (
    <div className="container-fluid">
      <Sidebar>
        <main>
          {role == "superadmin" ? <SuperAdmin /> : <SalesExecutiveDashboard />}
        </main>
      </Sidebar>
    </div>
  );
}

export default Home;
