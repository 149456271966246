import Login from "../Pages/Login/Login";
import Products from "../Pages/Products/Products";
import Home from "../Pages/Home/Home";
import SubscriberEnquiry from "../Pages/Sales/SubscriberEnquiry/SubscriberEnquiry";
import SalesExecutive from "../Pages/Sales/SalesExecutives/SalesExecutive";
import EnquiryAction from "../Pages/Sales/SubscriberEnquiry/EnquiryAction";
import Chat from "../Pages/Support/Chat";
import AddEnquiry from "../Pages/Sales/SubscriberEnquiry/AddEnquiry";
import AddSubscriber from "../Pages/Merchant/Merchant/AddSubscriber";
import CreateProduct from "../Pages/Products/CreateProduct";
import Packages from "../Pages/Packages/Packages";
import CreatePackage from "../Pages/Packages/CreatePackage";
import Invoice from "../Pages/Payment-invoice/Invoice";
import Payments from "../Pages/Payment-invoice/Payments";
import Support from "../Pages/Support/Support";
import ApkManagement from "../Pages/APK_Management/ApkManagement";
import BillingMessages from "../Pages/Subscriptions/BillingMessages";
import SubscriptionDetails from "../Pages/Subscriptions/SubscriptionDetails";
import CustomerRating from "../Pages/Reports/CustomerRating";
import AppUsage from "../Pages/Reports/AppUsage";
import Month_weekly_yearlyReport from "../Pages/Reports/Month_weekly_yearlyReport";
import SalesReport from "../Pages/Reports/SalesReport/SalesReport";
import Privileges from "../Pages/Privilege Management/Privileges";
import ViewProducts from "../Pages/Products/ViewProducts";
import Stages from "../Pages/Products/Stages";
import EditProduct from "../Pages/Products/EditProduct";
import AddSalesExecutive from "../Pages/Sales/SalesExecutives/AddSalesExecutive";
import ViewIssue from "../Pages/Support/ViewIssue";
import ViewPackages from "../Pages/Packages/ViewPackages";
import CreateAdmin from "../Pages/Admins/CreateAdmin";
import Admins from "../Pages/Admins/Admins";
import ViewAdmin from "../Pages/Admins/ViewAdmin";
import ViewSubscriber from "../Pages/Merchant/Merchant/ViewMerchant";
import RestrictedEntry from "../Components/Error/RestrictedEntry";
import PageNotFound from "../Components/Error/PageNotFound";
import AddPrivileges from "../Pages/Privilege Management/AddPrivileges";
import EditPrivilege from "../Pages/Privilege Management/EditPrivilege";
import AddStage from "../Pages/Products/AddStage";
import UpdateAdmin from "../Pages/Admins/UpdateAdmin";
import Merchant from "../Pages/Merchant/Merchant/Merchant";
import ViewMerchant from "../Pages/Merchant/Merchant/ViewMerchant";
import Features from "../Pages/Features/Features";
import AddFeature from "../Pages/Features/AddFeature";
import ViewFeature from "../Pages/Features/ViewFeature";
import ViewSubscriptionDetails from "../Pages/Subscriptions/ViewSubscriptionDetails";
import ViewEnquiry from "../Pages/Sales/SubscriberEnquiry/ViewEnquiry";
import EditSubscriber from "../Pages/Merchant/Merchant/EditSubscriber";
import StagesList from "../Pages/Sales/SubscriberEnquiry/StagesList";
import ViewReport from "../Pages/Reports/SalesReport/ViewReport";
import UpdateEnquiry from "../Pages/Sales/SubscriberEnquiry/UpdateEnquiry";
import Profile from "../Pages/Profile/Profile";
import FeatureGroup from "../Pages/Features/FeatureGroup";
import ApkRelease from "../Pages/APK_Management/ApkRelease";
import CreateApk from "../Pages/APK_Management/CreateApk";
import LeadList from "../Pages/Leads/LeadList";
import ViewSingle from "../Pages/Leads/ViewSingle";
import CustomisedInvoice from "../Pages/Payment-invoice/CustomisedInvoice";
import Temp1 from "../Pages/Proposal/Temp1";
import CreateTemplate from "../Pages/Templates/CreateTemplate";
import Temp2 from "../Pages/Proposal/Temp2";
import ContractTemp from "../Pages/Contract/ContractTemp";
import ContractSettings from "../Components/Stages/ContractSettings";
import Invoicing from "../Components/Stages/Invoicing";
import InvoiceSample from "../Pages/Payment-invoice/InvoiceSample";
import MerchantInvoice from "../Pages/Payment-invoice/MerchantInvoice";
import PartnerFoodpageInvoice from "../Pages/Payment-invoice/PartnerFoodpageInvoice";
import Proposals from "../Pages/Leads/Proposals";
import AddProposal from "../Pages/Leads/AddProposal";
import ContractDoc from "../Pages/Leads/ContractDoc";
import GenerateInvoice from "../Pages/Leads/GenerateInvoice";
import EditedInvoices from "../Pages/Leads/EditedInvoices";
import EditInvoice from "../Pages/Leads/EditInvoice";
import SingleInvoice from "../Pages/Leads/SingleInvoice";
import UpdatePayment from "../Pages/Leads/UpdatePayment";
import EventPackages from "../Pages/EventPackages/EventPackages";
import ClientContractTemp from "../Pages/Client/ClientContractTemp";
import ClientContractDoc from "../Pages/Client/ClientContractDoc";
import Customers from "../Pages/Customers/Customers";
import SingleCustomer from "../Pages/Leads/SingleCustomer";

const AppRoutes = [
  {
    isProtected: false,
    isProtectedAuth: true,
    path: "/login",
    element: <Login />,
  },
  {
    isProtected: true,
    path: "/",
    element: <Home />,
  },
  {
    isProtected: true,
    path: "/products",
    element: <Products jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/enquiry",
    element: <SubscriberEnquiry jobRole={["admin", "sales"]} />,
  },
  {
    isProtected: true,
    path: "/sales-executives",
    element: <SalesExecutive jobRole={["admin"]} />,
  },

  {
    isProtected: true,
    path: "/enquiry-action",
    element: <EnquiryAction jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/register-seller",
    element: <Merchant jobRole={["superadmin", "admin"]} />,
  },
  {
    isProtected: true,
    path: "/view-merchant/:id",
    element: <ViewMerchant jobRole={["superadmin", "admin"]} />,
  },
  {
    isProtected: true,
    path: "/view-feature",
    element: <ViewFeature jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/add-enquiry",
    element: <AddEnquiry jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/update-enquiry/:id",
    element: <UpdateEnquiry jobRole={["superadmin", "admin"]} />,
  },

  {
    isProtected: true,
    path: "/packages",
    element: <Packages jobRole={["superadmin", "admin", "support", "sales"]} />,
  },
  {
    isProtected: true,
    path: "/support",
    element: <Support jobRole={["superadmin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/apk-management",
    element: <ApkManagement jobRole={["superadmin", "admin"]} />,
  },
  {
    isProtected: true,
    path: "/apk-release",
    element: <ApkRelease jobRole={["superadmin", "admin"]} />,
  },
  {
    isProtected: true,
    path: "/create-apk",
    element: <CreateApk jobRole={["superadmin", "admin"]} />,
  },
  {
    isProtected: true,
    path: "/messages",
    element: <BillingMessages jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/subdetails",
    element: (
      <SubscriptionDetails jobRole={["superadmin", "admin", "support"]} />
    ),
  },
  {
    isProtected: true,
    path: "/direct-proposal-invoice",
    element: <Invoicing jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/create-direct-Invoice",
    element: <GenerateInvoice jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/edit-invoice/:id",
    element: <EditInvoice jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/generate-invoice",
    element: <MerchantInvoice jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/createpackage",
    element: <CreatePackage jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/payments",
    element: <Payments jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/partnerfoodpage-invoice/:id",
    element: <InvoiceSample jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/ferns-invoice/:id",
    element: <SingleInvoice jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/ferns-invoice-single/:id",
    element: <EditedInvoices jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/partner-foodpage-invoice/:id",
    element: <PartnerFoodpageInvoice jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/invoice",
    element: <Invoice jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/createproduct",
    element: <CreateProduct jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/addsubscriber",
    element: <AddSubscriber jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/salesreport",
    element: <SalesReport jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/dayreport",
    element: (
      <Month_weekly_yearlyReport jobRole={["superadmin", "admin", "support"]} />
    ),
  },
  {
    isProtected: true,
    path: "/usage",
    element: <AppUsage jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/ratings",
    element: <CustomerRating jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/all-privileges",
    element: <Privileges jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/add-privileges",
    element: <AddPrivileges jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/edit-privileges/:id",
    element: <EditPrivilege jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/viewproduct/:id",
    element: <ViewProducts jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/stages",
    element: <Stages jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/add-stages",
    element: <AddStage jobRole={["superadmin", "admin"]} />,
  },
  {
    isProtected: true,
    path: "/editproduct/:id",
    element: <EditProduct jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/add-sales-executive",
    element: <AddSalesExecutive jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/view-issue/:id/:ticketId",
    element: <ViewIssue jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/action",
    element: <EnquiryAction jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/view-package/:id",
    element: <ViewPackages jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/features",
    element: <Features jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/add-feature",
    element: <AddFeature jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/admin",
    element: <Admins jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/view-sub-details",
    element: <ViewSubscriptionDetails jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/view-enquiry/:id",
    element: <ViewEnquiry jobRole={["superadmin", "admin"]} />,
  },
  {
    isProtected: true,
    path: "/contract-settings",
    element: <ContractSettings jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/view-single-enquiry/:id/:leadId",
    element: <ViewSingle jobRole={["superadmin", "admin"]} />,
  },
  {
    isProtected: true,
    path: "/single-customer-view/:id/:leadId",
    element: <SingleCustomer jobRole={["superadmin", "admin"]} />,
  },
  {
    isProtected: true,
    path: "/pakwaan-contract/:id/:contractId?",
    element: <ClientContractTemp />,
  },
  {
    isProtected: true,
    path: "/pakwaan-contract/single/:id/:contractId?",
    element: <ClientContractDoc />,
  },
  {
    isProtected: true,
    path: "/create-admin",
    element: <CreateAdmin jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/custom-invoice",
    element: <CustomisedInvoice jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/payment-status",
    element: <UpdatePayment jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/leads",
    element: <LeadList jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/customers",
    element: <Customers jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/view-subscriber",
    element: <ViewSubscriber jobRole={["superadmin", "admin", "support"]} />,
  },
  {
    isProtected: true,
    path: "/edit-merchant",
    element: <EditSubscriber jobRole={["superadmin", "admin"]} />,
  },

  {
    isProtected: true,
    path: "/know-enquiry-stages/:id",
    element: <StagesList jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/update-admin/:id",
    element: <UpdateAdmin jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/view-report",
    element: <ViewReport jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/proposals",
    element: <Proposals jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/add-new-propsal",
    element: <AddProposal jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/feature-group",
    element: <FeatureGroup jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/admin/event-packages",
    element: <EventPackages jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/foodpage-contract/:id/:contractId?",
    element: <ContractTemp jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/ferns-contract/:id/:contractId?",
    element: <ContractDoc jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/profile",
    element: <Profile jobRole={["superadmin,admin,sales,support"]} />,
  },
  {
    isProtected: false,
    path: "/template-creation",
    element: <CreateTemplate jobRole={["superadmin"]} />,
  },
  {
    isProtected: false,
    path: "/chat",
    element: <Chat jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/template/:id",
    element: <Temp1 jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/template2/:id",
    element: <Temp2 jobRole={["superadmin"]} />,
  },
  {
    isProtected: true,
    path: "/view-admin/:id",
    element: <ViewAdmin jobRole={["superadmin"]} />,
  },
  {
    isProtected: false,
    path: "/restricted-entry",
    element: <RestrictedEntry />,
  },
  {
    isProtected: false,
    isProtectedAuth: false,
    path: "*",
    element: <PageNotFound />,
  },
];

export default AppRoutes;
