import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const usePayment = () => {
  const [paymentLoading, setPaymentLoading] = useState(false);
  
  const sendLink = async (payload, { onSuccess, onFailed }) => {
    try {
      setPaymentLoading(true);

      await BaseClient.post(APIEndpoints.directDebit, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPaymentLoading(false);
    }
  };
  const updatePaymentStatus = async (payload, { onSuccess, onFailed }) => {
    try {
      setPaymentLoading(true);

      await BaseClient.post(APIEndpoints.paymentStatusUpdate, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPaymentLoading(false);
    }
  };
  const fetchPaymentByLeadId = async (payload, { onSuccess, onFailed }) => {
    try {
      setPaymentLoading(true);

      await BaseClient.post(APIEndpoints.fetchInvoiceDetails, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setPaymentLoading(false);
    }
  };
  
  return {
    sendLink,
    fetchPaymentByLeadId,
    updatePaymentStatus,
    paymentLoading,
  };
};
export default usePayment;
