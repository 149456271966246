import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import img1 from "../../Assets/delivery-app.jpg";
import List from "../../Assets/restaurant-app.jpg";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import PackageSkelton from "../../Components/PackageSkelton";
import "react-loading-skeleton/dist/skeleton.css";

function ViewPackages() {
  const { id } = useParams();
  const [planData, setPlanData] = useState(null);
  const { planList, planLoading } = useContext(AppContext);
  useEffect(() => {
    getProductDetails();
  }, [planList]);

  const getProductDetails = () => {
    if (!id || !planList) return;

    const getSinglePlan = planList?.find((x) => x?.plnId == id);
    setPlanData(getSinglePlan);
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8 col-sm-12">
              <h4 className="head-h4 text-center">View Package</h4>
              {!planLoading ? (
                <div class="card ">
                  <h4 className="header text-center">
                    {planLoading ? "loading" : planData?.planName ?? "N/A"}
                  </h4>

                  <div className="admin-data">
                    <ul className="support-service">
                      <li>
                        <h6>
                          Products :{" "}
                          {planData != null &&
                            planData.products != null &&
                            Array.isArray(planData.products) &&
                            planData.products.map((pro, index) => (
                              <span key={index}>
                                {pro?.productName ?? "N/A"}
                              </span>
                            ))}
                        </h6>
                      </li>

                      <li>
                        <h6>
                          Price per Month :{" "}
                          <span>£{planData?.price_monthly ?? "N/A"}</span>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Price per Year :{" "}
                          <span>£{planData?.price_yearly ?? "N/A"}</span>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Special Features :{" "}
                          {planData != null &&
                            planData?.specialFeatures != null &&
                            Array.isArray(planData?.specialFeatures) &&
                            planData?.specialFeatures.map((feature, index) => (
                              <span key={index}>
                                {feature?.groupName ?? "N/A"}
                              </span>
                            ))}
                        </h6>
                      </li>
                      <li>
                        {planData != null &&
                          planData?.products != null &&
                          planData?.products.map((product, index) => (
                            <div key={index}>
                              <h4 className="mt-3 pro-named">
                                {product?.productName ?? "N/A"}
                              </h4>
                              <div className="border p-3">
                                {product?.features.map(
                                  (featureGroup, Index) => (
                                    <div key={Index}>
                                      <div className="col-md-12 col-lg-12 col-sm-12 border-bottom">
                                        <h6 className="feature_bolded text-center">
                                          {featureGroup?.groupName ?? "N/A"}
                                        </h6>
                                      </div>
                                      <div className="col-md-12 col-sm-12">
                                        {featureGroup?.features.map(
                                          (feature, featIndex) => (
                                            <div
                                              className="row"
                                              key={featIndex}
                                            >
                                              <div className="col-md-3">
                                                <p className="main-feature">
                                                  {feature?.featureName ??
                                                    "N/A"}
                                                </p>
                                              </div>
                                              <div className="col-md-9">
                                                <p className="feature-des">
                                                  {feature?.desc ?? "N/A"}
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          ))}
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <PackageSkelton />
              )}
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default ViewPackages;
