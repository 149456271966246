import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import Backbutton from "../../Components/Backbutton";
import Select from "react-select";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";

function CreatePackage() {
  const navigate = useNavigate();

  const {
    createProduct,
    featureList,
    productList,
    createPackage,
    getAllPlanList,
    deletePackage,
  } = useContext(AppContext);

  const [PackageData, setPackageData] = useState({
    planName: "",
    duration_yearly: "365",
    duration_monthly: "30",
    durationIn: "days",
    price_monthly: "",
    price_yearly: "",
    currency: "£",
    specialFeatures: "",
    products: [
      {
        pId: "",
        pdfId: "",
      },
    ],
  });

  const products = productList.map((item) => ({
    value: `${item.pdfId} ${item.pId}`,
    label: item.productName,
  }));

  const special = featureList.map((group) => ({
    value: group.pdfId,
    label: group.featureName,
  }));

  console.log(featureList, "featurelist");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPackageData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSpecialFeatures = (selectedOptions) => {
    const pdfIds = selectedOptions.map((option) => option.value);
    setPackageData((prev) => ({ ...prev, specialFeatures: pdfIds }));
  };

  const handleProductChange = (selectedOptions) => {
    const pIds = selectedOptions.map((product) => product.value);
    setPackageData((prev) => ({ ...prev, products: pIds }));
  };

  console.log("productList", productList);

  const handlePackage = async (e) => {
    e.preventDefault();

    const payload = {
      planName: PackageData.planName,
      price_monthly: PackageData.price_monthly,
      price_yearly: PackageData.price_yearly,
      durationIn: PackageData.durationIn,
      duration_monthly: PackageData.duration_monthly,
      duration_yearly: PackageData.duration_yearly,
      currency: "£",
      specialFeatures:
        PackageData.specialFeatures != null &&
        PackageData.specialFeatures.join(","),
      products: PackageData.products.map((pId) => {
        if (typeof pId === "string") {
          return {
            pId: pId.split(" ")[1],
            pdfId: pId.split(" ")[0],
          };
        } else {
          return "null";
        }
      }),
    };

    await createPackage(payload, {
      onSuccess: async (res) => {
        toast.success("Package Created Succsefully!");
        await getAllPlanList();
        setTimeout(() => {
          navigate("/packages");
        });
      },
      onFailed: (err) => {
        if (err.errorCode == "INR") {
          toast.error("Please fill in all details to create a new plan!");
        } else {
          toast.error(err.errormessage);
        }
      },
    });
  };


  return (
    <div className="container-fluid">
      <Sidebar>
        <div div className="container-fluid m-3">
          <Toaster position="top-center" reverseOrder={false} />
          <h4 className="text-center mt-3 mt-3">Create Package</h4>
        </div>
        <div className="card m-3 p-3 box mx-auto">
          <form onSubmit={(e) => handlePackage(e)} className=" mb-3">
            <div className="row m-2 mt-3">
              <div className="form-group col">
                <label className="form-label review-label">Plan Name</label>
                <div className="review-group">
                  <input
                    type="text"
                    className="form-control review-input"
                    name="planName"
                    value={PackageData.planName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="pdfSelect" className="form-label review-label">
                  Select Product
                </label>
                <Select
                  name="pId"
                  id="pdfSelect"
                  value={products.filter((option) =>
                    PackageData?.products?.includes(option.value)
                  )}
                  onChange={(e) => handleProductChange(e)}
                  options={products}
                  isMulti
                />
              </div>
            </div>
            <div className="row m-2 mt-3">
              <div className="form-group col">
                <label className="form-label review-label">Price Monthly</label>
                <div className="review-group">
                  <input
                    type="text"
                    className="form-control review-input"
                    name="price_monthly"
                    value={PackageData.price_monthly}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="" className="form-label review-label">
                  Price Yearly
                </label>
                <div className="review-group">
                  <input
                    className="form-control review-input"
                    type="text"
                    value={PackageData.price_yearly}
                    onChange={handleChange}
                    name="price_yearly"
                  />
                </div>
              </div>
            </div>

            <div className="row m-2 mt-3">
              <div className="form-group col">
                <label htmlFor="" className="form-label review-label">
                  Special Features
                </label>
                <Select
                  name="pdfId"
                  id="pdfSelect"
                  value={special.filter((option) =>
                    PackageData?.specialFeatures?.includes(option.value)
                  )}
                  onChange={(e) => handleSpecialFeatures(e)}
                  options={special}
                  isMulti
                />
              </div>
            </div>

            <div className="button-div text-center mx-auto">
              <button className="fp-button" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Sidebar>
    </div>
  );
}

export default CreatePackage;
