import React, { useContext } from "react";
import Sidebar from "../../Components/Sidebar";
import { Badge } from "react-bootstrap";
import * as Bi from "react-icons/bi";
import * as Ai from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ReactPaginate from "react-paginate";
import Utils from "../../Utils/utils";
import { useState } from "react";

function Support() {
  const navigate = useNavigate();
  const { chatList, supportList, fetchMessages } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const filteredList = supportList.filter((support) => {
    return (
      support.ticketID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      support.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      support.subject.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const currentItems = filteredList.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredList.length / itemsPerPage);

  const fetchAllTicket = {
    Open: filteredList.filter(
      (ticket) => ticket?.status === "Open" || ticket?.status === "Reopen"
    ),
    Resolved: filteredList.filter((ticket) => ticket?.status === "Closed"),
  };
  const sortedItems = currentItems.slice().sort((a, b) => {
    const itemA = a.name.toLowerCase();
    const itemB = b.name.toLowerCase();

    if (sortOrder === "asc") {
      return itemA.localeCompare(itemB);
    } else {
      return itemB.localeCompare(itemA);
    }
  });

  return (
    <>
      <div className="container-fluid">
        <Sidebar>
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h6 className="head-h4">Ticket List</h6>
              </div>
              <div className="col-md-4">
                <div className="text-center">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Search from the table"
                      className="new-search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="search-btn">
                      <i>
                        <Bi.BiSearch />
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-resolve mt-3 mb-3">
              <Tabs>
                <TabList>
                  <div className="d-flex">
                    <Tab className="sup-tab">OPEN</Tab>
                    <Tab className="sup-tab">CLOSED</Tab>
                  </div>
                </TabList>
                <TabPanel>
                  <div className="p-3 mb-3 mt-3 data-rep">
                    <table class="table">
                      <thead>
                        
                        
                        <tr>
                          <th>#</th>
                          <th scope="col">Ticket Id</th>
                          <th scope="col" className="ps-4">
                            Date
                          </th>
                          <th scope="col">Name</th>
                          <th scope="col">Subject</th>
                          <th scope="col" className="text-center">
                            View
                          </th>
                        </tr>
                      </thead>
              
                      <tbody>
                        {sortedItems != null &&
                          sortedItems.map((support, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{support.ticketID ?? "N/A"}</td>
                                  <td>
                                    {support.updated_at != null
                                      ? Utils.formatDate(support.updated_at)
                                      : "N/A"}
                                  </td>
                                  <td>{support.name ?? "N/A"}</td>
                                  <td>{support.subject ?? "N/A"}</td>
                                  <td
                                    className="text-center"
                                    onClick={() =>
                                      navigate(
                                        `/view-issue/${support?.Id}/${support?.ticketID}`
                                      )
                                    }
                                  >
                                    <i>
                                      <Ai.AiOutlineEye />
                                    </i>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      onPageChange={handlePageChange}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      containerClassName={"pagination"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      disabledClassName={"disabled"}
                      activeClassName={"paging__link--active"}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="p-3 mb-3 mt-3 data-rep">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Issue Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Subject</th>
                          <th scope="col">Closed Date</th>
                          <th scope="col" className="text-center">
                            View
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fetchAllTicket.Resolved != null &&
                          fetchAllTicket.Resolved.map((support, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{support.ticketID ?? "N/A"}</td>
                                  <td>{support.name ?? "N/A"}</td>
                                  <td>{support.subject ?? "N/A"}</td>
                                  <td>
                                    {" "}
                                    {support.closeDate != null
                                      ? Utils.formatDate(support.closeDate)
                                      : "N/A"}
                                  </td>
                                  <td
                                    className="text-center"
                                    onClick={() =>
                                      navigate(
                                        `/view-issue/${support?.Id}/${support?.ticketID}`
                                      )
                                    }
                                  >
                                    <i>
                                      <Ai.AiOutlineEye />
                                    </i>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </Sidebar>
      </div>
    </>
  );
}

export default Support;
