import React, { Fragment, useContext, useEffect, useState } from "react";
import * as Ai from "react-icons/ai";
import LeadQualification from "./LeadQualification";
import { AppContext } from "../../Context/AppContext";
import { toast } from "react-toastify";
import * as Bs from "react-icons/bs";
import Utils from "../../Utils/utils";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "antd";

function Stages({ allJobQueueArray, jobQueueStatusArray }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    allSellerEnquiries,
    fetchAllSellerEnquiries,
    cancelLead,
    adminList,
    alljobRole,
    enquiryLoading,
    contractSigning,
    sendLink,
    completeStage,
    multipleAssignment,
    updateLeadToSeller,
  } = useContext(AppContext);
  const [selectedStage, setSelectedStage] = useState(null);
  const [showRescheduleCard, setShowRescheduleCard] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [lastIndex, setLastIndex] = useState(-1);
  const [details, setDetails] = useState(false);
  const [stageLoadingAction, setStageLoadingAction] = useState("");
  const [enquiryData, setEnquiryData] = useState(null);
  const [salesmenList, setSalesmenList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const [detailsStage, setDetailsStage] = useState(false);
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [initial, setInitial] = useState({
    email: enquiryData?.sellerDetails?.sellerEmail || "",
  });
  const [onBoard, setOnBoard] = useState({
    email: enquiryData?.sellerDetails?.sellerEmail || "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInitial((prev) => {
      return {
        ...prev,
        [name]: isEditing
          ? value
          : enquiryData?.sellerDetails?.sellerEmail || "",
      };
    });
  };
  const handleEdit = (e) => {
    const { name, value } = e.target;
    setOnBoard((prev) => {
      return {
        ...prev,
        [name]: isEditable
          ? value
          : enquiryData?.sellerDetails?.sellerEmail || "",
      };
    });
  };
  useEffect(() => {
    getEnquiryDetails();
  }, [allSellerEnquiries]);

  const getEnquiryDetails = () => {
    if (!id || !allSellerEnquiries) return;

    const singleData = allSellerEnquiries?.find((x) => x?.pceId == id);
    setEnquiryData(singleData);
  };

  const stageDetails = async (stage) => {
    if (!stage) return;

    const arr = stage?.description;
    setShowEdit(false);
    setEmailEdit(false);
    if (arr != null && Array.isArray(arr) && arr.length > 0) {
      const keys = Object.keys(arr);
      const lastIndex = keys[keys.length - 1];
      setLastIndex(lastIndex);
    } else {
      setLastIndex(0);
    }

    setSelectedStage(stage === selectedStage ? null : stage);
    window.sessionStorage.setItem("selectedId", stage?.stpId);

    setShowRescheduleCard(true);
    setDetailsModal(true);
    // setDetailsStage
  };

  const handleLead = async () => {
    if (!selectedStage || !jobQueueStatusArray) {
      toast.error("CANNOT COMPLETE THE ACTION, JOB ID IS MISSING");
      return;
    }
    const payload = {
      psasId:
        selectedStage != null ? selectedStage.jobId : jobQueueStatusArray.jobId,
      cmpStatus: true,
      status: "completed",
    };

    setStageLoadingAction("complete");

    await completeStage(payload, {
      onSuccess: async (res) => {
        toast.success("Stage has been completed!");
        await fetchAllSellerEnquiries();
        stageDetails(selectedStage);
        setStageLoadingAction("");
      },
      onFailed: (err) => {
        toast.error("Error on completing the stage, Please try again!");
        setStageLoadingAction("");
      },
    });
  };

  const handleEditDescription = (e) => {
    setShowEdit(!showEdit);
  };

  const handleDescriptionSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      description: editedDescription,
      psasId:
        selectedStage != null ? selectedStage.jobId : jobQueueStatusArray.jobId,
      rescheuleStat: false,
      indx: parseInt(lastIndex),
    };

    await completeStage(payload, {
      onSuccess: async (res) => {
        toast.success("Description Added Successfully!");
        setShowEdit(false);
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
      },
    });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setInitial((prev) => ({
      ...prev,
      file: file,
    }));
    setSelectedFileName(file.name);
  };

  const handleReSchedule = async () => {
    const payload = {
      psasId: selectedStage?.jobId,
      rescheuleStat: true,
    };

    setStageLoadingAction("reSchedule");

    await completeStage(payload, {
      onSuccess: async (res) => {
        toast.success("Rescheduled!");
        await fetchAllSellerEnquiries();
        stageDetails(selectedStage);

        setStageLoadingAction({
          action: "",
          status: false,
        });
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
        setStageLoadingAction("");
      },
    });
  };

  const handleMandatory = () => {
    const mandatoryDatas = allJobQueueArray.filter((x) => x.mandatory === 1);

    if (!mandatoryDatas) return;

    const incompleteTasks = mandatoryDatas.filter(
      (data) => data.status === "pending"
    );
    if (incompleteTasks.length > 0) {
      incompleteTasks.map((data) => {
        toast.info(
          `You must complete ${data.taskname} first, before Customer On-Boarding`
        );
      });

      return false;
    }
    return true;
  };

  const handleOnBoarding = async () => {
    const payload = {
      pceId: enquiryData?.pceId,
    };

    const validation = handleMandatory();

    if (!validation) return;

    await updateLeadToSeller(payload, {
      onSuccess: async (res) => {
        toast.success("Lead has successfully changed as a Merchant!");
        await fetchAllSellerEnquiries();
        setTimeout(() => {
          navigate("/enquiry");
        }, 1500);
      },
      onFailed: async (err) => {
        if (err.errorCode == "INR") {
          toast.info("Please fill in all the mandatory fields to continue!");
          setTimeout(() => {
            navigate(`/update-enquiry/${enquiryData?.pceId}`);
          }, 1500);
        } else if (err.errorCode == "DSU") {
          toast.error(err.message);
          setTimeout(() => {
            navigate(`/update-enquiry/${enquiryData?.pceId}`);
          }, 1500);
        } else {
          toast.error(err?.message);
        }
        await fetchAllSellerEnquiries();
      },
    });
  };
  useEffect(() => {
    if (enquiryData) {
      setOnBoard((prev) => ({
        ...prev,
        email: enquiryData?.sellerDetails?.sellerEmail || "",
      }));
    }
  }, [enquiryData]);
  const handleContract = () => {
    if (selectedStage?.taskname === "Contract Signing") {
      setDetails(true);
    } else {
      setDetails(false);
    }
  };

  useEffect(() => {
    handleContract();
  }, [selectedStage]);

  useEffect(() => {
    if (enquiryData) {
      setInitial((prev) => ({
        ...prev,
        email: enquiryData?.sellerDetails?.sellerEmail || "",
      }));
    }
  }, [enquiryData]);

  useEffect(() => {
    filterSalesMen();
  }, [adminList, alljobRole]);

  useEffect(() => {
    if (!id) return;

    setAssignedValues((prev) => ({ ...prev, pceId: id }));
  }, [id]);

  const filterSalesMen = () => {
    if (!adminList || !alljobRole) return;

    const data = adminList.filter((list) => {
      const jobRole = list.jobRoles;
      return jobRole.some((role) => role.role === "sales");
    });
    setSalesmenList(data);
  };

  const [assignedValues, setAssignedValues] = useState({
    pceId: "",
    aId: "",
    remarks: "",
  });
  const sendContract = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("user", enquiryData?.sellerDetails?.sellerName);
    formData.append("email", initial.email);
    formData.append("file", initial.file);

    await contractSigning(formData, {
      onSuccess: async (res) => {
        toast.success("Successfully Completed!");
        setShowEdit(false);
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
      },
    });
  };

  const handleReAssign = async () => {
    const payload = {
      psasId: selectedStage?.jobId,
      aId: selectedValue,
    };

    await multipleAssignment(payload, {
      onSuccess: async (res) => {
        toast.success("Re-assigned Successfully!");
        setIsModalOpen(false);
        await fetchAllSellerEnquiries();
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
      },
    });
  };

  const handleDirectDebit = async (e) => {
    e.preventDefault();
    const payload = {
      email: onBoard.email,
    };

    await sendLink(payload, {
      onSuccess: async (res) => {
        toast.success("Details Sended Successfully!");
        await fetchAllSellerEnquiries();
        setEmailEdit(false);
      },
      onFailed: (err) => {
        toast.error("Please Try Again!");
      },
    });
  };
  const hideStageModal = () => {
    if (selectedStage?.taskname === "Invoice Generation") {
      setDetailsStage(false);
    }
  };
  useEffect(() => {
    hideStageModal();
  }, [selectedStage]);

  return (
    <Fragment className="stages">
      <>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
        >
          <div className="row">
            <div className="col">
              <h5>Current Executive Details</h5>
              <div className="card" style={{ overflow: "hidden" }}>
                <table className="table personal-info-table p-3 m-3">
                  <tbody>
                    <tr>
                      <td>
                        <b>Name</b>
                      </td>
                      <td>{enquiryData?.adminDetails?.name ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Email Address</b>
                      </td>
                      <td>{enquiryData?.adminDetails?.email ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Phone Number</b>
                      </td>
                      <td>{enquiryData?.adminDetails?.mobile ?? "N/A"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col">
              <h5 className="">Re-assign Employee</h5>
              <small>Assign This Stage to a New Executive</small>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <select
                  name=""
                  id=""
                  className="form-control assign_salesmen"
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  <option value="" disabled selected>
                    Choose a Salesmen
                  </option>
                  {salesmenList != null &&
                    Array.isArray(salesmenList) &&
                    salesmenList.map((list, index) => (
                      <option key={index} value={list.paId}>
                        {list.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="_">
                <button
                  type="submit"
                  className="re-as-btn"
                  onClick={handleReAssign}
                >
                  Assign
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
      {jobQueueStatusArray?.cmpStatus !== 1 && (
        <LeadQualification
          jobQueueStatusArray={jobQueueStatusArray}
          enquiryData={enquiryData}
          handleLead={handleLead}
          enquiryLoading={enquiryLoading}
          fetchAllSellerEnquiries={fetchAllSellerEnquiries}
        />
      )}

      {jobQueueStatusArray?.cmpStatus === 1 && (
        <div className="row mt-2" style={{ overflow: "hidden" }}>
          <div className="col-md-12 col-lg-6 col-sm-12 mb-3">
            <div className="card p-3">
              <table className="table stage_table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Stage Name</th>
                    <th scope="col" className="text-center stg-st">
                      Status
                    </th>
                    <th scope="col" className="text-center stg-st">
                      schedule Status
                    </th>
                    <th scope="col" className="text-center">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allJobQueueArray != null &&
                    Array.isArray(allJobQueueArray) &&
                    allJobQueueArray.map((data, index) => {
                      return (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <td>{data?.taskname}</td>
                          <td className="text-center td-bad">
                            <div
                              className={
                                data.status === "completed"
                                  ? "fp-badge success"
                                  : "fp-badge pending"
                              }
                            >
                              {data.status}
                            </div>
                          </td>
                          <td className="td-bad">
                            {data.rescheuleStat != null &&
                            data.rescheuleStat !== 0 ? (
                              <div className="fp-badge failed">
                                Re-Scheduled
                              </div>
                            ) : (
                              <div className="fp-badge pending">None</div>
                            )}
                          </td>
                          <td className="text-center">
                            <i onClick={() => stageDetails(data)}>
                              <Ai.AiOutlineEye />
                            </i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-sm-12 mb-3">
            {selectedStage != null &&
              enquiryData.status !== "cancelled" &&
              showRescheduleCard &&
              selectedStage?.taskname !== "Contract Signing" &&
              selectedStage?.taskname != "Invoice Generation" && (
                <div className="animate__animated animate__backInRight">
                  <div className="card p-3 stage-det-card">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <h4 className="text-center">
                          {selectedStage?.taskname ?? "N/A"}{" "}
                        </h4>
                        <span
                          className={
                            selectedStage?.status == "completed"
                              ? "status-completed"
                              : "status-pending"
                          }
                        >
                          {selectedStage?.status ?? ""}
                        </span>
                      </div>
                      {!showEdit ? (
                        <button
                          className="edit-stage"
                          onClick={() => handleEditDescription()}
                        >
                          <Bs.BsPencil />
                        </button>
                      ) : (
                        <button
                          className="edit-stage"
                          onClick={() => setShowEdit(false)}
                        >
                          <Ai.AiOutlineClose />
                        </button>
                      )}
                    </div>

                    {selectedStage !== null &&
                    selectedStage?.description !== null ? (
                      selectedStage?.description.map((data, index) => {
                        return (
                          <>
                            <p className="fp-badge pending" id="table-badge">
                              Schedule #{index + 1 ?? ""}
                            </p>
                            <p className="date__">
                              {data?.date != null
                                ? Utils.formatDate(data.date)
                                : ""}
                            </p>
                            {showEdit && lastIndex == index ? (
                              <Fragment>
                                <form onSubmit={handleDescriptionSubmit}>
                                  <div className="description_area">
                                    <textarea
                                      value={data?.description}
                                      onChange={(e) =>
                                        setEditedDescription(e.target.value)
                                      }
                                      placeholder="Add Description about the Stage"
                                    />
                                  </div>
                                  <div className="btn_grp_">
                                    <button type="submit" className="sub-btn">
                                      Submit
                                    </button>
                                  </div>
                                </form>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <p className="non-edited-description">
                                  {data?.schedule ??
                                    "Add Description about the Stage"}
                                </p>
                                <hr />
                              </Fragment>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <>
                        {" "}
                        <div className="fp-badge pending" id="table-badge">
                          Schedule #{selectedStage?.rescheuleStat + 1 ?? ""}
                        </div>
                        {showEdit && lastIndex != null ? (
                          <>
                            <form onSubmit={handleDescriptionSubmit}>
                              <div className="description_area">
                                <textarea
                                  onChange={(e) =>
                                    setEditedDescription(e.target.value)
                                  }
                                  placeholder="Add Description about the Stage"
                                />
                              </div>
                              <div className="btn_grp_">
                                <button type="submit" className="sub-btn">
                                  Submit
                                </button>
                              </div>
                            </form>
                          </>
                        ) : (
                          <p className="non-edited-description">
                            {selectedStage?.schedule ??
                              "Add Description about the Stage"}
                          </p>
                        )}
                      </>
                    )}

                    {/* {console.log(enquiryData?.datafill)} */}
                    {!showEdit && selectedStage.status != "completed" && (
                      <Fragment>
                        <div className="btn_grp_">
                          <Fragment>
                            {selectedStage?.stpId == 9 ? (
                              <button
                                className="complete"
                                onClick={() => handleOnBoarding()}
                                disabled={stageLoadingAction === "complete"}
                              >
                                {enquiryData?.datafill === null
                                  ? "On Board"
                                  : "Complete"}
                              </button>
                            ) : (
                              <>
                                <button
                                  className="complete"
                                  type="button"
                                  onClick={() => handleLead()}
                                >
                                  Complete
                                </button>
                              </>
                            )}
                          </Fragment>
                          <button
                            type="button"
                            className="reschedule"
                            onClick={() => handleReSchedule()}
                            disabled={stageLoadingAction === "reSchedule"}
                          >
                            Re-Schedule
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              )}

            {detailsModal &&
              detailsStage &&
              selectedStage?.taskname !== "Contract Signing" &&
              selectedStage?.taskname !== "Invoice Generation" &&
              selectedStage?.taskname !== "Customer On Boarding" &&
              selectedStage?.taskname !== "Status Closed & Live" && (
                <>
                  <div>
                    <h6 className="exe-det">
                      Details of Executive assigned for this stage. {"  "}
                      {selectedStage != null &&
                        selectedStage.cmpStatus === 0 && (
                          <>
                            <small className="re-assign" onClick={showModal}>
                              Re-Assign?
                            </small>
                          </>
                        )}
                    </h6>
                    <div class="dashboard-card card">
                      <div class="">
                        <table class="table personal-info-table p-3 m-3">
                          <tbody>
                            <tr>
                              <td>
                                <b>Name</b>
                              </td>
                              <td>
                                {selectedStage?.adminDetails?.name ?? "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Mobile</b>
                              </td>
                              <td>
                                {selectedStage?.adminDetails?.mobile ?? "N/A"}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <b>Email</b>
                              </td>
                              <td>
                                {selectedStage?.adminDetails?.email ?? "N/A"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}

            {details && (
              <>
                <div className="card mt-3 mb-3 ">
                  <form action="" className="p-3" onSubmit={sendContract}>
                    <h4 className="p-1 mt-2">Enter Contract Signing Details</h4>
                    <div className="row mt-3">
                      <div className="form-group col">
                        <label htmlFor="" className="form-label review-label">
                          Email
                        </label>
                        <div className="review-group">
                          <input
                            className="form-control review-input"
                            name="email"
                            value={initial.email}
                            onChange={handleChange}
                            disabled={!isEditing}
                          />
                          {isEditing ? (
                            <i onClick={() => setShowEdit(false)}>
                              <Ai.AiOutlineClose />
                            </i>
                          ) : (
                            <i onClick={() => setIsEditing(true)}>
                              <Bs.BsPencil />
                            </i>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="row">
                      <div className="form-group col">
                        <label className="text">Body</label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                        ></textarea>
                      </div>
                    </div> */}
                    <div className="row">
                      <div class="wrapper-file-input mt-3">
                        <div className="input-att">
                          <button className="attach-btn">
                            <a href="javascript: void(0)" className="att">
                              Upload File
                            </a>
                          </button>
                          <input
                            type="file"
                            id="upload_input"
                            name="file"
                            className="file-in"
                            onChange={(e) => handleFileChange(e)}
                          />
                        </div>
                        {selectedFileName && (
                          <p className="file-name">
                            Selected File: {selectedFileName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-3">
                      <button className="sub-btn " id="stag-sub">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}
            {selectedStage?.taskname === "Invoice Generation" && (
              <div className="card p-3">
                <h6 className="mb-3">Invoice Generation</h6>
                <form>
                  <div className="row mt-3">
                    <div className="form-group col">
                      <label htmlFor="" className="form-label review-label">
                        Email
                      </label>
                      <div className="review-group">
                        <input
                          className="form-control review-input"
                          name="email"
                          value={onBoard.email}
                          onChange={handleEdit}
                          disabled={!isEditable}
                        />

                        {isEditing ? (
                          <i onClick={() => setEmailEdit(false)}>
                            <Ai.AiOutlineClose />
                          </i>
                        ) : (
                          <i onClick={() => setIsEditable(true)}>
                            <Bs.BsPencil />
                          </i>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 d-flex">
                    {selectedStage.cmpStatus == 0 && (
                      <button
                        id="btn-btn"
                        onClick={(e) => handleDirectDebit(e)}
                      >
                        Send
                      </button>
                    )}

                    {selectedStage.cmpStatus == 1 ? (
                      <div className="text-center mx-auto alarm-div">
                        <div className="cl-co">
                          Invoice Generation Completed
                        </div>
                      </div>
                    ) : (
                      <button className="bb-btn" onClick={(e) => handleLead(e)}>
                        Complete
                      </button>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Stages;
