import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Image1 from "../../Assets/Doc/49575-O63MXV.jpg";
import Image2 from "../../Assets//Doc/9599.jpg";
import Image3 from "../../Assets/Doc/49575-O63MXV.jpg";
import { FaRegTrashAlt } from "react-icons/fa";
import { toast, Toaster } from "react-hot-toast";
import { json, useNavigate, useParams } from "react-router-dom";
import Utils from "../../Utils/utils";
import Slider from "react-slick";
import * as Ai from "react-icons/ai";
import * as Fa6 from "react-icons/fa6";
import * as Md from "react-icons/md";
import * as Go from "react-icons/go";
import { AppContext } from "../../Context/AppContext";
import { Table } from "react-bootstrap";
import { Modal } from "antd";
import { confirmAlert } from "react-confirm-alert";
import { BiTrash } from "react-icons/bi";
import { Popconfirm } from "antd";
import { elements } from "chart.js";

function Proposal({ selectedStage, enquiryData, handleLead }) {
  const navigate = useNavigate();
  const params = useParams();
  const { eventList } = useContext(AppContext);
  const dropBtnRef = useRef(null);
  const [values, setValues] = useState({
    subTotal: "",
    VAT: "",
    VATAmount: "",
    total: 0,
  });
  const [proposalData, setProposalData] = useState(null);
  const [updatePro, setUpdatePro] = useState(null);
  const [editedData, setEditedData] = useState([]);
  const {
    productList,
    getAllProposals,
    proposalList,
    deleteProposal,
    updateProposal,
    proposalSending,
    templates,
    proposalLoading,
    finalizeProposal,
    enquiryLoading,
  } = useContext(AppContext);
  const productData = {
    pId: "",
    price: null,
    tax: null,
    quantity: 1,
    subscription: "monthly",
    description: "",
  };
  const subscriptionOptions = ["monthly", "yearly"];
  const [addProposal, setAddProposal] = useState(false);
  const [addProduct, setAddProduct] = useState([productData]);
  const [selectedProposal, setSelectedProposal] = useState(0);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [editModal, setEditModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState();
  const [vatPercentage, setVatPercentage] = useState(0);
  const [elements, setElements] = useState([{ item: "", price: 0 }]);
  const [proposal, setProposal] = useState({
    email: enquiryData.sellerDetails.sellerEmail,
    clientName: enquiryData?.sellerDetails?.sellerName ?? "N/A",
    phone: enquiryData?.sellerDetails?.mobile,
    shopName: enquiryData?.sellerDetails?.shopName,
    currency: "",
    address:
      enquiryData?.sellerDetails.address1 +
      " " +
      enquiryData?.sellerDetails.address2 +
      " " +
      enquiryData?.sellerDetails.country +
      " " +
      enquiryData?.sellerDetails.town +
      " " +
      enquiryData?.sellerDetails.postcode,
  });
  const [items, setItems] = useState({
    mininumof: "",
    guestRate: "",
    softDrinksRate: "",
    guestNumber: "",
    staffingCharges: "",
    staffingCount: "",
    beforeCompulsary: "",
    waitersCount: "",
    transportationCharges: "",
    rubbishDisposal: "",
    kitchenEquipment: "",
  });
  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const handleAddItem = () => {
    const lastItem = elements[elements.length - 1];
    if (lastItem.item.trim() === "" || lastItem.price.trim() === "") {
      toast.error("Please fill in all fields before adding a new item.");
      return;
    }
    setElements([...elements, { item: "", price: "" }]);
  };

  const handleDeleteItem = (index) => {
    const newElements = [...elements];
    newElements.splice(index, 1);
    setElements(newElements);
  };

  const handleInputChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or event.target is undefined");
      return;
    }

    const { name, value } = e.target;
    setItems((prevItems) => ({
      ...prevItems,
      [name]: parseFloat(value) || 0,
    }));
  };
  const handleElementUpdate = (index, e) => {
    const { name, value } = e.target;
    const newElements = [...elements];

    if (name === "price") {
      newElements[index] = {
        ...newElements[index],
        [name]: value.startsWith("£") ? value : value,
      };
    } else {
      newElements[index] = { ...newElements[index], [name]: value };
    }

    setElements(newElements);
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const onProposalChange = (e) => {
    const { name, value } = e.target;

    setProposal((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const formedArray = [
    {
      description:
        "Dinner: Minimum of " +
        items?.mininumof +
        " guests @ £" +
        items?.guestRate +
        " per head",
      price: items?.mininumof * items?.guestRate,
    },
    {
      description:
        "Dinner: Soft Drinks Package @ £ " +
        items?.softDrinksRate +
        " per head for " +
        items?.guestNumber +
        " guests",
      price: items?.softDrinksRate * items?.guestNumber,
    },
    {
      description:
        "Dinner: Staffing charges @ £" +
        items?.staffingCharges +
        ". per head for" +
        items?.staffingCount +
        " guests",
      price: items?.staffingCharges * items?.staffingCount,
    },
    {
      description:
        items?.beforeCompulsary +
        " Compulsory Drink Waiters @" +
        items?.waitersCount +
        ".per head",
      price: items?.beforeCompulsary * items?.waitersCount,
    },
    {
      description: "Transportation Charges",
      price: items?.transportationCharges,
    },
    { description: "Rubbish Disposal", price: items?.rubbishDisposal },
    { description: "Kitchen Equipment Hire", price: items?.kitchenEquipment },
  ];

  const handleTemp = (temp) => {
    setSelectedProposal(temp.template_id);
  };

  const handleSelectProposal = (e) => {
    setSelectedPackage(e.target.value);
  };
  const eventUpdation = useCallback(() => {
    const eventTotal =
      (items?.mininumof || 0) * (items?.guestRate || 0) +
      (items?.softDrinksRate || 0) * (items?.guestNumber || 0) +
      (items?.beforeCompulsary || 0) * (items?.waitersCount || 0) +
      (items?.staffingCharges || 0) * (items?.staffingCount || 0) +
      (items?.kitchenEquipment || 0) +
      (items?.rubbishDisposal || 0) +
      (items?.transportationCharges || 0);

    const totalPrice = elements.reduce((acc, item) => {
      let price =
        typeof item?.price === "string" ? parseFloat(item?.price) : item?.price;
      return acc + price;
    }, 0);

    const inTotal = eventTotal + totalPrice;
    const taxTotal = (inTotal * vatPercentage) / 100;
    const allTotal = inTotal + values.taxAmount;

    setValues((prevValues) => ({
      ...prevValues,
      subTotal: inTotal,
      taxAmount: taxTotal,
      VAT: vatPercentage,
      total: allTotal,
    }));
  }, [items, elements, vatPercentage, values.taxAmount]);

  useEffect(() => {
    eventUpdation();
  }, [eventUpdation]);

  console.log(values, "values");
  const handleProposal = () => {
    if (proposal?.phone == undefined) {
      toast.error("Phone number Required!.");
      return;
    }
    if (vatPercentage == "") {
      toast.error("VAT Required!");
    }

    const proposalData = {
      formedArray,
      proposal,
      tempId: selectedProposal,
      elements,
      values,
      selectedPackage,
    };
    sessionStorage.setItem("proposalData", JSON.stringify(proposalData));
    sessionStorage.setItem("enquiryData", JSON.stringify(enquiryData));

    if (selectedProposal == 0) {
      toast.error("Please select a Proposal Template!");
      return;
    }
    if (selectedProposal == 1) {
      navigate(`/template/${selectedProposal}`);
    }
    if (selectedProposal == 2) {
      navigate(`/template2/${selectedProposal}`);
    }
  };
  const createProposal = () => {
    setAddProposal(!addProposal);
  };

  const handleAddModal = () => {
    setAddProposal(false);
  };

  const getDetailsofProposal = async () => {
    const payload = {
      psasId: selectedStage.jobId,
    };

    await getAllProposals(payload, {
      onSuccess: async (res) => {
        if (res?.data != null) {
          setProposalData(res.data);
        }
      },
      onFailed: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    if (!selectedStage) return;
    getDetailsofProposal();
  }, [selectedStage]);

  const hadleFinalizeProposal = async (proposal) => {
    const updatedId = proposal.psasId + 1;

    const payload = {
      psasId: proposal.psasId,
      propId: proposal.propId,
      updatedId,
      leadId: params.leadId,
    };

    await finalizeProposal(payload, {
      onSuccess: async (res) => {
        toast.success("Proposal Finalised!");
        setTimeout(() => {
          window.location.reload();
        }, []);
      },
      onFailed: (err) => {
        toast.error("Error in Fetching  Data!");
      },
    });
  };
  const handleUpdateProposal = (propId) => {
    const proposalToEdit = proposalData.find(
      (proposal) => proposal.propId === propId
    );
    setUpdatePro([proposalToEdit]);
    sessionStorage.setItem("selectedProposal", JSON.stringify(proposalToEdit));
    setEditModal(true);
  };

  const currencySymbol = updatePro != null && updatePro[0]?.currency;
  const getTotalTaxAmount = (pro) => {
    const amount = pro.quantity * pro.price;
    const taxAmount = (amount * pro.tax) / 100;
    return taxAmount;
  };
  const ConfirmDelete = (propId) => {
    confirmAlert({
      title: "Confirmation",
      message:
        "By Deleting the Proposal, associated data will be deleted from Finalized Proposal in Negotiation Stage and also remove from the database..",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteProposal(propId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDeleteProposal = async (propId) => {
    await deleteProposal(propId, {
      onSuccess: async (res) => {
        toast.success("Proposal Deleted Successfully!");
        window.location.reload();
      },
      onFailed: (err) => {
        toast.error("Something Went Wrong!");
      },
    });
  };

  const handleProposalChange = (e, proposalIndex, proIndex) => {
    const { name, value } = e.target;

    setUpdatePro((prev) => {
      const newData = [...prev];
      const productList = JSON.parse(newData[proposalIndex].productDetails);
      productList[proIndex][name] = value;

      productList[proIndex].total =
        productList[proIndex].quantity * productList[proIndex]?.price ??
        0 +
          (productList[proIndex].quantity *
            productList[proIndex].price *
            productList[proIndex].tax) /
            100;

      newData[proposalIndex].productDetails = JSON.stringify(productList);

      setEditedData(newData[0].productDetails);
      return newData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editedData.length === 0 || editedData == null) {
      toast.error("Product Details is not Changed!");
      return;
    }
    const propData = JSON.parse(sessionStorage.getItem("selectedProposal"));
    const payload = {
      propId: propData?.propId,
      sId: propData?.sId,
      psasId: propData.psasId,
      username: propData?.name,
      // currency: selectedCompany?.currency,
      email: propData?.email,
      productDetails: editedData,
    };

    await (payload,
    {
      onSuccess: async (res) => {
        toast.success("Proposal Updated and Send Successfully!");
        setEditModal(false);
        setTimeout(() => {
          window.location.reload();
        });
      },
      onFailed: (err) => {
        toast.error("Error in updating Proposal!");
      },
    });
  };
  const handleRemoveProduct = (proposalIndex, proIndex) => {
    setUpdatePro((prev) => {
      const newData = [...prev];
      const productList = JSON.parse(newData[proposalIndex].productDetails);
      productList.splice(proIndex, 1);
      const data = JSON.stringify(productList);
      setEditedData(data);
      newData[proposalIndex].productDetails = data;
      return newData;
    });
  };

  const handleProposalEditClose = () => {
    setEditModal(false);
    // window.location.reload();
  };

  return (
    <>
      <div className="container-fluid">
        <Modal
          open={editModal}
          footer={false}
          width={"70%"}
          onCancel={() => handleProposalEditClose()}
        >
          <div className="container">
            <h4 className="text-center mb-3">Edit Proposal</h4>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row new-modal-edit">
                {updatePro != null &&
                  updatePro.length !== 0 &&
                  updatePro.map((data, index) => {
                    const listOfProducts = JSON.parse(data.productDetails);

                    return (
                      <div key={index} className="row">
                        {listOfProducts != null &&
                          listOfProducts.map((pro, proIndex) => {
                            const taxAmount = getTotalTaxAmount(pro);
                            const total = pro.quantity * pro.price + taxAmount;

                            return (
                              <div className="card  mt-2 p-3" key={proIndex}>
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 col-sm-3 pro_name">
                                    <label
                                      htmlFor="Product"
                                      className="form-label"
                                    >
                                      Product
                                    </label>
                                    <select
                                      name="pId"
                                      id=""
                                      className="form-select"
                                      onChange={(e) =>
                                        handleProposalChange(e, index, proIndex)
                                      }
                                    >
                                      <option value="">Select a product</option>
                                      {productList &&
                                        productList.map((list, key) => {
                                          return (
                                            <option
                                              key={key}
                                              value={list?.pId}
                                              selected={pro.pId == list?.pId}
                                            >
                                              {list?.productName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {/* {findProductName(pro.pId)} */}
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-3">
                                    <label
                                      htmlFor="Price :"
                                      className="form-label"
                                    >
                                      Price
                                    </label>
                                    <input
                                      type="text"
                                      name="price"
                                      className="form-control"
                                      onChange={(e) =>
                                        handleProposalChange(e, index, proIndex)
                                      }
                                      placeholder="Price"
                                      value={pro?.price}
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-3">
                                    <label
                                      htmlFor="Quantity"
                                      className="form-label"
                                    >
                                      Quantity
                                    </label>
                                    <input
                                      type="text"
                                      name="quantity"
                                      className="form-control"
                                      onChange={(e) =>
                                        handleProposalChange(e, index, proIndex)
                                      }
                                      placeholder="quantity"
                                      value={pro?.quantity}
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-3">
                                    <label
                                      htmlFor="Subscription Type"
                                      className="form-label"
                                    >
                                      Subscription Type
                                    </label>
                                    <select
                                      name="subscription"
                                      className="form-select"
                                      onChange={(e) =>
                                        handleProposalChange(e, index, proIndex)
                                      }
                                      value={pro?.subscription}
                                    >
                                      {subscriptionOptions.map(
                                        (option, optionIndex) => (
                                          <option
                                            key={optionIndex}
                                            value={option}
                                          >
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-lg-3 col-md-3 col-sm-2">
                                    <label
                                      htmlFor="Tax (Percentage)"
                                      className="form-label"
                                    >
                                      VAT (%)
                                    </label>
                                    <input
                                      type="text"
                                      name="tax"
                                      className="form-control"
                                      placeholder="Tax Percentage"
                                      onChange={(e) =>
                                        handleProposalChange(e, index, proIndex)
                                      }
                                      value={pro?.tax}
                                    />
                                  </div>

                                  <div className="col-lg-3 col-md-3 col-sm-3">
                                    <label className="form-label">
                                  VAT Amount :
                                    </label>
                                    <input
                                      type="text"
                                      name=""
                                      id=""
                                      className="form-control pro-edit"
                                      value={taxAmount}
                                      disabled
                                    />
                                  </div>

                                  <div className="col-lg-12 col-sm-12 my-3">
                                    <label className="form-label">
                                      Description{" "}
                                    </label>
                                    <textarea
                                      name="description"
                                      className="form-control"
                                      value={pro?.description}
                                      onChange={(e) =>
                                        handleProposalChange(e, index, proIndex)
                                      }
                                    ></textarea>
                                  </div>
                                  <div className="col-md-10"></div>
                                  <div className="col-md-2">
                                    <i
                                      className="trasdh"
                                      onClick={() =>
                                        handleRemoveProduct(index, proIndex)
                                      }
                                    >
                                      <BiTrash />
                                    </i>
                                  </div>

                                  <span className="" disabled>
                                    Total:
                                    <b className="taxAmnt">
                                      {currencySymbol}
                                      {total}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
              <div className="text-center mx-auto mt-3 mb-3">
                {proposalLoading ? (
                  <button type="submit" className="sub-btn-new" disabled>
                    Submitting...
                  </button>
                ) : (
                  <button type="submit" className="sub-btn-new">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </Modal>
        <div className="row ">
          <div className="col-lg-6 col-md-4 col-sm-12">
            <h5 className="selc mt-3">{selectedStage?.taskname}</h5>
          </div>

          <div className="col-lg-6 col-md-4 col-sm-4 position-relative">
            <div className="btn_grp_proposal">
              {proposalData &&
                proposalData.length != 0 &&
                selectedStage.cmpStatus != 1 && (
                  <>
                    {enquiryLoading ? (
                      <button
                        className="complete complete_btn"
                        type="button"
                        onClick={() => handleLead()}
                      >
                        {selectedStage.cmpStatus != 1
                          ? "Completing"
                          : "Completed"}
                      </button>
                    ) : (
                      <button
                        className="complete complete_btn"
                        type="button"
                        onClick={() => handleLead()}
                      >
                        {selectedStage.cmpStatus != 1
                          ? "Complete"
                          : "Completed"}
                      </button>
                    )}
                  </>
                )}
              {proposalData &&
                proposalData.length != 0 &&
                addProposal === false && (
                  <button
                    type="button"
                    className="reschedule"
                    onClick={() => createProposal()}
                  >
                    Add Proposal
                  </button>
                )}
            </div>
          </div>
        </div>

        {proposalData && proposalData.length == 0 && addProposal === false && (
          <div className="add_proposal_" onClick={createProposal}>
            <h2>
              <i>
                <Fa6.FaPlus />
              </i>
              Add Proposal
            </h2>
          </div>
        )}

        {addProposal && (
          <div className="container">
            <div className="righgt" onClick={handleAddModal}>
              <button className="pro_close">
                <Ai.AiOutlineClose />
              </button>
            </div>

            <div className="slider-wrapper mt-3 mb-3">
              <h6 className="sub_title">Choose Template</h6>
              <Slider {...settings}>
                {templates != null &&
                  templates.map((temp) => (
                    <div
                      className={
                        selectedProposal !== temp.template_id
                          ? "image-slide"
                          : "image-slide selected"
                      }
                      key={temp.template_id}
                      onClick={() => handleTemp(temp)}
                    >
                      <img
                        src={
                          temp?.image != null
                            ? process.env.REACT_APP_AWS_IMAGE_URL +
                              "proposal-templates/" +
                              temp?.image
                            : Image1
                        }
                        className={`slider ${
                          temp.template_id === temp.image ? "selected" : ""
                        }`}
                        alt={"Template Image"}
                      />
                    </div>
                  ))}
              </Slider>
            </div>
            <hr className="line_dotted" />
            <div>
              <div className="form-group mb-2">
                <label htmlFor="" className="form-label">
                  Packages
                </label>
                <select
                  name=""
                  id=""
                  className="form-select"
                  value={selectedPackage}
                  onChange={handleSelectProposal}
                >
                  <option value="" disabled selected>
                    Select package
                  </option>

                  {eventList &&
                    eventList.length != 0 &&
                    eventList.map((data, key) => {
                      return (
                        <Fragment>
                          <option value={data?.packId}>{data?.title}</option>
                        </Fragment>
                      );
                    })}
                </select>
              </div>
              <form className="card new_card ">
                <b>annsdjfdgjk:</b>
                <div className="mt-3">
                  <table className="table table-bordered">
                    <tbody>
                      {selectedPackage && selectedPackage == 1 && (
                        <>
                          <tr>
                            <td>
                              Dinner: Minimum of{" "}
                              <input
                                className="normal_field"
                                value={items.mininumof}
                                name="mininumof"
                                onChange={(e) => handleInputChange(e)}
                              />
                              @{" "}
                              <input
                                className="normal_field"
                                value={items.guestRate}
                                name="guestRate"
                                onChange={(e) => handleInputChange(e)}
                              />{" "}
                              per head
                            </td>
                            <td>
                              {/* <input
                                type="text"
                                name="price"
                                className="item_input"
                                value={
                                  "£" + items?.mininumof * items?.guestRate
                                }
                              /> */}
                              <p>
                                {"£" + items?.mininumof * items?.guestRate ??
                                  "N/A"}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Dinner: Soft Drinks Package @ £{" "}
                              <input
                                className="normal_field"
                                name="softDrinksRate"
                                value={items.softDrinksRate}
                                onChange={(e) => handleInputChange(e)}
                              />
                              per head for
                              <input
                                className="normal_field"
                                name="guestNumber"
                                value={items.guestNumber}
                                onChange={(e) => handleInputChange(e)}
                              />
                              guests
                            </td>
                            <td>
                              {"£" +
                                items?.softDrinksRate * items?.guestNumber ??
                                "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Dinner: Staffing charges @ £{" "}
                              <input
                                className="normal_field"
                                name="staffingCharges"
                                value={items.staffingCharges}
                                onChange={(e) => handleInputChange(e)}
                              />
                              per head for
                              <input
                                className="normal_field"
                                placeholder="..."
                                name="staffingCount"
                                value={items.staffingCount}
                                onChange={(e) => handleInputChange(e)}
                              />
                              guests
                            </td>
                            <td>
                              <p>
                                {"£" +
                                  items?.staffingCharges *
                                    items?.staffingCount ?? "N/A"}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                className="normal_field"
                                placeholder="..."
                                name="beforeCompulsary"
                                value={items.beforeCompulsary}
                                onChange={(e) => handleInputChange(e)}
                              />
                              Compulsory Drink Waiters @{" "}
                              <input
                                className="normal_field"
                                name="waitersCount"
                                value={items.waitersCount}
                                onChange={(e) => handleInputChange(e)}
                              />
                              per head
                            </td>
                            <td>
                              <p>
                                {" "}
                                {"£" +
                                  items?.beforeCompulsary *
                                    items?.waitersCount ?? "N/A"}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Transportation Charges</td>
                            <td>
                              <input
                                type="text"
                                placeholder="£"
                                className="item_input"
                                name="transportationCharges"
                                value={items.transportationCharges}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Rubbish Disposal</td>
                            <td>
                              <input
                                type="text"
                                placeholder="£"
                                className="item_input"
                                name="rubbishDisposal"
                                value={items.rubbishDisposal}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Kitchen Equipment Hire</td>
                            <td>
                              <input
                                type="text"
                                placeholder="£"
                                className="item_input"
                                name="kitchenEquipment"
                                value={items.kitchenEquipment}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </td>
                          </tr>
                        </>
                        // <>
                        //   {particulars &&
                        //     particulars.map((data, index) => {
                        //       console.log(data, "particulars");
                        //       return <></>;
                        //     })}
                        // </>
                      )}
                      {elements.map((element, index) => (
                        <tr key={index}>
                          <td style={{ width: "80%" }}>
                            <div className="d-flex">
                              {index === elements.length - 1 && (
                                <>
                                  <button
                                    type="button"
                                    onClick={handleAddItem}
                                    style={{
                                      border: "none",
                                      background: "#fff",
                                    }}
                                  >
                                    <Ai.AiOutlinePlus className="iconsbtn" />
                                  </button>
                                </>
                              )}
                              <textarea
                                rows={1}
                                // cols={1}
                                name="item"
                                placeholder="..."
                                value={element.item}
                                className="item_input"
                                // className="p-1 m-2"
                                onChange={(e) => handleElementUpdate(index, e)}
                                style={{ resize: "none" }}
                              />
                            </div>
                            {/* {index === elements.length - 1 && (
                              <>
                                <button
                                  type="button"
                                  onClick={handleAddItem}
                                  style={{
                                    border: "none",
                                    background: "#fff",
                                  }}
                                >
                                  <Ai.AiOutlinePlus className="iconsbtn" />
                                </button>
                              </>
                            )} */}
                          </td>
                          <td style={{ width: "20%" }}>
                            <div className="d-flex">
                              <input
                                type="text"
                                name="price"
                                placeholder="£"
                                value={element.price}
                                className="item_input"
                                onChange={(e) => handleElementUpdate(index, e)}
                              />
                              {elements && elements.length > 1 && (
                                <button
                                  type="button"
                                  className="delete_btn_item"
                                  onClick={() => handleDeleteItem(index)}
                                >
                                  {" "}
                                  <Ai.AiOutlineDelete />
                                </button>
                              )}
                            </div>
                            {/* <button
                              className="delete_btn_item"
                              type="button"
                              style={{
                                border: "none",
                                background: "transparent",
                                position: "relative",
                                top: "-28px",
                              }}
                              onClick={() => handleDeleteItem(index)}
                            >
                              {" "}
                              <Ai.AiOutlineDelete
                                className="deleteiconsbtn"
                                style={{ color: "red" }}
                              />
                            </button> */}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td>
                          <b>Sub Total:</b>
                        </td>
                        <td>£{values.subTotal}</td>
                      </tr>
                      <tr>
                        <td>VAT %</td>
                        <td>
                          <input
                            className="normal_field p-1"
                            type="text"
                            name="vatPercentage"
                            min={0}
                            value={vatPercentage}
                            onChange={(e) => setVatPercentage(e.target.value)}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <b>Total Package Price:</b>
                        </td>
                        <td>£{values?.total ?? 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* {addProduct.map((item, index) => {
                  return (
                    <div className="row add_pro_data mb-3" key={index}>
                      <h6 className="mt-3 mb-3">Add Product Details</h6>
                      <div className="col-xl-6 col-md-6 col-sm-4">
                        <label>
                          Product <span>*</span>
                        </label>
                        <select
                          name="pId"
                          id="productSelect"
                          className="form-control"
                          onChange={(e) => handleChange(e, index)}
                        >
                          <option value="" disabled selected={!item?.pId}>
                            Select a product
                          </option>
                          {productList &&
                            productList.map((product) => {
                              return (
                                <option
                                  key={product.pId}
                                  value={product.pId}
                                  selected={product.pId == item?.pId}
                                >
                                  {product.productName}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="col-xl-4 col-md-6 col-sm-6">
                        <label>
                          Price(Value) <span>*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="price"
                          value={proposal?.price}
                          onChange={(e) => onPriceChange(e.target.value, index)}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-12 mt-3">
                        <label>
                          VAT %<span>*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="tax"
                          value={proposal?.tax}
                          onChange={(e) => onTaxChange(e.target.value, index)}
                          placeholder="%"
                        />
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-12 mt-3">
                        <label style={{ whiteSpace: "nowrap" }}>
                          Subscription Type<span>*</span>
                        </label>
                        <select
                          name="status"
                          className="form-control"
                          value={proposal?.subscription}
                          onChange={(e) => handleSub(e.target.value, index)}
                        >
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-12 mt-3">
                        <label>Quantity</label>
                        <div className="qty_wrapper">
                          <button
                            type="button"
                            className="qty_btn decrease-button"
                            onClick={() => decreaseCounter(index)}
                          >
                            -
                          </button>
                          <span className="qty_count" name="quantity">
                            {item?.quantity ?? 1}
                          </span>
                          <button
                            type="button"
                            className="qty_btn increase-button"
                            onClick={() => increaseCounter(index)}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      <div className="d-block mt-3">
                        <label htmlFor="" className="form-label">
                          Description
                        </label>
                        <textarea
                          name="description"
                          id=""
                          cols="30"
                          rows="3"
                          className="form-control"
                          placeholder="Write about product.."
                          value={item?.description}
                          onChange={(e) => handleChange(e, index)}
                        ></textarea>
                      </div>
                      <div className="d-flex">
                        <div className="mt-3">{renderAddButton(index)}</div>
                        <div className="ms-2">
                          {addProduct.length > 1 && (
                            <button
                              type="button"
                              className="prod_del_btn"
                              onClick={() => removeProduct(index)}
                            >
                              <FaRegTrashAlt />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })} */}
                <hr className="line_dotted" />
                <h6 className="sub_title">Client Details</h6>
                <div className="row p-2">
                  {" "}
                  <div className="col-xl-6 col-md-6 col-sm-12 mb-2">
                    <label>Client's Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="clientName"
                      min={0}
                      value={proposal.clientName}
                      onChange={(e) => onProposalChange(e)}
                    />
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12 mb-2">
                    <label>Phone</label>
                    <input
                      className="form-control"
                      type="text"
                      name="phone"
                      value={proposal?.phone}
                      onChange={(e) => onProposalChange(e)}
                    />
                  </div>
                  <div className="col-xl-12 col-md-12 col-sm-12">
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      min={0}
                      value={proposal.email}
                      onChange={(e) => onProposalChange(e)}
                    />
                  </div>
                </div>
                <div className="row p-2">
                  {" "}
                  <div className="col-md-12 col-sm-12 ">
                    <label>Address</label>
                    <br />
                    <textarea
                      className="des_text p-2"
                      name="address"
                      value={proposal.address}
                      onChange={onProposalChange}
                    ></textarea>
                  </div>
                </div>

                <button
                  type="button"
                  className="continue_btn mb-3 mt-3"
                  onClick={handleProposal}
                >
                  Continue{" "}
                  <i>
                    <Ai.AiOutlineArrowRight />
                  </i>
                </button>
              </form>
            </div>
          </div>
        )}

        {proposalLoading == true &&
        proposalData != null &&
        productData.length != 0 ? (
          <p className="loader-prop">Loading...</p>
        ) : (
          <div className="my-3" style={{ overflowY: "scroll" }}>
            {proposalData != null &&
            proposalData.length != 0 &&
            proposalData != undefined ? (
              <>
                {/* <h6 className="mt-3">Proposals</h6> */}
                <table
                  className="table table-secondary"
                  style={{ borderRadius: "5px" }}
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      {/* <th>Name</th> */}
                      <th>Email</th>
                      <th>Status</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposal &&
                      proposalData.length != 0 &&
                      proposalData.map((proposal, index) => {
                        return (
                          <Fragment>
                            <tr className="table-light">
                              <td className="cust_line_height">
                                {Utils.formatDate(proposal.created_at)}
                              </td>
                              {/* <td className="cust_line_height">
                                {proposal?.name ?? "N/A"}
                              </td> */}
                              <td className="cust_line_height">
                                {proposal?.email ?? "N/A"}
                              </td>
                              <td className="position-relative w-auto">
                                <div className="prop_status">
                                  {proposal?.completed ? (
                                    <i className="proposal_ico_ success">
                                      <Fa6.FaCircleCheck /> <p>Finalized</p>
                                    </i>
                                  ) : (
                                    <Popconfirm
                                      title="Finalize the Proposal"
                                      description="Are you sure to finalize this proposal?"
                                      onConfirm={() =>
                                        hadleFinalizeProposal(proposal)
                                      }
                                      // onCancel={cancel}
                                      okText="Yes"
                                      cancelText="No"
                                      okButtonProps={{
                                        danger: true,
                                      }}
                                    >
                                      <i className="proposal_ico_ pending">
                                        <Go.GoAlertFill /> <p>Pending</p>
                                      </i>
                                    </Popconfirm>
                                  )}
                                </div>
                              </td>
                              <td
                                className="position-relative"
                                ref={dropBtnRef}
                              >
                                <div className="btn-toggle">
                                  <a
                                    href={
                                      process.env.REACT_APP_AWS_IMAGE_URL +
                                      "proposal-docs/" +
                                      proposal?.file
                                    }
                                    target="_blank"
                                  >
                                    <button className="one-btn text-nowrap fs-6 pe-4">
                                      <Md.MdOutlineFileDownload />
                                    </button>
                                  </a>
                                  <button
                                    className="two-btn"
                                    onClick={() => toggleDropdown(index)}
                                  >
                                    <Md.MdOutlineArrowDropDown />
                                  </button>
                                </div>
                                {openDropdownIndex === index && (
                                  <ul className="table-mgt" role="menu">
                                    {proposal.completed == true ? (
                                      ""
                                    ) : (
                                      <li>
                                        <button
                                          className="btn"
                                          onClick={() =>
                                            handleUpdateProposal(
                                              proposal.propId
                                            )
                                          }
                                        >
                                          Edit
                                        </button>
                                      </li>
                                    )}

                                    <li>
                                      <button
                                        className="btn"
                                        onClick={() =>
                                          ConfirmDelete(proposal.propId)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </li>
                                    <li>
                                      {proposal.completed != true ? (
                                        <button
                                          className="btn"
                                          type="button"
                                          onClick={() =>
                                            hadleFinalizeProposal(proposal)
                                          }
                                        >
                                          Finalize
                                        </button>
                                      ) : (
                                        <p className="mb-3"> Finalized</p>
                                      )}
                                    </li>
                                  </ul>
                                )}
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                  </tbody>
                </table>
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Proposal;
