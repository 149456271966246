import React, { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";
import { async } from "q";

const useEnquiry = () => {
  const [enquiryLoading, setEnquiryLoading] = useState(false);

  const addLead = async (payload, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);

      await BaseClient.post(APIEndpoints.addLeadByAdmin, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };

  const assignSalesman = async (payload, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);

      await BaseClient.post(APIEndpoints.assignEnquiry, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };

  const updateSingleEnquiry = async (payload, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);

      await BaseClient.post(APIEndpoints.updateEnquiry, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };

  const completeStage = async (payload, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);

      await BaseClient.put(APIEndpoints.updateStage, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };
  const cancelLead = async (payload, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);
      await BaseClient.post(APIEndpoints.cancelStage, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };

  const contractSigning = async (formData, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);
      await BaseClient.post(APIEndpoints.sendAgreement, formData, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };

  const updateLeadToSeller = async (payload, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);

      await BaseClient.post(APIEndpoints.changeLeadToSeller, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };

  const updateLeadData = async (payload, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);

      await BaseClient.post(APIEndpoints.updateLeadDetails, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };

  const updateDeal = async (payload, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);

      await BaseClient.post(APIEndpoints.createDeal, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };
  const multipleAssignment = async (payload, { onSuccess, onFailed }) => {
    try {
      setEnquiryLoading(true);

      await BaseClient.post(APIEndpoints.reAssign, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setEnquiryLoading(false);
    }
  };

  return {
    addLead,
    updateDeal,
    enquiryLoading,
    assignSalesman,
    updateSingleEnquiry,
    completeStage,
    cancelLead,
    multipleAssignment,
    updateLeadToSeller,
    updateLeadData,
    contractSigning,
  };
};

export default useEnquiry;
