import { useState } from "react";
import { APIEndpoints } from "../Constants/APIEndpoints";
import BaseClient from "../Helpers/BaseClient";

const useFeatures = () => {
  const [featureList, setFeatureList] = useState([]);
  const [featureLoading, setFeatureLoading] = useState(false);
  const [featureGroupList, setFeatureGroupList] = useState(false);
  const fetchAllFeatures = async () => {
    try {
      setFeatureLoading(true);
      await BaseClient.get(APIEndpoints.getAllFeatures, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setFeatureList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting data", err);
        },
      });
    } finally {
      setFeatureLoading(false);
    }
  };
  const fetchAllFeatureGroup = async () => {
    try {
      setFeatureLoading(true);
      await BaseClient.get(APIEndpoints.getAllFeatureGroup, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setFeatureGroupList(res.data);
          }
        },
        onFailed: (err) => {
          console.log("Error in Getting data", err);
        },
      });
    } finally {
      setFeatureLoading(false);
    }
  };
  const createFeatureGroup = async (payload, { onSuccess, onFailed }) => {
    try {
      setFeatureLoading(true);

      await BaseClient.post(APIEndpoints.addFeatureGroup, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setFeatureLoading(false);
    }
  };
  const updateFeatureGroup = async (payload, { onSuccess, onFailed }) => {
    try {
      setFeatureLoading(true);

      await BaseClient.put(APIEndpoints.editFeatureGroup, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setFeatureLoading(false);
    }
  };
  const deleteFeatureGroup = async (id, { onSuccess, onFailed }) => {
    try {
      setFeatureLoading(true);

      await BaseClient.delete(APIEndpoints.deleteFeatureGroup + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setFeatureLoading(false);
    }
  };
  const createFeature = async (payload, { onSuccess, onFailed }) => {
    try {
      setFeatureLoading(true);

      await BaseClient.post(APIEndpoints.addFeature, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setFeatureLoading(false);
    }
  };
    const updateFeature = async (payload, { onSuccess, onFailed }) => {
      try {
        setFeatureLoading(true);

        await BaseClient.put(APIEndpoints.updateFeature, payload, {
          onSuccess: onSuccess,
          onFailed: onFailed,
        });
      } finally {
        setFeatureLoading(false);
      }
    };
  return {
    fetchAllFeatures,
    updateFeature,
    updateFeatureGroup,
    featureLoading,
    featureList,
    createFeature,
    featureGroupList,
    deleteFeatureGroup,
    createFeatureGroup,
    fetchAllFeatureGroup,
  };
};
export default useFeatures;
