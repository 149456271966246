import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { AppContext } from "../../Context/AppContext";
import { toast, Toaster } from "react-hot-toast";
import { PiPencilLineThin } from "react-icons/pi";
import { MdClose, MdDisabledVisible } from "react-icons/md";
import { FaRegTrashCan } from "react-icons/fa6";
import { BsTrash } from "react-icons/bs";
function EventPackages() {
  const {
    addEventPackage,
    updateEventPackage,
    getAllEvents,
    eventList,
    disableEventPackage,
    deleteEventPackage,
    packageLoading,
  } = useContext(AppContext);

  const [addPackage, setAddPackage] = useState(false);
  const [updatePackage, setUpdatePackage] = useState(false);
  const [packageName, setPackageName] = useState("");
  const [updateErrors, setUpdateErrors] = useState([]);
  const [updateState, setUpdateState] = useState(null);
  const [packageDes, setPackageDes] = useState("");
  const [count, setCount] = useState(1);
  const [errors, setErrors] = useState([]);
  const [inputValues, setInputValues] = useState([
    { courseHead: "", value: "" },
  ]);

  const validateUpdatePackage = () => {
    const validationErrors = [];
    if (!updateState?.packageName?.trim()) {
      validationErrors.push("Package Name cannot be empty");
    }

    const courseErrors =
      updateState?.courses?.map((course, index) => {
        if (!course.courseHead?.trim()) {
          return `Course #${index + 1} cannot be empty`;
        }
        return null;
      }) || [];

    return [
      ...validationErrors,
      ...courseErrors.filter((error) => error !== null),
    ];
  };

  // Handle input change for updating package
  const onChnageInfo = (e) => {
    const { name, value } = e.target;
    setUpdateState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Update course values
  const onchangeMeal = (e, index) => {
    const { name, value } = e.target;
    setUpdateState((prev) => {
      const updatedCourses = [...prev.courses];
      updatedCourses[index] = { ...updatedCourses[index], [name]: value };
      return { ...prev, courses: updatedCourses };
    });
  };

  // Add new course in update package
  const handleAddCourseInUpdate = () => {
    setUpdateState((prev) => ({
      ...prev,
      courses: [...prev.courses, { courseHead: "", value: "" }],
    }));
  };

  // Validate on every state update
  useEffect(() => {
    if (updateState) {
      setUpdateErrors(validateUpdatePackage());
    }
  }, [updateState]);

  const handleUpdate = (x) => {
    setUpdatePackage(true);
    setAddPackage(false);
    if (x) {
      setUpdateState({
        id: x.packId,
        packageName: x.title,
        packageDes: x.description,
        courses: JSON.parse(x.courses),
      });
    }
  };
  const handleRemoveCourse = (index) => {
    setUpdateState((prev) => {
      const updatedCourses = [...prev.courses];
      updatedCourses.splice(index, 1);
      return { ...prev, courses: updatedCourses };
    });
  };
  const updatePackageSubmit = async () => {
    const validationErrors = validateUpdatePackage();
    console.log(validationErrors, "calidation");
    console.log("calleda");
    if (validationErrors.length > 0) {
      setUpdateErrors(validationErrors);
      return;
    }

    const payload = {
      packId: updateState.id,
      title: updateState.packageName,
      description: updateState.packageDes,
      no_of_courses: updateState?.courses?.length,
      courses: JSON.stringify(updateState.courses),
    };

    try {
      await updateEventPackage(payload, {
        onSuccess: async () => {
          toast.success("Package Updated Successfully!");
          setUpdatePackage(false);
          await getAllEvents();
        },
        onFailed: () => {
          toast.error("Something Went Wrong!");
        },
      });
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = { ...updatedValues[index], [name]: value };
      return updatedValues;
    });

    setErrors((prevErrors) => {
      const updatedErrors = validateCourses();
      return updatedErrors;
    });
  };

  const handleIncrease = () => {
    setCount((prevCount) => prevCount + 1);
    setInputValues((prevValues) => [
      ...prevValues,
      { courseHead: "", value: "" },
    ]);
  };

  const handleDecrease = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
      setInputValues((prevValues) => prevValues.slice(0, -1));
    }
  };
  const handleAddPackage = () => {
    setAddPackage(!addPackage);
    setUpdatePackage(false);
  };
  const validateCourses = () => {
    const validationErrors = inputValues.map((input, index) => {
      if (!input.courseHead.trim()) {
        return `Course #${index + 1} cannot be empty`;
      }
      return null;
    });
    return validationErrors;
  };
  const addNewPackage = async (e) => {
    e.preventDefault();

    const validationErrors = validateCourses();

    const filteredErrors = validationErrors.filter((error) => error !== null);

    if (filteredErrors.length > 0) {
      setErrors(filteredErrors);
      return;
    }
    if (packageName == "") {
      toast.error("Please add a packagename!");
      return;
    }
    const payload = {
      title: packageName,
      description: packageDes,
      no_of_courses: inputValues.length,
      courses: JSON.stringify(inputValues),
    };

    try {
      await addEventPackage(payload, {
        onSuccess: async () => {
          toast.success("Package added Successfully!");
          setAddPackage(false);
          setPackageName("");
          setPackageDes("");
          setInputValues([{ courseHead: "", value: "" }]);
          setErrors([]);
          await getAllEvents();
        },
        onFailed: () => {
          toast.error("Something Went Wrong!");
        },
      });
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  };

  console.log(updateState, "event");
  const handleDisable = async (x) => {
    const id = x.packId;
    await disableEventPackage(id, {
      onSuccess: async (res) => {
        toast.success("Package Updated Successfully!");
        setTimeout(async () => {
          await getAllEvents();
        }, 1000);
      },
      onFailed: (err) => {
        toast.error("Something Went Wrong!");
      },
    });
  };
  const handleDelete = async (x) => {
    await deleteEventPackage(x.packId, {
      onSuccess: async (res) => {
        toast.success("Package Deleted Successfully!");
        setUpdatePackage(false);
        setTimeout(async () => {
          await getAllEvents();
        }, 1000);
      },
      onFailed: (err) => {
        toast.error("Something Went Wrong!");
      },
    });
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <Toaster position="top-center" reverseOrder={false} />
        <div className="container">
          <div div className="container-fluid m-3">
            <div className="row">
              <div className="col-md-10">
                <h6 className="head-h4">Meals</h6>
              </div>
              <div className="col-md-2">
                <button
                  className={!addPackage ? "pro-gen" : "pro-gen danger"}
                  type="button"
                  onClick={() => handleAddPackage()}
                >
                  {!addPackage ? (
                    "Add Meals"
                  ) : (
                    <>
                      <MdClose /> Close
                    </>
                  )}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {eventList && eventList.length != 0 ? (
                  <div className="card p-3 m-3 overflow">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Description</th>
                          <th scope="col" className="text-center">
                            Update
                          </th>
                          <th scope="col" className="text-center">
                            Disable
                          </th>
                          <th scope="col" className="text-center">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventList &&
                          eventList.map((x, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{x?.title ?? "N/A"}</td>
                                  <td>{x?.description ?? "N/A"}</td>
                                  <td className="text-center">
                                    <button
                                      type="button"
                                      onClick={() => handleUpdate(x)}
                                      style={{
                                        border: "none",
                                        color: "green",
                                        background: "#fff",
                                        fontWeight: "700",
                                      }}
                                    >
                                      <PiPencilLineThin />
                                    </button>
                                  </td>
                                  <td className="text-center">
                                    {x?.disabled ? (
                                      <button
                                        type="button"
                                        onClick={() => handleDisable(x)}
                                        style={{
                                          border: "1px solid green",
                                          color: "red",
                                          background: "#fff",
                                          fontWeight: "700",
                                          color: "green",
                                          borderRadius: "4px",
                                          padding: "3px",
                                        }}
                                      >
                                        Enable
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={() => handleDisable(x)}
                                        style={{
                                          border: "none",
                                          color: "red",
                                          background: "#fff",
                                          fontWeight: "700",
                                        }}
                                      >
                                        <MdDisabledVisible />
                                      </button>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <button
                                      type="button"
                                      onClick={() => handleDelete(x)}
                                      style={{
                                        border: "none",
                                        color: "red",
                                        background: "#fff",
                                        fontWeight: "700",
                                      }}
                                    >
                                      <FaRegTrashCan />
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  "No data available!"
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                {addPackage && (
                  <>
                    <h6>Add Meals</h6>

                    <form onSubmit={(e) => addNewPackage(e)}>
                      <div className="add_meals_card card">
                        {/* <button
                          className="cancel_btn"
                          onClick={() => setAddPackage(false)}
                        >
                          <MdClose />
                        </button> */}
                        <div className="row">
                          <div className="col-md-9 col-sm-12">
                            <label>Name</label>
                            <input
                              className="form-control"
                              type="text"
                              value={packageName}
                              onChange={(e) => setPackageName(e.target.value)}
                              name="packageName"
                            />
                          </div>
                          <div className="col-md-3 mb-3 col-sm-12">
                            <label>Courses</label>
                            <div className="quantity-field">
                              <button
                                className="value-button decrease-button"
                                title="Decrease"
                                onClick={handleDecrease}
                                type="button"
                              >
                                -
                              </button>
                              <div className="number">{count ?? 0}</div>
                              <button
                                className="value-button increase-button"
                                title="Increase"
                                onClick={handleIncrease}
                                type="button"
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="col-md-10">
                            {inputValues?.map((input, index) => (
                              <div key={index}>
                                <b>Course List</b>
                                <br />
                                <label>#{index + 1}</label>
                                <br />
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    name="courseHead"
                                    className="form-control mt-1 mb-1"
                                    value={input.courseHead}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                  />
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: "10px",
                                      fontSize: "13px",
                                      marginTop: "10px",
                                    }}
                                    onClick={() => handleDecrease()}
                                  >
                                    <i
                                      style={{
                                        border: "none",
                                        color: "red",
                                        background: "#fff",
                                        fontWeight: "700",
                                      }}
                                    >
                                      {" "}
                                      <BsTrash />
                                    </i>
                                  </span>
                                </div>
                                {errors[index] && (
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    {errors[index]}
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>
                          <div className="col-md-10 col-sm-12">
                            <label>Description</label>
                            <input
                              className="form-control"
                              type="text"
                              value={packageDes}
                              onChange={(e) => setPackageDes(e.target.value)}
                              name="packageDes"
                            />
                          </div>
                        </div>
                        <div className="d-flex mx-auto">
                          <button type="submit" className="add-pac mt-3">
                            {packageLoading ? "Submitting" : "   Submit"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
                {updatePackage && (
                  <>
                    <h6>Update Meals</h6>
                    <div className="card p-2">
                      <div className="row">
                        <div className="col-md-6">
                          <label>Name</label>
                          <input
                            className="form-control"
                            type="text"
                            value={updateState?.packageName || ""}
                            onChange={onChnageInfo}
                            name="packageName"
                          />
                          {updateErrors.includes(
                            "Package Name cannot be empty"
                          ) && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              Package Name cannot be empty
                            </span>
                          )}
                        </div>
                        <div className=" col-lg-6 col-md-6 col-sm-6">
                          <label>Description</label>
                          <input
                            className="form-control"
                            type="text"
                            value={updateState?.packageDes || ""}
                            onChange={onChnageInfo}
                            name="packageDes"
                          />
                        </div>

                        <div className="col-md-12 mt-1">
                          <div className="col-md-12">
                            <label>Courses</label>
                            <button
                              type="button"
                              className="add-pac"
                              onClick={handleAddCourseInUpdate}
                              style={{
                                float: "right",
                              }}
                            >
                              +
                            </button>
                          </div>
                          <br />
                          {updateState?.courses?.map((course, index) => (
                            <>
                              <div
                                className="d-flex align-items-center col-md-8 col-sm-12"
                                key={index}
                              >
                                <input
                                  className="form-control my-2"
                                  type="text"
                                  value={course.courseHead}
                                  onChange={(e) => onchangeMeal(e, index)}
                                  name="courseHead"
                                />
                                <br />

                                <button
                                  type="button"
                                  className="ms-2"
                                  onClick={() => handleRemoveCourse(index)}
                                  style={{
                                    border: "none",
                                    background: "transparent",
                                  }}
                                >
                                  <i
                                    style={{
                                      border: "none",
                                      color: "red",
                                      background: "#fff",
                                      fontWeight: "700",
                                    }}
                                  >
                                    <BsTrash />
                                  </i>
                                </button>
                              </div>
                              {updateErrors.includes(
                                `Course #${index + 1} cannot be empty`
                              ) && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  Course #{index + 1} cannot be empty
                                </span>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                      <button
                        className="add-pac mt-2"
                        onClick={updatePackageSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default EventPackages;
