import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { json, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import AdminSkelton from "../../Components/AdminSkelton";

function ViewAdmin() {
  const navigate = useNavigate();
  const { id, ppid } = useParams();

  const [adminData, setAdminData] = useState(null);
  const { adminList, allPrivilages, loading, alljobRole } =
    useContext(AppContext);

  const [previlegeData, setPrevilegeData] = useState({
    label: "",
    value: "",
  });
  useEffect(() => {
    getAdminDetails();
  }, [adminList]);

  const getAdminDetails = () => {
    if (!id || !adminList) return;

    const getSingleAdmin = adminList.find((x) => x?.paId == id);
    setAdminData(getSingleAdmin);
  };

  const findPrevilege = () => {
    if (!adminData) return;
    const previlegeId = adminData?.privilages;
    const dataList = previlegeId.split(",").map(Number);
    if (!Array.isArray(allPrivilages)) {
      setPrevilegeData([]);
      return;
    }
    const getPrevilegeNames = allPrivilages.filter((x) =>
      dataList.includes(x.ppId)
    );
    setPrevilegeData(getPrevilegeNames);
  };

  useEffect(() => {
    findPrevilege();
  }, [adminData]);

  console.log(adminData);

  const privilegeNames = adminData?.privileges.map(
    (privilege) => privilege.previlageName
  );

  console.log(adminData, "admindata");

  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Sidebar>
        {!loading ? (
          <div className="">
            <div className="card payment mastercard">
              <div className="header">
                <h4 className="text-center">{adminData?.name ?? "N/A"}</h4>
              </div>
              <div className="admin-data">
                <ul className="support-service">
                  <li>
                    <h6>
                      Role : <span>{adminData?.role ?? "N/A"}</span>
                    </h6>
                  </li>
                  <li>
                    <h6>
                      Email : <span>{adminData?.email ?? "N/A"}</span>
                    </h6>
                  </li>
                  <li>
                    <h6>
                      Mobile : <span>{adminData?.mobile ?? "N/A"}</span>
                    </h6>
                  </li>
                  <li>
                    <h6>
                      Designation : <span>{adminData?.title ?? "N/A"}</span>
                    </h6>
                  </li>
                  <li>
                    <h6>
                      {" "}
                      Previlege :<span>{privilegeNames ?? "N/A"}</span>
                    </h6>
                  </li>
                  <li></li>
                  <li>
                    <h6>
                      {" "}
                      JobRole :
                      {adminData?.jobRoles.map((role, index) => {
                        return (
                          <span key={index}>
                            {index > 0 && ", "} {role.role}
                          </span>
                        );
                      })}
                    </h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <>
            <AdminSkelton />
          </>
        )}
      </Sidebar>
    </div>
  );
}

export default ViewAdmin;
