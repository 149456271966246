import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";

function Admins() {
  const navigate = useNavigate();

  const { adminList } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const handleSort = (column) => {
    setSortBy(column);
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const sortedItems = adminList.slice().sort((a, b) => {
    if (sortOrder === "asc") {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    } else {
      return a[sortBy] < b[sortBy] ? 1 : -1;
    }
  });

  const displayedItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const filteredItems = displayedItems.filter((admin) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      admin.name.toLowerCase().includes(lowerCaseSearchTerm) ||
      admin.mobile.toLowerCase().includes(lowerCaseSearchTerm) ||
      admin.email.toLowerCase().includes(lowerCaseSearchTerm) ||
      admin.role.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container-fluid m-3">
          <div className="row mb-3 mt-3">
            <div className="col-md-4">
              <h6 className="">Admin List</h6>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="search-bar-div">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="sr-ch"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <button className="search-btn">
                    <i>
                      <Bi.BiSearch />
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <button
                className="fp-add add"
                onClick={() => navigate("/create-admin")}
              >
                + Add
              </button>
            </div>
          </div>
        </div>
        <div className="card p-3 m-3 overflow">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col" onClick={() => handleSort("name")}>
                  Name{" "}
                  {sortBy === "name" && (
                    <i className={`fa fa-sort-${sortOrder}`} />
                  )}
                </th>

                <th scope="col">Mobile </th>
                <th scope="col">Email </th>
                <th scope="col">Designation </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((admin, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{admin?.name ?? "N/A"}</td>
                  <td>{admin?.mobile ?? "N/A"}</td>
                  <td>{admin?.email ?? "N/A"}</td>
                  <td>{admin?.title ?? "N/A"}</td>
                  <td className="text-center position-relative">
                    <div className="btn-toggle">
                      <button
                        className="one-btn"
                        onClick={() => {
                          window.location.href = `/view-admin/${admin.paId}`;
                        }}
                      >
                        View
                      </button>
                      <button
                        className="two-btn"
                        onClick={() => toggleDropdown(index)}
                      >
                        <Md.MdOutlineArrowDropDown />
                      </button>
                    </div>
                    {admin?.title != "SUPER ADMIN" && (
                      <>
                        {openDropdownIndex === index && (
                          <ul className="table-mgt" role="menu">
                            <li>
                              <button
                                className="btn"
                                onClick={() => {
                                  window.location.href = `/update-admin/${admin.paId}`;
                                }}
                              >
                                Edit
                              </button>
                            </li>
                          </ul>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-center mx-auto">
          {adminList.length >= 1 && (
            <div className="pagination">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                onPageChange={handlePageChange}
                pageCount={Math.ceil(adminList.length / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"paging__link--active"}
              />
            </div>
          )}
        </div>
      </Sidebar>
    </div>
  );
}

export default Admins;
