import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoMdDownload } from "react-icons/io";
import { AppContext } from "../../Context/AppContext";
import html2canvas from "html2canvas";
import Pakwaan from "../../Assets/pakawaanlogoo.png";
import Utils from "../../Utils/utils";
import toast, { Toaster } from "react-hot-toast";
import { Table } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import SendMailTemplateModal from "../../Components/mail/SendMailTemplateModal";

function Temp2() {
  const targetRef = useRef();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    proposalSending,
    productList,
    fetchAllProposalData,
    createSingleProposal,
    eventList,
  } = useContext(AppContext);

  const [date, setDate] = useState(null);
  const [dueDate, setDueDate] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [proposalInfo, setProposalInfo] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [products, setProducts] = useState(null);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const [elementArray, setElementsArray] = useState(null);
  const [itemsArray, setItemsArray] = useState(null);
  const [showMailModal, setShowMailModal] = useState(false);
  const [parsedBody, setParsedBody] = useState(null);
  const [mailSubject, setMailSubject] = useState(null);
  const [ccValue, setCcValue] = useState(null);

  const searchParams = new URLSearchParams(location.search);
  const isSingle = searchParams.has("single");

  useEffect(() => {
    const proposeDate = new Date();
    setDate(proposeDate);
    const data = JSON.parse(sessionStorage.getItem("proposalData"));
    if (data != null) {
      setProposalInfo(data);
      setItemsArray(data.formedArray);
      setElementsArray(data.elements);
    }

    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (products) {
      const fetchProductNames = async () => {
        const names = await Promise.all(
          products.map((data) => findProductName(data.productName))
        );
      };
      fetchProductNames();
    }
  }, [products]);

  const productNamesArr = [];

  const findProductName = (pId) => {
    const foundProduct = productList.find((x) => x.pId == pId);
    const productName = foundProduct
      ? foundProduct.productName
      : "Not Available";
    if (productName) {
      productNamesArr.push(productName);
    }
    return productName;
  };

  // const handleGeneratePDF = async () => {
  //   setSending(true);
  //   if (dueDate.length === 0) {
  //     toast.error("Please Enter Due Date!");
  //     setSending(false);
  //     setError(true);

  //     return;
  //   }
  //   sessionStorage.setItem("companyName", companyName);
  //   sessionStorage.setItem("clientName", proposalInfo?.proposal?.clientName);
  //   sessionStorage.setItem(
  //     "serviceName",
  //     productNamesArr && JSON.stringify(productNamesArr)
  //   );
  //   sessionStorage.setItem("dueDate", dueDate);
  //   setShowMailModal(true);
  // };

  const handlePdf = async () => {
    if (dueDate == null || dueDate.length == 0) {
      toast.error("Due Date is Required!");
      // setSendLoading(false);
      setError(true);
      return;
    }
    sessionStorage.setItem("companyName", "Pakwaan");
    sessionStorage.setItem("clientName", proposalInfo?.proposal?.clientName);
    sessionStorage.setItem(
      "serviceName",
      productNamesArr && JSON.stringify(productNamesArr)
    );
    sessionStorage.setItem("dueDate", dueDate);
    setShowMailModal(true);
    // const pdfBlob = await html2canvas(targetRef.current).then(
    //   async (canvas) => {
    //     const imgData = canvas.toDataURL("image/png");
    //     return fetch(imgData).then((res) => res.blob());
    //   }
    // );
  };

  const handleGeneratePDF = async () => {
    const canvas = await html2canvas(targetRef.current, {
      imageTimeout: 0,
      scale: 3,
      width: targetRef.current.offsetWidth,
      height: targetRef.current.offsetHeight,
      allowTaint: true,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.95);

    // Create PDF
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(
      imgData,
      "JPEG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight()
    );

    pdf.save("proposal.pdf");
  };

  const sendMail = async () => {
    const canvas = await html2canvas(targetRef.current, {
      imageTimeout: 0,
      scale: 3,
      width: targetRef.current.offsetWidth,
      height: targetRef.current.offsetHeight,
      allowTaint: true,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.95);

    // Create PDF
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(
      imgData,
      "JPEG",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight()
    );

    const pdfBlob = pdf.output("blob");
    const enquiry = JSON.parse(sessionStorage.getItem("enquiryData"));
    const data = JSON.parse(sessionStorage.getItem("selectedStage"));
    const product = JSON.parse(sessionStorage.getItem("proposalData"));
    const details = JSON.stringify(proposalInfo?.proposal);
    const packageName =
      eventList &&
      eventList.find((x) => x.packId == proposalInfo.selectedPackage);
    const productData = {
      itemsArray,
      elementArray,
      values: proposalInfo?.values,
      packageName: packageName?.title,
      services:proposalInfo?.service,
    };

    const formData = new FormData();
    for (let i = 0; i < attachments.length; i++) {
      formData.append(`attachments`, attachments[i]);
    }

    const mailContent = {
      subject: mailSubject,
      body: parsedBody?.data ? parsedBody?.data : parsedBody,
      cc: ccValue ?? "",
    };
    console.log(productData, "prodata");
    formData.append("file", pdfBlob, "Proposal.pdf");
    formData.append("username", proposalInfo.proposal.clientName);
    formData.append("email", proposalInfo.proposal.email);
    formData.append("productDetails", JSON.stringify(productData));
    formData.append("due_date", dueDate);
    formData.append("currency", proposalInfo.currency);
    formData.append("customerDetails", details);
    formData.append("mailContent", JSON.stringify(mailContent));

    if (isSingle) {
      await createSingleProposal(formData, {
        onSuccess: async (res) => {
          toast.success("Successfully sent Proposal");
          setSending(false);
          sessionStorage.removeItem("companyName");
          sessionStorage.removeItem("clientName");
          sessionStorage.removeItem("serviceName");
          sessionStorage.removeItem("dueDate");
          await fetchAllProposalData();
          setTimeout(() => {
            navigate("/proposals");
          });
        },
        onFailed: (err) => {
          toast.error("Proposal Send Failed!");
          setSending(false);
        },
      });
      return;
    }

    formData.append("leadId", enquiry.leadId);
    await proposalSending(formData, {
      onSuccess: async (res) => {
        toast.success("Successfully sended Proposal");
        setSending(false);
        const path = sessionStorage.getItem("path");
        sessionStorage.removeItem("enquiryData");
        sessionStorage.removeItem("proposalData");
        sessionStorage.removeItem("serviceName");
        sessionStorage.removeItem("dueDate");
        setTimeout(() => {
          sessionStorage.removeItem("path");
          navigate(`/single-customer-view/${path}`);
        });
      },
      onFailed: (err) => {
        toast.error(err.errormessage);
        setSending(false);
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProposalInfo((prevProposalInfo) => ({
      ...prevProposalInfo,
      proposal: {
        ...prevProposalInfo.proposal,
        [name]: value,
      },
    }));
  };

  const onChangeDate = (e) => {
    const { name, value } = e.target;
    const selectedDate = new Date(value);
    const currentDate = new Date();

    if (selectedDate < currentDate) {
      toast.error("Selected date cannot be in the past");
      return;
    }
    setDueDate(value);
  };

  return (
    <Fragment>
      {showMailModal && (
        <SendMailTemplateModal
          showMailModal={showMailModal}
          setShowMailModal={setShowMailModal}
          sendMail={sendMail}
          parsedBody={parsedBody}
          setParsedBody={setParsedBody}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          ccValue={ccValue}
          setCcValue={setCcValue}
          title="proposal"
        />
      )}
      <div className="container-fluid">
        <Toaster position="top-center" />
        <div className="row mb-3">
          <div className="col-md-8"></div>
          <div className="col-md-4 col-sm-6 text-center">
            <button
              type="button"
              className="btn-download"
              onClick={() => handleGeneratePDF()}
            >
              <i>
                <IoMdDownload />
              </i>
            </button>

            <button className="btn-SEND" onClick={handlePdf}>
              Send
            </button>
          </div>
        </div>

        <div className="new-container p-3 m-3 mx-auto" ref={targetRef}>
          <div className="prop-for p-3 m-3">
            <div className="row mt-3 mb-3">
              <div className="col-md-6">
                <div className="mt-3">
                  <h5 className="bus_pro">Business Proposal</h5>
                </div>
              </div>
              <div className="col-md-6">
                <div className="logo-right">
                  <img src={Pakwaan} className="logo-temp p-2" />
                </div>
              </div>
            </div>

            <div className="prop-data">
              <p className="mb-1">Proposal Date: {Utils.formatDate(date)}</p>
              DueDate :
              <input
                type="date"
                onChange={(e) => onChangeDate(e)}
                name="due_date"
                value={dueDate.due_date}
                min={Utils.formatDate(new Date())}
                className="date-pro"
              />
              {/* <DatePicker
              className={
                error === true && !dueDate && dueDate.length === 0
                  ? "form-control  ms-2 alert_due"
                  : "form-control ms-2"
              }
              id="due_inp"
              startDate={startDate}
              placeholderText="Due Date"
              onChange={(date) => setDueDate(date)}
              selected={dueDate}
              dateFormat="dd-MM-yyyy"
              // isClearable
            /> */}
            </div>
            <p className="mt-2">Sales Agent</p>

            <div className="row mt-3">
              <div className="col-md-5 col-sm-12">
                <div className="row client_p">
                  <div className="col-md-10">
                    <p>Company Info</p>
                  </div>
                  {/* <div className="col-md-2">
                    {" "}
                    <i className="penciledit">
                      <BiPencil />
                    </i>
                  </div> */}
                </div>
                <div className="mt-3">
                  {/* <input
                    type="text"
                    name="companyName"
                    id="comp"
                    className="temp-input bold-head mb-3"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />

                  <textarea
                    type="text"
                    name="address"
                    id="addr"
                    className="temp-input bold-head"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    placeholder="address"
                  />
                  <input
                    type="text"
                    name="email"
                    id="addr"
                    className="temp-input bold-head"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="email"
                  />
                  <input
                    type="text"
                    name="address"
                    id="addr"
                    className="temp-input bold-head"
                    onChange={(e) => setContact(e.target.value)}
                    value={contact}
                    placeholder="address"
                  /> */}
                  <p className="adrs_det-1">
                    {/* companyDetails?.company_name ?? "N/A"
                    <br />
                    companyDetails?.address ?? "N/A" <br />
                    companyDetails?.phone ?? "N/A" <br /> companyDetails?.email
                    ?? "N/A" */}
                    Pakwaan <br />
                    Pakwaan Indian Punjabi Cuisine 1 Bridge St, Writtle,
                    Chelmsford CM1 3EY <br />
                    <a href="mailto:info@pakwaan.co.uk">info@pakwaan.co.uk</a>
                    <br />
                    +44 1245 422 891
                  </p>
                </div>
              </div>
              <div className="col-md-2 col-sm-1"></div>
              <div className="col-md-5 col-sm-12">
                <div className="col">
                  <div className="row client_p">
                    <div className="col-md-10">
                      <p>Customer Info</p>
                    </div>
                    {/* <div className="col-md-2">
                    {" "}
                    <i className="penciledit">
                      <BiPencil />
                    </i>
                  </div> */}
                  </div>
                  {/* <input
                  type="text"
                  name="clientName"
                  className="temp-input bold-head mb-3"
                  onChange={handleChange}
                  placeholder="Client Name"
                  id="comp"
                  value={proposalInfo?.proposal?.shopName}
                />

                <textarea
                  type="text"
                  name="address"
                  placeholder="Address of Client"
                  className="temp-input address"
                  onChange={handleChange}
                  value={proposalInfo?.proposal?.address}
                /> */}
                  <p className="adrs_det-1">
                    {proposalInfo?.proposal?.shopName ?? "N/A"}
                    <br />
                    {proposalInfo?.proposal?.address ?? "N/A"}
                    <br />
                  </p>
                  <b>
                    <small>Contact Info</small>
                  </b>
                  {/* <input
                  type="text"
                  name="clientName"
                  className="temp-input"
                  onChange={handleChange}
                  placeholder="Client Name"
                  value={proposalInfo?.proposal?.clientName}
                />
                <input
                  type="text"
                  name="email"
                  className="temp-input-email"
                  onChange={handleChange}
                  placeholder="Email Address"
                  value={proposalInfo?.proposal?.email}
                />

                <input
                  type="text"
                  name="phone"
                  className="temp-input-email"
                  placeholder="Phone"
                  onChange={handleChange}
                  value={proposalInfo?.proposal?.phone}
                /> */}
                  <p className="adrs_det-1">
                    {proposalInfo?.proposal?.clientName ?? "N/A"}
                    <br />

                    {proposalInfo?.proposal?.email ?? "N/A"}
                    <br />
                    {proposalInfo?.proposal?.phone ?? "N/A"}
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <small>
              <b>Your Proposal details is as follows:</b>
            </small>
            <div className="col-md-12 col-sm-12 col-lg-12 mt-2">
              <table className="table table-bordered">
                <tbody>
                  {itemsArray != null &&
                    itemsArray.length != 0 &&
                    Array.isArray(itemsArray) &&
                    itemsArray.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td>{item?.description ?? "N/A"}</td>
                            <td>£{item?.price}</td>
                          </tr>
                        </>
                      );
                    })}
                  {elementArray != null &&
                    elementArray.length != 0 &&
                    Array.isArray(elementArray) &&
                    elementArray.map((item, keyIndex) => {
                      console.log(item);
                      return (
                        <>
                          <tr>
                            <td>{item?.item ?? "N/A"}</td>
                            <td>£{item?.price}</td>
                          </tr>
                        </>
                      );
                    })}
                  {proposalInfo && proposalInfo?.service?.map((data, index) => {
                    return (
                      <>
                        {data && (
                          <tr>
                            <td>{data?.courseHead ?? "N/A"}</td>
                            <td>£{data?.value ?? 0}</td>
                          </tr>
                        )}
                      </>
                    )
                  })}
                  <tr>
                    <td
                      style={{
                        textAlign: "right",
                        alignItems: "right",
                      }}
                    >
                      <b>Sub Total</b>
                    </td>
                    <td>{proposalInfo?.values?.subTotal ?? "N/A"}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "right",
                        alignItems: "right",
                      }}
                    >
                      <b>VAT %</b>
                    </td>
                    <td>{proposalInfo?.values?.VAT ?? "N/A"}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "right",
                        alignItems: "right",
                      }}
                    >
                      <b>VAT Amount</b>
                    </td>
                    <td>£{proposalInfo?.values?.taxAmount ?? "N/A"}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "right",
                        alignItems: "right",
                      }}
                    >
                      <b>Total</b>
                    </td>
                    <td>
                      £
                      {proposalInfo?.values?.subTotal +
                        proposalInfo?.values?.taxAmount ?? "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p
              style={{
                fontSize: "10px",
                marginTop: "10px",
                marginLeft: "15px",
              }}
            >
              If you have any questions concerning this quotation contact +44
              1245 422 891
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-6 col-sm-12 mb-3 m-3">
              <label className="p-2">
                <b>Add Extra Attachments</b>
              </label>
              <input
                type="file"
                className="image_input m-2"
                onChange={(e) => setAttachments(e.target.files)}
                multiple
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Temp2;
