import React, { Fragment, useContext, useEffect, useState } from "react";
import * as Io from "react-icons/io";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { AppContext } from "../../Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import Utils from "../../Utils/utils";

const formatString = (template, ...values) => {
  return template.replace(/\{(\d+)\}/g, (match, index) => values[index]);
};

function SendMailTemplateModal({
  showMailModal,
  setShowMailModal,
  sendMail,
  sendLoading,
  parsedBody,
  setParsedBody,
  mailSubject,
  setMailSubject,
  ccValue,
  setCcValue,
  title,
  data,
}) {
  const { mailTemplates, proposalSending } = useContext(AppContext);
  const [proposalMail, setProposalMail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    if (!mailTemplates || !title) return;

    setContentLoading(true);
    try {
      const mailtmeplateData =
        mailTemplates.length != 0 &&
        mailTemplates.find((x) => x.module == title);
      mailtmeplateData && setProposalMail(mailtmeplateData);
      if (!proposalMail) return;
      formatAndAssign(formatAndAssign);
    } finally {
      setContentLoading(false);
    }
  }, [mailTemplates]);
  console.log(data, "eree");
  const formatAndAssign = (proposalMail) => {
    setParsedBody(proposalMail?.body);
  };

  useEffect(() => {
    if (!proposalMail) return;
    const body = proposalMail?.body;
    const subject = proposalMail?.subject;
    let username = sessionStorage.getItem("clientName");

    let companyname = "Pakwaan";
    const productData = sessionStorage.getItem("serviceName");
    const parsedProduct = productData && JSON.parse(productData);

    switch (title) {
      case "proposal":
        {
          // const serviceName =
          //   parsedProduct && Array.isArray(parsedProduct)
          //     ? parsedProduct.join(",")
          //     : "Product";
          const formattedMessage = formatString(
            body,
            username,
            username,
            companyname
          );
          setParsedBody(formattedMessage);
          const alteredSubject = formatString(subject, username, companyname);
          setMailSubject(alteredSubject);
        }
        break;

      case "contract":
        {
          const contractDate = sessionStorage.getItem("contractDate");
          const packName = data?.selectedDeal?.packageName;

          // const prodName =
          //   data?.selectedDeal?.productName ||
          //   data?.productDetails[0]?.productName;
          // const prodName = service[0].productName;
          const date = new Date(contractDate);
          const formattedMessage = formatString(
            body,
            username,
            companyname,
            Utils.formatDate(date)
          );
          setParsedBody(formattedMessage);

          const alteredSubject = formatString(subject, companyname, packName);
          console.log(alteredSubject, "altered");
          setMailSubject(alteredSubject);
        }
        break;

      case "invoice":
        {
          const customerName = data?.payload?.cust_name || data?.cust_name;
          const service = JSON.parse(
            data?.payload?.productDetails || data.productDetails
          );
          const prodName = service[0]?.productName;
          const totalDue = data?.payload?.total || data?.total;
          const formattedMessage = formatString(
            body,
            customerName,
            companyname,
            totalDue
          );
          setParsedBody(formattedMessage);
          console.log(subject);
          const alteredSubject = formatString(subject, prodName, companyname);
          setMailSubject(alteredSubject);
        }
        break;

      default:
        setParsedBody("mail body here");
        setMailSubject("subject here");
        break;
    }
  }, [proposalMail]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setParsedBody({ data });
    // console.log({ event, editor, data });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!parsedBody || parsedBody.length === 0) {
      toast.error("Mail body is required!");
      return;
    }
    if (!mailSubject || mailSubject.length === 0) {
      toast.error("Mail Subject is required!");
      return;
    }
    try {
      setIsLoading(true);
      await sendMail();
    } finally {
      setIsLoading(false);
    }
  };
  console.log(parsedBody, "hi");
  return (
    <Fragment>
      <Toaster position="top-center" />
      <div className="mail_modal_wrapper">
        <div className="mail_modal">
          {!contentLoading ? (
            <Fragment>
              <button
                type="button"
                className="close_btn_mail_modal"
                onClick={() => setShowMailModal(false)}
              >
                <Io.IoMdCloseCircle />
              </button>
              <h1 className="mail_title__">{title ?? "PAKWAAN"} Mail</h1>
              <form onSubmit={(e) => onFormSubmit(e)}>
                <div className="form-group">
                  <label htmlFor="" className="form-label fp-label">
                    CC <span>(optional)</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    onChange={(e) => setCcValue(e.target.value)}
                    value={ccValue}
                  />
                </div>
                <div className="form-group mt-3">
                  <lable className="form-label fp-label">
                    Subject <span>*</span>
                  </lable>
                  <input
                    type="text"
                    name="subject"
                    id=""
                    className="fp-input form-control"
                    onChange={(e) => setMailSubject(e.target.value)}
                    value={mailSubject}
                  />
                </div>
                <div className="form-group mt-3">
                  <lable className="form-label fp-label">
                    Mail body <span>*</span>
                  </lable>

                  {parsedBody != null && (
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: [
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "|",
                          "link",
                          "insertTable",
                          "mediaEmbed",
                          "|",
                          "bulletedList",
                          "numberedList",
                          "indent",
                          "outdent",
                        ],
                        plugins: [
                          Bold,
                          Essentials,
                          Heading,
                          Indent,
                          IndentBlock,
                          Italic,
                          Link,
                          List,
                          MediaEmbed,
                          Paragraph,
                          Table,
                          Undo,
                        ],
                        initialData: `${parsedBody != null ? parsedBody : ""}`,
                      }}
                      onChange={handleEditorChange}
                    />
                  )}
                </div>
                <button
                  type="submit"
                  className="send_btn_new mt-3"
                  disabled={isLoading}
                >
                  {!isLoading ? "Send" : "Loading..."}
                </button>
              </form>
            </Fragment>
          ) : (
            "Loading.."
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default SendMailTemplateModal;
