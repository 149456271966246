import React, { useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { Badge } from "react-bootstrap";
import Switch from "react-input-switch";

function AddStage() {
 const [value, setValue] = useState("yes");
 const [numberOfBoxes, setNumberOfBoxes] = useState(1);

 const handleAddBox = () => {
   setNumberOfBoxes((prev) => prev + 1);
 };

 const handleSubmit = (event) => {
   event.preventDefault();
  };
    const handleRemoveBox = (index) => {
      setNumberOfBoxes((prev) => prev - 1);
    };

  return (
    <div>
      <div className="container-fluid">
        <Sidebar>
          <div div className="container">
            <h6 className="head-h4 text-center">Product Details</h6>
            <div className="box">
              <div className="card p-3 mb-3 mt-3">
                <ul className="support-service">
                  <li>
                    <h6>
                      Product Name: <span>Waiter App</span>
                    </h6>
                  </li>
                  <li>
                    <h6>
                      Subscription Type : <span>Yearly/Monthly</span>
                    </h6>
                  </li>
                  <li>
                    <h6>
                      Description :
                      <span>
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate the visual
                        form of a document or a typeface without relying on
                        meaningful content. Lorem ipsum may be used as a
                        placeholder before final copy is available
                      </span>
                    </h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <h6 className="head-h4 text-center">Product Stages</h6>
          <div className="box">
            {[...Array(numberOfBoxes)].map((_, index) => (
              <div key={index} className="card p-3 m-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group row m-2 mb-3">
                    <div className="float-field">
                      <button
                        className="close-btn"
                        onClick={() => handleRemoveBox(index)}
                      >
                        X
                      </button>
                      <p className="align-right">
                        <b> Priortize : </b>
                        <Switch
                          on="yes"
                          off="no"
                          value={value}
                          onChange={setValue}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="form-group row m-2 mb-3">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Stage Name
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="stage-name"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter Stage Name"
                      />
                    </div>
                  </div>
                  <div className="form-group row m-2 mb-3">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Description
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="description"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Description of the stage"
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button className="stage-btn" type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            ))}
            <div className="container">
              <button
                className="btn btn-success align-right mb-3"
                onClick={handleAddBox}
              >
                + Add
              </button>
            </div>
          </div>
        </Sidebar>
      </div>
    </div>
  );
}

export default AddStage;
