import React, { useContext, useEffect, useState } from "react";
import Dashboard from "../../Components/Sidebar";
import * as Md from "react-icons/md";
import * as Ai from "react-icons/ai";
import Sidebar from "../../Components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
function Products({ jobRole }) {
  const navigate = useNavigate();

  const { deleteProduct, getAllProductData } = useContext(AppContext);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const { productList, productLoading } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [productList]);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const ConfirmDelete = (pId) => {
    confirmAlert({
      title: "Confirmation",
      message:
        "By Deleting the product associated data will be deleted from the database.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteProduct(pId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDeleteProduct = async (pId) => {
    await deleteProduct(pId, {
      onSuccess: async (res) => {
        toast.success("Product Deleted Successfully!");
        await getAllProductData();
      },
      onFailed: (err) => {
        toast.error("Something Went Wrong!");
        console.log(err);
      },
    });
  };
  console.log("productList", productList);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productList.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container-fluid m-3">
          <h6 className="head-h4">Products</h6>
          <p>
            List of Products
            <span>
              <button
                className="fp-add"
                onClick={() => navigate("/createproduct")}
              >
                + Add
              </button>
            </span>
          </p>
        </div>
        <div className="card p-3 m-3 overflow">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Product Name</th>
                <th scope="col">Price per Month</th>
                <th scope="col">Price per Year</th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems != null &&
                Array.isArray(currentItems) &&
                currentItems.map((product, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{product.productName ?? "N/A"}</td>
                      <td>£{product.price_monthly ?? "N/A"}</td>
                      <td>£{product.price_yearly ?? "N/A"}</td>
                      <td className="text-center position-relative">
                        <div className="btn-toggle">
                          <button className="one-btn">Action</button>
                          <button
                            className="two-btn"
                            onClick={() => toggleDropdown(index)}
                          >
                            <Md.MdOutlineArrowDropDown />
                          </button>
                        </div>
                        {openDropdownIndex === index && (
                          <ul className="table-mgt" role="menu">
                            <li>
                              <button
                                className="btn"
                                onClick={() => {
                                  window.location.href = `/viewproduct/${product.pId}`;
                                }}
                              >
                                View
                              </button>
                            </li>
                            <li>
                              <button
                                className="btn"
                                onClick={() => {
                                  window.location.href = `/editproduct/${product.pId}`;
                                }}
                              >
                                Edit
                              </button>
                            </li>
                            <li>
                              <button
                                className="btn"
                                onClick={() => {
                                  ConfirmDelete(product.pId);
                                }}
                              >
                                Delete
                              </button>
                            </li>
                          </ul>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="text-center mx-auto">
          {productList.length >= 1 && (
            <div className="pagination">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                onPageChange={handlePageChange}
                pageCount={Math.ceil(productList.length / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"paging__link--active"}
              />
            </div>
          )}
        </div>
      </Sidebar>
    </div>
  );
}

export default Products;
