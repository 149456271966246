import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import * as Bs from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import * as Ai from "react-icons/ai";
import { Modal } from "antd";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

function FeatureGroup() {
  const navigate = useNavigate();
  const [addModal, setAddModal] = useState(false);
  const [initialState, setInitialState] = useState({
    group_name: "",
  });
  const {
    fetchAllFeatureGroup,
    featureLoading,
    createFeatureGroup,
    featureGroupList,
    updateFeatureGroup,
    deleteFeatureGroup,
  } = useContext(AppContext);

  const [isModal, setIsModal] = useState(false);
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [initialData, setInitialData] = useState("");
  const handleOk = () => {
    setIsModal(false);
  };
  const handleCancel = () => {
    setIsModal(false);
  };
  const getGroupName = (id) => {
    const findData = featureGroupList.find((x) => x.id === id);
    setInitialData(findData);
    setIsModal(true);
    return findData;
  };

  useEffect(() => {
    fetchAllFeatureGroup();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setInitialState((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const changeEdit = (e) => {
    const { name, value } = e.target;

    setInitialData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleSelectChange = (value) => {
    setSelectedGroupName(value);
    setInitialState((prev) => ({ ...prev, group_name: value }));
  };
  const handleEditGroup = async (e) => {
    e.preventDefault();
    const payload = {
      groupId: initialData.id,
      groupName: initialData.group_name,
    };
    await updateFeatureGroup(payload, {
      onSuccess: async (res) => {
        toast.success("Feature Updated Succsefully!");
        fetchAllFeatureGroup();
        setTimeout(() => {
          navigate("/feature-group");
          setIsModal(false);
        });
      },
      onFailed: (err) => {
        toast.error(err.errormessage);
      },
    });
  };
  const ConfirmDelete = (id) => {
    confirmAlert({
      title: "Confirmation",
      message:
        "You are about to delete this Feature Group. Please be aware that this action will also permanently delete all corresponding details and data associated with this Group. Are you sure you want to proceed with the deletion?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteFeatureGroup(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDeleteFeatureGroup = async (id) => {
    await deleteFeatureGroup(id, {
      onSuccess: async (res) => {
        toast.success("Feature Group Successfully Deleted!");
        await fetchAllFeatureGroup();
      },
      onFailed: (err) => {
        toast.error("Something Went Wrong!");
        console.log(err);
      },
    });
  };

  const handleAddModal = () => {
    setAddModal(true);
  };
  const closeModal = () => {
    setAddModal(false);
  };
  const handleGroup = async (e) => {
    e.preventDefault();
    if (initialState.group_name == null && initialState.group_name === "") {
      toast.error("Please Add a GroupName!");
      return;
    }
    console.log(typeof initialState.group_name);
    const payload = {
      group_name: initialState.group_name,
    };
    await createFeatureGroup(payload, {
      onSuccess: async (res) => {
        toast.success("Feature Group Created Succsefully!");
        fetchAllFeatureGroup();
        setTimeout(() => {
          window.location.reload();
        });
      },
      onFailed: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <div>
      <Sidebar>
        <Toaster position="top-center" reverseOrder={false} />
        <Modal open={isModal} onOk={handleOk} onCancel={handleCancel}>
          <div className="mx-auto">
            <form>
              <label htmlFor="" className="form-label review-label">
                Group Name
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                name="group_name"
                value={initialData?.group_name}
                onChange={changeEdit}
              />
            </form>
            <div className="button-div text-center mx-auto">
              <button
                className="sub-btn"
                type="submit"
                onClick={handleEditGroup}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
        <Modal open={addModal} onOk={handleOk} onCancel={closeModal}>
          <div className="mx-auto">
            <form onClick={handleGroup}>
              <label htmlFor="" className="form-label review-label mt-3 mb-3">
                Create a New Feature Group
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                value={initialState.group_name}
                onChange={(e) => handleChange(e)}
                name="group_name"
              />

              <div className="button-div text-center mx-auto">
                {featureLoading ? (
                  <button className="sub-btn" type="submit" disabled>
                    Submitting...
                  </button>
                ) : (
                  <button className="sub-btn" type="submit">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </Modal>
        <div div className="container-fluid m-3">
          <h6 className="head-h4">Feature Group</h6>{" "}
          <button className="fp-add-grp" onClick={() => handleAddModal()}>
            + Add
          </button>
        </div>
        <div className="card p-3 m-3 overflow feature-table">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Group Name</th>
                <th scope="col" className="text-center">
                  Edit
                </th>
                <th scope="col" className="text-center">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {featureGroupList != null &&
                Array.isArray(featureGroupList) &&
                featureGroupList.map((data, index) => (
                  <>
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{data?.group_name ?? "N/A"}</td>

                      <td className="text-center ">
                        <button
                          className="icon-act"
                          onClick={() => {
                            getGroupName(data.id);
                          }}
                        >
                          <Ai.AiOutlineEdit />
                        </button>
                      </td>
                      <td className="text-center ">
                        <button
                          className="icon-act"
                          onClick={() => {
                            ConfirmDelete(data.id);
                          }}
                        >
                          <Bs.BsTrash />
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>
      </Sidebar>
    </div>
  );
}

export default FeatureGroup;
