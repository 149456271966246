import { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useSellerEnquiries = () => {
  const [allSellerEnquiries, setAllSellerEnquiries] = useState([]);

  const [sellerEnquiriesLoading, setSellerEnquiriesLoading] = useState(false);

  //! filter by company id
  // const fetchAllSellerEnquiries = async () => {
  //   try {
  //     setSellerEnquiriesLoading(true);
  //     await BaseClient.get(APIEndpoints.getAllSellerEnquiries, {
  //       onSuccess: (res) => {
  //         const data = JSON.parse(sessionStorage.getItem(""));

  //         if (Array.isArray(res.data) && res.data != null) {
  //           const enquiries = res.data;

  //           const filteredList =
  //             enquiries &&
  //             enquiries.filter((x) => x?.companyDetails?.companyId == data?.id);
  //           setAllSellerEnquiries(filteredList);
  //         }
  //       },
  //       onFailed: (err) => {
  //         console.log(err);
  //       },
  //     });
  //   } finally {
  //     setSellerEnquiriesLoading(false);
  //   }
  // };

  const fetchAllSellerEnquiries = async () => {
    try {
      setSellerEnquiriesLoading(true);
      await BaseClient.get(APIEndpoints.getAllSellerEnquiries, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data != null) {
            setAllSellerEnquiries(res.data);
          }
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } finally {
      setSellerEnquiriesLoading(false);
    }
  };
  const getDetailsOfUser = async (payload, { onSuccess, onFailed }) => {
    setSellerEnquiriesLoading(true);
    try {
      await BaseClient.post(APIEndpoints.getAllDealsofClient, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setSellerEnquiriesLoading(false);
    }
  };
  const DealSending = async (payload, { onSuccess, onFailed }) => {
    setSellerEnquiriesLoading(true);
    try {
      await BaseClient.post(APIEndpoints.sendDeal, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setSellerEnquiriesLoading(false);
    }
  };
  const updateDealData = async (payload, { onSuccess, onFailed }) => {
    setSellerEnquiriesLoading(true);
    try {
      await BaseClient.put(APIEndpoints.updateDeals, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setSellerEnquiriesLoading(false);
    }
  };

  const finalizeDeal = async (payload, { onSuccess, onFailed }) => {
    setSellerEnquiriesLoading(true);
    try {
      await BaseClient.put(APIEndpoints.finalizeDeal, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setSellerEnquiriesLoading(false);
    }
  };
  const deleteDealbyId = async (dealId, leadId, { onSuccess, onFailed }) => {
    try {
      setSellerEnquiriesLoading(true);

      await BaseClient.delete(
        APIEndpoints.deleteDeal + `/${dealId}` + `/${leadId}`,
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      setSellerEnquiriesLoading(false);
    }
  };

  return {
    fetchAllSellerEnquiries,
    allSellerEnquiries,
    updateDealData,
    deleteDealbyId,
    getDetailsOfUser,
    sellerEnquiriesLoading,
    finalizeDeal,
    DealSending,
  };
};

export default useSellerEnquiries;
