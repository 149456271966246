import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../Components/Sidebar";
import { useContext } from "react";
import { AppContext } from "../../../Context/AppContext";
import ReactPaginate from "react-paginate";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";

function Merchant() {
  const navigate = useNavigate();
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const { sellerList } = useContext(AppContext);
  const [merchantList, setMerchantList] = useState(null);
  const [filteredMerchantList, setFilteredMerchantList] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (sellerList !== null && Array.isArray(sellerList)) {
      const sortedSellerList = sellerList
        .slice()
        .sort((a, b) => a.shopName.trim().localeCompare(b.shopName.trim()));
      setMerchantList(sortedSellerList);
    }
  }, [sellerList]);

  const filteredMerchants = merchantList?.filter((merchant) => {
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    return (
      merchant.shopName.toLowerCase().includes(lowercaseSearchTerm) ||
      merchant.name.toLowerCase().includes(lowercaseSearchTerm) ||
      merchant.email.toLowerCase().includes(lowercaseSearchTerm)
    );
  });

  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container-fluid m-3">
          <div className="row">
            <div className="col-md-8">
              <h6 className="head-h4">Merchant Details</h6>
              <p>
                List of Merchants
                <span>
                  {/* <button
                  className="fp-add"
                  onClick={() => {
                    navigate("/addsubscriber");
                  }}
                >
                  + Add
                </button> */}
                </span>
              </p>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="">
                  <input
                    type="text"
                    placeholder="Search from the table"
                    className="new-search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />

                  <button className="search-btn">
                    <i>
                      <Bi.BiSearch />
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card p-3 m-3 overflow">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Shop Name</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col" className="text-center">
                  Status
                </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredMerchants != null &&
                filteredMerchants.map((row, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>{row?.shopName ?? "N/A"}</td>
                    <td>{row?.name ?? "N/A"}</td>
                    <td>{row?.email ?? "N/A"}</td>
                    <td className="text-center">
                      <div
                        className={
                          row.partner_seller_status === 0
                            ? "fp-badge failed"
                            : "fp-badge success"
                        }
                      >
                        {row?.partner_seller_status === 0
                          ? "Trial-User"
                          : "Verified"}
                      </div>
                    </td>
                    <td className="text-center position-relative">
                      <div className="btn-toggle">
                        <button
                          className="one-btn"
                          onClick={() => {
                            window.location.href = `/view-merchant/${row.id}`;
                          }}
                        >
                          View
                        </button>
                        <button
                          className="two-btn"
                          onClick={() => toggleDropdown(index)}
                        >
                          <Md.MdOutlineArrowDropDown />
                        </button>
                      </div>
                      {openDropdownIndex === index && (
                        <ul className="table-mgt" role="menu">
                          <li>
                            <button
                              className="btn"
                              onClick={() => {
                                window.location.href = `/view-merchant/${row.id}`;
                              }}
                            >
                              View
                            </button>
                          </li>
                          {/* <li>
                            <button
                              className="btn"
                              onClick={() => navigate("/edit-merchant")}
                            >
                              Edit Subscriber
                            </button>
                          </li> */}
                        </ul>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* <div className="text-center mx-auto">
          {sellerList.length >= 1 && (
            <div className="pagination">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                onPageChange={handlePageChange}
                pageCount={Math.ceil(sellerList.length / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"paging__link--active"}
              />
            </div>
          )}
        </div> */}
      </Sidebar>
    </div>
  );
}

export default Merchant;
