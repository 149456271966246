import React from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";

function ViewFeature() {
  const navigate = useNavigate();
  
  return (
    <div>
      <Sidebar>
        <div div className="container-fluid m-3">
          <h4 className="text-center">View Feature</h4>
        </div>
        <div className="container">
          <div className="card m-3">
            <form action="" className="box mt-3">
              <div className="row mt-3">
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Group Name
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>

                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Feature Name
                  </label>
                  <div className="review-group">
                    <input className="form-control review-input" type="text" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <label className="text">Description</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                  ></textarea>
                </div>
              </div>

              <div className="button-div text-center mx-auto">
                <button
                  className="fp-button m-3"
                  onClick={() => {
                    navigate("/edit-feature");
                  }}
                >
                  Edit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default ViewFeature;
