import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Admin from "../../Assets/lottie/Group 1.png";
import * as Md from "react-icons/md";
import { Button, Modal } from "antd";
import { FaCheck } from "react-icons/fa";
import Client from "../../Assets/client.jpeg";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";

function Chat() {
  const [chatMessages, setChatMessages] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { requestToken, sendMessage, newChat, setNewChat, chats } =
    useContext(AppContext);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChat = () => {
    setChatMessages(true);
  };
  const handleDecline = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const userData = "123456";
    const ARN = "arn:aws:ivschat:us-east-1:300996695197:room/Tr3CkjDLKfQw";
    if (userData != null && ARN != null) {
      requestToken({
        userId: userData,
        roomIdentifier: ARN,
      });
    }
    console.log("userData", userData);
    console.log("ARN", ARN);
  }, []);

  return (
    <div className="m-0 p-0 blk">
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <label className="form-label review-label">
          Reason for Decline Chat Request
        </label>
        <br />
        <input type="text" name="reason" className="form-decline p-2" />
        <button className="decline_btn_sub">Submit</button>
      </Modal>
      <div className="container-fluid">
        <div className="row mb-3 mt-3">
          <div className="col-lg-4 col-md-5 col-sm-12">
            <div className="m-1 p-3 chat_heads">
              <h5 className="mb-3">All Messages</h5>
              <Tabs>
                <TabList>
                  <Tab>All</Tab>
                  <Tab>Accepted</Tab>
                  <Tab>Declined</Tab>
                </TabList>

                <TabPanel>
                  <div className="card mb-2 col-md-12 p-1 col-sm-12">
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="PROFILE">
                          <img
                            src={Client}
                            alt="Profile image"
                            className="img-pr"
                            height={60}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mt-3">
                          <b>Parvathy Gopan</b>
                          <br />
                          <p className="message-para">
                            Do You Want to Accept this Chat?
                          </p>
                        </div>

                        <i className="accept_btn">
                          <FaCheck />
                        </i>
                        <i className="decline_btn" onClick={handleDecline}>
                          {" "}
                          <Md.MdClose />
                        </i>
                      </div>
                      <div className="col">
                        <div className="time">
                          <small className="">12-20-2022</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2 col-md-12 p-1 col-sm-12">
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="PROFILE">
                          <img
                            src={Client}
                            alt="Profile image"
                            className="img-pr"
                            height={60}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mt-3">
                          <b>Parvathy Gopan</b>
                          <br />
                          <p className="message-para">
                            Do You Want to Accept this Chat?
                          </p>
                        </div>

                        <i className="accept_btn">
                          <FaCheck />
                        </i>
                        <i className="decline_btn" onClick={handleDecline}>
                          {" "}
                          <Md.MdClose />
                        </i>
                      </div>
                      <div className="col">
                        <div className="time">
                          <small className="">12-20-2022</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="card" onClick={handleChat}>
                    <div className="d-flex chat-heads">
                      <div className="PROFILE">
                        <img
                          src={Admin}
                          alt="Profile image"
                          className="img-pr"
                          height={60}
                        />
                      </div>
                      <div className="mt-3">
                        <b>Sambath Chandran.C</b>
                        <br />
                        <p className="message-para">
                          This is a message received form the client.
                        </p>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="card" onClick={handleChat}>
                    <div className="d-flex chat-heads">
                      <div className="PROFILE">
                        <img
                          src={Client}
                          alt="Profile image"
                          className="img-pr"
                          height={60}
                        />
                      </div>
                      <div className="mt-3">
                        <b>Nivedhya Chandran</b>
                        <br />
                        <p className="message-para">
                          This is a message received form the client.
                        </p>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
          <div className="col-lg-8 col-md-7 col-sm-12">
            {chatMessages && (
              <div className="m-1 p-3">
                <h5 className="mb-3">Chats</h5>
                <div className="card">
                  <div className="flex">
                    <div className="PROFILE">
                      <img
                        src={Client}
                        alt="Profile image"
                        className="img-pr"
                        height={60}
                      />
                    </div>
                    <div className="text-name mt-3">
                      <b>Mark Antony</b>
                      <br />
                      <small>+91 963354545</small>
                    </div>
                  </div>
                  <hr />
                  <div className="m-2 chatAreaScroll">
                    <div className="card msg-card-client mb-3">
                      <p>
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate the visual
                        form of a document or a typeface without relying on
                        meaningful content.
                      </p>
                    </div>
                    <div className="card msg-card-admin">
                      <p>
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate the visual
                        form of a document or a typeface without relying on
                        meaningful content.
                      </p>
                    </div>
                    <div className="card msg-card-client mb-3">
                      <p>
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate the visual
                        form of a document or a typeface without relying on
                        meaningful content.
                      </p>
                    </div>
                    <div className="card msg-card-admin">
                      <p>
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate the visual
                        form of a document or a typeface without relying on
                        meaningful content.
                      </p>
                    </div>
                    <div className="card msg-card-client mb-3">
                      <p>
                        In publishing and graphic design, Lorem ipsum is a
                        placeholder text commonly used to demonstrate the visual
                        form of a document or a typeface without relying on
                        meaningful content.
                      </p>
                    </div>

                    <div class="send-box">
                      <form action="">
                        <textarea
                          type="text"
                          class="form-control"
                          autoFocus
                          aria-label="message…"
                          placeholder="Write message…"
                        />
                        <button type="button">
                          <Md.MdOutlineSend />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
