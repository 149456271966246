import React, { Fragment, useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import print from "../../Assets/print.png";
import PDF from "../../Assets/pdf.png";
import { Badge, Table } from "react-bootstrap";
import "../../Style/Style.css";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import * as Fa6 from "react-icons/fa6";
import * as Fi from "react-icons/fi";
import * as Io from "react-icons/io";
import * as Md from "react-icons/md";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import Utils from "../../Utils/utils";
import { toast } from "react-toastify";
import { Modal, Popconfirm } from "antd";

function Proposals() {
  const navigate = useNavigate();
  const {
    proposalsList,
    deleteProposal,
    setSelectedContractProduct,
    fetchAllProposalData,
    selectedContractProduct,
    proposalContracts,
    proposalInvoices,
  } = useContext(AppContext);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [viewProposal, setViewProposal] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const [lastInvoice, setLastInvoice] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [pageOffset, setPageOffset] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);

    const newOffset =
      (selectedPage.selected * itemsPerPage) % proposalsList?.length;
    setPageOffset(newOffset);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    if (selectedProposal?.invoiceData?.length > 0) {
      const lastIndex = selectedProposal.invoiceData.length - 1;
      const lastInvoiceData =
        selectedProposal.invoiceData[lastIndex].payementStatus;
      setLastInvoice(lastInvoiceData);
    }
  }, [selectedProposal]);

  useEffect(() => {
    setCurrentPage(1);
  }, [proposalsList]);

  const [contractDates, setContractDates] = useState({
    startDate: "",
    endDate: "",
    dueDate: "",
  });

  const todayDate = Utils.getTodayDate();

  useEffect(() => {
    if (viewProposal === false) {
      setContractDates({
        startDate: "",
        endDate: "",
        dueDate: "",
      });
    }
  }, [viewProposal]);

  const handleViewProposal = async (proposal, index) => {
    if (proposal == null) return;

    setSelectedProposal(proposal);
    // delete proposal.contractData;
    // delete proposal.invoiceData;
    sessionStorage.setItem("DirectProposal", JSON.stringify(proposal));
    if (selectedIndex === index && isExpanded) {
      setIsExpanded(false);
      setSelectedIndex(null);
    } else {
      setIsExpanded(true);
      setSelectedIndex(index);
    }
  };

  const handleCloseModal = () => {
    setViewProposal(false);
  };

  const handleDatechange = (e) => {
    const { name, value } = e.target;
    setContractDates((prev) => ({ ...prev, [name]: value }));
  };

  const handleDeleteProposal = async (propId, index) => {
    setDeleteIndex(index);
    await deleteProposal(propId, {
      onSuccess: async (res) => {
        toast.success("Proposal Deleted Successfully!");
        setTimeout(async () => {
          await fetchAllProposalData();
          setDeleteIndex(-1);
        }, 1000);
      },
      onFailed: (err) => {
        setDeleteIndex(-1);
        toast.error("Something Went Wrong!");
      },
    });
  };

  const toggleContractModal = async (data, list) => {
    setSelectedContractProduct(data);
    const details = {
      data,
      list,
    };
    sessionStorage.setItem("selectedProduct", JSON.stringify(data));
    sessionStorage.setItem("contractDetails", JSON.stringify(details));
    setViewProposal(true);
  };

  // const hanldeNavigateContract = (data) => {
  //   setSelectedContractProduct(data);
  // };

  const hanldeNavigateContract = () => {
    if (!selectedContractProduct) return;

    if (
      contractDates.dueDate.length === 0 ||
      contractDates.endDate.length === 0 ||
      contractDates.dueDate.length === 0
    ) {
      toast.error("Contract Dates are required!");
      return;
    }

    if (todayDate == contractDates.dueDate) {
      toast.error("Due date is invalid!");
    }

    sessionStorage.setItem("contractDates", JSON.stringify(contractDates));

    setTimeout(() => {
      navigate(`/ferns-contract/${selectedProposal.propId}`);
    }, 1000);
  };
  const handleUpdateInvoice = (invoice, encryptId) => {
    sessionStorage.setItem("updateInvoice", JSON.stringify(invoice));
    navigate(`/edit-invoice/${encryptId}`);
  };
  const handleInvoice = (selectedProposal, list) => {
    setSelectedContractProduct(selectedProposal);
    sessionStorage.setItem("selectedProduct", JSON.stringify(selectedProposal));
    setTimeout(() => {
      navigate("/direct-proposal-invoice");
    }, 1000);
  };
  // const handleInvoice =()=>{
  //   setTimeout(()=>{
  //     navigate("/create-direct-Invoice");
  //   },1000)
  // }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = proposalsList?.slice(indexOfFirstItem, indexOfLastItem);
  console.log(selectedProposal, "selectedProposal");

  const handleProposalCreation = () => {
    sessionStorage.removeItem("enquiryData");
    navigate("/add-new-propsal?isSingle");
  };

  return (
    <div className="container-fluid">
      <Modal
        open={viewProposal}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        footer={false}
        style={{ width: "70%" }}
      >
        <h4>Generate Contract</h4>
        <div className="form-group">
          <label htmlFor="" className="form-label">
            Start Date
          </label>
          <div className="fp-input">
            <input
              type="date"
              name="startDate"
              id=""
              className="form-control"
              value={contractDates?.startDate}
              onChange={handleDatechange}
              min={todayDate}
            />
          </div>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="" className="form-label">
            End Date
          </label>
          <div className="fp-input">
            <input
              type="date"
              name="endDate"
              id=""
              className="form-control"
              value={contractDates?.endDate}
              onChange={handleDatechange}
              min={todayDate}
            />
          </div>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="" className="form-label">
            Due Date
          </label>
          <div className="fp-input">
            <input
              type="date"
              name="dueDate"
              id=""
              className="form-control"
              value={contractDates.dueDate}
              onChange={handleDatechange}
              min={todayDate}
            />
          </div>
        </div>
        <button
          className="continue_btn mt-3 mx-auto my-2 py-2 d-block"
          onClick={hanldeNavigateContract}
        >
          Continue{" "}
          <i>
            <Fi.FiArrowRight />
          </i>
        </button>
      </Modal>
      <Sidebar>
        <div div className="container-fluid m-3">
          <div className="row">
            <div className="col-md-10">
              <h6 className="head-h4">Proposals</h6>
            </div>
            <div className="col-md-2">
              <button
                className="pro-gen"
                type="button"
                onClick={() => handleProposalCreation()}
              >
                New
              </button>
            </div>
          </div>
        </div>
        <p className="proposal_information">
          <i>
            <Bs.BsDiagram3Fill />
          </i>{" "}
          mentioned as the proposal created from Customers.
        </p>
        <div className="card p-3 m-3 overflow">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Client Name</th>
                <th scope="col"> Date</th>
                <th scope="col">email</th>

                <th scope="col" className="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {proposalsList &&
                proposalsList.length != 0 &&
                proposalsList.map((list, index) => {
                  const propId = list?.propId;
                  const encryptId = btoa(propId);
                  const contractData =
                    proposalContracts &&
                    proposalContracts.length != 0 &&
                    proposalContracts.find((x) => x.propId === list?.propId);

                  const invoiceData =
                    proposalInvoices &&
                    proposalInvoices.length != 0 &&
                    proposalInvoices.find((x) => x.propId == list?.propId);

                  const selectedContractProduct = contractData?.userDetails;
                  const parsedProduct =
                    selectedContractProduct &&
                    JSON.parse(selectedContractProduct);
                  const selectedProduct =
                    parsedProduct && parsedProduct?.selectedContractProduct;
                  const contractDetails = list?.contractData;

                  const invoiceDetails = list?.invoiceData;
                  return (
                    <Fragment>
                      <tr key={index}>
                        <th scope="row">{pageOffset + index + 1}</th>
                        <td className="position-relative">
                          {list?.name ?? "N/A"}{" "}
                          {list?.leadId != null && (
                            <div
                              className="proposal_lead_badge"
                              title="Created from Lead"
                            >
                              <Bs.BsDiagram3Fill />
                            </div>
                          )}
                        </td>
                        <td>
                          {list?.created_at
                            ? Utils.formatDate(list?.created_at)
                            : "N/A"}
                        </td>
                        <td>{list?.email ?? "N/A"}</td>
                        <td className="text-center">
                          <button
                            className={
                              selectedIndex == index && isExpanded
                                ? "view_proposal_btn up"
                                : "view_proposal_btn down"
                            }
                            onClick={() => handleViewProposal(list, index)}
                          >
                            <i className="arrow_btn__ ">
                              <Io.IoMdArrowDown />
                            </i>
                          </button>
                        </td>
                        {/* <td className="text-center">
                          {list?.file != null && (
                            <a
                              href={
                                list?.file != null &&
                                process.env.REACT_APP_AWS_IMAGE_URL +
                                  "proposal-docs/" +
                                  list?.file
                              }
                            >
                              <Bs.BsDownload />
                            </a>
                          )}
                        </td>
                        <td className="text-center">
                          <button
                            className="trash_btn_props"
                            onClick={() => handleDeleteProposal(propId, index)}
                            disabled={deleteIndex === index}
                          >
                            {deleteIndex === index ? (
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              <Fa6.FaRegTrashCan />
                            )}
                          </button>
                        </td> */}
                      </tr>

                      <tr className="details-row">
                        <td colSpan="8">
                          <div
                            className={
                              selectedIndex === index && isExpanded
                                ? "details-content "
                                : "details-content hide"
                            }
                          >
                            <div className="container">
                              <table className="table table-striped ">
                                <thead className="thead-light">
                                  <th className="lead-propo">
                                    Package Name{" "}
                                    {list.leadId != null && (
                                      <span>Customer Proposal </span>
                                    )}
                                  </th>
                                  <th>Total Amount</th>
                                  {/* <th>Quantity</th> */}
                                  <th>VAT(%)</th>
                                  {list.leadId == null && (
                                    <>
                                      {/* <th className="text-center">Contract</th> */}
                                      <th className="text-center">Invoice</th>
                                    </>
                                  )}
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {
                                        selectedProposal?.productDetails
                                          ?.packageName
                                      }
                                    </td>
                                    <td>
                                      {
                                        selectedProposal?.productDetails?.values
                                          ?.total
                                      }
                                    </td>
                                    <td>
                                      {
                                        selectedProposal?.productDetails?.values
                                          ?.VAT
                                      }
                                    </td>
                                    <td>
                                      {list.leadId == null ? (
                                        <Fragment>
                                          {lastInvoice != "paid" ? (
                                            <>
                                              <button
                                                className="add_contract_btn"
                                                onClick={() =>
                                                  handleInvoice(
                                                    selectedProposal
                                                  )
                                                }
                                              >
                                                Generate
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <p
                                                className="text-center"
                                                style={{ color: "green" }}
                                              >
                                                Paid
                                              </p>
                                            </>
                                          )}
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          <div className="d-flex mx-auto p-0 text-center fp-badge pending">
                                            {invoiceData?.file &&
                                              invoiceData?.file.length != 0 && (
                                                <a
                                                  href={
                                                    invoiceData?.file != null &&
                                                    process.env
                                                      .REACT_APP_AWS_IMAGE_URL +
                                                      "invoices/" +
                                                      invoiceData?.file
                                                  }
                                                >
                                                  <Bs.BsDownload />{" "}
                                                </a>
                                              )}
                                          </div>
                                          {!invoiceData?.file && (
                                            <p
                                              className="text-center m-0"
                                              style={{
                                                fontSize: "10px",
                                              }}
                                            >
                                              Invoice not generated
                                            </p>
                                          )}
                                        </Fragment>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                                {/* <tbody>
                                  {selectedProposal &&
                                    selectedProposal?.productDetails.map(
                                      (data, index) => {
                                        const matchedProductCheck =
                                          selectedProduct?.pId === data?.pId;
                                        return (
                                          <tr key={index}>
                                            <td>{data?.productName}</td>
                                            <td>{data?.price}</td>
                                            <td>{data?.quantity}</td>
                                            <td>{data?.tax}</td>
                                            {list.leadId == null && (
                                              <>
                                                <td>
                                                  {!matchedProductCheck &&
                                                    !contractData?.status ? (
                                                    <button
                                                      className="add_contract_btn"
                                                      onClick={() =>
                                                        toggleContractModal(
                                                          data,
                                                          list
                                                        )
                                                      }
                                                    >
                                                      Generate
                                                    </button>
                                                  ) : (
                                                    <Fragment>
                                                      <div className="d-flex">
                                                        <div
                                                          className={
                                                            contractData?.status ==
                                                              "pending"
                                                              ? "fp-badge pending"
                                                              : contractData?.status ==
                                                                "open"
                                                                ? "fp-badge success"
                                                                : "fp-badge failed"
                                                          }
                                                        >
                                                          {contractData?.status}

                                                          <a
                                                            href={
                                                              contractData?.attachments !=
                                                              null &&
                                                              process.env
                                                                .REACT_APP_AWS_IMAGE_URL +
                                                              "contract-docs/" +
                                                              contractData?.attachments
                                                            }
                                                            target="_blank"
                                                          >
                                                            <Bs.BsDownload />{" "}
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </Fragment>
                                                  )}
                                                </td>
                                                <td>
                                                  {!matchedProductCheck ? (
                                                    <button
                                                      className="add_contract_btn"
                                                      onClick={() =>
                                                        handleInvoice(data)
                                                      }
                                                    >
                                                      Generate
                                                    </button>
                                                  ) : (
                                                    <Fragment>
                                                      <div className="d-flex mx-auto p-0 text-center fp-badge pending">
                                                        {invoiceData?.file &&
                                                          invoiceData?.file
                                                            .length != 0 && (
                                                            <a
                                                              href={
                                                                invoiceData?.file !=
                                                                null &&
                                                                process.env
                                                                  .REACT_APP_AWS_IMAGE_URL +
                                                                "invoices/" +
                                                                invoiceData?.file
                                                              }
                                                            >
                                                              <Bs.BsDownload />{" "}
                                                            </a>
                                                          )}
                                                      </div>
                                                      {!invoiceData?.file && (
                                                        <p
                                                          className="text-center m-0"
                                                          style={{
                                                            fontSize: "10px",
                                                          }}
                                                        >
                                                          Invoice not generated
                                                        </p>
                                                      )}
                                                    </Fragment>
                                                  )}
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody> */}
                              </table>
                              {contractDetails?.length != 0 &&
                                contractDetails != undefined && (
                                  <>
                                    <h6>Contract List</h6>
                                    <table className="table table-striped ">
                                      <thead>
                                        <th>Sl No</th>
                                        <th>Contract Id</th>
                                        <th>Date</th>
                                        <th>Email</th>
                                        <th className="text-center">File</th>
                                      </thead>
                                      <tbody>
                                        {contractDetails &&
                                          contractDetails.map(
                                            (contract, index) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                      {contract.contractId ??
                                                        "N/A"}
                                                    </td>
                                                    <td className="text-left">
                                                      {Utils.formatDate(
                                                        contract?.created_at
                                                      )}
                                                    </td>
                                                    <td>
                                                      {contract?.email ?? "N/A"}
                                                    </td>
                                                    <td className="text-center">
                                                      {" "}
                                                      <a
                                                        href={
                                                          contract?.attachments !=
                                                            null &&
                                                          process.env
                                                            .REACT_APP_AWS_IMAGE_URL +
                                                            "contract-docs/" +
                                                            contract?.attachments
                                                        }
                                                        // className="download_btn_proposal"
                                                      >
                                                        <Bs.BsDownload />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </>
                                )}
                              {list?.invoiceData.length != 0 && (
                                <>
                                  <h6>Invoices List</h6>
                                  <table className="table table-striped ">
                                    <thead>
                                      <th>Sl No</th>
                                      <th>Inv Number</th>
                                      <th>email</th>
                                      <th>Status</th>
                                      <th className="text-center">File</th>
                                      {/* <th className="text-center">Action</th> */}
                                    </thead>

                                    <tbody>
                                      {list?.invoiceData != null &&
                                      list?.invoiceData.length != 0 ? (
                                        list?.invoiceData.map(
                                          (invoice, index) => {
                                            return (
                                              <Fragment key={index}>
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>
                                                    {invoice?.invoiceNum ??
                                                      "N/A"}
                                                  </td>
                                                  <td className="text-left">
                                                    {invoice?.cust_email ??
                                                      "N/A"}
                                                  </td>
                                                  <td>
                                                    {invoice?.payementStatus ??
                                                      "N/A"}
                                                  </td>
                                                  <td className="text-center">
                                                    {invoice?.file != null ? (
                                                      <a
                                                        href={
                                                          process.env
                                                            .REACT_APP_AWS_IMAGE_URL +
                                                          "invoices/" +
                                                          invoice?.file
                                                        }
                                                      >
                                                        <Bs.BsDownload />
                                                      </a>
                                                    ) : (
                                                      <p
                                                        className="text-center m-0 user-select-none"
                                                        style={{
                                                          fontSize: "10px",
                                                        }}
                                                      >
                                                        Invoice not generated
                                                      </p>
                                                    )}
                                                  </td>
                                                  {/* <td className="text-center">
                                              <button
                                                style={{
                                                  border: "none",
                                                  background: "transparent",
                                                  padding: "2px",
                                                  fontWeight: "500",
                                                }}
                                                onClick={() =>
                                                  handleUpdateInvoice(
                                                    invoice,
                                                    encryptId
                                                  )
                                                }
                                              >
                                                Edit
                                              </button>
                                            </td> */}
                                                </tr>
                                              </Fragment>
                                            );
                                          }
                                        )
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan="5"
                                            className="text-center"
                                          >
                                            No data available
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </>
                              )}

                              <div className="d-flex w-100">
                                {list?.file != null && list.leadId == null && (
                                  <a
                                    href={
                                      list?.file != null &&
                                      process.env.REACT_APP_AWS_IMAGE_URL +
                                        "proposal-docs/" +
                                        list?.file
                                    }
                                    className="download_btn_proposal"
                                  >
                                    Proposal <Bs.BsDownload />
                                  </a>
                                )}

                                {list?.leadId == null && (
                                  <>
                                    <Popconfirm
                                      title=" Delete Proposal"
                                      description="Are you sure to Delete this proposal?"
                                      onConfirm={() =>
                                        handleDeleteProposal(propId, index)
                                      }
                                      // onCancel={}
                                      okText="Yes"
                                      cancelText="No"
                                      okType="danger"
                                      icon={
                                        <Ai.AiOutlineQuestionCircle
                                          style={{ color: "red" }}
                                        />
                                      }
                                    >
                                      <button
                                        className="trash_btn"
                                        disabled={deleteIndex === index}
                                      >
                                        {deleteIndex === index ? (
                                          <span
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                        ) : (
                                          <span>
                                            <Fa6.FaRegTrashCan /> Delete
                                          </span>
                                        )}
                                      </button>
                                    </Popconfirm>
                                    <br />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div>
          {proposalsList?.length != 0 && (
            <div className="pagination">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                onPageChange={handlePageChange}
                pageCount={Math.ceil(proposalsList?.length / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"paging__link--active"}
              />
            </div>
          )}
        </div>
      </Sidebar>
    </div>
  );
}

export default Proposals;
