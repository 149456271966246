import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./Routes/routes";
import { AppContext } from "./Context/AppContext";
import { ProtectAuthRoute, ProtectRoute } from "./Routes/Protector";
import "../src/Style/Style.css";
import Loader from "./Components/Loader/Loader";
import ErrorComponent from "./Components/Error/ErrorComponent";


function App() {
  const { pageLoading, cookieError } = useContext(AppContext);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="colored"
        closeButton={false}
        style={{ textAlign: "center", width: "auto" }}
      />
      {pageLoading == false && cookieError == null ? (
        <BrowserRouter>
          <Routes>
            {AppRoutes.map((routes, key) => {
              return !routes.isProtected && !routes.isProtectedAuth ? (
                <Route
                  key={key}
                  path={routes.path}
                  element={routes.element}
                  exact
                />
              ) : !routes.isProtected && routes.isProtectedAuth ? (
                <Route
                  key={key}
                  path={routes.path}
                  element={
                    <ProtectAuthRoute>{routes.element}</ProtectAuthRoute>
                  }
                  exact
                ></Route>
              ) : (
                <Route
                  key={key}
                  path={routes.path}
                  element={<ProtectRoute>{routes.element}</ProtectRoute>}
                  exact
                ></Route>
              );
            })}
          </Routes>
        </BrowserRouter>
      ) : pageLoading == true && cookieError == null ? (
        <Loader />
      ) : (
        <ErrorComponent errorTitle={"Session Timeout"} errorMsg={cookieError} />
      )}
    </>
  );
}

export default App;
