import React, { useContext, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { Row } from "react-bootstrap";
import Select from "react-select";
import { AppContext } from "../../Context/AppContext";
import * as Fi from "react-icons/fi";
import { Button, Upload } from "antd";
import toast, { Toaster } from "react-hot-toast";
import * as Io from "react-icons/io"
import { useNavigate } from "react-router-dom";

function CreateProduct({ action, onChange, showUploadList }) {
  const navigate = useNavigate();
  const { createProduct, featureList, productLoading } = useContext(AppContext);
  const [productState, setProductState] = useState({
    profilephoto: null,
    productName: "",
    worksOn: [],
    subscription: [],
    device: [],
    pdfId: [],
    description: "",
    price_yearly: null,
    price_monthly: null,
    duration_monthly: 0,
    duration_yearly: 0,
    durationIn: "",
  });
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = e.target.files;
    const selectedFiles = [...selectedImages]; // Make a copy of the existing selected images

    // Iterate through selected files
    for (let i = 0; i < files.length; i++) {
      // Check if selectedFiles count is less than 10
      if (selectedFiles.length < 10) {
        selectedFiles.push(files[i]);
      } else {
        // If selectedFiles count reaches 10, break the loop
        break;
      }
    }

    // Update the state with selected files
    setSelectedImages(selectedFiles);
  };


  const handleSelectChange = (selectedOptions) => {
    const pdfIds = selectedOptions.map((option) => option.value);
    setProductState((prev) => ({ ...prev, pdfId: pdfIds }));
  };

  const options = featureList.map((group) => ({
    value: group.pdfId,
    label: group.featureName,
  }));
  const subscription = [
    { value: "MONTHLY", label: "MONTHLY" },
    { value: "YEARLY", label: "YEARLY" },
  ];
  const worksOn = [
    { value: "iOS", label: "iOS" },
    { value: "WINDOWS", label: "WINDOWS" },
    { value: "WEB", label: "WEB" },
    { value: "ANDROID", label: "ANDROID" },
  ];
  const deviceOption = [
    { value: "TABLET", label: "TABLET" },
    { value: "MOBILE", label: "MOBILE" },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;

    setProductState((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleWorksOnChange = (selectedOptions) => {
    setProductState((prev) => ({
      ...prev,
      worksOn: selectedOptions.map((option) => option.value),
    }));
  };
  const handleDeviceChange = (selectedDevice) => {
    setProductState((prev) => {
      const updatedDevices = prev.device.includes(selectedDevice)
        ? prev.device.filter((device) => device !== selectedDevice)
        : [...prev.device, selectedDevice];

      return { ...prev, device: updatedDevices };
    });
  };

  const handleSubscriprion = (subselected) => {
    setProductState((prev) => ({
      ...prev,
      subscription: subselected.map((option) => option.value),
    }));
  };

  const validate = () => {
    if (productState.profilephoto === null) {
      toast.error("Product Profile Image is Required!");
      return false;
    }

    return true;
  };
console.log(selectedImages,"imahes")
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();

    if (!isValid) {

      return;
    }
    const formData = new FormData();

    formData.append("productName", productState.productName);
    formData.append("description", productState.description);
    formData.append("price_monthly", productState.price_monthly);
    formData.append("price_yearly", productState.price_yearly);
    formData.append("pdfId", productState.pdfId.join(","));
    formData.append("currency", "£");
    formData.append("durationIn", "days");
    formData.append("device", JSON.stringify(productState.device));
    formData.append("duration_monthly", "30");
    formData.append("duration_yearly", "365");
    formData.append("worksOn", JSON.stringify(productState.worksOn));
    formData.append("subscription", JSON.stringify(productState.subscription));
    formData.append("profilephoto", productState.profilephoto);

    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("photos", selectedImages[i]);
    }

    await createProduct(formData, {
      onSuccess: async (res) => {
        toast.success("Succesfully Created Product!");

        setTimeout(() => {
          navigate("/products");
          window.location.reload();
        }, 1000);
      },
      onFailed: (err) => {
        if (err.errorCode == "DUP") {
          toast.error("Duplicate Product Name in Database!");
        } else {
          toast.error(err.errormessage);
        }
      },
    });
  };
  return (
    <div className="container-fluid">
      <Sidebar>
        <div div className="container-fluid m-3">
          <Toaster position="top-center" reverseOrder={false} />
          <h4 className="head-h4">Product Creation</h4>
        </div>
        <div className="container">
          <div className="card m-3">
            <h6 className="text-center m-3">Create A Product</h6>
            <form action="" onSubmit={(e) => handleSubmit(e)}>
              <div className="row m-3 p-3">
                <div className="col-md-6 col-sm-12 mt-3">
                  <label className="form-label review-label">
                    Product Name
                  </label>
                  <div className="review-group">
                    <input
                      type="text"
                      className="review-input"
                      name="productName"
                      value={productState.productName}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-3 col-sm-12 mt-3">
                  <label htmlFor="" className="form-label review-label">
                    Price Monthly
                  </label>
                  <div className="review-group">
                    <input
                      className="review-input"
                      type="text"
                      name="price_monthly"
                      value={productState.price_monthly}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 mt-3">
                  <label htmlFor="" className="form-label review-label">
                    Price Yearly
                  </label>
                  <div className="review-group">
                    <input
                      className="form-control review-input"
                      type="text"
                      name="price_yearly"
                      value={productState.price_yearly}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mt-3">
                  <label
                    htmlFor="pdfSelect"
                    className="form-label review-label"
                  >
                    Select Features
                  </label>
                  <Select
                    name="pdfId"
                    id="pdfSelect"
                    value={options.filter((option) =>
                      productState.pdfId.includes(option.value)
                    )}
                    onChange={(e) => handleSelectChange(e)}
                    options={options}
                    isMulti
                  />
                </div>
                <div className="col-md-4 col-sm-12 mt-3">
                  <label htmlFor="" className="form-label review-label">
                    Subscription Types
                  </label>
                  <Select
                    name="subscriprion"
                    id="subscription"
                    value={subscription.filter((option) =>
                      productState.subscription.includes(option.value)
                    )}
                    onChange={handleSubscriprion}
                    options={subscription}
                    isMulti
                  />
                </div>
                <div className="col-md-4 col-sm-12 mt-3">
                  <label htmlFor="" className="form-label review-label">
                    Available Platforms
                  </label>
                  <Select
                    name="worksOn"
                    id="worksOnSelect"
                    value={worksOn.filter((option) =>
                      productState.worksOn.includes(option.value)
                    )}
                    onChange={handleWorksOnChange}
                    options={worksOn}
                    isMulti
                  />
                </div>

                <div className="">
                  <div className="col-sm-12 mt-3">
                    <label htmlFor="" className="form-label review-label mb-2">
                      Description
                    </label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      name="description"
                      value={productState.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 mt-3">
                  <label className="form-label review-label">Devices</label>
                  {deviceOption.map((option) => (
                    <div key={option.value} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`device-${option.value}`}
                        value={option.value}
                        checked={productState.device.includes(option.value)}
                        onChange={() => handleDeviceChange(option.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`device-${option.value}`}
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="col-md-4 col-sm-12 ">
                  <div className="profile_upload">
                    <label htmlFor="" className="form-label review-label">
                      Upload Profile Image
                    </label>
                    <br />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        setProductState((prev) => ({
                          ...prev,
                          profilephoto: e.target.files[0],
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="profile_upload">
                    <label htmlFor="" className="form-label review-label mb-2">
                      Upload Images (max 10)
                    </label>
                    <br />
                    <input
                      type="file"
                      name="photo"
                      accept="image/*"
                      onChange={handleImageChange}
                      multiple 
                      maxLength={10} 
                    />
                   
                      {selectedImages.map((image, index) => (
                        <li key={index} className="list-pout">{image.name}</li>
                      ))}
                   
                  </div>
                </div>
              </div>
              <div className="row m-3 p-3">
                <small>   
                <i className="m-1"><Io.IoIosInformationCircleOutline /></i>
                <span>Uploading multiple images might take a bit of time, especially depending on the internet speed. </span></small>
                </div>
              <div className="button-div text-center mx-auto">
                {productLoading ? (
                  <Button
                    loading={true}
                    className="fp-button mb-3"
                    disabled={true}
                  >
                    Submitting
                  </Button>
                ) : (
                  <button className="fp-button mb-3" type="submit">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default CreateProduct;
