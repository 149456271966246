import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Sidebar from "../../Components/Sidebar";
import { AppContext } from "../../Context/AppContext";
import Image1 from "../../Assets/Doc/49575-O63MXV.jpg";
import Slider from "react-slick";
import { FaRegTrashAlt } from "react-icons/fa";
import * as Ai from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

function AddProposal() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSingle = searchParams.has("isSingle");
  const navigate = useNavigate();
  const { templates, eventList } = useContext(AppContext);
  const [selectedPackage, setSelectedPackage] = useState();
  const [elements, setElements] = useState([{ item: "", price: 0 }]);
  const [vatPercentage, setVatPercentage] = useState(20);
  const enquiryData = JSON.parse(sessionStorage.getItem("enquiryData")) ?? "";
  const [itemError, setItemError] = useState(false);
  const [services, setServices] = useState(null);

  const [values, setValues] = useState({
    subTotal: 0,
    VAT: 0,
    VATAmount: 0,
    total: 0,
  });

  const [items, setItems] = useState({
    mininumof: "",
    guestRate: "",
    softDrinksRate: "",
    guestNumber: "",
    staffingCharges: "",
    staffingCount: "",
    beforeCompulsary: "",
    waitersCount: "",
    transportationCharges: "",
    rubbishDisposal: "",
    kitchenEquipment: "",
  });

  const [selectedProposal, setSelectedProposal] = useState(0);
  const [proposal, setProposal] = useState({
    currency: "£",
    email: enquiryData?.sellerDetails?.sellerEmail ?? "",
    clientName: enquiryData?.sellerDetails?.sellerName ?? "",
    phone: enquiryData?.sellerDetails?.mobile
      ? "+" + enquiryData?.sellerDetails?.mobile
      : "",
    shopName: "",
    address: enquiryData?.sellerDetails
      ? enquiryData?.sellerDetails?.address1 +
        " " +
        enquiryData?.sellerDetails?.address2 +
        " " +
        enquiryData?.sellerDetails?.town +
        " " +
        enquiryData?.sellerDetails?.postcode
      : "",
  });
  const [vatAMt, setVatAmt] = useState(0);
  const [serviceErrors, setServiceErrors] = useState([]); // Add an error state for services
  const [service, setService] = useState([]);
  // Log the services to the console
  console.log(service, "service");

  // Populate services from the `services` array and initialize error state
  useEffect(() => {
    if (Array.isArray(services)) {
      setService(
        services.map((service) => ({
          courseHead: service?.courseHead ?? "N/A",
          value: "",
        }))
      );
      setServiceErrors(
        services.map(() => false) // Initialize error tracking for each service item
      );
    }
  }, [services]);

  // Handle changes in service fields
  const handleService = (e, index) => {
    const { name, value } = e.target;
    setService((prevItems) =>
      prevItems.map((item, idx) =>
        idx === index ? { ...item, [name]: value } : item
      )
    );
    // Reset error for the current field once it is filled
    setServiceErrors((prevErrors) =>
      prevErrors.map((error, idx) => (idx === index ? false : error))
    );
  };

  // Validate service fields before proceeding
  const validateServices = () => {
    let hasError = false;

    const updatedErrors = service.map((item) => {
      if (item.value.trim() === "") {
        hasError = true;
        return true;
      }
      return false;
    });

    setServiceErrors(updatedErrors); // Set errors for each field
    return hasError;
  };
  const handleTemp = (temp) => {
    setSelectedProposal(temp.template_id);
  };
  useEffect(() => {
    if (selectedPackage != null && selectedPackage != "") {
      const serviceData =
        eventList && eventList.find((x) => x?.packId == selectedPackage);

      if (serviceData != null || serviceData != undefined) {
        setServices(JSON.parse(serviceData?.courses));
      }
    }
  }, [selectedPackage]);
  console.log(services, "services");
  const handleSelectProposal = (e) => {
    setSelectedPackage(e.target.value);
  };
  const formedArray = [
    {
      description:
        "Dinner: Minimum of " +
        items?.mininumof +
        " guests @ £" +
        items?.guestRate +
        " per head",
      price: items?.mininumof * items?.guestRate,
    },
    {
      description:
        "Dinner: Soft Drinks Package @ £ " +
        items?.softDrinksRate +
        " per head for " +
        items?.guestNumber +
        " guests",
      price: items?.softDrinksRate * items?.guestNumber,
    },
    {
      description:
        "Dinner: Staffing charges @ £" +
        items?.staffingCharges +
        ". per head for" +
        items?.staffingCount +
        " guests",
      price: items?.staffingCharges * items?.staffingCount,
    },
    {
      description:
        items?.beforeCompulsary +
        " Compulsory Drink Waiters @" +
        items?.waitersCount +
        ".per head",
      price: items?.beforeCompulsary * items?.waitersCount,
    },
    {
      description: "Transportation Charges",
      price: items?.transportationCharges,
    },
    { description: "Rubbish Disposal", price: items?.rubbishDisposal },
    { description: "Kitchen Equipment Hire", price: items?.kitchenEquipment },
  ];
  const handleAddItem = () => {
    const lastItem = elements[elements.length - 1];
    if (lastItem != null) {
      if (lastItem.item.trim() === "" || lastItem.price.trim() === "") {
        setItemError(true);
        return;
      }
    }

    setItemError(false);
    setElements([...elements, { item: "", price: "" }]);
  };
  const eventUpdation = useCallback(() => {
    // Calculate event-specific totals
    const eventTotal =
      (parseFloat(items?.mininumof) || 0) *
        (parseFloat(items?.guestRate) || 0) +
      (parseFloat(items?.softDrinksRate) || 0) *
        (parseFloat(items?.guestNumber) || 0) +
      (parseFloat(items?.beforeCompulsary) || 0) *
        (parseFloat(items?.waitersCount) || 0) +
      (parseFloat(items?.staffingCharges) || 0) *
        (parseFloat(items?.staffingCount) || 0) +
      (parseFloat(items?.kitchenEquipment) || 0) +
      (parseFloat(items?.rubbishDisposal) || 0) +
      (parseFloat(items?.transportationCharges) || 0);

    // Calculate dynamic elements total
    const elementsTotal = elements.reduce((acc, item) => {
      let price =
        typeof item?.price === "string" ? parseFloat(item?.price) : item?.price;
      return acc + (price || 0);
    }, 0);

    // Add service amounts to the total
    const serviceTotal = service.reduce((acc, serviceItem) => {
      let value = parseFloat(serviceItem?.value) || 0; // Parse the service value as a number
      return acc + value;
    }, 0);

    // Combine all totals
    const inTotal = eventTotal + elementsTotal + serviceTotal;

    // Calculate tax based on VAT percentage
    const taxTotal = (inTotal * (parseFloat(vatPercentage) || 0)) / 100;

    // Calculate the final total including tax
    const allTotal = inTotal + taxTotal;

    // Update state with the calculated values
    setValues((prevValues) => ({
      ...prevValues,
      subTotal: parseFloat(inTotal.toFixed(2)),
      taxAmount: parseFloat(taxTotal.toFixed(2)),
      VAT: parseFloat(vatPercentage) || 0,
      total: parseFloat(allTotal.toFixed(2)),
    }));
  }, [items, elements, service, vatPercentage]); // Ensure 'service' is included in the dependency array

  const handlePercentageValue = (event) => {
    const { name, value } = event.target;
    setVatPercentage(value);
    eventUpdation(); // Recalculate on vatPercentage change
  };
  const calculateTaxAmount = (subTotal, vatPercentage) => {
    return parseFloat(subTotal) * (parseFloat(vatPercentage) / 100);
  };

  useEffect(() => {
    if (!values) return;

    setVatAmt(values.taxAmount);
  }, [vatPercentage, values]);

  useEffect(() => {
    eventUpdation();
  }, [eventUpdation]);

  const handleDeleteItem = (index) => {
    const newElements = [...elements];
    newElements.splice(index, 1);
    setElements(newElements);
  };

  const getTotalTaxAmount = (pro) => {
    const amount = pro.quantity * pro.price;
    const taxAmount = (amount * pro.tax) / 100;
    return taxAmount;
  };

  const handleInputChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or event.target is undefined");
      return;
    }

    const { name, value } = e.target;

    const regex = /^\d*\.?\d*$/;
    if (regex.test(value)) {
      setItems((prevItems) => ({
        ...prevItems,
        [name]: value || 0,
      }));
    }
  };

  const handleElementUpdate = (index, e) => {
    const { name, value } = e.target;
    const newElements = [...elements];

    if (name === "price") {
      const numericValue = value.replace(/[^0-9£]/g, "");

      setItemError(false);
      newElements[index] = {
        ...newElements[index],
        [name]: numericValue.startsWith("") ? numericValue : "" + numericValue,
      };
    } else {
      setItemError(false);
      newElements[index] = { ...newElements[index], [name]: value };
    }

    setElements(newElements);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const onProposalChange = (e) => {
    const { name, value } = e.target;
    setProposal((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const phonenumberValidation = (e) => {
    const value = e.target.value;
    let phoneNum = value.replace(/[^\d()+]/g, "");

    setProposal((prev) => ({ ...prev, phone: phoneNum }));
  };

  const validateProposal = () => {
    if (proposal?.phone == undefined) {
      toast.error("Phone number Required!.");
      return true;
    }
    validateServices();
    if (
      !proposal?.clientName ||
      proposal.clientName.trim() === "" ||
      !proposal?.phone ||
      proposal.phone.trim() === "" ||
      !proposal?.email ||
      proposal.email.trim() === "" ||
      !proposal?.address ||
      proposal.address.trim() === ""
    ) {
      toast.error("Please Fill All Client Details!.");
      return true;
    }

    if (selectedProposal === 0) {
      toast.error("Please select a Proposal Template!");
      return true;
    }

    if (values?.subTotal === 0 || values?.total === 0) {
      toast.error("Proposal Total must be greater than zero.");
      return true;
    }

    const hasEmptyItem = elements.some((elem) => elem.item.length === 0);
    if (hasEmptyItem) {
      if (elements[0].item.length != 0 || elements[0].price.length != 0) {
        return false;
      }
      toast.error("Please make sure the fields are not empty!");
      return true;
    }

    return false;
  };

  const handleProposal = () => {
    const isValidate = validateProposal();

    if (isValidate === false) {
      const selectedPackData = selectedPackage == 1 ? formedArray : [];
      const proposalData = {
        formedArray: selectedPackData,
        proposal,
        tempId: selectedProposal,
        elements,
        values,
        selectedPackage,
        service,
      };
      console.log(proposalData, "proposalData");

      if (selectedProposal == 0) {
        toast.error("Please select a Proposal Template!");
        return;
      }

      if (isSingle === true) {
        sessionStorage.setItem("proposalData", JSON.stringify(proposalData));
        if (selectedProposal == 1) {
          navigate(`/template/${selectedProposal}}?single`);
        }
        if (selectedProposal == 2) {
          navigate(`/template2/${selectedProposal}}?single`);
        }
      } else {
        sessionStorage.setItem("proposalData", JSON.stringify(proposalData));
        if (selectedProposal == 1) {
          navigate(`/template/${selectedProposal}`);
        }
        if (selectedProposal == 2) {
          navigate(`/template2/${selectedProposal}`);
        }
      }
    }
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <Toaster position="top-center" />
        <div className="col-md-8 mx-auto">
          <div className="slider-wrapper mt-3 mb-3">
            <h6 className="sub_title">Choose Template</h6>
            <Slider {...settings}>
              {templates != null &&
                templates.map((temp) => (
                  <div
                    className={
                      selectedProposal !== temp.template_id
                        ? "image-slide"
                        : "image-slide selected"
                    }
                    key={temp.template_id}
                    onClick={() => handleTemp(temp)}
                  >
                    <img
                      src={
                        temp?.image != null
                          ? process.env.REACT_APP_AWS_IMAGE_URL +
                            "proposal-templates/" +
                            temp?.image
                          : Image1
                      }
                      className={`slider ${
                        temp.template_id === temp.image ? "selected" : ""
                      }`}
                      alt={"Template Image"}
                    />
                  </div>
                ))}
            </Slider>
          </div>
          <hr className="line_dotted" />
          <div>
            <div className="form-group mb-2" style={{ width: "60%" }}>
              <label htmlFor="" className="form-label">
                Packages
              </label>
              <select
                name=""
                id=""
                className="form-select"
                value={selectedPackage}
                onChange={handleSelectProposal}
              >
                <option value="" disabled selected>
                  Select package
                </option>

                {eventList.length != 0 &&
                  eventList.map((data, index) => {
                    return (
                      <>
                        {data.disabled != true && (
                          <Fragment>
                            <option value={data?.packId}>{data?.title}</option>
                          </Fragment>
                        )}
                      </>
                    );
                  })}
              </select>
            </div>
            <form className="card new_card ">
              <b>Package is as follows:</b>
              <div className="mt-3">
                <table className="table table-bordered">
                  <tbody>
                    {selectedPackage && selectedPackage == 1 && (
                      <>
                        <tr>
                          <td>
                            Dinner: Minimum of{" "}
                            <input
                              className="normal_field"
                              value={items.mininumof}
                              name="mininumof"
                              onChange={(e) => handleInputChange(e)}
                            />
                            @{" "}
                            <input
                              className="normal_field"
                              value={items.guestRate}
                              name="guestRate"
                              onChange={(e) => handleInputChange(e)}
                            />{" "}
                            per head
                          </td>
                          <td>
                            {/* <input
                                type="text"
                                name="price"
                                className="item_input"
                                value={
                                  "£" + items?.mininumof * items?.guestRate
                                }
                              /> */}
                            <p>
                              {"£" + items?.mininumof * items?.guestRate ??
                                "N/A"}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Dinner: Soft Drinks Package @ £{" "}
                            <input
                              className="normal_field"
                              name="softDrinksRate"
                              value={items.softDrinksRate}
                              onChange={(e) => handleInputChange(e)}
                            />
                            per head for
                            <input
                              className="normal_field"
                              name="guestNumber"
                              value={items.guestNumber}
                              onChange={(e) => handleInputChange(e)}
                            />
                            guests
                          </td>
                          <td>
                            {"£" + items?.softDrinksRate * items?.guestNumber ??
                              "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Dinner: Staffing charges @ £{" "}
                            <input
                              className="normal_field"
                              name="staffingCharges"
                              value={items.staffingCharges}
                              onChange={(e) => handleInputChange(e)}
                            />
                            per head for
                            <input
                              className="normal_field"
                              placeholder="..."
                              name="staffingCount"
                              value={items.staffingCount}
                              onChange={(e) => handleInputChange(e)}
                            />
                            guests
                          </td>
                          <td>
                            <p>
                              {"£" +
                                items?.staffingCharges * items?.staffingCount ??
                                "N/A"}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              className="normal_field"
                              placeholder="..."
                              name="beforeCompulsary"
                              value={items.beforeCompulsary}
                              onChange={(e) => handleInputChange(e)}
                            />
                            Compulsory Drink Waiters @{" "}
                            <input
                              className="normal_field"
                              name="waitersCount"
                              value={items.waitersCount}
                              onChange={(e) => handleInputChange(e)}
                            />
                            per head
                          </td>
                          <td>
                            <p>
                              {" "}
                              {"£" +
                                items?.beforeCompulsary * items?.waitersCount ??
                                "N/A"}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>Transportation Charges</td>
                          <td>
                            <input
                              type="text"
                              placeholder="£"
                              className="item_input"
                              name="transportationCharges"
                              value={items.transportationCharges}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Rubbish Disposal</td>
                          <td>
                            <input
                              type="text"
                              placeholder="£"
                              className="item_input"
                              name="rubbishDisposal"
                              value={items.rubbishDisposal}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Kitchen Equipment Hire</td>
                          <td>
                            <input
                              type="text"
                              placeholder="£"
                              className="item_input"
                              name="kitchenEquipment"
                              value={items.kitchenEquipment}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </td>
                        </tr>
                      </>
                      // <>
                      //   {particulars &&
                      //     particulars.map((data, index) => {
                      //       console.log(data, "particulars");
                      //       return <></>;
                      //     })}
                      // </>
                    )}
                    {services != null &&
                      services != undefined &&
                      services.length != 0 && (
                        <>
                          {service.map((data, index) => (
                            <tr key={index}>
                              <td>{data?.courseHead ?? "N/A"}</td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="£"
                                  className="item_input"
                                  name="value"
                                  value={service[index]?.value || ""}
                                  onChange={(e) => handleService(e, index)}
                                />
                                {serviceErrors[index] && (
                                  <p style={{ color: "red" }}>
                                    This field is required.
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    {elements.map((element, index) => (
                      <tr key={index}>
                        <td style={{ width: "80%" }}>
                          <div className="d-flex">
                            {index === elements.length - 1 && (
                              <button
                                type="button"
                                onClick={handleAddItem}
                                style={{ border: "none", background: "#fff" }}
                              >
                                <Ai.AiOutlinePlus className="iconsbtn" />
                              </button>
                            )}
                            <textarea
                              rows={1}
                              name="item"
                              placeholder="..."
                              value={element.item}
                              className="item_input"
                              onChange={(e) => handleElementUpdate(index, e)}
                              style={{ resize: "none" }}
                            />
                          </div>
                        </td>
                        <td style={{ width: "20%" }}>
                          <div className="d-flex">
                            <input
                              type="text"
                              name="price"
                              placeholder="£"
                              value={element.price}
                              className="item_input"
                              onChange={(e) => handleElementUpdate(index, e)}
                              disabled={elements[index].item.length === 0}
                            />
                            {elements.length > 1 && (
                              <button
                                type="button"
                                className="delete_btn_item"
                                onClick={() => handleDeleteItem(index)}
                              >
                                <Ai.AiOutlineDelete />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}

                    {itemError && (
                      <tr>
                        <td colSpan="2">
                          <p style={{ color: "red" }}>
                            Please fill in all fields before adding a new item.
                          </p>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        <b>Sub Total:</b>
                      </td>
                      <td>£{values.subTotal}</td>
                    </tr>
                    <tr>
                      <td>
                        VAT %{" "}
                        <input
                          className="normal_field p-1"
                          type="text"
                          name="vatPercentage"
                          min={0}
                          value={vatPercentage}
                          onChange={handlePercentageValue}
                        />
                      </td>
                      <td>
                        {" "}
                        <p>{"£" + values.taxAmount}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Total Package Price:</b>
                      </td>
                      <td>£{values?.total ?? 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* {addProduct.map((item, index) => {
                  return (
                    <div className="row add_pro_data mb-3" key={index}>
                      <h6 className="mt-3 mb-3">Add Product Details</h6>
                      <div className="col-xl-6 col-md-6 col-sm-4">
                        <label>
                          Product <span>*</span>
                        </label>
                        <select
                          name="pId"
                          id="productSelect"
                          className="form-control"
                          onChange={(e) => handleChange(e, index)}
                        >
                          <option value="" disabled selected={!item?.pId}>
                            Select a product
                          </option>
                          {productList &&
                            productList.map((product) => {
                              return (
                                <option
                                  key={product.pId}
                                  value={product.pId}
                                  selected={product.pId == item?.pId}
                                >
                                  {product.productName}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="col-xl-4 col-md-6 col-sm-6">
                        <label>
                          Price(Value) <span>*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="price"
                          value={proposal?.price}
                          onChange={(e) => onPriceChange(e.target.value, index)}
                          placeholder="0"
                        />
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-12 mt-3">
                        <label>
                          VAT %<span>*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="tax"
                          value={proposal?.tax}
                          onChange={(e) => onTaxChange(e.target.value, index)}
                          placeholder="%"
                        />
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-12 mt-3">
                        <label style={{ whiteSpace: "nowrap" }}>
                          Subscription Type<span>*</span>
                        </label>
                        <select
                          name="status"
                          className="form-control"
                          value={proposal?.subscription}
                          onChange={(e) => handleSub(e.target.value, index)}
                        >
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                      </div>
                      <div className="col-xl-4 col-md-6 col-sm-12 mt-3">
                        <label>Quantity</label>
                        <div className="qty_wrapper">
                          <button
                            type="button"
                            className="qty_btn decrease-button"
                            onClick={() => decreaseCounter(index)}
                          >
                            -
                          </button>
                          <span className="qty_count" name="quantity">
                            {item?.quantity ?? 1}
                          </span>
                          <button
                            type="button"
                            className="qty_btn increase-button"
                            onClick={() => increaseCounter(index)}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      <div className="d-block mt-3">
                        <label htmlFor="" className="form-label">
                          Description
                        </label>
                        <textarea
                          name="description"
                          id=""
                          cols="30"
                          rows="3"
                          className="form-control"
                          placeholder="Write about product.."
                          value={item?.description}
                          onChange={(e) => handleChange(e, index)}
                        ></textarea>
                      </div>
                      <div className="d-flex">
                        <div className="mt-3">{renderAddButton(index)}</div>
                        <div className="ms-2">
                          {addProduct.length > 1 && (
                            <button
                              type="button"
                              className="prod_del_btn"
                              onClick={() => removeProduct(index)}
                            >
                              <FaRegTrashAlt />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })} */}
              <hr className="line_dotted" />
              <h6 className="sub_title">Client Details</h6>
              <div className="row p-2">
                {" "}
                <div className="col-xl-6 col-md-6 col-sm-12 mb-2">
                  <label>Client's Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="clientName"
                    min={0}
                    value={proposal?.clientName}
                    onChange={(e) => onProposalChange(e)}
                  />
                </div>
                <div className="col-xl-6 col-md-6 col-sm-12 mb-2">
                  <label>Phone</label>
                  <input
                    className="form-control"
                    type="text"
                    name="phone"
                    // disabled
                    value={proposal?.phone ?? ""}
                    onChange={(e) => phonenumberValidation(e)}
                  />
                </div>
                <div className="col-xl-12 col-md-12 col-sm-12">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    min={0}
                    value={proposal?.email}
                    onChange={(e) => onProposalChange(e)}
                  />
                </div>
              </div>
              <div className="row p-2">
                {" "}
                <div className="col-md-12 col-sm-12 ">
                  <label>Address</label>
                  <br />
                  <textarea
                    className="des_text p-2"
                    name="address"
                    value={proposal?.address ?? ""}
                    onChange={onProposalChange}
                  ></textarea>
                </div>
              </div>

              <button
                type="button"
                className="continue_btn mb-3 mt-3"
                onClick={handleProposal}
              >
                Continue{" "}
                <i>
                  <Ai.AiOutlineArrowRight />
                </i>
              </button>
            </form>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default AddProposal;
