import React, { Fragment, useContext, useRef, useState } from "react";
import "../../Style/Style.css";
import Utils from "../../Utils/utils";
import toast, { Toaster } from "react-hot-toast";
import Signature from "../../Assets/signature.png";
import { FaArrowLeftLong } from "react-icons/fa6";
import { AppContext } from "../../Context/AppContext";
import { Navigate, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import Pakwaan from "../../Assets/pakawaanlogoo.png";
import generatePDF from "react-to-pdf";
import jsPDF from "jspdf";
import SendMailTemplateModal from "../../Components/mail/SendMailTemplateModal";

function ContractTemp() {
  const { sendContractToClient, contractLoading, selectedContractProduct } =
    useContext(AppContext);
  const [showMailModal, setShowMailModal] = useState(false);
  const [parsedBody, setParsedBody] = useState(null);
  const [mailSubject, setMailSubject] = useState(null);
  const [ccValue, setCcValue] = useState(null);
  const currentDate = Utils.formatDate(new Date());
  const targetRef = useRef();
  const navigate = useNavigate();
  const info = JSON.parse(sessionStorage.getItem("contractDetails"));
  const selected = JSON.parse(sessionStorage.getItem("selectedContract"));
  const data = info.contractData;
  const dealDetails = info.selectedDeal;
  const enquiryData = JSON.parse(sessionStorage.getItem("enquiryData"));
  console.log(info, "seed");
  console.log(enquiryData,"enquiry");
  let filnename =
    "FOODPAGE-CONTRACT" + info?.selectedDeal?.dealId ?? 0 + ".pdf";

  const stageId = JSON.parse(sessionStorage.getItem("stageDetails"));

  const handleOpenModal = () => {
    setShowMailModal(true);
    sessionStorage.setItem("companyName", data?.company_name);
    sessionStorage.setItem("clientName", info?.selectedDeal?.name);
    sessionStorage.setItem("serviceName", JSON.stringify(dealDetails));
    sessionStorage.setItem("contractDate", data?.contractDate);
  };
  console.log(dealDetails, "deal");
  const handleSendContract = async () => {
    const contractData = JSON.stringify(info);

    const canvas = await html2canvas(targetRef.current, {
      imageTimeout: 0,
      scale: 2, // Adjust the scale to balance between quality and size
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.7); // Reduce quality to 70%
    const pdf = new jsPDF("p", "mm", "a4", true); // Enable compression

    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    const pdfBlob = pdf.output("blob");
    
    const mailContent = {
      subject: mailSubject,
      body: parsedBody?.data ? parsedBody?.data : parsedBody,
      cc: ccValue ?? "",
    };
  
    const formData = new FormData();

    formData.append("leadId", dealDetails.leadId);
    formData.append("psasId", stageId != null && stageId?.jobId);
    formData.append("sId", enquiryData.aId);
    formData.append("email", data.merchantEmail);
    formData.append("username", data.merchantCompanyName);
    formData.append("dealId", dealDetails.dealId);
    formData.append("userDetails", contractData);
    formData.append("file", pdfBlob, "contract.pdf");
    formData.append("mailContent", JSON.stringify(mailContent));
    // formData.append("contractId")

    await sendContractToClient(formData, {
      onSuccess: async (res) => {
        toast.success("Contract Sented Successfully!");
        sessionStorage.removeItem("companyName");
        sessionStorage.removeItem("clientName");
        sessionStorage.removeItem("serviceName");
        sessionStorage.removeItem("contractDate");
        navigate(-1);
      },
      onFailed: (err) => {
        toast.error("Something Went Wrong!");
        console.log(err);
      },
    });
  };

  const navBack = () => {
    navigate(-1);
  };

  return (
    <>
      {showMailModal && (
        <SendMailTemplateModal
          showMailModal={showMailModal}
          setShowMailModal={setShowMailModal}
          handleSendProposal={handleSendContract}
          parsedBody={parsedBody}
          setParsedBody={setParsedBody}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          ccValue={ccValue}
          setCcValue={setCcValue}
          title="contract"
          sendMail={handleSendContract}
          data={info}
        />
      )}
      <Toaster position="top-center" reverseOrder={false} />
      <div className="container">
        <div className="col-md-10 top_cont">
          <div className="container mt-3">
            <button className="back-contract" onClick={navBack}>
              <FaArrowLeftLong /> Back
            </button>
            {contractLoading ? (
              <button className="btn-contract" disabled>
                sending...
              </button>
            ) : (
              <button className="btn-contract" onClick={handleOpenModal}>
                Send Contract
              </button>
            )}
          </div>
        </div>
        <h4 className="mt-3 mb-3 cont_head text-center">
          Contract Signing Agreement
        </h4>
        <div
          className="contract-page MB-3 col-md-12 col-lg-10 col-sm-12 mx-auto"
          ref={targetRef}
        >
          <div className="mt-3">
            <div className="logo-are">
              <img className="logo-t p-2" src={Pakwaan}></img>
        
              <br />
            </div>
            <div className="data-cont">
              <b>{info.contractData.companyName}</b>
              {/* <b>{companyDetails?.company_name ?? "N/A"}</b> */}
              <br />
              <small>{info.contractData.phone}</small>
              {/* <small> {companyDetails.phone ?? "N/A"}</small> */}
              <br />
              <div className="row">
                <small>{info.contractData.email}</small>
                {/* <small> {companyDetails.email ?? "N/A"}</small> */}
                <small className="-rt">
                  Date :<b> {Utils.formatDate(data.contractDate) ?? "N/A"}</b>
                </small>
              </div>
            </div>
            <div className="contract">
              <p className="contract_details">
                {" "}
                This agreement is established on{" "}
                <b> {Utils.formatDate(data.contractDate) ?? "N/A "} </b>
                between <b> {info.contractData?.companyName ?? "N/A"}</b>, and
                its
                {/* between <b> {companyDetails?.company_name ?? "N/A"}</b>, and its */}
                principal office situated at
                {" " + info.contractData.address ?? "N/A"}, hereinafter referred
                to as
                {/* {" " + companyDetails.address ?? "N/A"}, hereinafter referred to as */}
                "Company".
                {/* <br /> */}
                <p className="mt-1">
                  and <br />
                  <b> {data.merchantCompanyName ?? "N/A"}</b>, having its
                  principal office at {data.merchantAddress ?? "N/A"},
                  hereinafter referred to as "Merchant". <br />
                </p>
                <div className="mt-3"></div>
                <b>Purpose:</b>
                <div className="mb-3"></div>
                The purpose of this agreement is to formalise the terms and
                conditions under which both parties agrees,
                <br />
                <div className="mt-3"></div>
                <h6>
                  <b>Terms and Conditions:</b>
                </h6>
                <div className="mb-3"></div>
                <ul className="list-cont">
                  <li>
                    <b>Product Details:</b> The parties hereby agree on the
                    specific products to be supplied, including quantities,
                    specifications, and quality standards.
                  </li>
                  <li>
                    <b>Price and Payment: </b>The price per unit and total
                    payment terms are as follows: Merchant shall pay
                    <b>
                      {" "}
                      {info.selectedDeal.currency}
                      {/* {companyDetails?.currency} */}
                      {dealDetails?.total ?? "N/A"}
                    </b>{" "}
                    to Pakwaan for the supplied products.
                  </li>
                  <li>
                    <b>Quality Assurance:</b> The Merchant agrees to adhere to
                    all applicable quality standards and regulations in the
                    production and supply of the Pakwaan products.
                  </li>
                  <li>
                    <b> Product Duration:</b> The Merchant acknowledges and
                    agrees that the duration{" "}
                    <b>
                      {dealDetails?.startDate ?? "N/A"} to
                      {" " + dealDetails?.endDate ?? "N/A"}
                    </b>{" "}
                    of product usage shall be as specified in this agreement,
                    building upon the terms established in the previous Deal.
                  </li>
                  <li>
                    <b> Confidentiality:</b> Both parties agree to maintain the
                    confidentiality of any proprietary or sensitive information
                    disclosed during the course of this agreement, including but
                    not limited to product specifications and business
                    strategies. The Merchant agrees that the software products
                    provided under this agreement shall not be shared with any
                    other party, and both parties shall take all necessary
                    measures to protect the confidentiality and security of the
                    data exchanged.
                  </li>
                  {/* <li>
                    <b> Consequences of Breach</b>: In the event of a breach of
                    this confidentiality agreement related to the usage of the
                    software product, the non-breaching party reserves the right
                    to immediately terminate this agreement. Furthermore, the
                    breaching party shall be liable for any resulting damages,
                    including but not limited to financial losses and legal
                    expenses incurred by the non-breaching party.
                  </li> */}
                </ul>
              </p>
              <div className="mt-3 mb-3">
                <p className="deal_head text-center mb-3">DEAL DETAILS</p>
                <div className="contract_Card">
                  <form className="deal_form">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Product</label>
                        <div className="form-disabled">
                          {dealDetails?.productName ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Price</label>
                        <div className="form-disabled">
                          {dealDetails?.price ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Quantity</label>
                        <div className="form-disabled">
                          {dealDetails?.quantity ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> VAT(%)</label>
                        <div className="form-disabled">
                          {dealDetails?.tax ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor="">VAT Amount</label>
                        <div className="form-disabled">
                          {(dealDetails?.price *
                            dealDetails?.quantity *
                            dealDetails?.tax) /
                            100}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor="">Total</label>
                        <div className="form-disabled">
                          {info.selectedDeal.currency}{dealDetails?.total ?? "N/A"}
                          {/* {companyDetails?.currency} {dealDetails?.total ?? "N/A"} */}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <label htmlFor=""> Start Date</label>
                        <div className="form-disabled">
                          {dealDetails?.startDate ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <label htmlFor=""> End Date</label>
                        <div className="form-disabled">
                          {dealDetails?.endDate ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor=""> Subscription Type</label>
                        <div className="form-disabled">
                          {dealDetails?.subscription ?? "N/A"}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <p className="desc">
                          {dealDetails.description ?? "N/A"}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="brkdiv"></div>

                <p className="details">
                  In witness whereof, the parties hereto have executed this
                  agreement as of the date mentioned above.{" "}
                </p>

                <div className="footer_contract footer_sign">
                  <p>
                    <b>
                      Contract Date:
                      {Utils.formatDate(data.contractDate) ?? "N/A"}
                    </b>
                  </p>
                  <p>Title: {data.title ?? "N/A"}</p>
                  <div className="row mb-3 footer-box">
                    <div className="col-md-8 col-sm-12">
                      <u>
                        <b>Company Info</b>
                      </u>
                      <p
                        className="mt-3 mb-3"
                        style={{ position: "relative", }}
                      >
                        {" "}
                      [Owner] <br />
                        Pakwaan
                        {/* {companyDetails?.company_name ?? "N/A"} */}
                      </p>
                      {/* <h6 style={{}}> {data.companyName ?? "N/A"}</h6> */}
                    </div>
                    <div className="col-md-4 col-sm-12">
                      {" "}
                      <p>
                        Authorised Signature:
                        <br />
                        <img src={Signature} className="signature" />
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3 mb-3 footer-box">
                    <div className="col-md-8 col-sm-12">
                      <u>
                        {" "}
                        <b>Merchant</b>
                      </u>
                      <h6 className="mt-3">
                        {" " + data.merchantCompanyName ?? "N/A"}
                        <br />( {" " + dealDetails?.shopDetails?.name ?? "N/A"})
                      </h6>
                      <h6 className="mt-3 address-contract">
                        {" " + data.merchantAddress ?? "N/A"}
                      </h6>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <p>
                        Merchant's Signature : <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractTemp;
