import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ProductSkelton({ count }) {
  return Array(count)
    .fill(0)
    .map((item, key) => (
      <>
        <div className="container">
          <>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-lg-6">
                <div className="card">
                  <h4 className="header text-center">
                    <Skeleton width={150} />
                  </h4>
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <div className="image-profile">
                        <Skeleton width={150} height={400} />
                      </div>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <div className="admin-data">
                        <ul className="support-service">
                          <li></li>

                          <li>
                            <h6>
                              <Skeleton width={150} />
                              <span>
                                <Skeleton width={150} />
                              </span>
                            </h6>
                          </li>
                          <li>
                            <h6>
                              <Skeleton width={150} />
                              <span>
                                <Skeleton width={150} />
                              </span>
                            </h6>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="p-3 m-3 des_">
                      <h6 className="head-des">
                        <Skeleton width={150} />
                        <span className="">
                          <Skeleton width={400} height={100} />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-lg-6">
                <div className="card images-div">
                  <div className="p-3 m-3">
                    <h4 className="text-center mb-3">
                      <Skeleton width={150} />
                    </h4>
                    <div className="row">
                      <div className="col-md-4 p-3 text-center">
                        <div className="row">
                          <Row>
                            <Col>
                              <Skeleton width={400} height={100} count={3} />
                            </Col>
                          </Row>
                        </div>
                        <div className="row">
                          <Skeleton width={150} height={100} />
                          <Skeleton width={150} height={100} />
                          <Skeleton width={150} height={100} />
                        </div>
                        <div className="row">
                          <Skeleton width={150} height={100} />
                          <Skeleton width={150} height={100} />
                          <Skeleton width={150} height={100} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mt-3">
                <div className="col-md-10">
                  <h5>Features</h5>

                  <div className="border">
                    <div className="col-md-12 col-lg-12 col-sm-12 border-bottom">
                      <h6 className="feature_bold">
                        <Skeleton width={150} />
                      </h6>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="row p-1">
                        <div className="col-md-3 ">
                          <p className="main-feature">
                            <Skeleton width={150} />
                          </p>
                        </div>
                        <div className="col-md-9">
                          <p className="feature-des">
                            <Skeleton width={150} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </>
    ));
}

export default ProductSkelton;
