import React, { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";
import CustomErrors from "../Constants/Error";
import jwt_decode from "jwt-decode";

const useAuth = () => {
  const [authLoading, setAuthLoading] = useState(false);
  const [cookieData, setCookieData] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [cookieError, setCookieError] = useState(null);
  const [isJobRole, setIsJobRole] = useState(null);
  const [role, setRole] = useState(null);

  const clearCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        for (let name of names) {
          caches.delete(name);
        }
      });
    }
    console.log("Cache cleared");
  };

  const loginAdmin = async (payload, { onSuccess, onFailed }) => {
    try {
      setAuthLoading(true);
      await BaseClient.post(APIEndpoints.adminLogin, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setAuthLoading(false);
    }
  };

  const getCookieData = async () => {
    try {
      setPageLoading(true);
      await BaseClient.get(APIEndpoints.getCookieData, {
        withCredentials: true,
        onSuccess: (res) => {
          if (res?.data != null && res?.data != "") {
            setCookieData(res?.data);
            const role = jwt_decode(res.data);
            setRole(role?.role);
            setIsJobRole(role?.jobRole);
          }
        },
        onFailed: (err) => {
          console.error("Session Error", err);
          setCookieError(CustomErrors.SESSION_ERROR);
        },
      });
    } finally {
      setPageLoading(false);
    }
  };

  const deleteCookieData = async ({ onLogoutSuccess }) => {
    try {
      setAuthLoading(true);
      await BaseClient.delete(APIEndpoints.deleteCookieData, {
        withCredentials: true,
        onSuccess: (res) => {
          setCookieData(null);
          onLogoutSuccess && onLogoutSuccess();
        },
        onFailed: (err) => {
          console.error("Session Logout Error", err);
        },
      });
    } finally {
      setAuthLoading(false);
    }
  };

  return {
    loginAdmin,
    getCookieData,
    deleteCookieData,
    authLoading,
    cookieData,
    pageLoading,
    isJobRole,
    role,
    cookieError,
    setCookieError,
    clearCache
  };
};

export default useAuth;
