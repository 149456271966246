import React from "react";
import Sidebar from "../../../Components/Sidebar";
import { Badge } from "react-bootstrap";
import * as Bs from "react-icons/bs";
import Profile from "../../../Assets/delivery-app.jpg";
import Switch from "react-input-switch";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../Context/AppContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

function StagesList() {
  const { id } = useParams();
  const { allSellerEnquiries } = useContext(AppContext);
  const [enquiryData, setEnquiryData] = useState(null);

  const getEnquiryDetails = () => {
    if (!id || !allSellerEnquiries) return;

    const singleData = allSellerEnquiries?.find((x) => x?.pceId == id);
    setEnquiryData(singleData);
  };

  useEffect(() => {
    getEnquiryDetails();
  }, [allSellerEnquiries]);



  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container-fluid">
          <section id="content-wrapper" class="dashboard-content-wrapper">
            <div class="row">
              <div class="col-lg-12 ">
                <h3 class="content-title mt-3 mb-3">Product Enquiry Details</h3>
              </div>
              <div class="col-md-6">
                <div class="dc-title">
                  <h6>Client info</h6>
                </div>
                <div class="dashboard-card card overflow">
                  <div class="">
                    <table class="table personal-info-table p-3 m-3">
                      <tbody>
                        <tr>
                          <td>
                            <b>Name</b>
                          </td>
                          <td>
                            {enquiryData?.sellerDetails?.sellerName ?? "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Email</b>
                          </td>
                          <td>
                            {" "}
                            {enquiryData?.sellerDetails?.sellerEmail ?? "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Phone</b>
                          </td>
                          <td> {enquiryData?.sellerDetails?.phone ?? "N/A"}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Mobile</b>
                          </td>
                          <td>
                            {" "}
                            {enquiryData?.sellerDetails?.mobile ?? "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="dc-title dashboard-card">
                  <h6>Assigned Employee Details</h6>
                </div>
                <div class="">
                  <div class="card dashboard-card p-3">
                    <ul className="details-en">
                      <li>
                        <h6>
                          Name :{" "}
                          <span>
                            {" "}
                            {enquiryData?.adminDetails?.name ?? "N/A"}
                          </span>
                        </h6>

                        <h6>
                          Email:{" "}
                          <span>
                            {" "}
                            {enquiryData?.adminDetails?.email ?? "N/A"}
                          </span>
                        </h6>
                        <h6>
                          Mobile:{" "}
                          <span>
                            {" "}
                            {enquiryData?.adminDetails?.mobile ?? "N/A"}
                          </span>
                        </h6>
                        <h6>Current Status :</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 col-sm-12 col-lg-6">
                <div className="card">
                  {enquiryData?.products != null &&
                    Array.isArray(enquiryData.products) &&
                    enquiryData.products.map((pro, index) => {
                      return (
                        <>
                          <h4 className="header text-center">
                            {pro.productName ?? "NOT Available"}
                          </h4>
                          <div className="row">
                            <div className="col-md-4 col-sm-12">
                              <div className="image-profile">
                                <img
                                  src={Profile}
                                  alt=""
                                  className="card-image"
                                />
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-12">
                              <div className="admin-data">
                                <ul className="support-service">
                                  <li>
                                    <h6>
                                      Price per Month :{" "}
                                      <span>
                                        £{pro.price_monthly ?? "Not Available"}
                                      </span>
                                    </h6>
                                  </li>
                                  <li>
                                    <h6>
                                      Price per Year :{" "}
                                      <span>
                                        {" "}
                                        £{pro.price_yearly ?? "Not Available"}
                                      </span>
                                    </h6>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="p-3 m-3 des_">
                              <h6 className="head-des">
                                {" "}
                                Description :
                                <span className="">
                                  {pro.description ?? "Not Available"}
                                </span>
                              </h6>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12">
                <h6>Stages</h6>
                <div className="card p-3">
                  <ul className="support-service">
                    <li>
                      <h6>
                        Stage Name : <span>Proposal</span>
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Stage Description :
                        <span style={{ textAlign: "justify" }}>
                          In publishing and graphic design, Lorem ipsum is a
                          placeholder text commonly used to demonstrate the
                          visual form of a document or a typeface without
                          relying on meaningful content. Lorem ip
                        </span>
                      </h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Sidebar>
    </div>
  );
}

export default StagesList;
