import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = () => {
  const [chart, setChart] = useState({});
  var baseUrl = "https://api.coinranking.com/v2/coins/?limit=10";
  var proxyUrl = "https://cors-anywhere.herokuapp.com/";
  var apiKey = "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx";
  const dummyChartData = {
    coins: [
      { name: "Coin1", price: 100 },
      { name: "Coin2", price: 150 },
      { name: "Coin3", price: 120 },
      { name: "Coin4", price: 180 },
      { name: "Coin5", price: 90 },
    ],
  };
  // useEffect(() => {
  //   const fetchCoins = async () => {
  //     await fetch(`${proxyUrl}${baseUrl}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-access-token": `${apiKey}`,
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           response.json().then((json) => {
  //             console.log(json.data);
  //             setChart(json.data);
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);f
  //       });
  //   };
  //   fetchCoins();
  // }, [baseUrl, proxyUrl, apiKey]);
  // console.log(dummyChartData);
  var data = {
    labels: dummyChartData?.coins?.map((x) => x.name),
    datasets: [
      {
        label: `${dummyChartData?.coins?.length} Coins Available`,
        data: dummyChartData?.coins?.map((x) => x.price),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "#ec2027",
          "rgba(54, 162, 235, 1)",
          "#fbad19",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {},
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  };

  return (
    <div>
      <Line data={data} height={400} options={options} />
    </div>
  );
};

export default LineChart;
