import React, { useContext, useState } from "react";
import Sidebar from "../../../Components/Sidebar";
import PhoneInput from "react-phone-input-2";

import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Bi from "react-icons/bi";
import * as Fa from "react-icons/fa";
import * as Io from "react-icons/io";
import * as Tb from "react-icons/tb";
import { AppContext } from "../../../Context/AppContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useEffect } from "react";
import Select from "react-select";

function AddEnquiry() {
  const navigate = useNavigate();
  const {
    shopCategories,
    companiesList,
    addLead,
    enquiryLoading,
    fetchAllSellerEnquiries,
    setCookieError,
  } = useContext(AppContext);

  const [enquiryData, setEnquiryData] = useState({
    shopName: " ",
    shopUrl: " ",
    name: " ",
    email: " ",
    mobile: " ",
    countryCode: " ",
    address1: " ",
    address2: " ",
    town: " ",
    country: " ",
    postCode: " ",
    password: " ",
    categoryId: [],
    enq_ref: " ",
    crAId: "",
  });
  const [categoryList, setCategoryList] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  useEffect(() => {
    if (!shopCategories) return;
    handleShopCategory();
  }, [shopCategories]);
  console.log(shopCategories, "categories");

  const handleShopCategory = () => {
    const newList =
      shopCategories != null &&
      shopCategories.map((cat) => ({
        value: cat.scgID,
        label: cat.name,
      }));
    console.log(newList, "newlist");
    setCategoryList(newList);
  };

  const onChangeJob = (item) => {
    if (!Array.isArray(item)) {
      console.error("onChangeJob: item is not an array");
      return;
    }
    setEnquiryData((prev) => ({
      ...prev,
      categoryId: item.map((e) => e.value),
    }));
  };

  useEffect(() => {
    const res = JSON.parse(localStorage.getItem("loginResponse"));
    if (!res) return;
    setEnquiryData((prev) => ({
      ...prev,
      crAId: res.adminId,
    }));
  }, []);
  const [isPassword, setIsPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(false);
  console.log(enquiryData.categoryId, "categoryId");
  const handlePhoneChange = (formattedValue, e) => {
    if (!formattedValue || !e) return;
    setEnquiryData((prev) => ({
      ...prev,
      mobile: formattedValue,
      countryCode: e.dialCode,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEnquiryData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlePassowrd = () => {
    if (isPassword.newPassword != isPassword.confirmPassword) {
      setError(true);
      toast.error("Password is not matching!");
      return false;
    } else if (isPassword.newPassword == isPassword.confirmPassword) {
      setError(false);
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordCheck = handlePassowrd();
    if (passwordCheck) {
      await addLead(enquiryData, {
        onSuccess: async (res) => {
          toast.success("Lead was added successfully!");
          await fetchAllSellerEnquiries();
          setTimeout(() => {
            navigate("/leads");
          }, 1200);
        },
        onFailed: (err) => {
          console.log(err);

          toast.error(err?.errormessage);

          if (err.errorCode === "TEXP") {
            setCookieError(null);
          }
          toast.error("Lead added not created, Try again!");
        },
      });
    }
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container-fluid">
          <div className="head-h4 text-center">Add Enquiry</div>
          <div className="container">
            <div className="card mt-3 p-3 main-gradient box">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  {/* <p className="form_sub">Lead info</p> */}
                  {/* <div className="col-lg-4 col-md-4 col-sm-4">
                    <label htmlFor="Privilege Name" className="review-label">
                      Client Name <span className="required_">*</span>
                    </label>
                    <div className="review-group ">
                      <input
                        name="shopName"
                        type="text"
                        id=""
                        className="review-input"
                        value={enquiryData?.shopName}
                        onChange={(e) => handleChange(e)}
                      />
                      <i className="inp_ico">
                        <Bs.BsShop />
                      </i>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-4 col-md-4 col-sm-4">
                    <label htmlFor="Privilege Name" className="review-label ">
                      Categories
                    </label>
                    <br />

                    <Select
                      options={categoryList}
                      // style={{
                      //   width: "100%",
                      //   border: "1px solid #e1e1e1",
                      //   height: "35px",
                      // }}

                      onChange={(item) => onChangeJob(item)}
                      isMulti
                    />
                  </div> */}
                  {/* <div className="col-lg-4 col-md-4 col-sm-4">
                    <label htmlFor="Privilege Name" className="review-label">
                      Shop Category
                    </label>
                    <div className="review-group ">
                      {" "}
                      <Select
                        className="category-select"
                        options={categoryList}
                        defaultValue={"Select category"}
                        onChange={onChangeJob}
                        isMulti
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-lg-4 col-md-4 col-sm-4">
                    <label htmlFor="Privilege Name" className="review-label">
                      Site URL
                    </label>
                    <div className="review-group ">
                      <input
                        name="shopUrl"
                        type="text"
                        id=""
                        className="review-input"
                        value={enquiryData?.shopUrl}
                        onChange={(e) => handleChange(e)}
                      />
                      <i className="inp_ico">
                        <Bs.BsShopWindow />
                      </i>
                    </div>
                  </div> */}
                  <div className="row mt-3">
                    <p className="form_sub">Contact info</p>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Contact Name <span className="required_">*</span>
                      </label>
                      <div className="review-group ">
                        <input
                          name="name"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData.name}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Bs.BsFilePerson />
                        </i>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label ">
                        Email Address <span className="required_">*</span>
                      </label>
                      <div className="review-group ">
                        <input
                          name="email"
                          type="email"
                          id=""
                          className="review-input"
                          value={enquiryData.email}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Io.IoMdMailOpen />
                        </i>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Phone Number <span className="required_"></span>
                      </label>

                      <div className="review-group">
                        <PhoneInput
                          className="phone-input"
                          country={"gb"}
                          value={enquiryData?.mobile}
                          onChange={handlePhoneChange}
                        />
                        <i className="inp_ico" id="phone_ico">
                          <Bs.BsTelephone />
                        </i>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Address Line 1
                      </label>
                      <div className="review-group ">
                        <input
                          name="address1"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.address1}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Fa.FaRegAddressCard />
                        </i>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Address Line 2
                      </label>
                      <div className="review-group ">
                        <input
                          name="address2"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.address2}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Fa.FaRegAddressCard />
                        </i>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Town/City
                      </label>
                      <div className="review-group ">
                        <input
                          name="town"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.town}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Bi.BiBuildingHouse />
                        </i>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4 mt-3">
                      <label htmlFor="Privilege Name" className="review-label">
                        County
                      </label>
                      <div className="review-group ">
                        <input
                          name="country"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.country}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Tb.TbBuildingBank />
                        </i>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4 mt-3">
                      <label htmlFor="Privilege Name" className="review-label">
                        Postal Code
                      </label>
                      <div className="review-group ">
                        <input
                          name="postCode"
                          type="text"
                          id=""
                          className="review-input"
                          value={enquiryData?.postCode}
                          onChange={(e) => handleChange(e)}
                        />
                        <i className="inp_ico">
                          <Bs.BsPostcard />
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <p className="form_sub">Password</p>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        New Password
                      </label>
                      <div
                        className={
                          !error ? "review-group" : "review-group error"
                        }
                      >
                        <input
                          type="password"
                          name=""
                          id=""
                          className="review-input"
                          value={isPassword?.newPassword}
                          onChange={(e) => {
                            setIsPassword((prev) => ({
                              ...prev,
                              newPassword: e.target.value,
                            }));
                            setEnquiryData((prev) => ({
                              ...prev,
                              password: e.target.value,
                            }));
                          }}
                        />
                        <i className="inp_ico">
                          <Bi.BiLock />
                        </i>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Confirm Password
                      </label>
                      <div
                        className={
                          !error ? "review-group" : "review-group error"
                        }
                      >
                        <input
                          type="text"
                          name=""
                          id=""
                          className="review-input"
                          value={isPassword?.confirmPassword}
                          onChange={(e) => {
                            setIsPassword((prev) => ({
                              ...prev,
                              confirmPassword: e.target.value,
                            }));
                          }}
                        />
                        <i className="inp_ico">
                          <Bi.BiLockOpen />
                        </i>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-4">
                      <label htmlFor="Privilege Name" className="review-label">
                        Select Company<span>*</span>
                      </label>
                      <div
                        className={
                          !error ? "review-group" : "review-group error"
                        }
                      >
                        <select
                          name=""
                          id=""
                          className="form-select company_select_proposal"
                          onChange={(e) =>
                            setEnquiryData((prev) => ({
                              ...prev,
                              companyId: e.target.value,
                            }))
                          }
                        >
                          <option value="" selected disabled>
                            Choose Company
                          </option>
                          {companiesList &&
                            companiesList.length != 0 &&
                            companiesList.map((company, index) => {
                              return (
                                <option value={company?.id} key={index}>
                                  {company?.company_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="row mt-3">
                  <p className="form_sub">Enquiry info</p>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="Privilege Name" className="review-label">
                      <Ai.AiOutlineEdit /> Reference
                    </label>
                    <textarea
                      name="enq_ref"
                      id=""
                      cols="30"
                      rows="10"
                      className="form-control"
                      value={enquiryData?.enq_ref}
                      onChange={(e) => handleChange(e)}
                    ></textarea>
                  </div>
                </div>
                <br />
                {enquiryLoading ? (
                  <button
                    type="submit"
                    className="fp-button ad_btn mt-3"
                    disabled
                  >
                    <ThreeDots
                      height="60"
                      width="60"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperClassName="loader_"
                    />
                  </button>
                ) : (
                  <button type="submit" className="fp-button ad_btn mt-3">
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default AddEnquiry;
