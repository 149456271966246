import React from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";

function CreateApk() {
    const navigate = useNavigate();
    return (
        <div className="container-fluid">
            <Sidebar>
                <div className="container-fluid m-4">
                    <h4 className=" text-center mt-4">Create APK</h4>
                </div>
                <div className="container">
                    <div className="card apk-heading m-3 mx-auto">
                        <form>
                            <div className="row m-3">
                                <div className="col-md-6">
                                    <label className="form-label review-label">Release Name</label>
                                    <div className="review-group">
                                        <input
                                            type="text"
                                            className="form-control review-input"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label review-label">Version</label>
                                    <div className="review-group">
                                        <input
                                            type="text"
                                            className="form-control review-input"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 mt-2">
                                    <div className="radio-group">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="tablet"
                                    />
                                    <label className="apk-label">
                                        tablet
                                    </label>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="phone"
                                    />
                                    <label className="apk-label" >
                                        Phone
                                    </label>
                                    </div>
                                </div>
                                <div className="col-md-10 mt-3">
                                    <div className="text-center">
                                        <textarea
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            placeholder="Release notes"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="upload-apk text-center">
                                        <input type="text" className="upload-input" />
                                        <button>Upload</button>
                                    </div>
                                </div>
                                <button type="submit" className="fp-button add_privilege mt-3">
                                    Create
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </Sidebar>
        </div>
    )
}

export default CreateApk;