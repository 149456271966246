import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import Select from "react-select";

import * as Md from "react-icons/md";
import * as Tb from "react-icons/tb";
import * as Bs from "react-icons/bs";
import { AppContext } from "../../Context/AppContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

function AddPrivileges() {
  const navigate = useNavigate();
  const { alljobRole, createPrivilege, getallPrivilages, privilageLoading } =
    useContext(AppContext);
  const [initialState, setInitialState] = useState({
    previlageName: "",
    f_route: "",
    b_route: "",
    jobRoles: [],
  });
  const [error, setError] = useState(false);
  const [singlejobRole, setSingleJobRole] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  useEffect(() => {
    if (!alljobRole) return;
    hanldeJobRoles();
  }, [alljobRole]);

  const hanldeJobRoles = () => {
    const newJobRoles = alljobRole.map((jobs) => ({
      value: jobs.p_jbId,
      label: jobs.role,
    }));
    setSingleJobRole(newJobRoles);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setInitialState((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleValidation = () => {
    if (
      initialState.previlageName.length == 0 ||
      initialState.f_route.length == 0 ||
      initialState.jobRoles.length == 0
    ) {
      setError(true);
      return false;
    }
  };

  const onChangeJob = (item) => {
    // console.log(item);
    setInitialState((prev) => ({
      ...prev,
      jobRoles: [...item.map((e) => e.value)],
    }));
  };

  const hanldeSubmit = async (e) => {
    e.preventDefault();

    const isError = handleValidation();

    if (isError) return;

    await createPrivilege(initialState, {
      onSuccess: async (res) => {
        // console.log(res);
        toast.success("Privilege has been added Successfully!");
        await getallPrivilages();

        setInitialState({
          previlageName: "",
          f_route: "",
          b_route: "",
          jobRoles: [],
        });

        setTimeout(() => {
          navigate("/all-privileges");
        }, 1200);
      },
      onFailed: (err) => {
        console.log(err);
        toast.error("Privilege is not inserted, Please try again!");
      },
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <Sidebar>
          <div div className="container-fluid m-3">
            <h6 className="head-h4">Privileges</h6>
            <p>Add a Privilege </p>
          </div>
          <div className="container">
            <div className="card m-3 p-3 box mx-auto">
              <form onSubmit={(e) => hanldeSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label htmlFor="Privilege Name" className="review-label">
                      Privilege Name
                    </label>
                    <div
                      className={
                        error && initialState.previlageName.length == 0
                          ? "review-group error"
                          : "review-group "
                      }
                    >
                      <input
                        type="text"
                        name="previlageName"
                        id=""
                        className="review-input"
                        value={initialState.previlageName}
                        onChange={handleChange}
                      />
                      <i className="inp_ico">
                        <Bs.BsPersonCheck />
                      </i>
                    </div>
                    {error && initialState.previlageName.length == 0 && (
                      <span className="error_">
                        Privilege Name is required!
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label htmlFor="Front Route" className="review-label">
                      Front Route
                    </label>
                    <div
                      className={
                        error && initialState.f_route.length == 0
                          ? "review-group error"
                          : "review-group "
                      }
                    >
                      <input
                        type="text"
                        name="f_route"
                        id=""
                        className="review-input"
                        value={initialState.f_route}
                        onChange={handleChange}
                      />
                      <i className="inp_ico">
                        <Tb.TbDirections />
                      </i>
                    </div>
                    {error && initialState.f_route.length == 0 && (
                      <span className="error_">Front Route is required!</span>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                    <label htmlFor="Back Route" className="review-label">
                      Back Route
                    </label>
                    <div
                      className={
                        error && initialState.b_route.length == 0
                          ? "review-group error"
                          : "review-group "
                      }
                    >
                      <input
                        type="text"
                        name="b_route"
                        id=""
                        className="review-input"
                        value={initialState.b_route}
                        onChange={handleChange}
                      />
                      <i className="inp_ico">
                        <Tb.TbDirections />
                      </i>
                    </div>
                    {error && initialState.b_route.length == 0 && (
                      <span className="error_">Back Route is required!</span>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                    <label htmlFor="Privilege Name" className="review-label">
                      Department
                    </label>

                    <Select
                      options={singlejobRole}
                      onChange={(item) => onChangeJob(item)}
                      isMulti
                    />
                    {error && initialState.jobRoles.length == 0 && (
                      <span className="error_">
                        Privilege Name is required!
                      </span>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="fp-button add_privilege mt-4"
                  disabled={privilageLoading}
                >
                  {!privilageLoading ? (
                    "Submit"
                  ) : (
                    <ThreeDots
                      height="60"
                      width="60"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperClassName="loader_"
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
        </Sidebar>
      </div>
    </div>
  );
}

export default AddPrivileges;
