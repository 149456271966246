import { useState } from "react";
import BaseClient from "../Helpers/BaseClient";
import { APIEndpoints } from "../Constants/APIEndpoints";

const useProfile = () => {
  const [profileLoading, setProfileLoading] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const fetchLoginData = async () => {
    try {
      setProfileLoading(true);
      await BaseClient.get(APIEndpoints.getAdminData, {
        onSuccess: (res) => {
          setLoginData(res.data);
        },
        onFailed: (err) => {
          console.log(err);
        },
      });
    } finally {
      setProfileLoading(false);
    }
  };

  const updateUserDetails = async (payload, { onSuccess, onFailed }) => {
    try {
      setProfileLoading(true);
      await BaseClient.put(APIEndpoints.updateLogin, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setProfileLoading(false);
    }
  };

  return {
    fetchLoginData,
    profileLoading,
    loginData,
    updateUserDetails,
  };
};
export default useProfile;
