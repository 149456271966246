import React from "react";

import print from "../../../Assets/print.png";
import PDF from "../../../Assets/pdf.png";
import { Badge, Table } from "react-bootstrap";
import * as Ai from "react-icons/ai";
import * as Fc from "react-icons/fc";
import Sidebar from "../../../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import MonthlyReport from "../../../Components/Charts/MonthlyReport";

function SalesReport() {
  const navigate = useNavigate();
  const handleViewReport = () => {
    navigate("/view-report");
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <h6>Monthly Report</h6>
        <div className="container">
          <div className="chart-comp">
            <MonthlyReport />
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default SalesReport;
