import React, { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import * as Bs from "react-icons/bs";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { toast, Toaster } from "react-hot-toast";

function Profile() {
  const { loginData, updateUserDetails } = useContext(AppContext);
  const [isEditable, setIsEditable] = useState(false);
  const [preValues, setPreValues] = useState({
    name: "",
    mobile: "",
    password: "",
  });

  useEffect(() => {
    setPreValues({
      name: loginData?.name || "",
      mobile: loginData?.mobile || "",
    });
  }, [loginData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPreValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleEditDetails = () => {
    setIsEditable(!isEditable);
  };
  const phonePattern = /^(\+\d{1,3})?(?:[0-9\-\(\)\/\.]\s?){6,15}[0-9]{1}$/;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phonePattern.test(preValues.mobile)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    const payload = {
      aId: loginData.paId,
      name: preValues.name,
      mobile: preValues.mobile,
      password: preValues.password,
    };

    try {
      await updateUserDetails(payload, {
        onSuccess: async (res) => {
          toast.success("Successfully Updated!");
          setIsEditable(false);
        },
        onFailed: (err) => {
          console.log("ERROR ON UPDATING ADMIN", err);
          toast.error(err.message);
        },
      });
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };
  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Sidebar>
        <h5 className="text-center mt-3">PROFILE</h5>
        <div className="container margin-container">
          <div className="col-md-10 mx-auto">
            <div className="row">
              <div className="col-lg-6 col-md-10 col-sm-12 mx-auto mt-3">
                <div className="card p-3 mt-3">
                  <div className="edit-pen" onClick={handleEditDetails}>
                    <i>
                      <Bs.BsPencil /> Edit
                    </i>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="">
                      <div className="col-auto">
                        <div className="form-group">
                          <label
                            htmlFor="Privilege Name"
                            className="review-label"
                          >
                            Name
                          </label>
                          <div className="review-group group-in">
                            <input
                              name="name"
                              type="text"
                              value={preValues.name}
                              onChange={handleChange}
                              id=""
                              className="review-input"
                              disabled={!isEditable}
                            />
                          </div>
                        </div>

                        <div className="form-group mt-2">
                          <label
                            htmlFor="Privilege Name"
                            className="review-label"
                          >
                            Mobile
                          </label>
                          <div className="review-group group-in">
                            <input
                              name="mobile"
                              type="mobile"
                              value={preValues.mobile}
                              className="review-input"
                              disabled={!isEditable}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {isEditable && (
                          <div className="form-group mt-2">
                            <label
                              htmlFor="Privilege Name"
                              className="review-label"
                            >
                              Password
                            </label>
                            <div className="review-group group-in">
                              <input
                                name="password"
                                type="password"
                                value={preValues.password}
                                onChange={handleChange}
                                className="review-input"
                              />
                            </div>
                          </div>
                        )}

                        <div className="text-center">
                          {isEditable && (
                            <button type="submit" className="submit_btn mt-3">
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default Profile;
