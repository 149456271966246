import React from "react";
import CustomErrors from "../../Constants/Error";
import { useNavigate } from "react-router-dom";
import restricted from "../../Assets/no-entry.png";

function RestrictedEntry() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="error_wrapper">
        <div className="error_content">
          <div className="row">
            <div className="col-12">
              <div className="error_img">
                <img src={restricted} />
              </div>
              <div className="err_">
                <h2>Can't Redirect to this Page</h2>
                <p className="msg-para">{CustomErrors.UNAUTHORIZED_ACCESS}</p>
                <br />
                <button
                  type="button"
                  className="err_btn"
                  onClick={() => navigate("/")}
                >
                  Back to Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestrictedEntry;
