import React, { useState } from "react";
import Sidebar from "../../Components/Sidebar";
import * as Md from "react-icons/md";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function SubscriptionDetails() {
  const navigate = useNavigate();
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const rowData = [
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      role: "admin",
      amount: "$2000",
      type: "Monthly",
      date: "22-05-2023",
      product: "Order Online System",
      status: "Pending",
    },
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      role: "admin",
      amount: "$2000",
      type: "Monthly",
      date: "22-05-2023",
      product: "Order Online System",
      status: "Pending",
    },
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      role: "admin",
      amount: "$2000",
      type: "Monthly",
      date: "22-05-2023",
      product: "Order Online System",
      status: "Pending",
    },
    {
      id: 1,
      name: "Stephen",
      email: "stephenkwen@gmal.com",
      mobile: "684654521221",
      type: "Monthly",
      role: "admin",
      amount: "$2000",
      date: "22-05-2023",
      product: "Order Online System",
      status: "Pending",
    },
  ];
  return (
    <div className="container-fluid">
      <Sidebar>
        <div div className="container-fluid m-3">
          <h6 className="head-h4">All Subscriptions</h6>
          <p>Subscription List</p>
        </div>
        <div className="card p-3 m-3 overflow">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Product</th>
                <th scope="col">Sub.Type</th>
                <th scope="col">Amount</th>
                <th scope="col" className="text-center">
                  Status
                </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {rowData.map((row, index) => (
                <tr key={row.id}>
                  <th scope="row">{row.id}</th>
                  <td>{row.name}</td>
                  <td>{row.product}</td>
                  <td>{row.type}</td>
                  <td>{row.amount}</td>
                  <td className="text-center">
                    <h4>
                      <Badge bg="danger" className="badges">
                        {row.status}
                      </Badge>
                    </h4>
                  </td>
                  <td className="text-center position-relative">
                    <div className="btn-toggle">
                      <button className="one-btn">Action</button>
                      <button
                        className="two-btn"
                        onClick={() => toggleDropdown(index)}
                      >
                        <Md.MdOutlineArrowDropDown />
                      </button>
                    </div>
                    {openDropdownIndex === index && (
                      <ul className="table-mgt" role="menu">
                        <li>
                          <button
                            className="btn"
                            onClick={() => {
                              navigate("/view-sub-details");
                            }}
                          >
                            View
                          </button>
                        </li>
                      </ul>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Sidebar>
    </div>
  );
}

export default SubscriptionDetails;
