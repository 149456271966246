import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "../../../Components/Sidebar";
import * as Ai from "react-icons/ai";
import * as Md from "react-icons/md";
import * as Lu from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import * as Bi from "react-icons/bi";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../../Style/Style.css";
import { AppContext } from "../../../Context/AppContext";
import AllLeads from "../../../Components/Leads/AllLeads";
import RegisteredUsers from "../../../Components/Leads/RegisteredUsers";
import CanceledLeads from "../../../Components/Leads/CanceledLeads";
import LeadByAdmin from "../../../Components/Leads/LeadByAdmin";

function SubscriberEnquiry() {
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const { allSellerEnquiries } = useContext(AppContext);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [sellerData, setSellerData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    fetchSellerDetails();
    // checkAddEnquiryPrivilege();
  }, [allSellerEnquiries]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpenDropdownIndex(-1);
    }
  };

  const fetchSellerDetails = () => {
    if (!allSellerEnquiries) return;
    const sellerDetailsArray = allSellerEnquiries?.map((data) => ({
      sellerDetails: data.sellerDetails,
      pceId: data.pceId,
    }));
    setSellerData(sellerDetailsArray);
  };

  const checkSellerStatus = (id) => {
    if (!id) return;
    const getStatus = allSellerEnquiries.find((x) => x.sId == id);

    return getStatus ? getStatus.status : "No Status";
  };

  const checkAssignedStatus = (id) => {
    if (!id) return;

    const getChecked = allSellerEnquiries.find((x) => x.sId == id);

    return getChecked ? getChecked.assigned : "Not Available";
  };

  const filteredEnquiries = {
    leads: allSellerEnquiries.filter(
      (enquiry) => enquiry.byAdmin === 1 && enquiry.cancelStatus === 0
    ),
    registeredUsers: allSellerEnquiries.filter(
      (enquiry) => enquiry.byAdmin === 0 && enquiry.cancelStatus === 0
    ),
    cancelled: allSellerEnquiries.filter(
      (enquiry) => enquiry.cancelStatus === 1
    ),
  };

  return (
    <div className="container-fluid">
      <Sidebar>
        <div className="container-fluid nav-for-tab">
          {/* <h6 className="">Subscriber Enquiries</h6>
          <p>
            <div className="search-bar-div">
              <input type="text" placeholder="Search something.." />
              <button className="search-btn">
                <i>
                  <Bi.BiSearch />
                </i>
              </button>
            </div>
            <span>
              <button
                className="fp-add"
                onClick={() => navigate("/add-enquiry")}
              >
                + Add
              </button>
            </span>
          </p> */}
          <div className="row mb-3 mt-3">
            <div className="col-md-4">
              <h6 className="">Subscriber Enquiries</h6>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <div className="search-bar-div">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="sr-ch"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <button className="search-btn">
                    <i>
                      <Bi.BiSearch />
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <button
                className="fp-add"
                onClick={() => navigate("/add-enquiry")}
              >
                + Add
              </button>
            </div>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Registered Users</Tab>
            <Tab>Cancelled</Tab>
          </TabList>

          <TabPanel>
            <RegisteredUsers
              wrapperRef={wrapperRef}
              filteredEnquiries={filteredEnquiries}
              searchTerm={searchTerm}
            />
          </TabPanel>
          <TabPanel>
            <CanceledLeads
              wrapperRef={wrapperRef}
              filteredEnquiries={filteredEnquiries}
              searchTerm={searchTerm}
            />
          </TabPanel>
        </Tabs>
      </Sidebar>
    </div>
  );
}

export default SubscriberEnquiry;
