import React, { useContext, useRef, useState } from "react";
import * as Md from "react-icons/md";
import Sidebar from "../../Components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { Button, Modal } from "antd";
import { AppContext } from "../../Context/AppContext";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { useEffect } from "react";

function Packages() {
  const packageRef = useRef();
  const navigate = useNavigate();
  const {
    planList,
    productList,
    featureList,
    deletePackage,
    updatePackage,
    getAllPlanList,
  } = useContext(AppContext);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedState, setUpdatedState] = useState({
    planName: "",
    duration_yearly: "365",
    duration_monthly: "30",
    durationIn: "days",
    price_monthly: "",
    price_yearly: "",
    currency: "£",
    specialFeatures: "",
    products: [
      {
        pId: "",
        pdfId: "",
      },
    ],
  });

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const products = productList.map((item) => ({
    value: `${item.pdfId} ${item.pId}`,
    label: item.productName,
  }));

  const special = featureList.map((group) => ({
    value: group.pdfId,
    label: group.featureName,
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [planList]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = planList.slice(indexOfFirstItem, indexOfLastItem);
  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  console.log("planList", planList);

  const showDeleteConfirm = (plnId) => {
    Modal.confirm({
      title: "Are you sure delete this Plan?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(plnId);
      },
    });
  };

  const handleDelete = async (plnId) => {
    await deletePackage(plnId, {
      onSuccess: async (res) => {
        toast.success("Package Deleted Successfully!");
        await getAllPlanList();
      },
      onFailed: (err) => {
        toast.error("Something Went Wrong!");
        console.log(err);
      },
    });
  };
  const handleUpdate = (plan) => {
    setUpdatedState({
      planName: plan.planName,
      duration_yearly: "365",
      duration_monthly: "30",
      durationIn: "days",
      price_monthly: plan.price_monthly,
      price_yearly: plan.price_yearly,
      currency: "£",
      specialFeatures: "",
      products: plan.products.map((product) => ({
        pId: product.pId,
        pdfId: product.pdfId,
      })),
    });
    console.log(updatedState, "updatedstate");

    console.log("selected", plan);
    setIsModalOpen(true);
  };
  const handleProductChange = (selectedProducts) => {
    setUpdatedState((prev) => ({
      ...prev,
      products: selectedProducts.map((selectedProduct) => ({
        pId: selectedProduct.value.split(" ")[1],
        pdfId: selectedProduct.value.split(" ")[0],
      })),
    }));
  };

  const handleSpecialFeatures = (selectedFeatures) => {
    console.log("Selected", selectedFeatures);
    setUpdatedState((prev) => ({
      ...prev,
      specialFeatures: selectedFeatures.map(
        (selectedFeature) => selectedFeature.value
      ),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (packageRef.current && !packageRef.current.contains(event.target)) {
        setOpenDropdownIndex(-1);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="container-fluid">
      <Sidebar>
        <div div className="container-fluid m-3">
          <Toaster position="top-center" reverseOrder={false} />
          <Modal
            title="Basic Modal"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <form onSubmit={(e) => handleUpdate(e)} className=" mb-3">
              <div className="row m-2 mt-3">
                <div className="form-group col">
                  <label className="form-label review-label">Plan Name</label>
                  <div className="review-group">
                    <input
                      type="text"
                      className="form-control review-input"
                      name="planName"
                      value={updatedState.planName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group col">
                  <label
                    htmlFor="pdfSelect"
                    className="form-label review-label"
                  >
                    Select Product
                  </label>
                  <Select
                    name="pId"
                    id="pdfSelect"
                    value={updatedState.products.map((product) => ({
                      value: `${product.pdfId} ${product.pId}`,
                      label: productList.find(
                        (item) => item.pId === product.pId
                      )?.productName,
                    }))}
                    onChange={handleProductChange}
                    options={products}
                    isMulti
                  />
                </div>
              </div>
              <div className="row m-2 mt-3">
                <div className="form-group col">
                  <label className="form-label review-label">
                    Price Monthly
                  </label>
                  <div className="review-group">
                    <input
                      type="text"
                      className="form-control review-input"
                      name="price_monthly"
                      value={updatedState.price_monthly}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Price Yearly
                  </label>
                  <div className="review-group">
                    <input
                      className="form-control review-input"
                      type="text"
                      onChange={handleChange}
                      name="price_yearly"
                      value={updatedState.price_yearly}
                    />
                  </div>
                </div>
              </div>

              <div className="row m-2 mt-3">
                <div className="form-group col">
                  <label htmlFor="" className="form-label review-label">
                    Special Features
                  </label>
                  <Select
                    name="pdfId"
                    id="pdfSelect"
                    value={updatedState.specialFeatures}
                    onChange={(e) => handleSpecialFeatures(e)}
                    options={special}
                    isMulti
                  />
                </div>
              </div>

              <div className="button-div text-center mx-auto">
                <button className="fp-button" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </Modal>
          <h6 className="head-h4">Packages</h6>
          <p>
            List of Packages
            <span>
              <button
                className="fp-add"
                onClick={() => navigate("/createpackage")}
              >
                + Add
              </button>
            </span>
          </p>
        </div>
        <div className="card m-3 p-3 overflow">
          <div className="container">
            {" "}
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Price Monthly</th>
                  <th scope="col">Price Yearly</th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems != null &&
                  Array.isArray(currentItems) &&
                  currentItems.map((plan, index) => (
                    <tr key={plan.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{plan?.planName ?? "N/A"}</td>
                      <td>£{plan?.price_monthly ?? "N/A"}</td>
                      <td>£{plan?.price_yearly ?? "N/A"}</td>

                      <td className="text-center position-relative">
                        <div className="btn-toggle">
                          <button className="one-btn">Action</button>
                          <button
                            className="two-btn"
                            onClick={() => toggleDropdown(index)}
                          >
                            <Md.MdOutlineArrowDropDown />
                          </button>
                        </div>
                        {openDropdownIndex === index && (
                          <ul
                            className="table-mgt"
                            role="menu"
                            ref={packageRef}
                          >
                            <li>
                              <button
                                className="btn"
                                onClick={() => {
                                  window.location.href = `/view-package/${plan.plnId}`;
                                }}
                              >
                                View
                              </button>
                            </li>
                            <li>
                              <button
                                className="btn"
                                onClick={() => handleUpdate(plan)}
                              >
                                Edit
                              </button>
                            </li>
                            <li>
                              <button
                                className="btn"
                                onClick={() => showDeleteConfirm(plan.plnId)}
                              >
                                Delete
                              </button>
                            </li>
                          </ul>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-center mx-auto">
          {planList.length >= 1 && (
            <div className="pagination">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                onPageChange={handlePageChange}
                pageCount={Math.ceil(planList.length / itemsPerPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"disabled"}
                activeClassName={"paging__link--active"}
              />
            </div>
          )}
        </div>
      </Sidebar>
    </div>
  );
}

export default Packages;
