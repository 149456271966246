import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { toast, Toaster } from "react-hot-toast";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";

function MerchantInvoice() {
  const navigate = useNavigate();
  const { sellerList, productList, createMerchantInvoice } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);

  const [productData, setProductData] = useState([
    {
      pId: "",
      productName: "",
      price: "",
      quantity: "",
      discount: "",
      tax: "",
      total: "",
    },
  ]);

  const [invoiceData, setInvoiceData] = useState({
    email: "",
    invoiceDate: null,
    bankDetails: {
      paymentAccount: "40020254151212212",
      bank: "State Bank Of India",
      ifsc: "SBI001523",
    },
    companyInfo: {
      address: "30A Ipswich Road, Colchester, United Kingdom",
      email: "mail@ferns-It.com",
      phone: "+91 7994281474",
    },
    method: "other",
    subscription: "monthly",
    billingAddress: "",
    contractDate: null,
    dueDate: null,
    recurring_type: "monthly",
    recurring_limit: null,
  });

  const [itemCount, setItemCount] = useState(1);

  const handleAddInput = () => {
    setItemCount(itemCount + 1);
  };

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selectedSellerData = sellerList.find(
      (seller) => seller.id.toString() === selectedId
    );
    setSelectedSeller(selectedSellerData);
    setInvoiceData((prevData) => ({
      ...prevData,
      email: selectedSellerData?.email || "",
      billingAddress: `${selectedSellerData?.address1 || ""} ${
        selectedSellerData?.address2 || ""
      }`,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setInvoiceData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const onChnageInfo = (e) => {
    const { name, value } = e.target;
    const updatedInvoiceData = {
      ...invoiceData,
      bankDetails: {
        ...invoiceData.bankDetails,
        [name]: value,
      },
    };
    setInvoiceData(updatedInvoiceData);
  };
  const onchangeCompany = (e) => {
    const { name, value } = e.target;
    const updatedInvoiceData = {
      ...invoiceData,
      companyInfo: {
        ...invoiceData.companyInfo,
        [name]: value,
      },
    };
    setInvoiceData(updatedInvoiceData);
  };
  const handleProductChange = (event, index) => {
    const selectedProductId = event.target.value;
    const selectedProduct = productList.find(
      (product) => product.pId === parseInt(selectedProductId)
    );

    setProductData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        pId: selectedProduct.pId,
        productName: selectedProduct.productName,
        price: selectedProduct.price,
      };
      return newData;
    });
  };

  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    setProductData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [name]: value };
      return newData;
    });
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prev) => ({ ...prev, [name]: value }));
  };
  const onAddressChnage = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    productData.forEach((item) => {
      subtotal +=
        parseFloat(item.price) * parseFloat(item.quantity) -
        parseFloat(item.discount) +
        parseFloat(item.tax);
    });

    return subtotal.toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const total = parseFloat(subtotal);
    return total.toFixed(2);
  };
  const handleTestChange = (event) => {
    const { name, value } = event.target;
    setInvoiceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validate = () => {
    if (invoiceData.invoiceDate == null || invoiceData.dueDate == null) {
      toast.error("Please Fill date!");
      return;
    }
    if (productData.every((product) => product.pId === "")) {
      toast.error("Please add at least one product");
      return;
    }
    if (calculateTotal() === NaN) {
      toast.error("Total is not valid!");
      return;
    }
    if (
      invoiceData.recurring_limit === null ||
      invoiceData.recurring_limit === ""
    ) {
      toast.error("Please update Recurring Limit!");
    }
    if (invoiceData.billingAddress === "" || invoiceData.cust_email === "") {
      toast.error("Please update Empty Fields!");
    }

    return true;
  };
  const productValidation = () => {
    const isValid = productData.every((product) => {
      if (
        !product.price ||
        !product.quantity ||
        !product.productName ||
        !product.tax
      ) {
        toast.error("Please fill in all fields for each product");
        return false;
      }
      return true;
    });
    return isValid;
  };

  const handleSubmit = async () => {
    validate();
    productValidation();
    const product = productData.map((item) => {
      return {
        ...item,
        total:
          (parseFloat(item.price) || 0) * (parseFloat(item.quantity) || 0) +
          (parseFloat(item.tax) || 0) -
          (parseFloat(item.discount) || 0),
      };
    });

    const invoiceTotal = calculateTotal();
    // const product = JSON.stringify(productData);
    const bankDetails = JSON.stringify({
      paymentAccount: invoiceData?.bankDetails.paymentAccount,
      bank: invoiceData?.bankDetails.bank,
      ifsc: invoiceData?.bankDetails.ifsc,
    });
    const companyInfo = JSON.stringify({
      address: invoiceData?.companyInfo.address,
      email: invoiceData?.companyInfo.email,
      phone: invoiceData?.companyInfo.phone,
    });
    const payload = {
      sId: selectedSeller?.id,
      cust_name: selectedSeller?.name,
      total: invoiceTotal,
      billingAddress: invoiceData?.billingAddress,
      cust_email: invoiceData?.email,
      invoice_date: invoiceData?.invoiceDate,
      due_date: invoiceData?.dueDate,
      method: invoiceData?.method,
      subscription: invoiceData?.subscription,
      productDetails: JSON.stringify(product),
      bankDetails: bankDetails,
      company_info: companyInfo,
      recurring_limit: invoiceData.recurring_limit,
      recurring_type: invoiceData.recurring_type,
    };

    sessionStorage.setItem("merchantInvoice", JSON.stringify(payload));

    await createMerchantInvoice(payload, {
      onSuccess: (res) => {
        // toast.success("Successfully sented Invoice");
        const invnum = res.data;
        console.log(invnum);
        sessionStorage.setItem("invoiceResponse", JSON.stringify(invnum));
        setLoading(false);
        navigate(
          `/partner-foodpage-invoice/${invnum != null && invnum?.invoiceId}`
        );
      },
      onFailed: (err) => {
        setLoading(false);
        toast.error(err.errormessage);
      },
    });
  };

  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Sidebar>
        <section id="content-wrapper" class="dashboard-content-wrapper">
          <div className="row">
            <div className="col-md-6">
              {" "}
              <h6 className="gen_in">Generate Invoice</h6>
            </div>
            <div className="col-md-6">
              {loading ? (
                <button className="sub_btn" disabled>
                  Generating...
                </button>
              ) : (
                <button
                  type="submit"
                  className="sub_btn"
                  onClick={handleSubmit}
                >
                  Generate
                </button>
              )}
            </div>
          </div>
          <div className="row gen-area">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <small>Select a Merchant</small> <br />
              <select
                name=""
                id=""
                className="form-control assign_salesmen"
                onChange={handleSelectChange}
              >
                <option>Select Merchant</option>
                {sellerList &&
                  sellerList.map((seller) => (
                    <option key={seller.id} value={seller.id}>
                      {seller?.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <small> Email</small> <br />
              <div className="mt-1">
                <input
                  type="email"
                  className="p-1 invoice_input"
                  value={invoiceData.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-2 col-sm-12">
              <div className="subscription_">
                <small >Subscription Type</small>
                <br />
                <input
                  name="subscription"
                  value="monthly"
                  className="in_inv"
                  type="radio"
                  id="monthly"
                  checked={invoiceData?.subscription === "monthly"}
                  onChange={handleChange}
                />
                <label for="MONTHLY">Monthly</label>
                <br />
                <input
                  type="radio"
                  name="subscription"
                  value="yearly"
                  className="in_inv"
                  id="yearly"
                  checked={invoiceData?.subscription === "yearly"}
                  onChange={handleChange}
                />
                <label>Yearly</label>
              </div>
            </div>
            <div className="col-md-2 col-sm-12">
              <small className="mb-3"> Customer Make Payment </small>
              <br />
              <input
                name="method"
                value="directDebit"
                id="directDebit"
                className="in_inv"
                type="radio"
                checked={invoiceData?.method === "directDebit"}
                onChange={handleChange}
              />
              <label for="direct debit">Direct debit</label>
              <br />
              <input
                name="method"
                value="other"
                type="radio"
                className="in_inv"
                id="other"
                checked={invoiceData?.method === "other"}
                onChange={handleChange}
              />
              <label for="vehicle2">Other ways</label>
              <br />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <small className="">Billing Address</small>
              <br />
              <textarea
                type="text"
                value={invoiceData.billingAddress}
                className="p-1 invoice_teactarea"
                name="billingAddress"
                onChange={onAddressChnage}
              />
            </div>
            <div className="col-md-4 col-lg-2 col-sm-12">
              <small>Invoice Date</small>
              <br />
              <input
                id="invoiceDate"
                className="form-control"
                type="date"
                value={invoiceData.invoiceDate}
                onChange={handleChangeData}
                name="invoiceDate"
              />
            </div>
            <div className="col-md-4 col-lg-2 col-sm-12">
              <small>Due Date</small>

              <br />
              <input
                id="dueDate"
                className="form-control"
                type="date"
                value={invoiceData.dueDate}
                onChange={handleChangeData}
                name="dueDate"
              />
            </div>

            <div className="col-md-4 col-lg-2 col-sm-12">
              <small>Recurring Type</small>
              <br />
              <select
                name="recurring_type"
                id="recurring_type"
                value={invoiceData.recurring_type}
                onChange={handleTestChange}
                className="form-control"
              >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </select>
            </div>
            <div className="col-md-4 col-lg-2 col-sm-12">
              <small>Recurring limit</small>
              <input
                type="text"
                value={invoiceData.recurring_limit}
                className="form-control"
                name="recurring_limit"
                onChange={handleChange}
              />
            </div>
           

            <b className="mt-3 mb-1">Our Info</b>
            <div className="col-md-4 col-sm-12">
              <small className="">Address</small>
              <br />
              <textarea
                type="text"
                value={invoiceData.companyInfo.address}
                className="p-1 invoice_teactarea"
                name="address"
                onChange={onchangeCompany}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <small className="">Email</small>
              <br />
              <input
                type="email"
                value={invoiceData.companyInfo.email}
                className="form-control"
                name="email"
                onChange={onchangeCompany}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <small className="">Phone</small>
              <br />
              <input
                type="text"
                value={invoiceData.companyInfo.phone}
                className="form-control"
                name="phone"
                onChange={onchangeCompany}
              />
            </div>
          </div>

          <div className="row mt-3">
            <h6 className="mt-3">Enter Invoice Details</h6>
            <div className="mer_inoice">
            <table className="table inv_table">
              <tr>
                <th className="new"></th>
                <th className="sl_no">Sl No:</th>
                <th>Services</th>
                <th>Quantity</th>
                <th style={{ width: "20%" }}>Rate</th>
                <th>Discount</th>
                <th>Tax Amount</th>
                <th>Total</th>
              </tr>

              <tbody>
                {[...Array(itemCount)].map((_, index) => (
                  <tr key={index}>
                    <td className="new">
                      <i className="add_icon" onClick={handleAddInput}>
                        <IoIosAddCircleOutline />
                      </i>
                    </td>
                    <td className="sl_no">{index + 1}</td>
                    <td>
                      <select
                        className="sel_inv p-2"
                        onChange={(event) => handleProductChange(event, index)}
                      >
                        <option>Select Product</option>
                        {productList &&
                          productList.map((product) => (
                            <option key={product.pId} value={product.pId}>
                              {product?.productName}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="p-2 small_input"
                        name="quantity"
                        value={productData[index]?.quantity || ""}
                        onChange={(event) => handleOnChange(event, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="p-2 small_input"
                        name="price"
                        value={productData[index]?.price || ""}
                        onChange={(event) => handleOnChange(event, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="p-2 small_input"
                        name="discount"
                        value={productData[index]?.discount || ""}
                        onChange={(event) => handleOnChange(event, index)}
                      />
                    </td>

                    <td>
                      <input
                        type="text"
                        className="p-2 small_input"
                        name="tax"
                        value={productData[index]?.tax || ""}
                        onChange={(event) => handleOnChange(event, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="p-2 small_input"
                        name="total"
                        value={
                          parseFloat(productData[index]?.price || 0) *
                            parseFloat(productData[index]?.quantity || 0) +
                            parseFloat(productData[index]?.tax || 0) -
                            parseFloat(productData[index]?.discount || 0) || ""
                        }
                        onChange={(event) => handleOnChange(event, index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                {/* <tr className="text-offset">
                  <td colSpan="7">
                    <b>SubTotal</b>
                  </td>
                  <td>
                    {isNaN(calculateSubtotal()) ? "0.00" : calculateSubtotal()}
                  </td>
                </tr> */}
                <tr className="text-offset">
                  <td colSpan="7">
                    <b>Payable</b>
                  </td>
                  <td>
                    {" "}
                    {isNaN(calculateTotal()) ? "0.00" : calculateTotal()}
                  </td>
                </tr>
              </tfoot>
            </table>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="row p-1">
                <div className="card">
                  <div className="mt-3">
                    <b> Message on Invoice</b> <hr />
                  </div>
                  <p>
                    Payment Account :
                    <input
                      type="text"
                      className="p-1 mt-1 invoice_input"
                      name="paymentAccount"
                      value={invoiceData.bankDetails.paymentAccount}
                      onChange={onChnageInfo}
                    />
                  </p>
                  <p>
                    Bank Name :{" "}
                    <input
                      type="text"
                      className="p-1 mt-1 invoice_input"
                      name="bank"
                      value={invoiceData.bankDetails.bank}
                      onChange={onChnageInfo}
                    />
                  </p>
                  <p>
                    IFSC Code :{" "}
                    <input
                      type="text"
                      className="p-1 mt-1 invoice_input"
                      value={invoiceData.bankDetails.ifsc}
                      name="ifsc"
                      onChange={onChnageInfo}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Sidebar>
    </div>
  );
}

export default MerchantInvoice;
