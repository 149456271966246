import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import { Modal, Select, message } from "antd";
import { toast, Toaster } from "react-hot-toast";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import * as Bs from "react-icons/bs";

function GenerateInvoice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    generateInvoice,
    balanceDue,
    setBalanceDue,
    invoiceNumber,
    setInvoiceNumber,
    companiesList,
    singleInvoiceGeneration,
  } = useContext(AppContext);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  // const invoiceData = JSON.parse(sessionStorage.getItem("DirectProposal"));
  const enquiry = JSON.parse(sessionStorage.getItem("enquiryData"));
  const seletedPro = sessionStorage.getItem("selectedProduct");
  const [packageDetails, setPackageDetails] = useState(null);
  const product = JSON.parse(seletedPro);

  const [totalAmount, setTotalAmount] = useState(0);
  const [isExport, setIsExport] = useState(false);

  useEffect(() => {
    if (seletedPro != null) {
      const pack = JSON.parse(seletedPro);
      setPackageDetails(JSON.parse(pack?.productDetails));
    }
  }, []);

  const [editInvoice, setEditInvoice] = useState({
    username: enquiry?.sellerDetails?.sellerName,
    email: enquiry?.sellerDetails?.sellerEmail,
    billingAddress:
      enquiry?.sellerDetails?.address1 +
      " " +
      enquiry?.sellerDetails?.address2 +
      " " +
      enquiry?.sellerDetails?.town +
      " " +
      enquiry?.sellerDetails?.postcode +
      " " +
      enquiry?.sellerDetails?.country,
    dueDate: null,
    paymentAccount: "123456",
    branch: "Trivandrum",
    method: "other",
    bank: "State Bank of India ",
    ifsc: "SBI123456",
    price: product?.price,
    sortCode: "SORTCODE",
    swifCode: "SWIFCODE",
    discount: 0,
    deposit: 0,
    message: "",
    productDescription: "",
  });

  useEffect(() => {
    if (packageDetails) {
      const totalwithoutroundoff =
        ((packageDetails?.values?.subTotal - editInvoice?.discount) *
          packageDetails?.values?.VAT) /
          100 +
        packageDetails?.values?.subTotal -
        editInvoice?.discount;
      const beforeAdvance = totalwithoutroundoff.toFixed(2);
      setTotalAmount(beforeAdvance);

      setBalanceDue(beforeAdvance);
      const after =
        ((packageDetails?.values?.subTotal - editInvoice?.discount) *
          packageDetails?.values?.VAT) /
          100 +
        packageDetails?.values?.subTotal -
        editInvoice?.discount -
        editInvoice.deposit;

      setBalanceDue(after);
    }
  }, [editInvoice, packageDetails]);
  const handleMethod = (e) => {
    setEditInvoice((prevEditDeals) => ({
      ...prevEditDeals,
      method: e.target.value,
    }));
  };
  const handleDiscount = (e) => {
    setEditInvoice((prev) => ({
      ...prev,
      discount: e.target.value,
    }));
  };
  const handleOk = () => {
    setIsExport(false);
  };

  const handleCancel = () => {
    setIsExport(false);
  };

  const handleDepositChange = (e) => {
    setEditInvoice((prev) => ({
      ...prev,
      deposit: e.target.value,
    }));
  };
  // const handleSub = (e) => {
  //   setEditInvoice((prevEditDeals) => ({
  //     ...prevEditDeals,
  //     subscription: e.target.value,
  //   }));
  // };
  useEffect(() => {
    setEditInvoice((prev) => ({
      ...prev,
      invoiceDate: getCurrentDate(),
    }));
  }, []);

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setEditInvoice((prev) => {
      const isDateInput = e.target.type === "date";
      const formattedValue = isDateInput ? e.target.value : value;
      return { ...prev, [name]: formattedValue };
    });
  };
  const handlePriceChange = (e) => {
    setEditInvoice((prev) => ({
      ...prev,
      price: e.target.value,
    }));
  };
  const onChnageInfo = (e) => {
    const { name, value } = e.target;
    setEditInvoice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // const onChangeDescription = (e) => {
  //   const { name, value } = e.target;
  //   setEditInvoice((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const getCurrentDate = () => {
    const currentDate = new Date();

    const formattedDate = currentDate.toISOString().split("T")[0];
    return formattedDate;
  };
  const contractDetails = JSON.parse(
    sessionStorage.getItem("selectedContract")
  );

  const validate = () => {
    if (
      editInvoice.username == undefined ||
      editInvoice.email == undefined ||
      editInvoice.billingAddress == undefined ||
      editInvoice.method.length === 0
    ) {
      toast.error("Please Fill all Fields!");
      return;
    }
    // if (
    //   !product ||
    //   !product?.productName ||
    //   !product?.price ||
    //   !product?.tax ||
    //   !product?.quantity
    // ) {
    //   toast.error(
    //     "Product details are not available. Please try again by generating the invoice!"
    //   );
    //   navigate(-1);
    //   return;
    // }
  };

  const submitInvoice = async () => {
    if (editInvoice.dueDate == null) {
      toast.error("Please Select Duedate!");
      return;
    }
    setInvoiceLoading(true);
    const taxAmount = (
      ((packageDetails?.values?.subTotal - editInvoice?.discount) *
        packageDetails?.values?.VAT) /
      100
    ).toFixed(2);

    validate();
    const ldId = sessionStorage.getItem("leadId");
    const leadId = ldId ?? "";

    const invoiceData = JSON.parse(seletedPro);
    const productData = [
      {
        productName: packageDetails?.packageName,
        price: packageDetails?.values?.subTotal,
        tax: packageDetails?.values?.VAT,
        taxAmount: taxAmount,
        discount: editInvoice?.discount,
        totalBeforeDeposit: totalAmount,
        deposit: editInvoice?.deposit,
        services: packageDetails?.services,
      },
    ];
    const bankdata = {
      paymentAccount: editInvoice.paymentAccount,
      bank: editInvoice.bank,
      ifsc: editInvoice.ifsc,
      sortCode: editInvoice.sortCode,
      swifCode: editInvoice.swifCode,
      branch: editInvoice.branch,
    };
    const payload = {
      propId: invoiceData.propId,
      total: balanceDue,
      cust_name: editInvoice.username,
      cust_email: editInvoice.email,
      billingAddress: editInvoice.billingAddress,
      invoice_date: editInvoice.invoiceDate,
      due_date: editInvoice.dueDate,
      method: editInvoice.method,
      bankDetails: JSON.stringify(bankdata),
      productDetails: JSON.stringify(productData),
      deposit: editInvoice?.deposit,
      message: editInvoice.message,
      leadId: leadId && typeof leadId == "string" ? parseInt(leadId) : leadId,
    };
    const storeData = {
      payload,
      message: editInvoice.message,
      productDescription: editInvoice.productDescription,
      bankDetails: editInvoice,
      packageDetails,
    };
    sessionStorage.setItem("invoiceDetails", JSON.stringify(storeData));

    await singleInvoiceGeneration(payload, {
      onSuccess: async (res) => {
        setInvoiceLoading(false);
        sessionStorage.setItem("DirectInvoiceNumber", JSON.stringify(res.data));
        const Invoice = JSON.parse(
          sessionStorage.getItem("DirectInvoiceNumber")
        );
        navigate(`/ferns-invoice/${Invoice != null && Invoice?.invoiceId}`);
        setTimeout(() => {
          setEditInvoice({
            username: "",
            email: "",
            billingAddress: "",
            dueDate: null,
            discount: 0,
            deposit: 0,
            message: "",
            productDescription: "",
          });
        }, [1000]);
      },
      onFailed: (err) => {
        toast.error(err.message);
        setInvoiceLoading(false);
      },
    });
  };
  const Nodata = () => toast("product Details are Empty");

  return (
    <div className="container-fluid">
      <Toaster position="top-center" reverseOrder={false} />
      <Sidebar>
        <section id="content-wrapper" class="dashboard-content-wrapper">
          <div className="row">
            <div className="col-md-10">
              <h6 className="gen_in">Generate Invoice</h6>
            </div>
            <div className="col-md-2">
              {invoiceLoading ? (
                <button className="gen_btn" type="button" disabled>
                  Generating...
                </button>
              ) : (
                <button
                  className="gen_btn"
                  type="button"
                  onClick={() => submitInvoice()}
                >
                  Generate
                </button>
              )}
            </div>
          </div>

          <Modal
            open={isExport}
            onOk={handleOk}
            width={"20%"}
            footer={false}
            onCancel={handleCancel}
          >
            {invoiceLoading ? (
              <h6>Generating....</h6>
            ) : (
              <>
                <p className="mt-3 mb-3">
                  Generate Invoice
                  <button
                    className="exis_btn"
                    onClick={() => {
                      sessionStorage.setItem("isExport", "false");
                      submitInvoice();
                    }}
                  >
                    <Bs.BsArrowRight className="animated-arrow" />
                  </button>
                </p>
              </>
            )}
          </Modal>

          <div className="row">
            <div className="col in_data ">
              <small> Customer Name</small> <br />
              <div className="mt-1">
                <input
                  type="text"
                  className="p-1 invoice_input"
                  value={editInvoice.username}
                  name="username"
                  onChange={onChnageInfo}
                />
              </div>
            </div>
            <div className="col in_data custo_name">
              <small> Email</small> <br />
              <div className="mt-1">
                <input
                  type="email"
                  className="p-1 invoice_input"
                  value={editInvoice.email}
                  onChange={onChnageInfo}
                  name="email"
                />
              </div>
            </div>
            <div className="col in_data">
              <small> Payment Method</small> <br />
              <select
                name="method"
                className="form-control"
                value={editInvoice?.method}
                onChange={handleMethod}
              >
                <option value="stripe">Stripe</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <small className="">Customer Address</small>
              <br />
              <textarea
                type="text"
                className="p-1 invoice_teactarea"
                value={editInvoice.billingAddress}
                onChange={onChnageInfo}
                name="billingAddress"
              />
            </div>
            <div className="col-md-2 col-sm-6">
              <small>Invoice Date</small>
              <br />
              <input
                id="invoiceDate"
                className="form-control"
                type="date"
                value={editInvoice.invoiceDate}
                onChange={handleChangeData}
                name="invoiceDate"
              />
            </div>
            <div className="col-md-2 col-sm-6">
              <small>Due Date</small>
              <br />
              <input
                type="date"
                className="inv_dat"
                value={editInvoice.dueDate}
                name="dueDate"
                onChange={onChnageInfo}
              />
            </div>
          </div>

          <div className="row mt-3">
            <h6 className="mt-3">Invoice Details</h6>
            <table className="table inv_table">
              <tr>
                {/* <th className="new"></th> */}
                <th className="sl_no">Sl No:</th>
                <th>Package</th>
                <th>Price</th>
                <th>VAT(%)</th>
              </tr>
              <tbody>
                <tr>
                  {/* <td className="new">
                      <i className="add_icon" onClick={handleAddInput}>
                        <IoIosAddCircleOutline />
                      </i>
                    </td> */}
                  <td className="sl_no">1</td>
                  <td>
                    {/* <Select options={deals} className="sel_inv" /> */}
                    {packageDetails?.packageName ?? "N/A"}
                    <br />
                    <br />

                    {packageDetails != null &&
                      packageDetails?.itemsArray != null &&
                      packageDetails?.itemsArray?.map((items, index) => {
                        return (
                          <tr key={index}>
                            <td>{items?.description ?? "N/A"}</td>
                            <td>{items?.price ?? "N/A"}</td>
                          </tr>
                        );
                      })}
                    {packageDetails != null &&
                      packageDetails?.elementArray != null &&
                      packageDetails?.elementArray?.map((items, index) => {
                        return (
                          <tr key={index}>
                            <td>{items?.item ?? "N/A"}</td>
                            <td>{items?.price ?? "N/A"}</td>
                          </tr>
                        );
                      })}
                    {packageDetails != null &&
                      packageDetails?.services != null &&
                      packageDetails?.services?.map((items, index) => {
                        return (
                          <tr key={index}>
                            <td>{items?.courseHead ?? "N/A"}</td>
                            <td>{items?.value ?? 0}</td>
                          </tr>
                        );
                      })}
                    <div className="mt-2"></div>
                    <label disabled style={{ color: "gray" }}>
                      Package Description
                    </label>
                    <input
                      type="text"
                      className="p-1 mt-1 invoice_input"
                      value={editInvoice.productDescription}
                      name="productDescription"
                      onChange={onChnageInfo}
                    />
                  </td>
                  <td>
                    {/* {" "}
                    <input
                      type="text"
                      className="discount"
                     
                      onChange={handlePriceChange}
                      name="price"
                    /> */}
                    <p>{packageDetails?.values?.subTotal ?? 0}</p>
                  </td>
                  <td> {packageDetails?.values?.VAT ?? "N/A"}</td>

                  {/* <td>
                    {(
                      ((invoiceData.dealData.price *
                        invoiceData.dealData.quantity -
                        editInvoice.discount) *
                        invoiceData.dealData.tax) /
                        100 +
                      invoiceData.dealData.price * invoiceData.dealData.quantity
                    ).toFixed(2)}
                  </td> */}
                </tr>
              </tbody>
              <tfoot>
                {/* <tr class="text-offset">
                  <td colspan="7">
                    <b>Total</b>
                  </td>
                  <td className="text-left">
                    {((invoiceData.dealData.price *
                      invoiceData.dealData.quantity -
                      editInvoice.discount) *
                      invoiceData.dealData.tax) /
                      100 +
                      invoiceData.dealData.price *
                        invoiceData.dealData.quantity -
                      editInvoice.discount}
                  </td>
                </tr> */}
                <tr class="text-offset" style={{ border: "none !important" }}>
                  <td colspan="3">
                    <span>Sub Total</span>
                  </td>
                  <td className="text-left">
                    {/* {(
                      ((product?.price * product?.quantity -
                        editInvoice?.discount) *
                        product?.tax) /
                      100 +
                      product?.price * product?.quantity
                    ).toFixed(2)} */}
                    {packageDetails?.values?.subTotal ?? 0}
                  </td>
                </tr>
                <tr class="text-offset table-row">
                  <td colspan="3">
                    <span>Discount amount</span>
                  </td>
                  <td className="text-left">
                    {" "}
                    <input
                      type="text"
                      className="discount"
                      value={editInvoice.discount}
                      onChange={handleDiscount}
                      name="discount"
                    />
                  </td>
                </tr>
                <tr class="text-offset" style={{ border: "none !important" }}>
                  <td colspan="3">
                    <span>VAT Amount</span>
                  </td>
                  <td className="text-left">
                    {(
                      ((packageDetails?.values?.subTotal -
                        editInvoice?.discount) *
                        packageDetails?.values?.VAT) /
                      100
                    ).toFixed(2)}
                    {/* {((packageDetails?.values?.subTotal -
                      editInvoice.discount) *
                      packageDetails?.values?.VAT) /
                      100}
                    {packageDetails?.values?.taxAmount ?? 0} */}
                  </td>
                </tr>
                <tr class="text-offset table-row">
                  <td colspan="3">
                    <h6>
                      <b>Total</b>
                    </h6>
                  </td>
                  <td className="text-left">
                    <span>{totalAmount ?? 0}</span>
                  </td>
                </tr>
                <tr></tr>
                <tr class="text-offset table-row">
                  <td colspan="3" className="table-row mt-3">
                    Deposit
                  </td>
                  <td className="text-left table-row">
                    <input
                      type="text"
                      className="discount"
                      value={editInvoice.deposit}
                      onChange={handleDepositChange}
                      name="deposit"
                    />
                  </td>
                </tr>{" "}
                <tr class="text-offset">
                  <td colspan="3">
                    <b>Balance Due</b>
                  </td>
                  <td className="text-left">
                    {balanceDue}
                    {/* {balanceDue.toFixed(1) ?? "N/A"} */}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="row mt-3">
            {/* <div className="col-md-6 col-sm-6 col-xl-4">
              <div className="card p-3">
                <div className="mt-3">
                  <b> Message on Invoice</b> <hr />
                </div>
                <p>
                  {" "}
                  {editInvoice?.bank} a/c :{editInvoice?.paymentAccount}
                </p>
                {editInvoice?.ifsc != null && editInvoice?.ifsc.length != 0 && (
                  <p>IFSC : {editInvoice?.ifsc ?? "N/A"}</p>
                )}

                <p>Branch : {editInvoice?.branch}</p>

                {editInvoice?.sortCode != null &&
                  editInvoice?.sortCode != undefined &&
                  editInvoice?.sortCode != "" && (
                    <p> SortCode : {" " + editInvoice?.sortCode ?? "N/A"}</p>
                  )}
                {editInvoice?.swifCode != null &&
                  editInvoice?.swifCode != undefined &&
                  editInvoice?.swifCode != "" && (
                    <p> SwtftCode : {" " + editInvoice?.swifCode ?? "N/A"}</p>
                  )}
              </div>
            </div>
            <div className="col-md-4"></div> */}
            <div className="col-md-4">
              <div className="col in_data">
                <label htmlFor="">Message</label>
                <div className="mt-1">
                  <textarea
                    className="message-inv p-2"
                    value={editInvoice.message}
                    name="message"
                    onChange={onChnageInfo}
                  />
                </div>
              </div>
              {/* <div className="col in_data">
                <label htmlFor="">Attach file</label>
                <div className="mt-1">
                
                  <input type="file" className="attach-inv"></input>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Sidebar>
    </div>
  );
}

export default GenerateInvoice;
