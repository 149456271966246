import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function PackageSkelton({ count }) {
  return Array(count)
    .fill(0)
    .map((item, key) => (
      <>
        <div class="card ">
          <h4 className="header text-center">
            <Skeleton width={200} />
          </h4>

          <div className="admin-data">
            <ul className="support-service">
              <li>
                <h6>
                  <Skeleton width={150} />
                </h6>
              </li>

              <li>
                <h6>
                  <Skeleton width={150} />
                </h6>
              </li>
              <li>
                <h6>
                  <Skeleton width={150} />
                  <span>
                    {" "}
                    <Skeleton width={150} />
                  </span>
                </h6>
              </li>
              <li>
                <h6>
                  <Skeleton width={150} />
                  <span>
                    <Skeleton width={150} />
                  </span>
                </h6>
              </li>
              <li>
                <div>
                  <h4 className="mt-3 pro-named">
                    <Skeleton width={150} />
                  </h4>
                  <div className="border p-3">
                    <div>
                      <div className="col-md-12 col-lg-12 col-sm-12 border-bottom">
                        <h6 className="feature_bolded text-center">
                          <Skeleton width={150} />
                        </h6>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <Skeleton
                          width={750}
                          baseColor="#ede8e8"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={750}
                          baseColor="#ede8e8"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={750}
                          baseColor="#ede8e8"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={750}
                          baseColor="#ede8e8"
                          highlightColor="#444"
                        />
                        <Skeleton
                          width={750}
                          baseColor="#ede8e8"
                          highlightColor="#444"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <h4 className="mt-3 pro-named">
            <Skeleton width={100} baseColor="#ede8e8" highlightColor="#444" />
          </h4>
          <div className="border" key={key}>
            <div className="xol-md-12">
              <p className="feature-bolder">
                <Skeleton
                  width={200}
                  baseColor="#ede8e8"
                  highlightColor="#444"
                />
              </p>
              <div className="main-feature">
                <Skeleton
                  width={600}
                  height={30}
                  baseColor="#ede8e8"
                  highlightColor="#444"
                />
              </div>
            </div>
          </div>
        </SkeletonTheme>
      </>
    ));
}

export default PackageSkelton;
