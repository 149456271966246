import React, { useContext, useEffect, useState } from "react";
import "../../Style/Style.css";
import Logo from "../../Assets/pakawaanlogoo.png";
import * as Ai from "react-icons/ai";
import useAuth from "../../Hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

function Login() {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const { loginAdmin, authLoading, setPrevilages, allPrivilages } =
    useContext(AppContext);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setLoginCredentials((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const formLoginSubmit = async (e) => {
    e.preventDefault();

    if (
      loginCredentials.email.length === 0 ||
      loginCredentials.password.length === 0
    ) {
      setError(true);
      return;
    }

    await loginAdmin(loginCredentials, {
      onSuccess: async (res) => {
        sessionStorage.setItem("userId", res?.data?.adminId);
        const companyId = res?.data?.companyId;
        sessionStorage.setItem("loginCompanyId", companyId);
        setPrevilages((prev) => ({ ...prev, ...res?.data }));
        window.localStorage.setItem("loginResponse", JSON.stringify(res?.data));
        sessionStorage.setItem("jobRoleData", res?.data?.jobRole);
        sessionStorage.setItem("userEmail", loginCredentials?.email);
        toast.success("Login Successfully!");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err?.message);
      },
    });
  };

  const loginResponseString = window.localStorage.getItem("loginResponse");

  let loginResponse;
  try {
    loginResponse = JSON.parse(loginResponseString);
  } catch (error) {
    console.error("Error parsing loginResponse:", error);
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="flex">
          <div className="flex-one">
            <div className="image-background" />
          </div>
          <div className="flex-two">
            <div className="container p-3">
              <div className="p-3 m-3 logged">
                <img src={Logo} className="logo-image" />

                <div className="form-box">
                  <h3 className="head-log">Enter your login credentials</h3>
                  <form action="" onSubmit={formLoginSubmit}>
                    <div className="form-group mb-2">
                      <label className="form-label review-label">Email</label>
                      <div
                        className={
                          error && loginCredentials.email.length == 0
                            ? "review-group error"
                            : "review-group "
                        }
                      >
                        <input
                          name="email"
                          type="text"
                          className="form-control review-input"
                          placeholder="Enter your email"
                          value={loginCredentials.email}
                          onChange={handleChange}
                        />
                        <i className="inp_ico">
                          <Ai.AiOutlineUser />
                        </i>
                      </div>
                      {error && loginCredentials.email.length == 0 && (
                        <span className="error_">Username is required!</span>
                      )}
                    </div>
                    <div className="form-group" style={{ marginTop: "15px" }}>
                      <label htmlFor="" className="form-label review-label">
                        Password
                      </label>
                      <div
                        className={
                          error && loginCredentials.password.length == 0
                            ? "review-group error"
                            : "review-group "
                        }
                      >
                        <input
                          className="form-control review-input"
                          type={passwordType}
                          placeholder="Enter Password"
                          name="password"
                          autoComplete="off"
                          value={loginCredentials.password}
                          onChange={handleChange}
                        />
                        <i
                          className="inp_ico"
                          id="pass-eye"
                          onClick={togglePassword}
                        >
                          {passwordType === "password" ? (
                            <Ai.AiOutlineEye />
                          ) : (
                            <Ai.AiOutlineEyeInvisible />
                          )}
                        </i>
                      </div>
                      {error && loginCredentials.password.length == 0 && (
                        <span className="error_">Password is required!</span>
                      )}
                    </div>
                    <br />
                    <button
                      className="fp-button"
                      disabled={authLoading}
                      style={{ width: "97%" }}
                    >
                      {!authLoading ? (
                        "Submit"
                      ) : (
                        <ThreeDots
                          height="60"
                          width="60"
                          radius="9"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          wrapperClassName="loader_"
                        />
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
