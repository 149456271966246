import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import Select from "react-select";

import * as Md from "react-icons/md";
import * as Tb from "react-icons/tb";
import * as Bs from "react-icons/bs";
import { AppContext } from "../../Context/AppContext";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

function EditPrivilege() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    allPrivilages,
    alljobRole,
    updatePrivileges,
    getallPrivilages,
    privilageLoading,
  } = useContext(AppContext);

  const [privilege, setPrivilege] = useState({
    previlageName: "",
    f_route: "",
    b_route: "",
    jobRoles: [],
  });
  const [singlejobRole, setSingleJobRole] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [selectedJob, setSelectedJob] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [error, setError] = useState(false);

  useEffect(() => {
    getPrivilegeById();
  }, [allPrivilages]);

  useEffect(() => {
    findSelectedjob();
  }, [privilege, alljobRole]);

  const getPrivilegeById = () => {
    if (!id) return;
    const privilegeData = allPrivilages.find((x) => x?.ppId == id);
    setPrivilege(privilegeData);
  };

  useEffect(() => {
    if (!alljobRole) return;
    hanldeJobRoles();
  }, [alljobRole]);

  const hanldeJobRoles = () => {
    const newJobRoles = alljobRole.map((jobs) => ({
      value: jobs.p_jbId,
      label: jobs.role,
    }));

    setSingleJobRole(newJobRoles);
  };


  const findSelectedjob = () => {
    if (!privilege || privilege.length === 0) return;
    const selected = privilege?.jobRoles;
    const jobRoles = [];

    selected.forEach((item) => {
      const foundJob = singlejobRole.find((x) => item === x.value);
      if (foundJob) {
        jobRoles.push(foundJob);
      }
    });

    setSelectedJob(jobRoles);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPrivilege((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onChangeJob = (item) => {
    setSelectedJob(item);
    setPrivilege((prev) => ({
      ...prev,
      jobRoles: [...item.map((e) => e.value)],
    }));
  };

  const handleValidation = () => {
    if (
      privilege.previlageName.length == 0 ||
      privilege.f_route.length == 0 ||
      privilege.b_route.length == 0 ||
      privilege.jobRoles.length == 0
    ) {
      setError(true);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidated = handleValidation();
    if (isValidated) return;

    // console.log(privilege);

    await updatePrivileges(privilege, {
      onSuccess: async (res) => {
        // console.log(res);
        toast.success("Privilege has been updated Successfully!");
        await getallPrivilages();

        setTimeout(() => {
          navigate("/all-privileges");
        }, 1200);
      },
      onFailed: (err) => {
        console.log(err);
        toast.error("Privilege is not updated, Please try again!");
      },
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <Sidebar>
          <div div className="container-fluid m-3">
            <h4 className="text-center">Privileges</h4>
            <p className="text-center">Add a Privilege </p>
          </div>
          <div className="container">
            <div className="card m-3 p-3 box mx-auto">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label htmlFor="Privilege Name" className="review-label">
                      Privilege Name
                    </label>
                    <div className="review-group ">
                      <input
                        type="text"
                        name="previlageName"
                        id=""
                        className="review-input"
                        value={privilege?.previlageName ?? "N/A"}
                        onChange={handleChange}
                      />
                      <i className="inp_ico">
                        <Bs.BsPersonCheck />
                      </i>
                    </div>
                    {error && privilege.previlageName.length == 0 && (
                      <span className="error_">
                        Privilege Name is required!
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label htmlFor="Front Route" className="review-label">
                      Front Route
                    </label>
                    <div className="review-group ">
                      <input
                        type="text"
                        name="f_route"
                        id=""
                        className="review-input"
                        value={privilege?.f_route ?? "N/A"}
                        onChange={handleChange}
                      />
                      <i className="inp_ico">
                        <Tb.TbDirections />
                      </i>
                    </div>
                    {error && privilege.f_route.length == 0 && (
                      <span className="error_">Front Route is required!</span>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                    <label htmlFor="Back Route" className="review-label">
                      Back Route
                    </label>
                    <div className="review-group ">
                      <input
                        type="text"
                        name="b_route"
                        id=""
                        className="review-input"
                        value={privilege?.b_route ?? "N/A"}
                        onChange={handleChange}
                      />
                      <i className="inp_ico">
                        <Tb.TbDirections />
                      </i>
                    </div>
                    {error && privilege.b_route.length == 0 && (
                      <span className="error_">Back Route is required!</span>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                    <label htmlFor="Privilege Name" className="review-label">
                      Department
                    </label>

                    <Select
                      options={singlejobRole}
                      value={selectedJob}
                      onChange={(item) => onChangeJob(item)}
                      isMulti
                    />
                    {error && privilege.jobRoles.length == 0 && (
                      <span className="error_">
                        Please Select a Department!
                      </span>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="fp-button add_privilege mt-4"
                  disabled={privilageLoading}
                >
                  {!privilageLoading ? (
                    "Submit"
                  ) : (
                    <ThreeDots
                      height="60"
                      width="60"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperClassName="loader_"
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
        </Sidebar>
      </div>
    </div>
  );
}

export default EditPrivilege;
