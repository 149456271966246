import React, { useContext } from "react";
import Lottie from "react-lottie";
import LottieFile from "../../Assets/lottie/timeout.json";
import "./Error.css";
import { AppContext } from "../../Context/AppContext";

function ErrorComponent(props) {
  const { setCookieError } = useContext(AppContext);
  const lottieOption = {
    loop: true,
    autoplay: true,
    animationData: LottieFile, 
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleToken = () => {
    setCookieError(null);
  };

  return (
    <>
      <div className="error_wrapper">
        <div className="error_content">
          <h1 className="text-center">{props.errorTitle}</h1>
          <div className="err-img">
            <Lottie options={lottieOption} width={300} height={250}></Lottie>
          </div>
          <div className="err_" id="err_msg_cont">
            <p>{props.errorMsg}</p>
          </div>
          <button className="err_btn" onClick={handleToken}>
            Login
          </button>
        </div>
      </div>
    </>
  );
}

export default ErrorComponent;
